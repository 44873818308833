import { doc, onSnapshot,collection, orderBy, query, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

export default function TakeTest() {
  const {courseId,lectureId} = useParams()
  const [uid,setuid] = useState("")
  const [status,setstatus] = useState("")
  const [orginalFullName,setorginalFullName] = useState("")

  const [loading,setloading] = useState(false)
  const [certificateTaken,setcertificateTaken] = useState()

  const [spinner,setspinner] = useState(true)
  const [users,setusers] = useState([])

  const [fullNameModal,setfullNameModal] = useState(false)

  const [emptyQuestionError,setemptyQuestionError] = useState(true)
  const [emptyQuestionErrorMessage,setemptyQuestionErrorMessage] = useState('')



  const [correct,setcorrect] = useState('correct')
  
  

  const [Test,setTest] = useState({})

  let [question,setquestion] = useState()
  const [course,setcourse] = useState([])
  const [lecture,setlecture] = useState([])
  const [lectures,setlectures] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLectureId,setCurrentLectureId] = useState("")
  const [AnswerError,setAnswerError] = useState("")
  const [fullName,setfullName] = useState("")
  const [fullNameError,setfullNameError] = useState("")
  const [accountDuplicateError,setaccountDuplicateError] = useState("")



  const [correctAnswer,setCorrectAnswer] = useState("")
  const [percentAnswer,setpercentAnswer] = useState("")
  const [incorrectIndices, setIncorrectIndices] = useState([]);
  const [lock, setlock] = useState(false);
  const [result, setresult] = useState(false);



  const [userAnswers, setUserAnswers] = useState([]);
  const [incorrectAnswers, setincorrectAnswers] = useState([]);

  let [questionIndex,setquestionIndex] = useState(0)


  const [selectedlecture,setSelectedLecture] = useState([])
  const [certificateId,setcertificateId] = useState('')


  const navigate = useNavigate()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);



  const [score, setScore] = useState(0);
  const scorePercentage = (score / Test.length) * 100;

 
const handleNextQuestion = () => {
  // Check if the answer is correct
  const currentQuestion = Test[currentQuestionIndex];
  const selectedAnswerIndex = answers[currentQuestionIndex];
  
  // Proceed only if the currentQuestion exists
  if (currentQuestion) {
    const selectedAnswer = currentQuestion.choices[selectedAnswerIndex];
    if (selectedAnswerIndex.toLowerCase() === currentQuestion.answer.toLowerCase()) {
      setScore((prevScore) => prevScore + 1);
    }

    // Proceed to the next question or finish the test
    if (currentQuestionIndex < Test.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      // Test completed
      setCurrentQuestionIndex(-1);
    }
  }
}
function fullNameSet(e){
  setfullName(e.target.value)
  if(e.target.value.length === 0){
    setfullNameError('error')
  }
  else{
    setfullNameError('')
  }
}
function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }
function desktopSpinnerHandler(){
setspinner(false)
}




    function loaderSet(){
      setloading(true)

    }



    // Profile Photo Click Handler
    const popProfile = () =>{
      const profile = document.getElementById("profileData");
      if(profile.style.display === ("block")){
          profile.style.display=("none")

      }
      else{
      profile.style.display=("block")


      }
  }

  // Notification Icon Click Handler

  const popNotification = () =>{
      const notification = document.getElementById("notification");
      if(notification.style.display === ("block")){
          notification.style.display=("none")

      }
      else{
      notification.style.display=("block")


      }
  }

  function openCertificate(){
  const certificateref = (doc(firestore,'AccountDetails',uid,'Certificate',courseId + uid))
  const mainCertificateref = (doc(firestore,'Certificate',courseId + uid))
  const courseRef = (doc(firestore,'Courses',courseId))


  let CertificateData = {
    user:uid,
    courseId:courseId,
    issuanceDate:serverTimestamp(),
    certificateId:courseId + uid,
    certificateStudentName:fullName
    
    }
    let data2 = {
    tookCertificate:true
      
      }
  if(certificateTaken == true){
    navigate(`${`/course/${courseId}/certificate/${certificateId}`}`)


  }
  else{
    if(fullName.length === 0){
      setfullNameError('error')
    }
    else if (fullName != orginalFullName){
      setaccountDuplicateError('error')

    }
    else{
        setDoc(certificateref,CertificateData,{merge:true}).then(
          setDoc(mainCertificateref,CertificateData,{merge:true})
        ).then(
          setDoc(courseRef,data2,{merge:true})
        ).then(
          navigate(`${`/course/${courseId}/certificate/${certificateId}`}`)
        )

    }
  }
  }

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       
  onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
    let temp = []
    temp.push({...snapshot.data()})
    setorginalFullName(snapshot.data().fullName)
    setusers(temp)
  })
  
  const certificateRef = doc(firestore,'AccountDetails',uid,'Certificate',courseId + uid )
  onSnapshot(certificateRef,doc=>{

            if(doc.data() && doc.data().certificateId.length !=0){
              setcertificateTaken(true)
            }
          
            
  })
            
    const documentRef = collection(firestore,"Courses",courseId,"lectures")
    onSnapshot(documentRef,snapshot=>{
      let main =[]
      snapshot.docs.forEach(doc=>{
              main.push({...doc.data()})
              setlecture(main)
            
  
      })
           
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
      let main =[]
      
      main.push({...snapshot.data()})
      setcourse(main)
      setcertificateId(courseId + uid)
  
    })
  
 
  
 

    
    })



              
   
    const lecturesRef = collection(firestore,"Courses",courseId,"lectures",courseId,"lectures");
  const q = query(lecturesRef, orderBy('createdTime'));
  
  onSnapshot(q,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      setlectures(main)
    })

    

    
    
  

  })
  console.log(Test)
  

  const lectureRef = doc(firestore,"Courses",courseId,"lectures",lectureId);
  
  onSnapshot(lectureRef,snapshot=>{
    let main =[]
    main.push({...snapshot.data(),id:snapshot.id})
    setSelectedLecture(main)
  
  })
        
  const TestRef = doc(firestore,"Courses",courseId,"Test",courseId);
  
  onSnapshot(TestRef,snapshot=>{
    let main =[]
      if(snapshot.exists()){
        console.log(snapshot.data())

      main.push({...snapshot.data(),id:snapshot.id})
      main.forEach((item,index) => {
        setTest(item.question)
        setquestion(item.question)
setquestion(item.question[questionIndex])
        
      
        
      });

    }
   
   
  
  
  })

           
 
//    })
  
            
  
  
  
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, [lectureId,questionIndex]);

  function nextLecture(){
    const documentRef = doc(firestore, "AccountDetails", uid, "Enrollments", courseId, "lectures", lectureId, "Test", lectureId);

    setDoc()
  }


const checkAnswer = (e,ans) =>{
  setemptyQuestionError(false)


if(lock == false){
  
  if(question.answer.toLowerCase() == ans.toLowerCase()){
    e.target.classList.add('correct')
    setlock(true)
    setScore(prev=>(prev + 1))
      }
      else{
    e.target.classList.add('incorrect')
    setlock(true)
    
    
      }
    }

}
function closeTestErrorMessage(){
  setemptyQuestionErrorMessage('')
}
 const  next = (instructorId)=>{
  const ref = (doc(firestore,'AccountDetails',uid,'Courses',courseId))
  const ref2 = (doc(firestore,'AccountDetails',instructorId,'Students',uid))
  const ref3 = (doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId,'Students',uid))

  window.scrollTo(0, 0);
if(emptyQuestionError == true){
  setemptyQuestionErrorMessage('An empty answer cannot be submitted')
}  

    let data = {
        progress:{
          finalTest:{
            result:scorePercentage + '%',
            score:score,
            totalQuestion:Test.length,
            completedTime:serverTimestamp(),
            tookTest:true,
          }
        }
      }

      

      let data2 = {
        progress:{
          [courseId]:{
            finalTest:{
              score:score,
              result:scorePercentage,
              totalQuestions:Test.length,
              completedTime:serverTimestamp(),
              tookTest:true,
            }
       
          }
        }
      }
    
      let data3 = {
        progress:{
            finalTest:{
              score:score,
              tookTest:true,
              result:scorePercentage,
              totalQuestions:Test.length,
              completedTime:serverTimestamp()
            }
       
        }
      }


  
  if(lock == true){
    if(questionIndex == Test.length - 1){
      setresult(true)
      setquestionIndex(0)
      if(75 < scorePercentage){
    
          setDoc(ref,data,{merge:true}).then(
            updateDoc(ref, {
                completed:true
            },{merge:true})
          ) .then(
            setDoc(ref2,data2,{merge:true})
          ).then(
            setDoc(ref3,data3,{merge:true}),

          ).then(
            setfullNameModal(true)
          )
        
      }

      else{
        setDoc(ref,data,{merge:true}).then(
            updateDoc(ref, {
                completed:true
            },{merge:true})
          ) .then(
            setDoc(ref2,data2,{merge:true})
          ).then(
            setDoc(ref3,data3,{merge:true}),

          )


      }
    }
    else{
        setquestionIndex(++questionIndex)
            setemptyQuestionErrorMessage('')
            setemptyQuestionError(true)

    setlock(false)
    const elementsToRemoveClasses = document.querySelectorAll('.incorrect, .correct');
 // Remove both classes using a loop
 for (const element of elementsToRemoveClasses) {
  element.classList.remove('incorrect', 'correct');
}
    }
    
  
   
  }
  
}
const handlePreviousLecture = () => {
  const currentIndex = lectures.findIndex((lecture) => lecture.id === currentLectureId);

  if (currentIndex > 0) {
    const previousLecture = lectures[currentIndex - 1];
    const previousLectureId = previousLecture.id;

    navigate(`/course/${courseId}/preview/${previousLectureId}`);

    // Update the current lecture ID or perform any other necessary actions
    setCurrentLectureId(previousLectureId);
  } else {
    console.log("No previous lectures");
  }
};



  function resetVideoDesktop(currentIndex){
    setSelectedLecture([])
    const lecture = lectures[currentIndex]

    navigate(`/course/${courseId}/preview/${lecture.id}`)
  }

  function hideMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const open = document.getElementById("openDesktop")
    const body = document.getElementById("lectureBody")

    menu.style.width="0"
    hide.style.display="none"
    open.style.display="flex"

    body.style.width="100%"

    
  }
  
  const handleRestartTest = () => {
    setCurrentQuestionIndex(0);
    setAnswers([]);
    setScore(0);
  };


  function answerSet(e,index){
    const lowercaseValue = e.target.value.toLowerCase();
    if (lowercaseValue !== "a" && lowercaseValue !== "b" && lowercaseValue !== "c" && lowercaseValue !== "d") {
      setAnswerError("error");
    } else {
      setAnswerError("");
    

    }
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = e.target.value;
    setUserAnswers(updatedAnswers);
  }

  function openMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const body = document.getElementById("lectureBody")

    const open = document.getElementById("openDesktop")
    hide.style.display="flex"
    open.style.display="none"

    body.style.width="75%"
    
    menu.style.width="25%"
  }

  function TestPopDesktop(){
    const video = document.getElementById("courseVideoDesktop")

    video.style.display="none"



  }

  const submitTestDesktop = () => {
    const result = document.getElementById("resultDesktop");
    const loading = document.getElementById("resultLoadingDesktop");
    const body = document.getElementById("lectureBodyDesktop");
    const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId, "Test", lectureId);
    const documentRef3 = doc(firestore, "Courses", courseId, "lectures",lectureId, "Test", lectureId);
  
  
    
    let correctCount = 0;
    const incorrectIndices = []; // Array to store indices of incorrect answers
  
    for (let index = 0; index < Test.length; index++) {
      const userAnswer = userAnswers[index];
      const correctAnswer = Test[index].answer;
  
      if (userAnswer === correctAnswer) {
        correctCount++;
      } else {
        incorrectIndices.push(index + 1); // Store index of incorrect answer
      }
    }
  
    const percentage = (correctCount / Test.length) * 100;
    setCorrectAnswer(correctCount);
    setpercentAnswer(percentage);
    setIncorrectIndices(incorrectIndices); // Set the state variable for incorrect indices
    
     const lectureData = {
         passed: status,
       };

       if(percentage < 75){
        setDoc(documentRef, {
            passed: false,
        }, { merge: true }).then(
            setDoc(documentRef3, {
                passed: false,
              }, { merge: true })
        ).then(
            setTimeout(() => {
                body.style.display = "none";
                loading.style.display = "flex";
                setincorrectAnswers(incorrectIndices)
            
                setTimeout(() => {
                  loading.style.display = "none";
                  result.style.display = "flex";
                }, 1000);
              }, 500)
        )
    


     }
     else{
        setDoc(documentRef, {
            passed: true,
        }, { merge: true }
        ).then(
            setDoc(documentRef3, {
                passed: true,
            }, { merge: true })
        )
     .then(
            setTimeout(() => {
                body.style.display = "none";
                loading.style.display = "flex";
                setincorrectAnswers(incorrectIndices)
            
                setTimeout(() => {
                  loading.style.display = "none";
                  result.style.display = "flex";
                }, 1000);
              }, 500)
        )
     }

       



   
  };

  

  function retake(){
  setquestionIndex(0)
      setresult(false)
      setlock(false)
      setScore(0)
   
  }

 const handleAnswer = (answerIndex) => {
    const answer = convertIndexToChoice(answerIndex);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = answer;
      return updatedAnswers;
    });
  };

  const convertIndexToChoice = (index) => {
    const choices = ['A', 'B', 'C', 'D'];
    return choices[index];
  };

  return (
    users.map(i=>{
      const {fullName,accountType} = i
      return(
        accountType === 'Student' ?
          question  ?
          <div>
                    <div id='desktop'  className='relative'>
                    <div id='stickynav' className='border-r border-b px-1'>
                    <div className='w-full '>
                      <Link to="/dashboard">
                    <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 
      
                    </Link>
                    </div>
         
      
      <div className="flex flex-col justify-center " >
      <div className="  ">
            <nav aria-label="Main Nav" className="flex flex-col ">
      
      
      
      
          
        
      
      
      
      
              
      
              <ul className="space-y-1 border-t border-gray-100 pt-4">
                
      <li>
      
      
      
        
      
      
        
      <Link to="/achievements"
                    className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      
      
      >
      <button
                    className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    
                  >
              
      <img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />
      
          
                  </button>
              </Link>
      <Link to="/courses"
                   className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      
      
      >
      <button
                   className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                   
                 >
             
      <img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />
      
         
                 </button>
             </Link>
      
      
      
                
               
               </li>
              <Link to="/enrolledcourses"
                    className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    >
         <li>
                  <button
                    className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      
                  >
        
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
      </svg>
               
                  </button>
                </li>
      
      </Link>
      
      
                <li>
      
      
      
        
              
      
      
      
         
      
      
              
       
      
      
           
                 
                
                </li>
      
                <li>
      
      <Link to="/transactions"
                    className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    >
                  <button
                    className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    
                  >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
      </svg>
      
                
                  </button>
                </Link>
                </li>
      
      
      
      <li>
      
      
      
        
      
      
        
              
       
      
      
      
                 
                
                </li>
              </ul>
            </nav>
          </div>
       
      
        <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
          <div >
            <button
             onClick={handleLogOut}
              className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 opacity-75"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
      
              <span 
               style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
              >
                Logout
              </span>
            </button>
          </div>
        </div>
      </div>
      </div>
      
      <div  className='flex flex-col items-center justify-center h-full backLinear ' >
      
      {
        course.length !=0 ?
      course.map(i=>{
        const {instructorId,certificate} = i
        return(
          (certificate === true && fullNameModal === true) ?
          certificateTaken === true ?
          <div className='bg-white rounded py-8 px-10 my-10 flex justify-center items-center flex-col' style={{width:'55%',border:'#ff4a4a'}}>
    
     {
          
      <button onClick={openCertificate} style={{fontSize:"15px"}}  className="bg-blue-800  fjalla  w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Open Certificate
                                        </button>
                                       
      }
      
      
          </div>:

          <div className='bg-white rounded py-8 px-10 my-10 flex justify-center items-center flex-col' style={{width:'55%',border:'#ff4a4a'}}>
          <h1 className=' text-center text-gray-900 capitalize fjalla' style={{fontSize:"20px"}}> Enter your full legal name as it will appear on your certificate 
      </h1>
        <p className='borel text-gray-500' style={{fontSize:"11px"}}> The name you choose cannot be changed again</p>
          
        <div className="relative my-10 mx-auto" style={{width:'90%'}}>
      
      <p style={{fontSize:'12px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-2 font-medium text-gray-600
         absolute fjalla">Full Name</p>
      <input style={{height:'70px',fontSize:'12px'}} required  onChange={e=>fullNameSet(e)} placeholder={fullName} type="text" 
      className={`${"borel  border input-field-signUp placeholder-gray-400 focus:outline-none focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white border-gray-300 rounded-md" }
      
      `}/>
      <div className={`${fullNameError === 'error'? "block":"hidden"}`} style={{margin:'auto'}}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Full Name can't be empty
    </div>
    </div>
    </div>
      {
        accountDuplicateError &&
        <div  style={{margin:'auto'}}>
        <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
         <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
         The full name on your account must match the full name on your account. If you wish to change it, you can do so in your profile
        </div>
        </div>
        </div>
      }
      </div>{
          
      <button onClick={openCertificate} style={{fontSize:"15px"}}  className="bg-blue-800 fjalla  w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Open Certificate
                                        </button>
                                       
      }
      
      
          </div>
          :
      
          result == true ?
          75 < scorePercentage ?
          <div className='bg-white rounded py-8 px-10 my-10 flex justify-center items-center flex-col' style={{width:'55%',border:'#ff4a4a'}}>
          <h1 className=' text-center text-gray-900 capitalize fjalla' style={{fontSize:"20px"}}> You have successfully completed the course, congratulations 
      </h1>
        <p className='borel text-gray-500' style={{fontSize:"11px"}}> You have passed the Test with a score of {score} out of {Test.length}</p>
          
          <img style={{width:'50%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
      {
        certificate == true ?
        <Link to={`/course/${courseId}/certificate/${uid}`} className='w-full'>
          
      <button style={{fontSize:"15px"}}  className="bg-blue-800 fjalla  w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">View Certificate
                                        </button></Link>
                                        :
                                        <Link to={`/dashboard`} className='w-full'>
          
      <button style={{fontSize:"15px"}}  className="bg-blue-800 fjalla  w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Dashboard
                                        </button></Link>
      }
      
      
          </div>
          : 
      
          <div className='rounded py-8 px-10 flex justify-center items-center flex-col my-10' style={{width:'55%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
          <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"35px"}}> You failed the Test with a score of {score} out of {Test.length}
          </h1>
          <p className='fjalla text-gray-500'>In order to complete this course, you must score at least 75%. </p>
      
         
          <img onLoad={loaderSet}  style={{width:'50%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />
      
          <button onClick={(e)=>retake(e)}   className="bg-red-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize">Retake Test
                                        </button>
          </div>
          :
          <div className='bg-white rounded py-8 px-10 my-10' style={{width:'55%',height:'90%',border:'#ff4a4a'}}>
          {
        emptyQuestionErrorMessage.length != 0 &&  emptyQuestionError == true  &&
      
          <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border border-red-300 bg-red-50 rounded-xl" role="alert">
          <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <div class="ml-3 text-sm font-medium Mooli ">
          {emptyQuestionErrorMessage}
          </div>
          <button onClick={closeTestErrorMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
            <span class="sr-only">Dismiss</span>
            <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>
      }
           <div className='flex justify-between'>
           <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Test</h1><hr />
              <p className='fjalla'>{questionIndex + 1} of {Test.length} questions </p>
           </div>
      
              <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {question && question.input}</p>
              <ul className='ulChoice'>
                
                <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{question && question.choices[0]}</li>
                <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{question && question.choices[1]}</li>
                <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{question && question.choices[2]}</li>
                <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{question && question.choices[3]}</li>
      
      
              </ul>
              <button onClick={(e)=>next(instructorId)} style={{fontSize:'15px'}}   className="bg-blue-800 fjalla  w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                            rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                                        </button>
      
      
        </div>
        )
      })
      :
      <div>
        no course 
      </div>
       }
      </div>
          </div>
          </div>
          :
          <div>
          <div class="h-screen  flex items-center">
      <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
              <div class="w-full lg:w-1/2 mx-8">
                  <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
              <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                  Sorry we couldn't find the page you're looking for
              </p>
              <Link to={`/dashboard`}>
              <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 capitalize">Back to My Test</a>
      
              </Link>
      </div>
          <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
          <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
          </div>
      
      </div>
      </div>
          </div> 
        :  
        navigate('/dashboard')

      )
    })

  )
}
