import { doc, onSnapshot,collection, orderBy, query, setDoc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';

export default function TakeQuiz() {
  const {courseId,lectureId} = useParams()
  const [uid,setuid] = useState("")
  const [status,setstatus] = useState("")
  const [loading,setloading] = useState(false)
  const [spinner,setspinner] = useState(false)
  const [isLoading,setisLoading] = useState(true)
  const [notificationPop,setnotificationPop] = useState(false)
  const [pop,setpop] = useState(false)

  const [nextLectureId,setNextLectureId] = useState("")

  const [correct,setcorrect] = useState('correct')
  const [emptyAnswerError,setemptyAnswerError] = useState(true)
  const [emptyAnswerErrorMessage,setemptyAnswerErrorMessage] = useState('')
  

  const [quiz,setQuiz] = useState({})

  let [question,setquestion] = useState()
  const [course,setcourse] = useState([])
  const [lecture,setlecture] = useState([])
  const [lectures,setlectures] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLectureId,setCurrentLectureId] = useState("")
  const [AnswerError,setAnswerError] = useState("")
  const [correctAnswer,setCorrectAnswer] = useState("")
  const [percentAnswer,setpercentAnswer] = useState("")
  const [incorrectIndices, setIncorrectIndices] = useState([]);
  const [lock, setlock] = useState(false);
  const [result, setresult] = useState(false);



  const [userAnswers, setUserAnswers] = useState([]);
  const [incorrectAnswers, setincorrectAnswers] = useState([]);

  let [questionIndex,setquestionIndex] = useState(0)


  const [selectedlecture,setSelectedLecture] = useState([])

  const navigate = useNavigate()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);



  const [score, setScore] = useState(0);
  const scorePercentage = (score / quiz.length) * 100;

 
const handleNextQuestion = () => {
  // Check if the answer is correct
  const currentQuestion = quiz[currentQuestionIndex];
  const selectedAnswerIndex = answers[currentQuestionIndex];
  
  // Proceed only if the currentQuestion exists
  if (currentQuestion) {
    const selectedAnswer = currentQuestion.choices[selectedAnswerIndex];
    if (selectedAnswerIndex.toLowerCase() === currentQuestion.answer.toLowerCase()) {
      setScore((prevScore) => prevScore + 1);
    }

    // Proceed to the next question or finish the quiz
    if (currentQuestionIndex < quiz.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      // Quiz completed
      setCurrentQuestionIndex(-1);
    }
  }
}

  



    function loaderSet(){
      setloading(true)

    }



    const popProfile = () =>{
      if(pop == true){
        setpop(false)
        setnotificationPop(false)
  
      }
      else{
      setpop(true)
      setnotificationPop(false)
  
  
      }
  }
  const popNotification = () =>{
    if(notificationPop == true){
      setnotificationPop(false)
      setpop(false)
    }
    else{
    setnotificationPop(true)
    setpop(false)
  
  
  
    }
  }
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       
  
    const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
           
        const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
        onSnapshot(lecturesCollectionQuery, (snapshot) => {
          let main = [];
          snapshot.docs.forEach(doc => {
            main.push({ ...doc.data() });
          });
        
          const currentLectureIndex = main.findIndex(lecture => lecture.lectureId === lectureId);
          if (currentLectureIndex !== -1 && currentLectureIndex < main.length - 1) {
            const nextLecture = main[currentLectureIndex + 1];
            const nextLectureId = nextLecture?.lectureId;
            setNextLectureId(nextLectureId);
          } else {
            setNextLectureId(null); // No more lectures, set nextLectureId to null
          }
        });

            
    const documentRef = collection(firestore,"Courses",courseId,"lectures")
    onSnapshot(documentRef,snapshot=>{
      let main =[]
      snapshot.docs.forEach(doc=>{
              main.push({...doc.data()})
              setlecture(main)
            
  
      })
           
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
      let main =[]
      
      main.push({...snapshot.data()})
      setcourse(main)
  
    })
  
 
  
 

    
    })



              
   
    const lecturesRef = collection(firestore,"Courses",courseId,"lectures",courseId,"lectures");
  const q = query(lecturesRef, orderBy('createdTime'));
  
  onSnapshot(q,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      setlectures(main)
    })

    

    
    
  

  })
  

  const lectureRef = doc(firestore,"Courses",courseId,"lectures",lectureId);
  
  onSnapshot(lectureRef,snapshot=>{
    let main =[]
    main.push({...snapshot.data(),id:snapshot.id})
    setSelectedLecture(main)
  
  })
        
  const quizRef = collection(firestore,"Courses",courseId,"lectures",lectureId,"quiz");
  
  onSnapshot(quizRef,snapshot=>{
    let main =[]
    snapshot.forEach(doc=>{
      if(doc.exists()){

      main.push({...doc.data(),id:doc.id})
      main.forEach((item,index) => {
        setQuiz(item.question)
        setquestion(item.question)
setquestion(item.question[questionIndex])
        setTimeout(() => {
      setisLoading(false)
          
        }, 1000);
      
        
      });

    }
   
    })
   
  
  
  })

           
 
//    })
  
            
  
  
  
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, [lectureId,questionIndex]);

  function nextLecture(){
    const documentRef = doc(firestore, "AccountDetails", uid, "Enrollments", courseId, "lectures", lectureId, "quiz", lectureId);

    setDoc()
  }

  function closeTestErrorMessage(){
    setemptyAnswerErrorMessage('')
  }
const checkAnswer = (e,ans) =>{
  setemptyAnswerError(false)

if(lock == false){
  
  if(question.answer.toLowerCase() == ans.toLowerCase()){
    e.target.classList.add('correct')
    setlock(true)
    setScore(prev=>(prev + 1))
      }
      else{
    e.target.classList.add('incorrect')
    setlock(true)
    
    
      }
}

}

const next = (instructorId)=>{
  const ref = (doc(firestore,'AccountDetails',uid,'Courses',courseId))
  const ref2 = (doc(firestore,'AccountDetails',instructorId,'Students',uid))
  const ref3 = (doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId,'Students',uid))


  

  window.scrollTo(0, 0);
if(emptyAnswerError == true){
  setemptyAnswerErrorMessage('An empty answer cannot be submitted')
}  
   

      let data2 = {
        progress:{
          quizResults:{
            [lectureId]:{
              score:score,
              result:scorePercentage,
              totalQuestions:quiz.length,
              completedTime:serverTimestamp()
            }
       
          }
        }
      }
    
    
  if(lock == true){
    if(questionIndex == quiz.length - 1){
      setresult(true)
      setquestionIndex(0)
    if(75 < scorePercentage){
    
      setDoc(ref,data2,{merge:true})  .then(
        setDoc(ref2,data2,{merge:true})
      ).then(
        setDoc(ref3,data2,{merge:true})

      ).then(
        getDoc(ref).then(doc => {
          const currentProgress = doc.data().progress || {};
      
          // Update the progress object with the completed lecture
          currentProgress[lectureId] = {
            status: 'completed',
            completedTime: serverTimestamp()
          };
      
          const lecturesTotal = Object.keys(currentProgress);
          const totalCompletedLectures = lecturesTotal.filter(lecture => currentProgress[lecture].status === 'completed').length;
      
          let dataProgress = {
            progress: currentProgress,
            progressPercentage: (totalCompletedLectures / lectures.length) * 100
          };
      
          let dataProgress2 = {
            progress: {
              [courseId]: currentProgress
            }
          };
      
          let dataProgress3 = {
            progress: {
              [lectureId]: {
                status: 'completed',
                completedTime: serverTimestamp()
              }
            }
          };
      
          setDoc(ref, dataProgress, { merge: true }).then(() => {
            setDoc(ref3, dataProgress3, { merge: true }).then(() => {
              setDoc(ref2, dataProgress2, { merge: true });
            });
          });
        })
      )
    
  }
  else{
    
    setDoc(ref,data2,{merge:true}) .then(
      setDoc(ref2,data2,{merge:true})
    ).then(
      setDoc(ref3,data2,{merge:true})

    )
  }
  }
  else{
    setquestionIndex(++questionIndex)
    setemptyAnswerErrorMessage('')
            setemptyAnswerError(true)
setlock(false)
const elementsToRemoveClasses = document.querySelectorAll('.incorrect, .correct');
// Remove both classes using a loop
for (const element of elementsToRemoveClasses) {
element.classList.remove('incorrect', 'correct');
}
}
  
   
  }
  
}
const handlePreviousLecture = () => {
  const currentIndex = lectures.findIndex((lecture) => lecture.id === currentLectureId);

  if (currentIndex > 0) {
    const previousLecture = lectures[currentIndex - 1];
    const previousLectureId = previousLecture.id;

    navigate(`/course/${courseId}/preview/${previousLectureId}`);

    // Update the current lecture ID or perform any other necessary actions
    setCurrentLectureId(previousLectureId);
  } else {
    console.log("No previous lectures");
  }
};



  function resetVideoDesktop(currentIndex){
    setSelectedLecture([])
    const lecture = lectures[currentIndex]

    navigate(`/course/${courseId}/preview/${lecture.id}`)
  }

  function hideMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const open = document.getElementById("openDesktop")
    const body = document.getElementById("lectureBody")

    menu.style.width="0"
    hide.style.display="none"
    open.style.display="flex"

    body.style.width="100%"

    
  }
  
  const handleRestartQuiz = () => {
    setCurrentQuestionIndex(0);
    setAnswers([]);
    setScore(0);
  };


  function answerSet(e,index){
    const lowercaseValue = e.target.value.toLowerCase();
    if (lowercaseValue !== "a" && lowercaseValue !== "b" && lowercaseValue !== "c" && lowercaseValue !== "d") {
      setAnswerError("error");
    } else {
      setAnswerError("");
    

    }
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = e.target.value;
    setUserAnswers(updatedAnswers);
  }

  function openMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const body = document.getElementById("lectureBody")

    const open = document.getElementById("openDesktop")
    hide.style.display="flex"
    open.style.display="none"

    body.style.width="60%"
    
    menu.style.width="25%"
  }

  function quizPopDesktop(){
    const video = document.getElementById("courseVideoDesktop")

    video.style.display="none"



  }

  const submitQuizDesktop = () => {
    const result = document.getElementById("resultDesktop");
    const loading = document.getElementById("resultLoadingDesktop");
    const body = document.getElementById("lectureBodyDesktop");
    const documentRef = doc(firestore, "AccountDetails", uid, "CoursesCreated", courseId, "lectures", lectureId, "quiz", lectureId);
    const documentRef3 = doc(firestore, "Courses", courseId, "lectures",lectureId, "quiz", lectureId);
  
  
    
    let correctCount = 0;
    const incorrectIndices = []; // Array to store indices of incorrect answers
  
    for (let index = 0; index < quiz.length; index++) {
      const userAnswer = userAnswers[index];
      const correctAnswer = quiz[index].answer;
  
      if (userAnswer === correctAnswer) {
        correctCount++;
      } else {
        incorrectIndices.push(index + 1); // Store index of incorrect answer
      }
    }
  
    const percentage = (correctCount / quiz.length) * 100;
    setCorrectAnswer(correctCount);
    setpercentAnswer(percentage);
    setIncorrectIndices(incorrectIndices); // Set the state variable for incorrect indices
    
     const lectureData = {
         passed: status,
       };

       if(percentage < 75){
        setDoc(documentRef, {
            passed: false,
        }, { merge: true }).then(
            setDoc(documentRef3, {
                passed: false,
              }, { merge: true })
        ).then(
            setTimeout(() => {
                body.style.display = "none";
                loading.style.display = "flex";
                setincorrectAnswers(incorrectIndices)
            
                setTimeout(() => {
                  loading.style.display = "none";
                  result.style.display = "flex";
                }, 1000);
              }, 500)
        )
    


     }
     else{
        setDoc(documentRef, {
            passed: true,
        }, { merge: true }
        ).then(
            setDoc(documentRef3, {
                passed: true,
            }, { merge: true })
        )
     .then(
            setTimeout(() => {
                body.style.display = "none";
                loading.style.display = "flex";
                setincorrectAnswers(incorrectIndices)
            
                setTimeout(() => {
                  loading.style.display = "none";
                  result.style.display = "flex";
                }, 1000);
              }, 500)
        )
     }

       



   
  };

  

  function retake(){
  
      setresult(false)
      setlock(false)
      setquestionIndex(0)
      setScore(0)
   
  }
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
function desktopSpinnerHandler(){
  setspinner(false)
 }
 const handleAnswer = (answerIndex) => {
    const answer = convertIndexToChoice(answerIndex);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = answer;
      return updatedAnswers;
    });
  };

  const convertIndexToChoice = (index) => {
    const choices = ['A', 'B', 'C', 'D'];
    return choices[index];
  };

  return (
      isLoading  ?
  <div className='flex items-center justify-center h-full flex-col' style={{height:'100vh'}}>
  <h1 className='anton'>Loading...</h1>
  <p className='borel'>Fetching data</p>
</div>
:

    question  ?
 
    
    <div>
              <div id='desktop'  className='relative'>
              <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>

<div  className='flex items-center justify-center h-full backLinear' >

{

  course.length !=0 ?
course.map(i=>{
  const {courseTitle,instructorId} = i
  return(
    result == true ?
    75 < scorePercentage ?
   
    

    <div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col my-12' style={{width:'60%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
    <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}>You have successfully completed the lecture, congratulations 
 </h1>
  <p className='fjalla' style={{fontSize:"12px"}}> You have passed the quiz with a score of {score} out of {quiz.length}</p>
    
    <img style={{width:'50%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
<Link to={`/course/${courseId}/lecture/${nextLectureId}`}>
<button   style={{fontSize:"15px"}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next Lecture
                                  </button></Link>
 
    </div>
    
         :
         <div className='rounded py-8 px-5 flex justify-center items-center flex-col my-12' style={{width:'60%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
         <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}> You failed the Quiz with a score of {score} out of {quiz.length}
         </h1>
         <p className='fjalla text-gray-500' style={{fontSize:"12px"}}>In order to complete this lecture, you must score at least 60%. </p>
      
        
         <img onLoad={loaderSet}  style={{width:'50%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />

         <button onClick={(e)=>retake(e)}  style={{fontSize:'12px'}} className="bg-red-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                           rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize fjalla">Retake Test
                                       </button>
         </div>
    :
    <div className='bg-white rounded py-8 px-10 my-12' style={{width:'60%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
     {
  emptyAnswerErrorMessage.length != 0 &&  emptyAnswerError == true  &&

    <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border border-red-300 bg-red-50 rounded-xl" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli ">
    {emptyAnswerErrorMessage}
    </div>
    <button onClick={closeTestErrorMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
  </div>
}
     <div className='flex justify-between'>
     <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Quiz</h1><hr />
        <p className='fjalla'>{questionIndex + 1} of {quiz.length} questions </p>
     </div>

        <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {question && question.input}</p>
        <ul className='ulChoice'>
          
          <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{question && question.choices[0]}</li>
          <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{question && question.choices[1]}</li>
          <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{question && question.choices[2]}</li>
          <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{question && question.choices[3]}</li>


        </ul>
        <button onClick={(e)=>next(instructorId)} style={{fontSize:'15px'}}   className="fjalla bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                                  </button>


  </div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
    </div>
    <div id='tablet'  className='relative'>
    <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' width="18" height="18" style={{objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>


  
        
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>

<div id='mainPage' className='flex items-center justify-center h-full backLinear' >

{

  course.length !=0 ?
course.map(i=>{
  const {courseTitle,instructorId} = i
  return(
    result == true ?
    75 < scorePercentage ?
   
    

<div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col my-12 w-full' style={{width:'75%',height:'90%'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}>You have successfully completed the course, congratulations 
</h1>
<p className='fjalla' style={{fontSize:"12px"}}> You have passed the Test with a score of {score} out of {quiz.length}</p>

<img style={{width:'60%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
<Link to={`/course/${courseId}/lecture/${nextLectureId}`} style={{width:'90%'}}>

<button   style={{fontSize:"13px",}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next Lecture
                              </button></Link>

</div>
: 

<div className='rounded py-8 px-5 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}> You failed the Quiz with a score of {score} out of {quiz.length}
</h1>
<p className='fjalla text-gray-500' style={{fontSize:"12px"}}>In order to complete this lecture, you must score at least 75%. </p>


<img onLoad={loaderSet}  style={{width:'60%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />

<button onClick={(e)=>retake(e)}  style={{fontSize:'12px'}} className="bg-red-800 w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize fjalla">Retake Test
                              </button>
</div>
                        
:
<div className='bg-white rounded py-8 px-10 my-12' style={{width:'75%',height:'90%'}}>
 <div className='flex justify-between'>
 <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Test</h1><hr />
    <p className='fjalla'>{questionIndex + 1} of {quiz.length.length} questions </p>
 </div>

    <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {question && question.input}</p>
    <ul className='ulChoice'>
      
    <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{question && question.choices[0]}</li>
          <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{question && question.choices[1]}</li>
          <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{question && question.choices[2]}</li>
          <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{question && question.choices[3]}</li>


    </ul>

    <button onClick={(e)=>next(instructorId)}   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg fjalla transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                              </button>


</div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
    </div>

    <div id='mobile'  className='relative'>
    <div  className='flex flex-col  justify-center h-full backLinear w-full'  >
    <nav className=' flex justify-between navMobile  w-full'>
<Link to="/dashboard"  style={{width:'22%'}}>
      <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht_.__2_-removebg-preview.png?alt=media&token=17e900e3-c5a4-43ad-aac9-4b380ae7d27e' alt="logo" /> 
    </Link>
                <div   className=' flex items-center'>




                <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"9vw"}} fill="currentColor" class="bi bi-list text-gray-800" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
                </div>




</nav>
    {
      pop == true &&
      <div id='popoutMobileHome' className='relative '>
      <nav className='shadow flex justify-between navMobile border-b w-full'>
            <div   className=' 'style={{width:'28%'}}>
   
   <svg  onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"8vw"}} fill="currentColor" class="bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
   </svg>
            </div>
   
   
   <Link to="/dashboard" onClick={popProfile} style={{width:'19%'}}>
   <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
   </Link>
   
   
   <div   className='flex justify-end ' style={{width:'33.33%'}}>
   <div   className=' '>
   
   <Link to='/courses' >
   <button type='submit' style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw"}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
            rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer">Courses
   </button>
   </Link>
   </div>
   
   
   
   </div>
   
   </nav>
   
   
   
   <ul className='w-full'>
           
                  <li className=' text-gray-800 '><Link to="/achievements">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Achievements </div>
      
   <img style={{width:"5vw"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />
   
                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/enrolledcourses">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Enrolled Courses </div>
   
   
   <svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
   <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
   </svg>
                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/transactions">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Transactions </div>
    
   
   <svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
   </svg>
   
                        </div>
                  </Link></li>
                
                  <li className=' text-gray-800 '>
                   <Link to='/notification'>
   
                  <div  style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                   <div className='borel 'style={{fontSize:"3vw"}}>Notifications </div>
                        <svg xmlns="http://www.w3.org/2000/svg" class=" text-gray-800"style={{width:"5vw"}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
      </svg>
      </div>
   
      </Link>
                  </li>
                  <li onClick={handleLogOut}className=' text-gray-800 '>
                  <div style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='borel' style={{fontSize:"3vw"}}>Log out </div>
                     
                        <svg  className='' xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
    <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
   </svg>
                        </div>
                  </li>
         
        
   
   
              </ul>
   </div>
    }
<div  className='flex flex-col items-center justify-center h-full  w-full'  >

{

  course.length !=0 ?
course.map(i=>{
  const {courseTitle,instructorId} = i
  return(
    result == true ?
    75 < scorePercentage ?
   
    

<div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col my-12 w-full' style={{width:'75%',height:'90%'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}>You have successfully completed the course, congratulations 
</h1>
<p className='fjalla' style={{fontSize:"12px"}}> You have passed the Test with a score of {score} out of {quiz.length}</p>

<img style={{width:'60%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
<Link to={`/course/${courseId}/lecture/${nextLectureId}`} style={{width:'90%'}}>

<button   style={{fontSize:"13px",}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next Lecture
                              </button></Link>

</div>
: 

<div className='rounded py-8 px-5 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}> You failed the Quiz with a score of {score} out of {quiz.length}
</h1>
<p className='fjalla text-gray-500' style={{fontSize:"12px"}}>In order to complete this lecture, you must score at least 75%. </p>


<img onLoad={loaderSet}  style={{width:'60%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />

<button onClick={(e)=>retake(e)}  style={{fontSize:'12px'}} className="bg-red-800 w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize fjalla">Retake Test
                              </button>
</div>
                        
:
<div className='bg-white rounded py-8 px-10 my-12' style={{width:'75%',height:'90%'}}>
 <div className='flex justify-between'>
 <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Test</h1><hr />
    <p className='fjalla'>{questionIndex + 1} of {quiz.length.length} questions </p>
 </div>

    <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {question && question.input}</p>
    <ul className='ulChoice'>
      
    <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{question && question.choices[0]}</li>
          <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{question && question.choices[1]}</li>
          <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{question && question.choices[2]}</li>
          <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{question && question.choices[3]}</li>


    </ul>

    <button onClick={(e)=>next(instructorId)}   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg fjalla transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                              </button>


</div>
  )
})
:
<div>
  no course 
</div>
 }
</div>
    </div>
    </div>

    </div>
    :
    <div>
    <div class="h-screen  flex items-center">
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to={`/dashboard`}>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 capitalize">Back to My Quiz</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
    </div> 
  )
}
