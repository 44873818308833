import React,{useEffect,useState} from 'react'
import Nav from './Nav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import {auth, firestore} from '../firebase'
import { collection, doc, onSnapshot } from 'firebase/firestore'

export default function ChoosePricingSignUp() {
    const {user} = useParams()
    const [institutes,setInstitutes] = useState(false)
    const [oneMonth,setoneMonth] = useState(true)
    const [threeMonth,setthreeMonth] = useState(false)
    const [year,setyear] = useState(false)
    const [User,setuser]=useState([])
    const [transaction,settransaction]=useState([])

  const navigate = useNavigate()   



    function oneMonthTeacher(){
      if(oneMonth == false){
        setoneMonth(true)
        setyear(false)
        setthreeMonth(false)
  
      }
  
   
  
    }
  
    function threeMonthTeacher(){
      if(threeMonth == false){
        setthreeMonth(true)
        setoneMonth(false)
        setyear(false)
  
      }
  
    }
    function yearTeacher(){
      if(year == false){
        setoneMonth(false)
        setyear(true)
        setthreeMonth(false)
  
      }
   
  
    }

   
    function toInstitutesDesktop(){
   navigate('/signup/pricing/institute')
  
    
  
  
    }
  
    function toTeacherDesktop(){
   navigate('/signup/pricing/teacher')

       
       
  
    }
  
    


 

 


    useEffect(() => {
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
     
        onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
            let temp = []
            temp.push({...snapshot.data()})
            setuser(temp)

        })

        
        onSnapshot(collection(firestore,'AccountDetails',uid,'Transactions'),doc=>{
          let temp = []
          doc.docs.forEach(snapshot=>{
            temp.push({...snapshot.data()})
            settransaction(temp)
          })
        

      })
    
    
    
    
    
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);
  return (
    <div>
        <Nav/>
        <div>
     {
        User.length !=0 ?
        User.map(i=>{
          const {accountType,billingDate,isPending} = i
          return(
              <div>
      {
       isPending == true ?
       <div id="desktop">
   
   {
   user == 'institute' ?
   <section
   
   className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
   >
   <h2
   style={{fontSize:'3vw'}}
          className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
        >
          Our Pricing Plan
        </h2>
   <div className='flex-col'>
   <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
   
   
   
   <li onClick={toTeacherDesktop} class=" flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",margin:0}}>
   
   Teachers
   
   </li>
   
   
   <li  onClick={toInstitutesDesktop}class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>
   
   <p className='block text-gray-900 text-center '>Institutes</p>
   
   </li>
   </ol>
   <div className="flex justify-between">
   <div>
   
   </div>
   <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
              <div class="sm:-mx-0.5 flex">{
                oneMonth == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto  sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
   
   
              }
   
              {
                threeMonth == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto   text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
   
   
              }
                                    {
                year == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto  sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
   
   
              }
              </div>
          </div>
   </div>
   </div>
   {
   oneMonth == true ?
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
    <span className="mb-4 block text-lg font-semibold text-primary">
     Basic
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
      <span>20,000 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a personal website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
   <Link to="/signup/institute">
   
   <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
    >
      Choose Plan
    </button></Link>
   
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
    <span className="mb-4 block text-lg font-semibold text-primary ">
     Premium
    </span>
    <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
      <span>50,000 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a Professional website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
   <Link to="/signup/institute">
   <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
    >
      Choose Plan
    </button>
   </Link>
   
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
   </div>
   </div>
   </div>
   </div>
   :
   threeMonth == true ?
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
    Basic
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
    <span>30,000 ETB</span>
   </h2>
   <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
    Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
   
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
   </div>
   <Link to="/signup/institute">
   <button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
    Choose Plan
   </button></Link>
   
   <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
   </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
    Premium
   </span>
   <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
    <span>75,000 ETB</span>
   </h2>
   <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
    Perfect for using in a Professional website or a client project.
   </p>
   <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
   
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
   </div>
   <Link to="/signup/institute">
   
   <button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
    Choose Plan
   </button>
   </Link>
   
   <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
   </div>
   </div>
   </div>
   </div>
   </div>
   :
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
   Basic
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>60,000 ETB</span>
   </h2>
   <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
   Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
   </p>
   
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
   </p>
   </div>
   
   <Link to="/signup/institute">
   
   <button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
   Choose Plan
   </button></Link>
   
   <div>
   <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
   </span>
   </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
   Premium
   </span>
   <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
   <span>150,000 ETB</span>
   </h2>
   <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
   Perfect for using in a Professional website or a client project.
   </p>
   <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
   </p>
   
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
   </p>
   </div>
   <Link to="/signup/institute">
   
   <button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
   Choose Plan
   </button>
   </Link>
   
   <div>
   <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
   </span>
   </div>
   </div>
   </div>
   </div>
   </div>
   }
   </section>
   
   
   :
   user == 'teacher' ?
   <section
   
   className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
   >
   <h2
          className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
   style={{fontSize:'3vw'}}
        
        >
          Our Pricing Plan
        </h2>
   <div className='flex-col'>
   <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
   
   
   
   
   <li onClick={toTeacherDesktop}  class="flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>
   
   Teachers
   
   </li>
   
   
   <li  onClick={toInstitutesDesktop} class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50" style={{width:"50%",margin:0}}>
   
   <p className='block text-gray-900 text-center '>Institutes</p>
   
   </li>
   </ol>
   <div className="flex justify-between">
   <div>
   
   </div>
   <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
              <div class="sm:-mx-0.5 flex">{
                oneMonth == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
   
   
              }
   
              {
                threeMonth == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
   
   
              }
                                    {
                year == true ?
                  <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
   :
   <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
   
   
              }
              </div>
          </div>
   </div>
   </div>
   {
   oneMonth == true ?
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Free Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
      <span>0 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a personal website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        5 Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        15% Transactional Fee
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
   <Link to="/signup/teacher">
   <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
    >
      Start For Free
    </button></Link>
   
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
   </div>
   </div>
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Basic Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
      <span>1,000 ETB</span>
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a personal website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        10% Transactional Fee
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
   <Link to="/signup/teacher">
   
   <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
    >
      Choose Plan
    </button></Link>
   
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
    <span className="mb-4 block text-lg font-semibold text-primary">
      Premium Plan
    </span>
    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   
      <span>5,000 ETB</span>
   
    </h2>
    <p
      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
    >
      Perfect for using in a Professional website or a client project.
    </p>
    <div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Certification and Recognition
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        5% Transactional Fee
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
   <Link to="/signup/teacher">
   
   <button
      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
    >
      Choose Plan
    </button>
   </Link>
   
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3056D3" stopOpacity="0.09" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            fill="#3056D3"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            fill="#3056D3"
          />
        </svg>
      </span>
    </div>
   </div>
   </div>
   </div>
   </div>
   :
   threeMonth == true ?
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
    Free Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
    <span>0 ETB</span>
   </h2>
   <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
    Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      5 Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      15% Transactional Fee
    </p>
   
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
   </div>
   <Link to="/signup/teacher">
   
   <button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
    Start For Free
   </button></Link>
   
   <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
   </div>
   </div>
   </div>
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
    Basic Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
    <span>2,715 ETB</span>
   </h2>
   <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
    Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
   
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      10% Transactional Fee
    </p>
   
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
   </div>
   
   <Link to="/signup/teacher">
   
   <button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
    Choose Plan
   </button></Link>
   
   <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
   </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
    Premium Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   
    <span>13,600 ETB</span>
   
   </h2>
   <p
    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
    Perfect for using in a Professional website or a client project.
   </p>
   <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certification and Recognition
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      5% Transactional Fee
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
   </div>
   <Link to="/signup/teacher">
   
   <button
    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
    Choose Plan
   </button>
   </Link>
   
   <div>
    <span className="absolute right-0 top-7 z-[-1]">
      <svg
        width="77"
        height="172"
        viewBox="0 0 77 172"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="86"
            y1="0"
            x2="86"
            y2="172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3056D3" stopOpacity="0.09" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
    <span className="absolute right-4 top-4 z-[-1]">
      <svg
        width="41"
        height="89"
        viewBox="0 0 41 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="38.9138"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 38.9138 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 38.9138 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 38.9138 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 38.9138 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 38.9138 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 38.9138 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 38.9138 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="38.9138"
          cy="1.42021"
          r="1.42021"
          transform="rotate(180 38.9138 1.42021)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 26.4157 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 26.4157 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 26.4157 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 26.4157 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 26.4157 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 26.4157 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 26.4157 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="26.4157"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 26.4157 1.4202)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 13.9177 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 13.9177 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 13.9177 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 13.9177 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 13.9177 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 13.9177 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 13.9177 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="13.9177"
          cy="1.42019"
          r="1.42021"
          transform="rotate(180 13.9177 1.42019)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="87.4849"
          r="1.42021"
          transform="rotate(180 1.41963 87.4849)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="74.9871"
          r="1.42021"
          transform="rotate(180 1.41963 74.9871)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="62.4892"
          r="1.42021"
          transform="rotate(180 1.41963 62.4892)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="38.3457"
          r="1.42021"
          transform="rotate(180 1.41963 38.3457)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="13.634"
          r="1.42021"
          transform="rotate(180 1.41963 13.634)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="50.2754"
          r="1.42021"
          transform="rotate(180 1.41963 50.2754)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="26.1319"
          r="1.42021"
          transform="rotate(180 1.41963 26.1319)"
          fill="#3056D3"
        />
        <circle
          cx="1.41963"
          cy="1.4202"
          r="1.42021"
          transform="rotate(180 1.41963 1.4202)"
          fill="#3056D3"
        />
      </svg>
    </span>
   </div>
   </div>
   </div>
   </div>
   </div>
   :
   <div className="container mx-auto">
   <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
   <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
   
   
   
   </div>
   </div>
   </div>
   
   <div className="-mx-4 flex flex-wrap justify-center">
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
   Free Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>0 ETB</span>
   </h2>
   <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
   Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    5 Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    15% Transactional Fee
   </p>
   
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
   </p>
   </div>
   <Link to="/signup/teacher">
     
   <button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
   Start For Free
   </button></Link>
   
   <div>
   <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
   </span>
   </div>
   </div>
   </div>
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
   Basic Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   <span>7,980 ETB</span>
   </h2>
   <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
   Perfect for using in a personal website or a client project.
   </p>
   <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
   </p>
   
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    10% Transactional Fee
   </p>
   
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
   </p>
   </div>
   
   <Link to="/signup/teacher">
   
   <button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
   Choose Plan
   </button></Link>
   
   <div>
   <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
   </span>
   </div>
   </div>
   </div>
   
   <div className="w-full px-4 md:w-1/2 lg:w-1/3">
   <div
   className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
   >
   <span className="mb-4 block text-lg font-semibold text-primary">
   Premium Plan
   </span>
   <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
   
   <span>39,900 ETB</span>
   
   </h2>
   <p
   className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
   >
   Perfect for using in a Professional website or a client project.
   </p>
   <div className="mb-7">
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certification and Recognition
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    5% Transactional Fee
   </p>
   <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
   </p>
   </div>
   
   <Link to="/signup/teacher">
   
   <button
   className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
   >
   Choose Plan
   </button>
   </Link>
   
   <div>
   <span className="absolute right-0 top-7 z-[-1]">
    <svg
      width="77"
      height="172"
      viewBox="0 0 77 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="86"
          y1="0"
          x2="86"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3056D3" stopOpacity="0.09" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
   </span>
   <span className="absolute right-4 top-4 z-[-1]">
    <svg
      width="41"
      height="89"
      viewBox="0 0 41 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="38.9138"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 38.9138 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 38.9138 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 38.9138 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 38.9138 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 38.9138 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 38.9138 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 38.9138 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="38.9138"
        cy="1.42021"
        r="1.42021"
        transform="rotate(180 38.9138 1.42021)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 26.4157 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 26.4157 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 26.4157 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 26.4157 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 26.4157 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 26.4157 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 26.4157 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="26.4157"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 26.4157 1.4202)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 13.9177 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 13.9177 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 13.9177 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 13.9177 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 13.9177 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 13.9177 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 13.9177 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="13.9177"
        cy="1.42019"
        r="1.42021"
        transform="rotate(180 13.9177 1.42019)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="87.4849"
        r="1.42021"
        transform="rotate(180 1.41963 87.4849)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="74.9871"
        r="1.42021"
        transform="rotate(180 1.41963 74.9871)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="62.4892"
        r="1.42021"
        transform="rotate(180 1.41963 62.4892)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="38.3457"
        r="1.42021"
        transform="rotate(180 1.41963 38.3457)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="13.634"
        r="1.42021"
        transform="rotate(180 1.41963 13.634)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="50.2754"
        r="1.42021"
        transform="rotate(180 1.41963 50.2754)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="26.1319"
        r="1.42021"
        transform="rotate(180 1.41963 26.1319)"
        fill="#3056D3"
      />
      <circle
        cx="1.41963"
        cy="1.4202"
        r="1.42021"
        transform="rotate(180 1.41963 1.4202)"
        fill="#3056D3"
      />
    </svg>
   </span>
   </div>
   </div>
   </div>
   </div>
   </div>
   }
   </section>
   :
   user != 'institute' & user != 'teacher'  &&
   <div class="h-screen  flex items-center">
   <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
   <div class="w-full lg:w-1/2 mx-8">
      <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
   <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
      Sorry we couldn't find the page you're looking for
   </p>
   <Link to='/'>
   <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>
   
   </Link>
   </div>
   <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
   <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
   </div>
   
   </div>
   </div>
   }
   
   
   
   
   </div>
       :
   
       
         transaction && transaction.map(i=>{
           const {chosenMethod,amount} = i
           return(
             <div class="h-screen   flex items-center">
             <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                     <div class="w-full lg:w-1/2 mx-8">
                         <div style={{fontSize:'3vw',lineHeight:'4vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                         <p className='fjalla flex flex-col' style={{fontSize:'1.5vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                         </div>
                    
                         <Link to={`/dashboard`}>
                         <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                       
                         Check out my account until the payment is verified
                                     </button>
                         </Link>      
          
                     </div>
                 <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
                 </div>
             
             </div>
         </div>
           )
         })
       
   
      }
           
          
               
          
         
          
                  </div>
          )
      })
      
        :
        <div id="desktop">

        {
           user == 'institute' ?
          <section
      
          className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
        >
           <h2
            style={{fontSize:'3vw'}}
                    className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                  >
                    Our Pricing Plan
                  </h2>
          <div className='flex-col'>
    <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
    
    
    
    <li onClick={toTeacherDesktop} class=" flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",margin:0}}>
    
    Teachers
    
    </li>
    
    
    <li  onClick={toInstitutesDesktop}class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>
    
     <p className='block text-gray-900 text-center '>Institutes</p>
    
    </li>
    </ol>
    <div className="flex justify-between">
        <div>
    
        </div>
        <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                        <div class="sm:-mx-0.5 flex">{
                          oneMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
  
  
                        }
  
                        {
                          threeMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
  
  
                        }
                                              {
                          year == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
  
  
                        }
                        </div>
                    </div>
      </div>
    </div>
       {
        oneMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
         
             
        
            </div>
          </div>
        </div>
    
        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
               Basic
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>20,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a personal website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/signup/institute/basic/month">
            
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button></Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
    
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary ">
               Premium
              </span>
              <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
                <span>50,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a Professional website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
           <Link to="/signup/institute/premium/month">
           <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button>
           </Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      threeMonth == true ?
      <div className="container mx-auto">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
       
           
      
          </div>
        </div>
      </div>
  
      <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Basic
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>30,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a personal website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/basic/3months">
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Premium
            </span>
            <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
              <span>75,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a Professional website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/premium/3months">

         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <div className="container mx-auto">
    <div className="-mx-4 flex flex-wrap">
      <div className="w-full px-4">
        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
     
         
    
        </div>
      </div>
    </div>
  
    <div className="-mx-4 flex flex-wrap justify-center">
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>60,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a personal website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
   
      <Link to="/signup/basic/annual">
        
      <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button></Link>
  
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
  
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium
          </span>
          <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
            <span>150,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a Professional website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
      <Link to="/signup/premium/annual">

       <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button>
       </Link>
  
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
       }
        </section>
    
    
    :
    user == 'teacher' ?
        <section
          
          className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
        >
           <h2
                    className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                  >
                    Our Pricing Plan
                  </h2>
          <div className='flex-col'>
    <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
    
    
    
    
    <li onClick={toTeacherDesktop}  class="flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>
    
    Teachers
    
    </li>
    
    
    <li  onClick={toInstitutesDesktop} class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50" style={{width:"50%",margin:0}}>
    
     <p className='block text-gray-900 text-center '>Institutes</p>
    
    </li>
    </ol>
    <div className="flex justify-between">
        <div>
    
        </div>
        <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                        <div class="sm:-mx-0.5 flex">{
                          oneMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
  
  
                        }
  
                        {
                          threeMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
  
  
                        }
                                              {
                          year == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
  :
  <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
  
  
                        }
                        </div>
                    </div>
      </div>
    </div>
       {
        oneMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
         
             
        
            </div>
          </div>
        </div>
    
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Free Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>0 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a personal website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  5 Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  15% Transactional Fee
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/signup/teacher/free/month">
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Start For Free
              </button></Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Basic Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>1,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a personal website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
  
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  10% Transactional Fee
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/signup/teacher/basic/month">

          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button></Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
    
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Premium Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
  
                <span>5,000 ETB</span>
  
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a Professional website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  5% Transactional Fee
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/signup/teacher/premium/month">

           <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button>
           </Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      threeMonth == true ?
      <div className="container mx-auto">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
       
           
      
          </div>
        </div>
      </div>
  
      <div className="-mx-4 flex flex-wrap justify-center">
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Free Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>0 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a personal website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher/free/3months">
          
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Start For Free
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Basic Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>2,715 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a personal website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
  
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
       
        <Link to="/signup/teacher/basic/3months">

        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button></Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
  
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Premium Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
  
              <span>13,600 ETB</span>
  
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              Perfect for using in a Professional website or a client project.
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5% Transactional Fee
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/signup/teacher/premium/3months">

         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
  
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <div className="container mx-auto">
    <div className="-mx-4 flex flex-wrap">
      <div className="w-full px-4">
        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
     
         
    
        </div>
      </div>
    </div>
  
    <div className="-mx-4 flex flex-wrap justify-center">
    <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Free Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>0 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a personal website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              5 Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              15% Transactional Fee
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
      <Link to="/signup/teacher/free/annual">
      <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Start For Free
          </button></Link>
  
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>7,980 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a personal website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
  
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              10% Transactional Fee
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
  
      <Link to="/signup/teacher/basic/annual">

      <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button></Link>
  
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
  
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
  
          <span>39,900 ETB</span>
  
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            Perfect for using in a Professional website or a client project.
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              5% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
      
      <Link to="/signup/teacher/premium/annual">

       <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button>
       </Link>
  
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
       }
        </section>
:
user != 'institute' & user != 'teacher'  &&
<div class="h-screen  flex items-center">
    <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
            <div class="w-full lg:w-1/2 mx-8">
                <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
            <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                Sorry we couldn't find the page you're looking for
            </p>
            <Link to='/'>
            <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

            </Link>
    </div>
        <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
        <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
        </div>
    
    </div>
</div>
        }
  
  
  
  
    </div>
     }

   

    <section
    id="tablet"
      className="relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
    >
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
         
              <h2
                className="home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
              >
                Our Pricing Plan
              </h2>
        
            </div>
          </div>
        </div>
    
        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Monthly Subscription
              </span>
              <h2 className=" font-bold text-dark home_description-header" style={{fontSize:"4vw"}}>
                <span>1,000 ETB</span>
                <span className="text-base font-medium text-body-color" style={{fontSize:"2.5vw"}}> / Month </span>
              </h2>
              <p
                style={{marginBottom:"4vw"}} className=" border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a personal website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/signup">
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button></Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
    
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Pay Per Course
              </span>
              <h2 className="mb-5 text-[42px] font-bold text-dark">
                <span>$256</span>
                <span className="text-base font-medium text-body-color"> / year </span>
              </h2>
              <p
                style={{marginBottom:"4vw"}} className=" border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                Perfect for using in a Professional website or a client project.
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
           <Link to="/signup">
           <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button>
           </Link>
    
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
    </div>
  )
}
