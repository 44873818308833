import { setDoc,doc, addDoc, collection } from 'firebase/firestore';
import React, { useState,useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firestore,auth } from '../firebase';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import Footer from './footer';

export default function ContactUs() {
    const [name,setname] = useState(null)
    const [email,setemail] = useState(null)
    const [message,setmessage] = useState(null)
    const [isLoading,setisLoading] = useState(false)
    const [success,setsuccess] = useState(false)
    const [pop,setpop] = useState(false)
    const [loading,setloading] = useState(false)
    const [spinner, setspinner] = useState(true);
    





    
  function desktopSpinnerHandler(){
    setspinner(false)

   }
   const send = (e) =>{
    e.preventDefault()
    setisLoading(true)
    addDoc(collection(firestore, 'complaintSubmited'),{
      name:name,
      email:email,
      message:message
    } ).then(
        setisLoading(false),
        setsuccess(true)


    ).catch(e=>console.log(e.message))
  
  
  }

  function popOpenSet(){
    setpop(true)

  }
  function popRemove(){
    setpop(false)

  }
 
  return (
    <div>
            {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  }
        <div id='desktop'>
            <div id='desktop'>
      


        <div class="bg-white relative ">
        <Nav/>

        <div className='flex items-center justify-center min-h-screen hidden' id='loadingCheckoutDesktop'>
   
   <div style={{borderTopColor:"transparent",width:"70px",height:70}} className="w-8  border-4 border-blue-500 rounded-full animate-spin"></div>
   {/* <p className="ml-3" style={{fontSize:"30px"}}>Proccessing...</p> */}
</div>
{
    isLoading == true ?
    <div id='loadingDesktop' >
    <div class="relative">
    <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
    <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
</div>

      </div>
      :

      success == true ?

<div id='submitDesktop'>

<div className='align' >
  <Link to="/">
  <svg style={{position:"absolute",top:"50px",right:"50px"}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" id='cancel' fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
</svg></Link>
   
   <h1 className='color-black capitalize anton text-center'style={{color:"black"}}>Message Successfully Sent!! <br /> We will get back to you</h1>
   </div>
        </div>
        :
        <div class="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl
        xl:px-5 lg:flex-row">
      <div class="flex  items-center w-full pt-5 pr-10 pb-20 pl-10 lg:pt-20 lg:flex-row">
        <div class="w-full bg-cover relative max-w-md lg:max-w-2xl lg:w-7/12">
          <div class="flex flex-col items-center justify-center w-full h-full relative lg:pr-10">
            <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/governmentsonny%2FCall%20center-bro.svg?alt=media&token=e5a38e46-82e7-434c-9ced-38fe5c54e4e2" class="btn-"/>
          </div>
        </div>
        <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
          <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl
              relative z-10">
            <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Send us a message</p>
            <form onSubmit={send} class="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
              <div class="relative">
                <p style={{fontSize:'14px'}} class="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Full Name</p>
                <input style={{fontSize:'12px'}} required onChange={e=>setname(e.target.value)} placeholder="Abebe Solomon" type="text" class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"/>
              </div>
              <div class="relative">
                <p style={{fontSize:'14px'}} class="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
                <input style={{fontSize:'12px'}} required onChange={e=>setemail(e.target.value)} placeholder="abebe@gmail.com" type="text" class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"/>
              </div>
              <div class="relative">
                <p style={{fontSize:'14px'}}class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Message</p>
                <textarea  style={{fontSize:'12px'}} required onChange={e=>setmessage(e.target.value)}  class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"></textarea>
              </div>
              <div class="relative" >
                <button type='submit' style={{backgroundColor:"#2141ce" ,fontSize:'14px'}} class="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">Send Message</button>
              </div>
            </form>
          </div>
          <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
          <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
              fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
              cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
              r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
              cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
              transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
              r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
              cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
              r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
              cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
              cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
              r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
              transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
              r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
              cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
              r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
              cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
              cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
              r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
              transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
              r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
              cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
              r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
              cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
              cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
              r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
              transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
              r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
              cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
              r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
        </div>
      </div>
    </div>

}

   
    </div>
        </div>
            
        </div>
        <div id='tablet'>
   


        <div class="bg-white relative ">
      
        <nav className='flex justify-between navTablet w-full  pt-5'>

           
<Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={popOpenSet} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={popRemove} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
      

        <div className='flex items-center justify-center min-h-screen hidden' id='loadingCheckoutNavMid'>
   
   <div style={{borderTopColor:"transparent",width:"70px",height:70}} className="w-8  border-4 border-blue-500 rounded-full animate-spin"></div>
   {/* <p className="ml-3" style={{fontSize:"30px"}}>Proccessing...</p> */}
</div>

{
  success == true &&
  <div id='submitTablet'>

<div className='align' >
  <Link to="/">
  <svg style={{position:"absolute",top:"50px",right:"50px"}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" id='cancel' fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
</svg></Link>
   
   <h1 className='anton text-center'style={{color:"black"}}>Message Successfully Sent!! <br /> We will get back to you</h1>
   </div>
        </div>
}
      <div class="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl
          xl:px-5 lg:flex-row">
        <div class="flex  items-center w-full pt-5 pr-10 pb-20 pl-10 lg:pt-20 lg:flex-row">

          <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
            <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl
                relative z-10">
              <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Send us a message</p>
              <form onSubmit={send} class="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
              <div class="relative">
                <p style={{fontSize:'14px'}} class="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Full Name</p>
                <input style={{fontSize:'12px'}} required onChange={e=>setname(e.target.value)} placeholder="Abebe Solomon" type="text" class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"/>
              </div>
              <div class="relative">
                <p style={{fontSize:'14px'}} class="bg-white fjalla  pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
                <input style={{fontSize:'12px'}} required onChange={e=>setemail(e.target.value)} placeholder="abebe@gmail.com" type="text" class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"/>
              </div>
              <div class="relative">
                <p style={{fontSize:'14px'}}class="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                    absolute">Message</p>
                <textarea  style={{fontSize:'12px'}} required onChange={e=>setmessage(e.target.value)}  class="border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                    border-gray-300 rounded-md input-field-signUp borel"></textarea>
              </div>
              <div class="relative" >
                <button type='submit' style={{backgroundColor:"#2141ce" ,fontSize:'14px'}} class="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">Send Message</button>
              </div>
            </form>
            </div>
            <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
                fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
                cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
                r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
                cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
                transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
                r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
                cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
                r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
                cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
                cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
                r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
                transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
                r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
                cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
                r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
                cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
                cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
                r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
                transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
                r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
                cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
                r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
                cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
                cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
                r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
                transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
                r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
                cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
                r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
            <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
                fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
                cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
                r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
                cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
                transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
                r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
                cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
                r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
                cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
                cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
                r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
                transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
                r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
                cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
                r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
                cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
                cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
                r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
                transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
                r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
                cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
                r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
                cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
                cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
                r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
                transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
                r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
                cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
                r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
          </div>
        </div>
      </div>
    </div>
        </div>

        <div id="mobile" className='mobile__home'>
        <div className='body'>
        <div>
        {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={popOpenSet} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
   {
    pop == true &&
    <div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
    <div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>
    
    <Link to='/contact'>
    
    
    <button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
    </Link>
    </div>
             <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>
    
             <div className='flex justify-between w-full mt-3'>
                   <div>
                   </div>
                   <button onClick={popRemove} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                     <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                   </button>
                 </div>
       
                {
                 <li>
                 <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
             </li>
                 }
                 <li>
                 <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
             </li>
    
    {
                 <li>
                 <Link to='/pricing'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
             </li>
                 }
          <li>
                 <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
             </li>
             
             </ul>
         </div>
   }
      

            

        </div>
<div onClick={popRemove} >
  <div >
<h1 className='barlow' style={{color:"black",fontSize:"8vw",width:"95%",marginTop:"30vw",paddingLeft:"10%"}}>We'd love to hear from you</h1>

<div>
{
  loading == true &&
  <div className='flex items-center justify-center min-h-screen' id='loadingCheckoutMobile'>
   
  <div style={{borderTopColor:"transparent",width:"70px",height:70}} className="w-8  border-4 border-blue-500 rounded-full animate-spin"></div>
  {/* <p className="ml-3" style={{fontSize:"30px"}}>Proccessing...</p> */}
</div>
}
{
  success == true &&
  <div id='submitMobile'>

<div className='align'>
<Link to="/">
<svg style={{position:"absolute",top:"50px",right:"50px",color:"black"}} xmlns="http://www.w3.org/2000/svg" width="10vw" height="10vw" id='cancel' fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
</svg></Link>
   <h1 className='anton text-center' style={{color:"black",fontSize:"10vw",width:"95%",margin:0,paddingLeft:"10%"}}>Message Successfully Sent !!</h1>
   
   </div>
        </div>
}

              <div class="w-full  mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
            <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white rounded-xl
                relative z-10">
              {/* <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Send us a message</p> */}
              <form onSubmit={send}   class="w-full  " id="third_modalMobile" style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>


<div className="relative m-auto " style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white fjalla  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Full Name</p>

           <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md borel" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>setname(e.target.value)}/>
           
   </div>
   <div className="relative m-auto " style={{width:"100%",marginBottom:"5vw"}}>
           
           <p class="bg-white fjalla  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>

           <input  placeholder="abebe@gmail.com"   className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md borel" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"3vw",fontSize:"3vw"}} type='email'  onChange={e=>setemail(e.target.value)}/>
           
   </div>

   <div className="relative m-auto " style={{width:"100%",marginBottom:"5vw",marginTop:'5vw'}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Message</p>

           <textarea    className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white borel
                 border-gray-300 rounded-md" style={{paddingTop:"2vw",paddingBottom:"2vw",paddingLeft:"3vw",fontSize:"3vw",height:"50vw"}} type='email'  onChange={e=>setmessage(e.target.value)}>

                 </textarea>
           
   </div>


       
   <div class="flex justify-center">
  <button type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "50vw", height: "15vw" }} class="w-full barlow flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
    Send Message
  </button>
</div>
        </form>
            </div>
      {/* <img className='w-full h-40' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/agile%20method-rafiki.svg?alt=media&token=81a36c58-f42a-40f9-9dfb-b421e298060e" alt="" /> */}
          </div>
        </div>
        </div>
        <img className='img-support' src="https://firebasestorage.googleapis.com/v0/b/shekay-f603c.appspot.com/o/Creative%20team-bro.svg?alt=media&token=cec715d2-96a8-4de5-b93f-76ac494f9c10" alt="" />
        <Footer/>
</div>
        
        </div>
        
        </div>
        </div>
  )
}
