import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';
import Nav from './Nav';

export default function NotFound() {
    const [uid,setuid] = useState('')
    const [user,setuser] = useState([])

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
    
       
        
    

      const userRef = doc(firestore,"TotalUsers",uid);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
    
        
        
      
    
      })
      
   
    
    
    
    
    
           
      
    
    
    
    
            
    
               
     
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);
  return (
    user.length == 0 ?
    <div>
    <Nav/>
    <div class="h-screen  flex items-center" id='desktop'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='tablet'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<section class="bg-white  " id='mobile'>
<div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
        
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
    <div class=" flex items-center  px-6 py-12 mx-auto mt-16">
        <div class="flex flex-col items-center max-w-sm mx-auto text-center">
            <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>
            </p>
            <h1 style={{fontSize:'30px',fontWeight:'900'}} className='barlow'>
            Page not found</h1>
            <p class="mt-4 text-gray-500 barlow ">The page you are looking for doesn't exist.</p>

            <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
   <Link to='/'>
   <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>


                    <span className='barlow'>Go back</span>
                </button></Link>
      <Link to='/contact'>
                <button class="barlow px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                    Contact Us
                </button>
      </Link>

            </div>
        </div>
    </div>
</section></div>
:
<div>
<nav className='flex justify-between px-5 navDesktop'>
   
   <Link to="/dashboard" className='hire_header  logo' > <div className='logo     capitalize'style={{fontSize:"30px"}}>
                   <img  style={{width:"50%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                   
                     </div>
                     </Link>

       <div>

       </div>

</nav>
<div class="h-screen  flex items-center" id='desktop'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='tablet'>
<div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>

        </Link>
</div>
    <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>

</div>
</div>
<div class="h-screen  flex items-center" id='mobile'>
<div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
    <img style={{width:'70%',margin:'auto'}} src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
    </div>
<div class=" flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div class="w-full lg:w-1/2 mx-8">
            <div class="text-7xl text-gray-800 font-dark font-extrabold mb-8" style={{fontSize:'10vw',marginTop:'10%'}}> 404</div>
        <p class="text-2xl md:text-3xl font-light leading-normal mb-8"style={{fontSize:'4vw'}}>
            Sorry we couldn't find the page you're looking for
        </p>
        <Link to='/cakes'>
        <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900 barlow"style={{fontSize:'3vw',width:'80%',height:'13vw'}}>Back to homepage</a>

        </Link>
</div>
   

</div>
</div></div>
  )
}
