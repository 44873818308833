import React, { useEffect, useState } from 'react'
import DashboardNav from './dashboardNav'
import { Link, useHref, useNavigate } from 'react-router-dom'
import Chart from './Chart';
import { collection, doc, onSnapshot, orderBy, query,where,getDocs, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { UseAuth } from '../context/contex';

export default function Dashboard() {
  const [uid,setuid] = useState("")
  const [student,setstudent] = useState([])
  const [studentResult,setstudentResult] = useState([])

  
  const [totalEarnings,settotalEarnings] = useState()
  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)
  


  const [averageMark,setaverageMark] = useState(0)
  const [underPerformingStudents,setunderPerformingStudents] = useState(0)
  const [underPerformingLength,setunderPerformingLength] = useState(0)


  const [totalStudents,settotalStudents] = useState("")
  const [course,setCourse] = useState([])
  const [instructor,setinstructor] = useState([])

  const [spinner,setspinner] =  useState(true)
  const navigate = useNavigate()



    const [courses,setcourses] = useState([])
    const [teacher,setTeacher] = useState([])
    const [user,setuser] = useState([])
    const [notification,setnotification] = useState([])
    const [accountType,setaccountType] = useState('')







    
    
  // Mobile Nav Pop
  function mobileNavPop(){
    setpop(true)


  

}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}





  useEffect(()=>{

    const coursesCollectionRef = collection(firestore, "Courses");
    
    onSnapshot(coursesCollectionRef, snapshot => {
      const temp = [];
    
      snapshot.docs.forEach(docSnapshot => {
        temp.push({...docSnapshot.data()});
      setCourse(temp);
      });

    });
  
    },[])



    

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       

    
  const coursesRef = collection(firestore,"AccountDetails",uid,"CoursesCreated");
  const studentRef = collection(firestore,"AccountDetails",uid,"Students");

  const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
  const teacherRef = doc(firestore,"TotalUsers",uid);
  const myuserRef = doc(firestore,"TotalUsers",uid);

  onSnapshot(myuserRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      setaccountType(snapshot.data().accountType)
      setuser(main)

    
    
  

  })
  onSnapshot(notificationRef,snapshot=>{
    let main =[]
    



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })
let i = 0
 onSnapshot(studentRef, async (snap) => {

  const main = [];
  let temp = [];

  const fetchStudentData = snap.docs.map((sdoc) => {
    const studentId = sdoc.data().studentId;
if(studentId){
  
  const userRef = doc(firestore, 'TotalUsers', studentId);
  return new Promise((resolve) => {
    const unsubscribeUserSnapshot = onSnapshot(userRef, (snapshott) => {

      const studentData = {
        ...sdoc.data(),
        fullName: snapshott.data().fullName,
        photo: snapshott.data().photo,
        totalEarnings: sdoc.data().paid
      };
      main.push(studentData);

      const progress = sdoc.data().progress;
      if (Object.keys(progress).length > 0) {
        let finalTestResults = [];
        let quizResults = [];

        const keys = Object.keys(progress);

        keys.forEach((key) => {
          if (progress[key].finalTest) {
            finalTestResults.push(progress[key].finalTest.result);
          }
        });

        if (progress.quizResults) {
          const quizKeys = Object.keys(progress.quizResults);
          quizKeys.forEach((key) => {
            const quizResult = progress.quizResults[key];
            if (quizResult && quizResult.result !== undefined) {
              quizResults.push(quizResult.result);
            }
          });
        }

        let averageResult = null;
        const totalFinalTestResults = finalTestResults.length;
        const totalQuizResults = quizResults.length;
        const totalResults = totalFinalTestResults + totalQuizResults;

        const sumFinalTestResults = finalTestResults.reduce((a, b) => a + b, 0);
        const sumQuizResults = quizResults.reduce((a, b) => a + b, 0);
        const totalSumResults = sumFinalTestResults + sumQuizResults;
        averageResult = totalSumResults / totalResults;

        const studentResultData = {
          ...sdoc.data(),
          fullName: snapshott.data().fullName,
          photo: snapshott.data().photo,
          result: averageResult
        };

        temp.push(studentResultData);
      }

      unsubscribeUserSnapshot(); // Unsubscribe from the user snapshot
      resolve(); // Resolve the promise
    });
  });
}
  });

  await Promise.all(fetchStudentData);

  setstudent(main);
  settotalStudents(main.length);
  setstudentResult(temp);

  const allStudentsResults = temp.map((student) => student.result);
  const totalStudents = allStudentsResults.length;

  let averageAllStudents = null;
  if (totalStudents > 0) {
    const sumAllStudentsResults = allStudentsResults.reduce((a, b) => a + b, 0);
    averageAllStudents = sumAllStudentsResults / totalStudents;
  }

  const underperformings = temp.filter((student) => student.result < 50);
  const underPerformingStudentsRatio = underperformings.length / temp.length;

  setunderPerformingStudents(underPerformingStudentsRatio || 0);
  setunderPerformingLength(underperformings.length || 0);
  setaverageMark(averageAllStudents || 0);

});

const earningsQuery = query(collection(firestore, 'AccountDetails', uid, 'Students'));

onSnapshot(earningsQuery, (earningsSnapshot) => {
  let totalEarningsSum = 0;

  earningsSnapshot.forEach((doc) => {
    const earningAmount = doc.data().paid;


    totalEarningsSum += earningAmount;
  });
 

  const updatedTotalEarnings = totalEarningsSum;
  setDoc(myuserRef, {
    totalEarnings: updatedTotalEarnings
  }, { merge: true });

  settotalEarnings(updatedTotalEarnings);
}, (error) => {
});
  
  onSnapshot(coursesRef,snapshot=>{
    let main =[]
    let earning = 0;
    let students = 0;
    let markAverage = 0;



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      const {totalEarnings,totalStudents,averageMark}= doc.data()

      earning += totalEarnings;
      students += totalStudents ;
      markAverage += averageMark ;

      markAverage = (markAverage/ main.length)
   

      setcourses(main)
    })

    
    
  

  })


       
  
  onSnapshot(teacherRef,snapshot=>{
    let main =[]
    let earning = 0;
    let students = 0;
    let markAverage = 0;



      main.push({...snapshot.data(),id:snapshot.id})
      
  setTeacher(main)

    
    
  

  })



        

           
 
//    })
  
            
  
  
  
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);


  function handleLogOut(){
   signOut(auth).then(
    navigate('/')
   )
  }

  function desktopSpinnerHandler(){
    setspinner(false)
   }
  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
    setnotificationPop(false)


    }
    else{
    setpop(true)
    setnotificationPop(false)



    }
}
const convertTimestampToDate = (timestamp) => {
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
};
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
   function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  return (
   <div>
     {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
className="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
    {
      user.map(i=>{
        const{accountType,plan,fullName,photo} = i
        return(
          accountType == "Student" ?
          <div>
            <div id='desktop'>
          <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>

      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
              className="cursor-pointer  Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
              style={{fontSize:'12px'}}
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer  Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   border-b border-blue-800' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/enrolledcourses' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >Enrolled Courses</li>
      
                    </Link>
      <Link to="/courses" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
                    <Link to="/achievements" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Achievements</li>
      
                    </Link>
      
           
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            <div className='flex  justify-center flex-col '>
          {/* <h1 className='fjalla ml-5 text-gray-900 mt-8' style={{fontSize:"40px"}}>Start learning in-demand skills with these courses
      
      
      </h1> */}
     
        </div>
        <h1 className='fjalla ml-5 text-gray-900 mt-8 capitalize' style={{fontSize:"30px"}}>Start learning in-demand skills with these courses
      
      
      </h1>
          <div className='courseGrid h-full'>

    

              {
  course.filter(i=>i.status=== 'published').map(i=>{
    const {courseTitle,courseThumbnail,coursePricing,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
    return(

     <Link to={`/course/${courseId}`} >

      <div className="h-full border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-lg transform hover:scale-105 transition duration-500">
      <h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
      <div className="relative">
        {
         courseThumbnail&& courseThumbnail.length == 0 ?
  <img  className="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

        }

        <p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
      </div>
      <h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
      <span style={{fontSize:"12px"}} className="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{intendedAgeGroup}</span>
  
    </div>
    </Link>

  
    )
  })
}
            </div>

    
            </div>
            <div id='tablet'>
     <DashboardNav/>
            <div className='flex  justify-center flex-col '>
          {/* <h1 className='fjalla ml-5 text-gray-900 mt-8' style={{fontSize:"40px"}}>Start learning in-demand skills with these courses
      
      
      </h1> */}
     
        </div>
        <h1 className='fjalla ml-5 text-gray-900 my-10 capitalize' style={{fontSize:"30px"}}>Start learning in-demand skills with these courses
      
      
      </h1>
      <div className='courseGrid'>

    

{
course.map(i=>{
const {courseTitle,courseThumbnail,coursePricing,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
return(

<Link to={`/course/${courseId}`} >

<div className="h-full border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-lg transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  className="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img onLoad={desktopSpinnerHandler} className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<span style={{fontSize:"12px"}} className="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{intendedAgeGroup}</span>

</div>
</Link>


)
})
}
</div>

    
            </div>
            <div id='mobile'>
                <DashboardNav/>
   

<div className='flex  justify-center flex-col '>
<h1 className='fjalla ml-5 text-gray-900 mt-8 capitalize' style={{fontSize:"6vw"}}>Start learning in-demand skills with these courses


</h1>

</div>

<div className="  text-gray-500 flex   mobileGridCourses " style={{overflowX:"scroll", padding:"2vw 2vw"}}>


{
course.map(i=>{
const {instructorName,photo,courseTitle,courseThumbnail,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
return(

  <Link to={`/course/${courseId}`}>
<div className=" border  bg-white rounded-2xl shadow-md " style={{backgroundColor:"white",width:'100%',height:'100%',paddingLeft:'2vw',paddingBottom:'4vw',paddingRight:'2vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton " style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  className="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white font-semibold  rounded-br-lg rounded-tl-lg fjalla" style={{padding:'1.5vw 2vw 1vw 1.5vw ',fontSize:'3vw'}}>{coursePrice} ETB</p>
        </div>
        <h1 className=" text-gray-800  fjalla capitalize" style={{fontSize:"4vw",marginLeft:'3vw'}}>{courseTitle}</h1>
        <div className="">
    
    
          <div className="flex  items-center w-full">
            
    
          </div>
 
  
        </div>
      </div>
      </Link>


)
})
}
</div>
            </div>
      
          </div>
          :
          <div>
              <div  id='mobile'>
<DashboardNav/>
    <div className='w-full'>
 <div className='flex w-full justify-between  items-center' style={{padding:'0vw 3vw'}}>
  <div>
    <h1 className='fjalla' style={{fontSize:'6vw'}}>Dashboard</h1>
  </div>
  <div className='flex justify-end w-full'>

 <Link to={`/withdraw`}> <button style={{fontSize:'2.8vw',padding:'2.5vw 3vw',marginTop:'5vw',marginLeft:'4vw'}} className="fjalla inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
           
           Withdraw

         </button>
         </Link>
  </div>

  <div>

  </div>

 </div>
  <div className='flex justify-evenly' style={{marginTop:'3vw'}}>
        <div className='border shadow' style={{width:'45%'}}>
        <div className="flex items-center  bg-white  rounded-lg" style={{padding:'4vw 3vw'}}>
                <div style={{width:'11vw',height:'11vw',marginRight:'2vw'}}className="inline-flex flex-shrink-0 items-center justify-center text-blue-600 bg-blue-100 rounded-full ">
                  <svg style={{width:'5vw',height:'5vw'}}aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl fjalla" style={{fontSize:'4.5vw'}}>{totalStudents ? totalStudents : 0}</span>
                </div></div>
                  <span className="block text-gray-500 borel" style={{fontSize:'2.8vw',paddingLeft:'3vw',paddingBottom:'2vw'}}>Total Students</span>

              </div>
        <div className='border shadow' style={{width:'45%'}}>

                    <div className="flex items-center  bg-white rounded-lg"style={{padding:'4vw 3vw'}}> 
                <div style={{width:'11vw',height:'11vw',marginRight:'2vw'}}className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-100 rounded-full mr-6">
                  <svg style={{width:'5vw',height:'5vw'}} aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl fjalla" style={{fontSize:'4.5vw'}}>{ averageMark}</span>
                </div>

              </div>
                  <span className="block text-gray-500 borel" style={{fontSize:'2.8vw',paddingLeft:'3vw',paddingBottom:'2vw'}}>Average mark</span>

              
              </div>
              </div>
              
  <div className='flex justify-evenly'style={{marginTop:'8vw',marginBottom:'15vw'}}>
        <div className='border shadow'style={{width:'45%'}}>

              <div className="flex items-center  bg-white  rounded-lg" style={{padding:'4vw 3vw'}}>
                <div style={{width:'11vw',height:'11vw',marginRight:'2vw'}} className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-red-600 bg-red-100 rounded-full mr-6">
                  <svg style={{width:'5vw',height:'5vw'}}aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                  </svg>
                </div>
         
                  
                    <div>
                    <span className="block text-2xl fjalla" style={{fontSize:'4.5vw'}}>({underPerformingLength}){underPerformingStudents}%</span>
                  </div>
                  
           
               
              </div>
                    <span className="block text-gray-500 borel" style={{fontSize:'2.8vw',paddingLeft:'3vw',paddingBottom:'2vw'}}>Underperforming students</span>

              </div>
        <div className='border shadow'style={{width:'45%'}}>

              <div className="flex items-center  bg-white  rounded-lg" style={{padding:'4vw 3vw'}}>
                <div style={{width:'11vw',height:'11vw',marginRight:'2vw'}} className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
              
                  <svg style={{width:'5.5vw',height:'5.5vw'}}xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-currency-dollar h-6 w-6" viewBox="0 0 16 16">
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
      </svg>
                </div>
                <div>
                  <span className="block text-2xl fjalla" style={{fontSize:'4.5vw'}}>{totalEarnings} ETB</span>
                </div>
              </div>
                  <span className="block text-gray-500 borel" style={{fontSize:'2.8vw',paddingLeft:'3vw',paddingBottom:'2vw'}}>Total earning</span>

              </div>

    </div>
  
    <div style={{width:'90%',margin:'15vw auto'}}className=" bg-white shadow-md border rounded-lg mx-auto">
                <div className="flex items-center justify-center  border-b border-gray-100">
                  <span className='fjalla text-center capitalize ' style={{fontSize:'5vw',padding:'5vw 5vw'}}>Students by average mark</span>
             <div>
              </div>
                  {/* <!-- Refer here for full dropdown menu code: https://tailwindui.com/components/application-ui/elements/dropdowns --> */}
                </div>
                <div className="overflow-y-auto" >
                  <ul className="p-6 space-y-6">
                    {
                      studentResult.length == 0 ?
                      <div className='flex items-center flex-col justify-center'>
                        <img className='mx-auto' style={{width:'70%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro%20(1).svg?alt=media&token=ce654ce4-0e08-440b-b68d-93db825ae5be" alt="No Student" />
                        <p className='fjalla  text-center capitalize text-red-900' style={{fontSize:'4vw'}}> There is no student data available to display</p>
                      
                      </div>
                      :
                      studentResult.map(i=>{
                        const {fullName,photo,result} = i
                        return(
                          <li className="flex items-center">
                          <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                            {
          photo == '' ?  
                          <div
                            className=" fjalla flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
                          >
                            {fullName && fullName[0]}
                            
            
                          </div>
                          :
<img className="w-full rounded-full mr-1 border" src={photo} alt="Instructor"  />
      
            
                          }
                          </div>
                          <span className="text-gray-600 fjalla" style={{fontSize:'4vw'}}>{fullName}</span>
                          <span className="ml-auto font-semibold fjalla" style={{fontSize:'4vw'}}>{result ? result : 0}</span>
                        </li>
                        )
                      })
                    }
          
                 
                  </ul>
                </div>
              </div>
    </div>


              </div>
      
              <div  id='tablet'>
                    <DashboardNav/>
                   
        <div className="flex-grow text-gray-800">
        <main className="p-6 sm:p-10 space-y-6">
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
              <div className="flex justify-between w-full items-center">
                <h1 className="text-4xl fjalla " style={{fontSize:'30px'}}>Dashboard</h1>
                <Link to="/withdraw">
           <button style={{fontSize:'13px'}} className="inline-flex fjalla px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
                  Withdraw Funds
                </button></Link>
              </div>
          
             
            </div>
            <section className="">
         <div className='flex justify-evenly'>
         <div className="flex justify-center flex-col p-8 bg-white  rounded-lg shadow-md border" style={{width:'45%'}}>
         <div className='flex items-center'style={{marginBottom:'2vw'}}>
         <div className="inline-flex flex-shrink-0 items-center justify-center h-14 w-14 text-blue-600 bg-blue-100 rounded-full mr-6">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold"style={{fontSize:'20px'}}>{totalStudents ? totalStudents : 0}</span>
               
                </div>
         </div>
                <span className=" block text-gray-500 borel " style={{fontSize:'15px'}}>Total Students</span>

              </div>
              <div className="flex flex-col  p-8 bg-white shadow-md border rounded-lg" style={{width:'45%'}}>
          
              <div className='flex items-center'style={{marginBottom:'2vw'}}>
              <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-100 rounded-full mr-6">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold"style={{fontSize:'20px'}}>{ averageMark}</span>
                </div>

            </div>
            <span className="block  text-gray-500 borel " style={{fontSize:'15px'}}>Average mark</span>

              </div>

         </div>

         <div className='flex justify-evenly'style={{marginTop:'2vw'}}>

              <div className="flex flex-col p-8 bg-white shadow-md border  rounded-lg" style={{width:'45%'}}>
         <div className='flex items-center'style={{marginBottom:'2vw'}}>

               
                <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-red-600 bg-red-100 rounded-full mr-6">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                  </svg>
                </div>
         
                  
                    <div>

                    <span className="block text-2xl font-bold"style={{fontSize:'20px'}}>({underPerformingLength}){underPerformingStudents}%</span>
                  </div>
                  </div>

                  
                  <span className="block  text-gray-500 borel " style={{fontSize:'15px'}}>Underperforming students</span>
           
               
              </div>
              <div className="flex flex-col p-8 bg-white shadow-md border rounded-lg" style={{width:'45%'}}>
         <div className='flex items-center'style={{marginBottom:'2vw'}}>

                <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
              
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-currency-dollar h-6 w-6" viewBox="0 0 16 16">
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
      </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold"style={{fontSize:'20px'}}>{totalEarnings} ETB</span>

                </div>
              </div>
              <span className="block  text-gray-500 borel " style={{fontSize:'15px'}}>Total earning</span>

              </div>


              </div>

            </section>
            <section className="flex w-full justify-between mt-20">
    
      
       
              <div style={{width:'40%'}}className=" bg-white shadow-md border rounded-lg ">
                <div className="flex items-center justify-between px-6 py-5  border-b border-gray-100">
                  <span className='fjalla capitalize ' style={{fontSize:'20px'}}>Students by average mark</span>
             <div>
              </div>
                  {/* <!-- Refer here for full dropdown menu code: https://tailwindui.com/components/application-ui/elements/dropdowns --> */}
                </div>
                <div className="overflow-y-auto" >
                  <ul className="p-6 space-y-6">
                    {
                      studentResult.length == 0 ?
                      <div className='flex items-center flex-col justify-center'>
                        <img className='mx-auto' style={{width:'70%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro%20(1).svg?alt=media&token=ce654ce4-0e08-440b-b68d-93db825ae5be" alt="No Student" />
                        <p className='fjalla  text-center capitalize text-red-900' style={{fontSize:'15px'}}> There is no student data available to display</p>
                      
                      </div>
                      :
                      studentResult.map(i=>{
                        const {fullName,photo,result} = i
                        return(
                          <li className="flex items-center">
                          <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                            {
          photo == '' ?  
                          <div
                            className=" fjalla flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
                          >
                            {fullName && fullName[0]}
                            
            
                          </div>
                          :
<img className=" rounded-full mr-1 border w-full" src={photo} alt="Instructor"  />
      
            
                          }
                          </div>
                          <span className="text-gray-600">{fullName}</span>
                          <span className="ml-auto font-semibold">{result ? result : 0}</span>
                        </li>
                        )
                      })
                    }
          
                 
                  </ul>
                </div>
              </div>
             
            </section>
      
          </main>
       
        </div>
      </div>
      <div  id='desktop'>
 
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   border-b border-blue-800' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
      
            {
              teacher.map(i=>{
            const {teacherName,teacherEmail} =i
      return(
        <body className="flex  min-h-screen">
       
        <div className="flex-grow text-gray-800">
        <main className=" sm:p-6">
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
              <div className="ml-2">
                <h1 className="text-4xl font-semibold mb-2" style={{fontSize:'27px'}}>Dashboard</h1>
                {/* <h2 className="text-gray-600 ml-0.5">Mobile UX/UI Design course</h2> */}
              </div>
              <div className="flex flex-wrap items-start justify-end -mb-3 mr-1.5 mt-1.2">
           <Link to="/withdraw">
           <button style={{fontSize:'12px'}} className="inline-flex px-4 py-2.5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
                  Withdraw Funds
                </button></Link>
      
        
              </div>
             
            </div>
            <section className="mt-4 mb-10 grid md:grid-cols-2 xl:grid-cols-4 gap-6">
              <div className="flex items-center p-7 bg-white shadow rounded-lg">
                <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-blue-600 bg-blue-100 rounded-full mr-5">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold" style={{fontSize:'18px'}}>{totalStudents ? totalStudents : 0}</span>
                  <span className="block text-gray-500"style={{fontSize:'12px'}} >Total Students</span>
                </div>
              </div>
              <div className="flex items-center p-6 bg-white shadow rounded-lg">
                <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-green-600 bg-green-100 rounded-full mr-5">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold" style={{fontSize:'18px'}}>{ averageMark}</span>
                  <span className="block text-gray-500"style={{fontSize:'12px'}}>Average mark</span>
                </div>
              </div>
              <div className="flex items-center p-6 bg-white shadow rounded-lg">
                <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-red-600 bg-red-100 rounded-full mr-5">
                  <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                  </svg>
                </div>
         
                  
                    <div>
                    <span className="inline-block text-2xl font-bold"></span>
                    <span className="inline-block text-xl text-gray-500 font-semibold"style={{fontSize:'18px'}}>({underPerformingLength}){underPerformingStudents}%</span>
                    <span className="block text-gray-500"style={{fontSize:'12px'}}>Underperforming students</span>
                  </div>
                  
           
               
              </div>
              <div className="flex items-center p-6 bg-white shadow rounded-lg">
                <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-blue-600 bg-blue-100 rounded-full mr-5">
              
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-currency-dollar h-5 w-5" viewBox="0 0 16 16">
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
      </svg>
                </div>
                <div>
                  <span className="block text-2xl font-bold" style={{fontSize:'18px'}}>{totalEarnings} ETB</span>
                  <span className="block text-gray-500"style={{fontSize:'12px'}}>Total earning</span>
                </div>
                
              </div>
            </section>
            <section className="flex w-full justify-between">
          
      
       
              <div style={{width:'30%'}}className=" bg-white shadow rounded-lg">
                <div className="flex items-center justify-between px-6 py-5 font-semibold border-b border-gray-100">
                  <span className='fjalla capitalize ' style={{fontSize:'13px'}}>Students by average mark</span>
             <div>
              </div>
                  {/* <!-- Refer here for full dropdown menu code: https://tailwindui.com/components/application-ui/elements/dropdowns --> */}
                </div>
                <div className="overflow-y-auto" >
                  <ul className="p-6 space-y-6">
                    {
                      studentResult.length == 0 ?
                      <div className='flex items-center flex-col justify-center'>
                        <img className='mx-auto' style={{width:'70%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/college%20students-bro%20(1).svg?alt=media&token=ce654ce4-0e08-440b-b68d-93db825ae5be" alt="No Student" />
                        <p className='mt-4 text-center capitalize text-red-900 fjalla' style={{fontSize:'18px'}}> There is no student data available to display</p>
                      
                      </div>
                      :
                      studentResult.map(i=>{
                        const {fullName,photo,result} = i
                        return(
                          <li className="flex items-center">
                          <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                            {
          photo == '' ?  
                          <div
                            className=" fjalla flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
                          >
                            {fullName && fullName[0]}
                            
            
                          </div>
                          :
<img className="w-full rounded-full mr-1 border " src={photo} alt="Instructor"  />
      
            
                          }
                          </div>
                          <span className="text-gray-600">{fullName}</span>
                          <span className="ml-auto font-semibold">{result ? result : 0}</span>
                        </li>
                        )
                      })
                    }
          
                 
                  </ul>
                </div>
              </div>
             
            </section>
      
          </main>
       
        </div>
      </body>
      )
              })
            }
      
      
      </div>

              
      
      
          </div>
        )
      })
    }
   </div>
  )
}
