import React, { useState,useEffect } from 'react'
import Nav from '../home/Nav'
import { Link, useNavigate } from 'react-router-dom'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { addDoc, collection, doc, setDoc,query,where,onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

import {auth}from "../firebase"

export default function Signin() {
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [emailError,setEmailError] = useState("")
    const [registrationError,setregistrationError] = useState("")
    const [popSignin,setpopSignin] = useState(false)
    const [forgotPassword,setforgotPassword] = useState(false)
    const [sent,setsent] = useState(false)
    const [loading,setloading] = useState(false)
    const [spinner,setspinner] = useState(true)
    const [isLoading,setisLoading] = useState(false)


    const [resetEmail,setresetEmail] = useState('')
    const [resetEmailError,setresetEmailError] = useState('')
    const [resetFunctionError,setresetFunctionError] = useState('')
    const [duplicate,setduplicate] = useState(false)









    const [passwordError,setPasswordError] = useState("")
    const navigate = useNavigate()


    function emailSet(e){
        setEmail(e.target.value)

        if(e.target.value.length == 0){
            setEmailError("error")
        }

        else{
            setEmailError("")
            
        }

    }

    function passwordSet(e){
        setPassword(e.target.value)

        if(e.target.value.length == 0){
            setPasswordError("error")
        }

        else{
            setPasswordError("")
            
        }

    }
    function signIn(e) {
        e.preventDefault();
        if(email.length === 0){
          setEmailError('error')
        }
        else{
          if(password.length === 0){
            setPasswordError('error')

          }
          else{
            if(email.length !=0 && password.length !=0){
              signInWithEmailAndPassword(auth, email, password).then(setisLoading(true))
              .then(() => {
                navigate("/dashboard");
            })
            .catch((error) => {
              setisLoading(false)
                // Handle specific error cases
                if (error.code === "auth/user-not-found") {
                    setregistrationError('User not found.');
                } else if (error.code === "auth/invalid-login-credentials") {
                    setregistrationError('Invalid login credentials');
                } else if (error.code === "auth/network-request-failed") {
                  setregistrationError('Check your Internet Connection');
      
                }
                else if (error.code === "auth/invalid-email") {
                  setregistrationError('Invalid login credentials');
      
                }
                 else {
                    setregistrationError(error.message);
                }
            });
             }
          }

        }
      
      
       
    }



       function popOpenSet(){
        if(popSignin === false){
          setpopSignin(true)
        }
        else{
          setpopSignin(false)
        }
       }

       function popRemove(){
        setpopSignin(false)
       }
       function resetPassword(e) {
        if (resetEmail.length === 0) {
          setresetEmailError('error');
        } else {
          setloading(true);
      
          sendPasswordResetEmail(auth, resetEmail)
            .then(() => {
              setloading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetFunctionError(errorMessage);
              setloading(false);
            });
        }
      }

       function minimizeResetPassword(){
        setsent(false)
        setforgotPassword(false)
        setresetEmail('')
       }

       function openResetPassword(){
        setsent(false)
        setforgotPassword(true)
       }

      function setResetPasswordEmail(e){
        setresetEmail(e.target.value)
        setduplicate(false)
        if(e.target.value.length == 0){
          setresetEmailError('error')
        }
        else{
          setresetEmailError('')
        }

      }

      
    useEffect(() => {
        
      (async()=>{
    
        const q = query(collection(firestore, "TotalUsers"), where("email", "==", resetEmail))

      if(resetEmail){
        onSnapshot(q,snapshot=>{
          snapshot.forEach((doc) => {
            
            if(doc.exists ){
              setduplicate(true)
            }
    
            else {
              setduplicate(false)
            }
           
     })
    
        });
      }
     
      })
     ()
    }, [resetEmail]);

    function desktopSpinnerHandler(){
      setspinner(false)
     }
  return (
  

    <div className='relative'>
   {/* {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  className="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  } */}
<div id='desktop' className='h-full relative'>
{
    forgotPassword == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center' >
              {
                loading == true ?
                <div className=" mx-auto  my-5 bg-white rounded flex items-center justify-center" style={{width:"80%",height:"80%",paddingTop:'5vw'}}>
 
                <div className="relative">
                <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             </div>
             :
                  </div>
                  :
                  sent == true ?
                  <div id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"80%"}}>
<div className="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
 
   <button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div>
:
<main className="relative mx-auto   bg-white rounded" style={{width:"80%",height:"80%"}}>

<button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-8 h-8 text-gray-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>

          <p className="w-full  font-medium text-center mb-2 leading-snug font-serif text-gray-800 mt-12"style={{fontSize:"30px"}}>Forgot Password</p>
          <p className='borel text-center mb-8 text-gray-500'>We'll send you a link to reset your password</p>
          <div className={`${resetEmailError == "error" ? "block":"hidden"}`} style={{width:'90%',margin:'auto'}}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Email can't be empty
    </div>
    </div>
    </div>
    <div className={`${resetEmail.length != 0 && duplicate == false  ? "block":"hidden"}`} style={{width:'90%',margin:'auto'}}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     No User Found
    </div>
    </div>
    </div>

<div className="relative mb-10 mx-auto" style={{width:'90%'}}>

<p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
   absolute barlow">Email</p>
<input style={{height:'70px',fontSize:'12px'}} required  onChange={e=>setResetPasswordEmail(e)} placeholder="yohannes@gmail.com " type="email" 
className={`${"borel lowercase border input-field-signUp placeholder-gray-400 focus:outline-none focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white border-gray-300 rounded-md" }

`}/>

</div>

            <div className="relative mx-auto" style={{width:'90%'}}>
      
<button  onClick={resetPassword} style={{height:'60px',fontSize:'15px',color:'white'}}  className="barlow w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Recover Password
</button>

            </div>

    </main>

              }
      
    
    
    
    </div>
  }

<div className="flex justify-between items-center ">
    
    <div style={{width:'40%'}} className=" h-full">
      
    <div className="absolute top-0 let-0 ">
        <div>
          <Link  to='/' style={{width:'12%',display:'flex'}} className='mt-10 ml-10'>
              <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />

          </Link>
    </div>
    </div>
          <img style={{width:'80%'}} className='mx-auto mt-14' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/Add%20a%20heading%20(1).png?alt=media&token=4aee755f-36c5-4f31-8aff-d1b3b70f52b8" alt="Teacher" />
          
        
     
   
    </div>
    <div style={{width:'60%'}} className="flex justify-center flex-col py-10 mt-20">
       
       
       <div class="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 ">
         <div class="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-md border rounded-xl
             relative z-10">
              
           <p class="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800">Sign In</p>
        
    <div  autoComplete="off" className=" mt-6 mr-0 mb-0 ml-0 relative space-y-8  " id="first_modal" style={{width:"80%",margin:'auto'}}>
               <div className={`${registrationError.length != 0 ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     {registrationError}
    </div>
    </div>
    </div>
           
                <div className="relative mb-10">
                  <p className="barlow bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute" style={{fontSize:'13px'}}>Email</p>
                  <input onChange={e=>emailSet(e)} style={{color:"black",fontSize:'11px'}} required  autoComplete="off"  placeholder="yohannes@gmail.com" type="text" className="border  focus:outline-none
                      focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                      border-gray-300 rounded-md lowercase input-field-signUp"/>
                               <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Email Can't be empty
    </div>
    </div>
    </div>
                </div>
                <div className="relative">
                  <p className="barlow bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute" style={{fontSize:'13px'}}>Password</p>
                  <input  onChange={e=>passwordSet(e)}style={{color:"black",fontSize:'11px'}} required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
                      focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                      border-gray-300 rounded-md lowercase input-field-signUp"/>
                                            <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Password Can't be empty
    </div>
    </div>
    </div>
                </div>
         
                <div className="relative" >
  {
    isLoading == true ?
    <button style={{height:'60px',fontSize:'15px',color:'white'}}  className="barlow w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
    rounded-lg transition duration-200 bg-[#1333c0] cursor-not-allowed ease ">Sign In
 
<div role="status">
<svg aria-hidden="true" class="w-5 h-5 ml-2 text-white animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
<span class="sr-only">Loading...</span>
</div>
</button>
:
<button onClick={signIn} style={{height:'60px',fontSize:'15px',color:'white'}}  className="barlow w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
rounded-lg transition duration-200 bg-[#2141ce] hover:bg-[#1333c0] cursor-pointer">Sign In
</button>
  }
    <p onClick={openResetPassword} className="w-full font-medium text-center leading-snug font-serif text-blue-800 my-6 mb-12 cursor-pointer" style={{fontSize:"13px"}}>Forgot Password?</p>

    </div>
              
              </div>
  
         </div>
         <svg viewbox="0 0 91 91" class="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
         <svg viewbox="0 0 91 91" class="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
             fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
             cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
             r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
             cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
             transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
             r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
             cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
             r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
             cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
             cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
             r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
             transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
             r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
             cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
             r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
             cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
             cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
             r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
             transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
             r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
             cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
             r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
             cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
             cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
             r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
             transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
             r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
             cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
             r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
       </div>
   
 
   </div>
  
  </div>

</div>

<div id='mobile'  >
  {
    forgotPassword == true &&
    <div  className='uploadFileDesktop ' style={{zIndex:1000}}>
      {
            loading == true ?
         <div className=" mx-auto  my-5 bg-white rounded flex items-center justify-center" style={{width:"80%",height:"80%",paddingTop:'5vw'}}>
 
            <div className="relative">
            <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
            <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         </div>
         :
              </div>
              :
        sent == false ?
        <main className="relative mx-auto  my-5 bg-white rounded" style={{width:"80%",height:"80%",paddingTop:'5vw'}}>
          <button onClick={minimizeResetPassword}  type="button" className="text-gray-900 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
            <svg aria-hidden="true" style={{width:'7vw',height:'7vw'}} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span className="sr-only">Close modal</span>
        </button>
        <p className="w-full  text-4xl font-medium text-center leading-snug font-serif text-blue-800 " style={{fontSize:"7vw",marginTop:'8vw',padding:0}}>Forgot password</p>
                <p className='borel text-center text-gray-500' style={{marginBottom:'7vw',fontSize:'3vw'}}> We'll send you a link to reset your password.</p>
                <div className={`${resetEmailError.length != 0 ? "block":"hidden"}`}>
    <div style={{marginBottom:'5vw',paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',marginTop:'5vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
     Email can't be empty
    </div>
    </div>
    </div>
    <div className={`${duplicate == false && resetEmail.length !=0   ? "block":"hidden"}`}>
    <div style={{marginBottom:'5vw',paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',marginTop:'5vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
     No User found
    </div>
    </div>
    </div>    
        <div className="relative m-auto " style={{width:"100%",margin:"5vw 0"}}>
     <p className="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>
     
                <input  placeholder="yohannes@gmail.com"   className="w-full m-auto form-control text-black border  focus:outline-none
                      focus:border-black w-full  text-gray-700 block bg-white
                      border-gray-300 rounded-md" style={{height:'20vw',paddingLeft:"3vw",fontSize:"3vw"}} type='email'  onChange={e=>setResetPasswordEmail(e)}/>
             <div className='flex w-full justify-center'>
             <button onClick={resetPassword} type="submit" style={{marginTop:'5vw', backgroundColor: "#2141ce", fontSize: "4vw", width: "50vw", height: "12vw" }} className="w-full flex justify-center items-center  barlow text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
         Recover Password
       </button>
             </div>
        </div>
        
        
        </main>
    
             :
             <div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',backgroundColor:'blue',zIndex:'100'}} className='flex items-center justify-center flex-col'>
        <button onClick={minimizeResetPassword}  type="button" className="text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
            <svg aria-hidden="true" style={{width:'7vw',height:'7vw'}} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span className="sr-only">Close modal</span>
        </button>
        
        <div style={{width:'12vw',height:'12vw'}}className=" rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
            <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
            <span className="sr-only">Success</span>
        </div>
        <p className="barlow   capitalize " style={{fontSize:'10vw',color:'white'}}>Email has been sent</p>
        <p className="borel  capitalize " style={{fontSize:'3vw',color:'white'}}>Please check your email inbox for a password recovery link</p>
        
        
        
        
        
        
        </div>
  
      }
 
   
   </div>
   


  }

<div className='body'>
            <div>
            {
              popSignin === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button className=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span className="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={popOpenSet} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
                } 
   

   {
              popSignin === false &&
              <div className="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div className="navigation-bar__left-container">
              <div className="flex w-full justify-between items-center">
              <Link to='/'style={{width:'20%'}}>
             <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
             </Link>
                
        
       
     
                
          
             <div className='flex'>
             {/* <Link to='/signin'>
              <div className="navbar-item__container
            
            header-button-one"><div  className="navbar-item__button border-button hover:bg-[#eeeeee]
                navbar-item__button-default" ><span style={{fontSize:'3vw'}} className="navbar-item__button-text">Log in</span></div></div>
                
                </Link> */}
              <Link to='/signup/student' className='mx-2'>
                
                <div className="navbar-item__container
            
            header-button-two"><div  className="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} className="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></div></div>
                </Link>
                <button onClick={popOpenSet} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                           <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                         </button>
             </div>
                </div>
        
                   
              
                   
      
                
                      
      
                       
                  </div>
                 
                  </div>
             }

            

        </div>
        <div className='mobile__homebody pt-[64px]'  onClick={popRemove}>
 



   
      
        <div className=" relative " id='fullMobile'>


<div className="flex flex-col items-center justify-center   ml-auto 
     ">
  <div className="flex flex-col items-center w-full "style={{width:"100%"}}>
 
    <div className="w-full  relative z-10   ">
    <div id='mobile' >

<div className='body'>
        
        <div className='mobile__homebody' onClick={popRemove}>
 



   
      
   
    <div className="w-full  relative z-10   "style={{marginTop:"5vw",width:"100%"}}>
      <div className="flex flex-col items-start justify-start    rounded-xl
          relative z-10  w-full " style={{paddingTop:"8vw",paddingBottom:"8vw"}}>
            

     

 

        <div onSubmit={signIn}   className="w-full  " id="third_modalMobile" style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>

<p className="w-full  text-4xl font-medium text-center leading-snug font-serif text-blue-800" style={{fontSize:"7vw",margin:0,padding:0}}>Sign In</p>
<div className={`${registrationError.length != 0 ? "block":"hidden"}`}>
    <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',marginTop:'5vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
     {registrationError}
    </div>
    </div>
    </div>
<div className="relative m-auto " style={{width:"100%",marginTop:"4vw",marginBottom:"4vw"}}>
           
           <p className="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Email</p>

           <input type='email'  placeholder='abebe@gmail.com' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{height:'20vw',paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>emailSet(e)}/>
                  <div style={{width:'90%',margin:'auto'}}className={`${emailError.length != 0 ? "block":"hidden"}`}>
    <div style={{marginBottom:'5vw',paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
     Email can't be empty
    </div>
    </div>
    </div>
   </div>
   <div className="relative m-auto " style={{width:"100%",margin:"5vw 0"}}>
           
           <p className="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Password</p>

           <input  placeholder="•••••••••"   className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{height:'20vw',paddingLeft:"3vw",fontSize:"3vw"}} type='password'  onChange={e=>passwordSet(e)}/>
                     <div style={{width:'90%',margin:'auto'}} className={`${passwordError=== 'error' ? "block":"hidden"}`}>
    <div style={{marginBottom:'5vw',paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
     Password can't be empty
    </div>
    </div>
    </div>
   </div>

       
   <div className="flex justify-center">
  <button onClick={signIn} style={{ backgroundColor: "#2141ce", fontSize: "3.5vw", width: "50vw", height: "12vw" }} className="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer barlow">
    Sign In
  </button>
</div>
<p onClick={openResetPassword} className="w-full text-4xl font-medium text-center leading-snug font-serif text-blue-800" style={{fontSize:"3vw",marginTop:'3vw'}}>Forgot Password?</p>

        </div>

      
      </div>
   
    </div>






 
</div>
            </div>
            </div>

   
    </div>
  </div>
</div>
</div>





 
</div>
            </div>
            </div>


            <div id='tablet'>
    
            {
    forgotPassword == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center' >
              {
                loading == true ?
                <div className=" mx-auto  my-5 bg-white rounded flex items-center justify-center" style={{width:"80%",height:"80%",paddingTop:'5vw'}}>
 
                <div className="relative">
                <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             </div>
             :
                  </div>
                  :
                  sent == true ?
                  <div id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"80%"}}>
<div className="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
 
   <button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div>
:
<main className="relative mx-auto   bg-white rounded" style={{width:"80%",height:"80%"}}>

<button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-8 h-8 text-gray-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>

          <p className="w-full  font-medium text-center mb-2 leading-snug font-serif text-gray-800 mt-12"style={{fontSize:"30px"}}>Forgot Password</p>
          <p className='borel text-center mb-8 text-gray-500'>We'll send you a link to reset your password</p>
          <div className={`${resetEmailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Email can't be empty
    </div>
    </div>
    </div>
    <div className={`${resetEmail.length != 0 && duplicate == false  ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     No User Found
    </div>
    </div>
    </div>

<div className="relative mb-10 mx-auto" style={{width:'90%'}}>

<p style={{fontSize:'15px'}} className="bg-white barlow pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
   absolute barlow">Email</p>

      <input onChange={e=>setResetPasswordEmail(e)}  style={{color:"black",fontSize:'11px'}} required  autoComplete="off"  placeholder="yohannes@gmail.com" type="text" className="border  focus:outline-none
                      focus:border-black w-full pt-5 pr-4 pb-5 pl-4  mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                      border-gray-300 rounded-md lowercase input-field-signUp"/>

</div>

            <div className="relative mx-auto" style={{width:'90%'}}>
            <button onClick={resetPassword} style={{backgroundColor:"#2141ce" ,height:'60px',color:'white'}}  className="w-full flex justify-center barlow  items-center pt-4 pr-5 pb-4 pl-5  text-center text-white 
                  rounded-lg transition duration-200   hover:bg-indigo-600 ease cursor-pointer barlow">Recover Password
</button>

            </div>

    </main>

              }
      
    
    
    
    </div>
  }
          {
              popSignin === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>

              <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

              <div className='flex justify-between w-full mt-3'>
                    <div>
                    </div>
                    <button onClick={popRemove} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-8 h-8 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
                  </div>
        
                 {
                  <li>
                  <Link to='/about'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">About Us</p> </Link>
              </li>
                  }
  
  {
                  <li>
                  <Link to='/contact'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Contact</p> </Link>
              </li>
                  }
           <li>
                  <Link to='/services'><p class="block py-2 pr-4 pl-3 report-card-text text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 " aria-current="page">Services</p> </Link>
              </li>
              
              </ul>
          </div>
                } 
   

   {
              popSignin === false &&
              <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
              <div className="flex w-full justify-between items-center">
              <Link to='/'style={{width:'14%',marginRight:'50px'}}>
             <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
             </Link>
                
        
       
     
                
          
             <div className='flex'>
             <Link to='/signin'>
              <div class="navbar-item__container
            
            header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
                navbar-item__button-default" ><span style={{fontSize:'11px'}} class="navbar-item__button-text">Log in</span></a></div>
                
                </Link>
              <Link to='/signup/student' className='mx-2'>
                
                <div class="navbar-item__container
            
            header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa',fontSize:'11px'}}><span style={{fontSize:'11px'}} class="navbar-item__button-text text-gray-900 " >Register as a student</span></a></div>
                </Link>
                <button onClick={popOpenSet}  data-collapse-toggle="mega-menu" type="button" class="inline-flex items-center p-2  text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="mega-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg aria-hidden="true" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
             </div>
                </div>
        
                   
              
                   
      
                
                      
      
                       
                  </div>
                 
                  </div>
             }


    <div className="bg-white relative css-16pdqkj " id='full' onClick={popRemove}>


  <div className="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl
      xl:px-5 ">
    <div className="flex  items-center w-full pt-5 pr-10 pb-20 pl-10 lg:pt-20 ">
   
      <div className="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
        <div className="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl 
            relative z-10">
              
    

      

          <div   className="w-full mt-6 mr-0 mb-0 ml-0 space-y-8 py-20" id="third_modal" style={{height:"65vh",display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <p className="w-full  font-medium text-center mb-0 leading-snug font-serif text-gray-800"style={{fontSize:"20px"}}>Sign In</p>
          <p className="w-full font-medium text-center leading-snug font-serif text-blue-800 my-6 mb-12" style={{fontSize:"15px"}}>Don't have an account?<Link to="/signup/student" className='pl-2 underline'>Sign Up</Link></p>




          <div className="relative mb-10">
                  <p className="barlow bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute" style={{fontSize:'13px'}}>Email</p>
                  <input onChange={e=>emailSet(e)} style={{color:"black",fontSize:'11px'}} required  autoComplete="off"  placeholder="yohannes@gmail.com" type="text" className="border  focus:outline-none
                      focus:border-black w-full pt-5 pr-4 pb-5 pl-4  mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                      border-gray-300 rounded-md lowercase input-field-signUp"/>
                               <div className={`${emailError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Email Can't be empty
    </div>
    </div>
    </div>
                </div>
                <div className="relative">
                  <p className="barlow bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute" style={{fontSize:'13px'}}>Password</p>
                  <input  onChange={e=>passwordSet(e)}style={{color:"black",fontSize:'11px'}} required  autoComplete="off" placeholder="********" type="password" className="border  focus:outline-none
                      focus:border-black w-full pt-5 pr-4 pb-5 pl-4  mr-0 mb-0 borel ml-0 text-gray-700 block bg-white
                      border-gray-300 rounded-md lowercase input-field-signUp"/>
                                            <div className={`${passwordError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
     Password Can't be empty
    </div>
    </div>
    </div>
                </div>
            <div className="relative" >
            <button onClick={signIn} style={{backgroundColor:"#2141ce",color:'white' ,height:'60px'}}  className="w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5  text-center text-white 
                  rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer barlow">Sign in
</button>
<p onClick={openResetPassword} className="w-full font-medium text-center leading-snug font-serif text-blue-800 my-6 mb-12 cursor-pointer" style={{fontSize:"14px"}}>Forgot Password?</p>

            </div>
          </div>

        
        </div>
        <svg viewBox="0 0 91 91" className="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-purple-300
            fill-current"><g stroke="none" strokeWidth="1" fillRule="evenodd"><g fillRule="nonzero"><g><g><circle
            cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
            r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
            cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
            transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
            r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
            cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
            r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
            cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
            cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
            r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
            transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
            r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
            cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
            r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
            cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
            cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
            r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
            transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
            r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
            cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
            r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
            cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
            cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
            r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
            transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
            r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
            cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
            r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
        <svg viewBox="0 0 91 91" className="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
            fill-current"><g stroke="none" strokeWidth="1" fillRule="evenodd"><g fillRule="nonzero"><g><g><circle
            cx="3.261" cy="3.445" r="2.72"/><circle cx="15.296" cy="3.445" r="2.719"/><circle cx="27.333" cy="3.445"
            r="2.72"/><circle cx="39.369" cy="3.445" r="2.72"/><circle cx="51.405" cy="3.445" r="2.72"/><circle cx="63.441"
            cy="3.445" r="2.72"/><circle cx="75.479" cy="3.445" r="2.72"/><circle cx="87.514" cy="3.445" r="2.719"/></g><g
            transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72"/><circle cx="15.296" cy="3.525"
            r="2.719"/><circle cx="27.333" cy="3.525" r="2.72"/><circle cx="39.369" cy="3.525" r="2.72"/><circle
            cx="51.405" cy="3.525" r="2.72"/><circle cx="63.441" cy="3.525" r="2.72"/><circle cx="75.479" cy="3.525"
            r="2.72"/><circle cx="87.514" cy="3.525" r="2.719"/></g><g transform="translate(0 24)"><circle cx="3.261"
            cy="3.605" r="2.72"/><circle cx="15.296" cy="3.605" r="2.719"/><circle cx="27.333" cy="3.605" r="2.72"/><circle
            cx="39.369" cy="3.605" r="2.72"/><circle cx="51.405" cy="3.605" r="2.72"/><circle cx="63.441" cy="3.605"
            r="2.72"/><circle cx="75.479" cy="3.605" r="2.72"/><circle cx="87.514" cy="3.605" r="2.719"/></g><g
            transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72"/><circle cx="15.296" cy="3.686"
            r="2.719"/><circle cx="27.333" cy="3.686" r="2.72"/><circle cx="39.369" cy="3.686" r="2.72"/><circle
            cx="51.405" cy="3.686" r="2.72"/><circle cx="63.441" cy="3.686" r="2.72"/><circle cx="75.479" cy="3.686"
            r="2.72"/><circle cx="87.514" cy="3.686" r="2.719"/></g><g transform="translate(0 49)"><circle cx="3.261"
            cy="2.767" r="2.72"/><circle cx="15.296" cy="2.767" r="2.719"/><circle cx="27.333" cy="2.767" r="2.72"/><circle
            cx="39.369" cy="2.767" r="2.72"/><circle cx="51.405" cy="2.767" r="2.72"/><circle cx="63.441" cy="2.767"
            r="2.72"/><circle cx="75.479" cy="2.767" r="2.72"/><circle cx="87.514" cy="2.767" r="2.719"/></g><g
            transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72"/><circle cx="15.296" cy="2.846"
            r="2.719"/><circle cx="27.333" cy="2.846" r="2.72"/><circle cx="39.369" cy="2.846" r="2.72"/><circle
            cx="51.405" cy="2.846" r="2.72"/><circle cx="63.441" cy="2.846" r="2.72"/><circle cx="75.479" cy="2.846"
            r="2.72"/><circle cx="87.514" cy="2.846" r="2.719"/></g><g transform="translate(0 73)"><circle cx="3.261"
            cy="2.926" r="2.72"/><circle cx="15.296" cy="2.926" r="2.719"/><circle cx="27.333" cy="2.926" r="2.72"/><circle
            cx="39.369" cy="2.926" r="2.72"/><circle cx="51.405" cy="2.926" r="2.72"/><circle cx="63.441" cy="2.926"
            r="2.72"/><circle cx="75.479" cy="2.926" r="2.72"/><circle cx="87.514" cy="2.926" r="2.719"/></g><g
            transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72"/><circle cx="15.296" cy="3.006"
            r="2.719"/><circle cx="27.333" cy="3.006" r="2.72"/><circle cx="39.369" cy="3.006" r="2.72"/><circle
            cx="51.405" cy="3.006" r="2.72"/><circle cx="63.441" cy="3.006" r="2.72"/><circle cx="75.479" cy="3.006"
            r="2.72"/><circle cx="87.514" cy="3.006" r="2.719"/></g></g></g></g></svg>
      </div>
    </div>
  </div>
</div>
    </div>


    </div>
  )
}
