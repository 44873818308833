import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, serverTimestamp, updateDoc, setDoc, collection } from 'firebase/firestore';
import { auth } from '../firebase';
import { v4 } from 'uuid';
import moment from 'moment';

export const UserContext = createContext();

const Context = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [accountType, setAccountType] = useState('');
  const [userData, setUserData] = useState(null);

  function getCurrentDateTimeString() {
    const currentDate = new Date();
    return currentDate.toLocaleString('en-GB', {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Africa/Nairobi'
    });
  }

  function compareStringDates(date1, date2) {
    if (date1 && date2) {
      const date1Obj = new Date(Date.parse(date1));
      const date2Obj = new Date(Date.parse(date2));
      return date1Obj.getTime() - date2Obj.getTime();
    }
  }

  const currentDateTime = getCurrentDateTimeString();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        
        onSnapshot(collection(firestore, "AccountDetails", auth.currentUser.uid,'CoursesCreated'),snap=>{
          
          snap.docs.forEach(snapDoc=>{
            onSnapshot(collection(firestore, "AccountDetails", auth.currentUser.uid,'CoursesCreated',snapDoc.data().courseId,'Students'),studentDoc=>{
              studentDoc.docs.forEach(studentDoc2=>{
                console.log(studentDoc2.data())

            })
          })
    
          })
    
        })
        const userRef = doc(firestore, "TotalUsers", user.uid);
        if (navigator.onLine) {
          if (user.uid.length !== 0) {
            const unsubscribe = onSnapshot(userRef, (snapshot) => {
              setAccountType(snapshot.data()?.accountType);
              setUserData(snapshot.data());
            });
          }
        } else {
          // Handle offline state
        }
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
   
    if (userData?.billingDate) {
      const currentTime = moment();
      const billingTime = moment(userData.billingDate);
  
      if (currentTime.isAfter(billingTime)) {
        updateUserData();
      }
    }
  }, [userData]);

  const updateUserData = async () => {
    const userRef = doc(firestore, "TotalUsers", auth.currentUser.uid);
    try {
      await updateDoc(userRef, {
        plan: 'Free',
        billingDate: '',
        state: 'Declined',
        status: 'Passed',
      });
      await setDoc(
        doc(firestore, 'AccountDetails', auth.currentUser.uid, 'Notification', v4()),
        {
          notificationTitle: `We regret to inform you that your Kesht ${userData.plan} ${
            userData.length === 'month'
              ? 'monthly'
              : userData.length === '3months'
              ? '3 months'
              : 'Annual'
          } Membership has expired.`,
          time: serverTimestamp(),
          seen: false,
        }
      );
    } catch (error) {
      console.error('Error in updateUserData:', error);
    }
  };


  
  return (
    <UserContext.Provider value={{ accountType, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const UseAuth = () => {
  return useContext(UserContext);
};

export default Context;