import { collection, doc, getDocs, onSnapshot, query, where,deleteDoc, orderBy } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';

export default function EnrolledCourses() {
  const [reason,setreason] = useState(false)
  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)
  const [uid,setuid] = useState('')


  const [user,setUser] = useState([])
  const [pendingCourses,setpendingCourses] = useState([])
  const [notification,setnotification] = useState([])


  const navigate = useNavigate()
  const [spinner,setspinner] =  useState(true)
  const [completed,setcompleted] =  useState(true)
  const [pendingLoading,setpendingLoading] =  useState(false)



  const [isLoading, setIsLoading] = useState(true); // For loading state


    

  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    deleteDoc(notificationRef)
    .then(() => {
      // Document successfully deleted from the database
      // Now remove the item from the array
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
      console.error("Error deleting document: ", error);
    });


   }

function desktopSpinnerHandler(){
  setspinner(false)
 }
// Profile Photo Click Handler
const popProfile = () =>{
  if(pop == true){
    setpop(false)
    setnotificationPop(false)

  }
  else{
  setpop(true)
  setnotificationPop(false)


  }
}
const popNotification = () =>{
if(notificationPop == true){
  setnotificationPop(false)
  setpop(false)
}
else{
setnotificationPop(true)
setpop(false)



}
}

function handleLogOut(){

}


    const [completedCourses, setCompletedCourses] = useState([]);

 
    
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setuid(user.uid)
            // Fetch user data (replace with your actual logic)
            await onSnapshot(doc(firestore, 'TotalUsers', user.uid),snapshot=>{
              setUser([{...snapshot.data()}]);

            })
            // Fetch completed courses using onSnapshot
            const completedCourseIds = await fetchCompletedCourseIds(user.uid);
            if (completedCourseIds.completedCourseIds.length !== 0) {
              
              const completedSubcollectionRef = collection(firestore, 'Courses');
              const completedQuery = query(
                completedSubcollectionRef,
                where('courseId', 'in', completedCourseIds.completedCourseIds)
              );
              const completedSnapshot = await getDocs(completedQuery);
              const courses = [];
              completedSnapshot.forEach((doc) => {
                const courseData = { ...doc.data(), id: doc.id };
                const index = completedCourseIds.completedCourseIds.indexOf(doc.id);
                if (index !== -1) {
                  const enrollmentDate = completedCourseIds.enrollmentDates[index];
                  const progress = completedCourseIds.progress[index];
                  const tookCertificate = completedCourseIds.tookCertificate;
                  

                  if (enrollmentDate) {
                    courseData.enrollmentDate = enrollmentDate;
                  }
                    courseData.progressPercentage = progress;
                    courseData.tookCertificate = tookCertificate;

                }

                courses.push(courseData);
              });
              setCompletedCourses(courses);
              
              setTimeout(() => {
                
                
              }, 200);
            } else {
              console.log('No completed courses found');
              // Set empty state or loading state if no courses
            }
    
            // Fetch pending courses using onSnapshot
            const pendingCourseIds = await fetchPendingCourseIds(user.uid);
            if (pendingCourseIds.pendingCourseIds.length !== 0) {
              const pendingSubcollectionRef = collection(firestore, 'Courses');
              const pendingQuery = query(
                pendingSubcollectionRef,
                where('courseId', 'in', pendingCourseIds.pendingCourseIds)
              );
            
              const pendingSnapshot = await getDocs(pendingQuery);
            
              const courses = [];
              pendingSnapshot.forEach((doc) => {
                const courseData = { ...doc.data(), id: doc.id };
                const index = pendingCourseIds.pendingCourseIds.indexOf(doc.id);
                if (index !== -1) {
                  const enrollmentDate = pendingCourseIds.enrollmentDates[index];
                  const progress = pendingCourseIds.progress[index];

                  if (enrollmentDate) {
                    courseData.enrollmentDate = enrollmentDate;
                  }
                    courseData.progressPercentage = progress;
                }

                courses.push(courseData);
              })
          
              setpendingCourses(courses);
                setpendingLoading(false)
                    
            } else {
              console.log('No pending courses found');
              // Set empty state or loading state if no courses
            }
    
            setIsLoading(false); // Set loading state to false
          } else {
            // Handle unauthenticated state (redirect, etc.)
          }
        });
    
        return () => unsubscribe();
      }, []);
  
    
    // Implement fetchCompletedCourseIds and fetchPendingCourseIds based on your data structure
    async function fetchCompletedCourseIds(currentUserId) {
      try {
        const completedSubcollectionRef = collection(firestore, 'AccountDetails', currentUserId, 'Courses');
        const completedQuery = query(completedSubcollectionRef, where('completed', '==', true));
        const completedSnapshot = await getDocs(completedQuery);
        const enrollmentDates = [];
        const progress = [];
        const completedCourseIds = [];
        let tookCertificate = null;


        completedSnapshot.forEach((doc) => {
          completedCourseIds.push(doc.id); // Document ID is likely the 'courseId'
        });
        completedSnapshot.forEach((doc) => {
          const courseId = doc.id;
          const enrollmentDate = doc.data().enrollmentDate;
           // Assuming enrollmentDate is a field in the document

          const courseProgress = doc.data().progressPercentage;
          let tookCertificate2 = doc.data().tookCertificate;

          
          tookCertificate = tookCertificate2
          // Assuming progress is a field in the document
          completedCourseIds.push(courseId);
          enrollmentDates.push(enrollmentDate);
          progress.push(courseProgress);
        });
    
        return { completedCourseIds, enrollmentDates, progress,tookCertificate };

      }  catch (error) {
        console.error('Error fetching pending course IDs:', error);
        return { pendingCourseIds: [], enrollmentDates: [], progress: [] }; // Handle errors gracefully and potentially notify user
      }
    }
    
    async function fetchPendingCourseIds(currentUserId) {
      try {
        const pendingSubcollectionRef = collection(firestore, 'AccountDetails', currentUserId, 'Courses');
        const pendingQuery = query(pendingSubcollectionRef, where('completed', '==', false));
        const pendingSnapshot = await getDocs(pendingQuery);
    
        const pendingCourseIds = [];
        const enrollmentDates = [];
        const progress = [];
    
        pendingSnapshot.forEach((doc) => {
          const courseId = doc.id;
          const enrollmentDate = doc.data().enrollmentDate; // Assuming enrollmentDate is a field in the document
          const courseProgress = doc.data().progressPercentage; // Assuming progress is a field in the document
          pendingCourseIds.push(courseId);
          enrollmentDates.push(enrollmentDate);
          progress.push(courseProgress);
        });
    
        return { pendingCourseIds, enrollmentDates, progress };
      } catch (error) {
        console.error('Error fetching pending course IDs:', error);
        return { pendingCourseIds: [], enrollmentDates: [], progress: [] }; // Handle errors gracefully and potentially notify user
      }
    }
    
    const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString();
      };
  
      const orderedPendingCourses = pendingCourses.sort((b, a) => {
        const dateA = a.enrollmentDate.toDate();
        const dateB = b.enrollmentDate.toDate();
        return dateA - dateB;
      });

      const orderedCompletedCourses = completedCourses.sort((b, a) => {
        const dateA = a.enrollmentDate.toDate();
        const dateB = b.enrollmentDate.toDate();
        return dateA - dateB;
      });
  
      function handleLogout(){
        signOut(auth).then(
         navigate('/')
        )
       }

       function desktopSpinnerHandler(){
        setspinner(false)
       }

       function InprogressHandler(){
        setcompleted(true)
       }

       
       function completedHandler(){
        setcompleted(false)
       }
  return (
    <div>
 {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
    { 
    user.map(i=>{
     const{accountType,plan,fullName,photo} = i
     
   return(
     accountType == 'Student' ?
  
    
<div>
            <div id='desktop'>
           <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>

      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 oswald' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='oswald'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 oswald">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat  ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/enrolledcourses' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Enrolled Courses</li>
      
                    </Link>
      <Link to="/courses" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
                    <Link to="/achievements" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Achievements</li>
      
                    </Link>
      
           
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            
            
                         {
                           
                            completed == true ?
                            pendingCourses.length == 0 ?
                            pendingLoading == true ?
                            <div className='flex items-center justify-center h-full flex-col' >
                            <h1 className='anton'>Loading...</h1>
                            <p className='borel'>Fetching data</p>
                          </div>
                          :
                            <div className='flex  justify-center flex-col '>
                  
                            <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'11px'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                  <h1 className='oswald text-center mt-12' style={{fontSize:"30px",width:'100%',marginTop:'10px'}} >This is where you can find the courses you are currently enrolled in
                  
                        </h1>
                        <div className='flex items-center justify-center flex-col '>
                          <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                        
                        
                       
                          </div>
                        
                     
                          </div>:
                          <div>
                                   <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'11px'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                            

                {          orderedPendingCourses.map(i=>{
                            const {courseTitle,courseThumbnail,courseId,progressPercentage,enrollmentDate} = i
                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                              <div className='flex items-center  '>
                               <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                           <div>
                 <Link to={`/course/${courseId}`} >
                 <h1 style={{fontSize:'25px',width:'100%',padding:0}} className='bg-blue-800capitalize ml-4 mb-1 oswald cursor-pointer hover:text-blue-800 hover:underline'>
                                     {
                                     courseTitle
   
                                     }
                                   </h1></Link>
                              <p className='oswald text-gray-600 mb-1 ml-4' style={{fontSize:'12px'}}>{enrollmentDate}</p>
                                   <div class="w-full bg-neutral-200 ml-3">
       {
        progressPercentage >= 0 &&  progressPercentage <= 15?
        <div
        
        class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white`}
        style={{ width: progressPercentage + '%',height:'20px' }}
      >
      </div>
      :
      <div
      class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white`}
      style={{ width: progressPercentage + '%',height:'20px' }}
    >
      {Math.round(progressPercentage)} %
    </div>
       }
        </div>
                           </div>
                              </div>
                 <Link to={`/course/${courseId}`} >

                           <button   style={{fontSize:'15px'}}  className="bg-blue-800 oswald  pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">Continue Course
                                     </button>
                                     </Link>
   
   
                                 </div>
                            )
                          })}
                          </div>

                          :
                          completedCourses.length  == 0 ?
                          <div className='flex  justify-center flex-col '>
                
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                
                
                
                <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0}}>
                
                In Progress
                
                </li>
                
                
                <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0,borderBottom:"3px solid blue"}}>
                
                 <p className='block text-gray-900 text-center '>Completed</p>
                
                </li>
                </ol>
                <h1 className='oswald ml-5 text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Your completed courses will be listed here
                
                      </h1>
                      <div className='flex items-center justify-center flex-col '>
                        <img  onLoad={desktopSpinnerHandler}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                      
                      
                     
                        </div>
                        
                      
                   
                        </div>
                        :
                          
                          <div className='flex  justify-center flex-col '>
                  
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                    
                    
                    
                    
                    
                    
                    <li onClick={InprogressHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                    
                    <p className='block text-gray-900 text-center '>In Progress</p>
                    
                    </li>
                    <li onClick={completedHandler} class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px',borderBottom:"3px solid blue"}}>
                    <p className='block text-gray-900 text-center '>Completed</p>
                    
                    
                    
                    </li>
                    </ol>
                    
                {          orderedCompletedCourses.map(i=>{
                            const {tookCertificate,courseTitle,courseThumbnail,certificate,courseId,certificateId} = i
                           const progress = 100
                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                           <div className='flex items-center  mr-5'>
                            <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                        <div className='ml-5'>
                 <h1 style={{fontSize:'25px',wordBreak:'keep-all',padding:0,width:'100%',margin:'auto'}} className='text-center capitalize ml-4 mb-1 oswald cursor-pointer mb-5 hover:text-blue-800 hover:underline'>

                                  {
                                  courseTitle

                                  }
                                </h1>
                                <div class="w-full bg-neutral-200 ">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
                        </div>
                           </div>

             
         {
          tookCertificate == true && 
          <Link to={`/course/${courseId}/certificate/${courseId + uid}`} >

          <button  style={{fontSize:'15px',color:'white',marginRight:'8px'}}   className="bg-blue-800 oswald  pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">View Certificate
                    </button>
                    </Link>
         }

                              </div>
                            )
                          })}
                 
                   
                    
                        </div>
                         }
                
            
                  
                 </div>    
                 
                 <div id='tablet'>
      <DashboardNav/>
            
            
                         {
                          pendingLoading == true ?
                          <div className='flex items-center justify-center h-full flex-col' >
                          <h1 className='anton'>Loading...</h1>
                          <p className='borel'>Fetching data</p>
                        </div>
                          :
                            completed == true ?
                            pendingCourses.length == 0 ?
                            <div className='flex  justify-center flex-col '>
                  
                            <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'11px'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                  <h1 className='oswald ml-5 text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>This is where you can find the courses you are currently enrolled in
                  
                        </h1>
                        <div className='flex items-center justify-center flex-col '>
                          <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                        
                        
                       
                          </div>
                        
                     
                          </div>:
                          <div>
                                   <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'11px'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                            

                {          orderedPendingCourses.map(i=>{
                            const {courseTitle,courseThumbnail,courseId,progressPercentage,enrollmentDate} = i
                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                              <div className='flex items-center  ' style={{width:'80%'}}>
                               <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                           <div>
                 <Link to={`/course/${courseId}`} >
                 <h1 style={{fontSize:'20px'}} className='capitalize ml-4 mb-1 oswald cursor-pointer hover:text-blue-800 hover:underline'>
                                     {
                                     courseTitle
   
                                     }
                                   </h1></Link>
                              <p className='oswald text-gray-600 mb-1 ml-4' style={{fontSize:'12px'}}>{enrollmentDate}</p>
                                   <div class="w-full bg-neutral-200 ml-3 my-1">
       {
        progressPercentage >= 0 &&  progressPercentage <= 15?
        <div
        
        class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white`}
        style={{ width: progressPercentage + '%',height:'20px' }}
      >
      </div>
      :
      <div
      class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white`}
      style={{ width: progressPercentage + '%',height:'20px' }}
    >
      {Math.round(progressPercentage)} %
    </div>
       }
        </div>
                           </div>
                              </div>
                 <Link to={`/course/${courseId}`}  style={{width:'20%'}}>

                           <button   style={{fontSize:'15px'}}  className="bg-blue-800 oswald  pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">Continue 
                                     </button>
                                     </Link>
   
   
                                 </div>
                            )
                          })}
                          </div>

                          :
                          completedCourses.length  == 0 ?
                          <div className='flex  justify-center flex-col '>
                
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                
                
                
                <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0}}>
                
                In Progress
                
                </li>
                
                
                <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0,borderBottom:"3px solid blue"}}>
                
                 <p className='block text-gray-900 text-center '>Completed</p>
                
                </li>
                </ol>
                <h1 className='oswald ml-5 text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your completed courses will be listed here
                
                      </h1>
                      <div className='flex items-center justify-center flex-col '>
                        <img  onLoad={desktopSpinnerHandler}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                      
                      
                     
                        </div>
                        
                      
                   
                        </div>
                        :
                          
                          <div className='flex  justify-center flex-col '>
                  
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                    
                    
                    
                    
                    
                    
                    <li onClick={InprogressHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px'}}>
                    
                    <p className='block text-gray-900 text-center '>In Progress</p>
                    
                    </li>
                    <li onClick={completedHandler} class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",margin:0,fontSize:'11px',borderBottom:"3px solid blue"}}>
                    <p className='block text-gray-900 text-center '>Completed</p>
                    
                    
                    
                    </li>
                    </ol>
                    
                {          orderedCompletedCourses.map(i=>{
                            const {courseTitle,courseThumbnail,certificate,courseId,certificateId} = i
                           const progress = 100
                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                           <div className='flex items-center  '>
                            <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                        <div>
                        <h1 style={{fontSize:'25px',width:'100%',padding:0}} className='capitalize ml-4 mb-2 oswald cursor-pointer hover:text-blue-800 hover:underline'>
                                  {
                                  courseTitle

                                  }
                                </h1>
                                <div class="w-full bg-neutral-200 ml-3">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
                        </div>
                           </div>

             
         {
          certificate == true && 
          <Link to={`/course/${courseId}/certificate/${certificateId}`} >

          <button  style={{fontSize:'13px'}}   className="bg-blue-800 oswald  pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">View Certificate
                    </button>
                    </Link>
         }

                              </div>
                            )
                          })}
                 
                   
                    
                        </div>
                         }
                
            
                  
                 </div>  
                 <div id='mobile'>
      <DashboardNav/>
            
            
                         {
                          pendingLoading == true ?
                          <div className='flex items-center justify-center h-full flex-col' >
                          <h1 className='anton' style={{fontSize:'5vw'}}>Loading...</h1>
                          <p className='borel'style={{fontSize:'3vw'}}>Fetching data</p>
                        </div>
                          :
                            completed == true ?
                            pendingCourses.length == 0 ?
                            <div className='flex  justify-center flex-col '>
                  
                            <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg oswald" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'2.8vw'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l   cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg oswald" style={{width:"40%",margin:0,fontSize:'2.8vw'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                  <h1 className='oswald ml-5 text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>This is where you can find the courses you are currently enrolled in
                  
                        </h1>
                        <div className='flex items-center justify-center flex-col '>
                          <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                        
                        
                       
                          </div>
                        
                     
                          </div>:
                          <div>
                                   <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                  
                  
                  
                  <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",borderBottom:"3px solid blue",margin:0,fontSize:'2.8vw'}}>
                  
                  In Progress
                  
                  </li>
                  
                  
                  <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",margin:0,fontSize:'2.8vw'}}>
                  
                   <p className='block text-gray-900 text-center '>Completed</p>
                  
                  </li>
                  </ol>
                            

                {          orderedPendingCourses.map(i=>{
                            const {courseTitle,courseThumbnail,courseId,progressPercentage,enrollmentDate} = i
                            let title = courseTitle.length <= 50 ? courseTitle : courseTitle.slice(0,50)+ '...'
                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                              <div className='flex items-center  '>
                               <img style={{width:'23vw',height:'25vw',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                           <div className='overflow-hidden'>
                 <Link to={`/course/${courseId}`} >
                 <h1 style={{fontSize:'4vw'}} className='capitalize ml-4 mb-1 oswald cursor-pointer hover:text-blue-800 hover:underline'>
                                     {
                                     title
   
                                     }
                                   </h1></Link>
                              <p className='oswald text-gray-600 mb-1 ml-4' style={{fontSize:'2.5vw'}}>{enrollmentDate}</p>
                                   <div class="w-full bg-neutral-200 ml-3">
       {
        progressPercentage >= 0 &&  progressPercentage <= 15?
        <div
        
        class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white`}
        style={{ width: progressPercentage + '%',height:'5vw' }}
      >
      </div>
      :
      <div
      class={`bg-green-700 p-0.5 text-center text-xs font-medium leading-none text-white oswald flex items-center justify-center`}
      style={{ width: progressPercentage + '%',height:'5vw',fontSize:'3vw' }}
    >
      {Math.round(progressPercentage)} %
    </div>
       }
        </div>
                           </div>
                              </div>
                 <Link to={`/course/${courseId}`} >

                           <button   style={{fontSize:'2.5vw',height:'12vw',padding:'0 3vw',marginRight:'2vw'}}  className="bg-blue-800 flex justify-center items-center oswald  -medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize ">Continue 
                                     </button>
                                     </Link>
   
   
                                 </div>
                            )
                          })}
                          </div>

                          :
                          completedCourses.length  == 0 ?
                          <div className='flex  justify-center flex-col '>
                
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                
                
                
                <li onClick={InprogressHandler}  class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0}}>
                
                In Progress
                
                </li>
                
                
                <li onClick={completedHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg" style={{width:"40%",fontSize:'11px',margin:0,borderBottom:"3px solid blue"}}>
                
                 <p className='block text-gray-900 text-center '>Completed</p>
                
                </li>
                </ol>
                <h1 className='oswald ml-5 text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>Your completed courses will be listed here
                
                      </h1>
                      <div className='flex items-center justify-center flex-col '>
                        <img  onLoad={desktopSpinnerHandler}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>
                      
                      
                     
                        </div>
                        
                      
                   
                        </div>
                        :
                          
                          <div className='flex  justify-center flex-col '>
                  
                          <ol class="pt-8 flex items-center justify-center border-none  space-x-2 text-sm font-medium text-center text-gray-500      ">
                    
                    
                    
                    
                    
                    
                    <li onClick={InprogressHandler}  class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg oswald" style={{width:"40%",margin:0,fontSize:'2.8vw'}}>
                    
                    <p className='block text-gray-900 text-center '>In Progress</p>
                    
                    </li>
                    <li onClick={completedHandler} class=" flex items-center  text-gray-900 text-center justify-center border cursor-pointer  hover:bg-blue-50 pt-3 pb-3 rounded-lg oswald" style={{width:"40%",margin:0,fontSize:'2.8vw',borderBottom:"3px solid blue"}}>
                    <p className='block text-gray-900 text-center '>Completed</p>
                    
                    
                    
                    </li>
                    </ol>
                    
                {          orderedCompletedCourses.map(i=>{
                            const {courseTitle,courseThumbnail,certificate,courseId,certificateId,enrollmentDate} = i
                           const progress = 100
                           let title = courseTitle.length <= 50 ? courseTitle : courseTitle.slice(0,50)+ '...'

                            return(
                              <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                              <div className='flex items-center  '>
                               <img style={{width:'23vw',height:'25vw',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                           <div className='overflow-hidden'>
                 <Link to={`/course/${courseId}`} >
                 <h1 style={{fontSize:'4vw'}} className='capitalize ml-4 mb-1 oswald cursor-pointer hover:text-blue-800 hover:underline'>
                                     {
                                     title
   
                                     }
                                   </h1></Link>
                              <p className='oswald text-gray-600 mb-1 ml-4' style={{fontSize:'2.5vw'}}>{enrollmentDate}</p>
                                   <div class="w-full bg-neutral-200 ml-3">
   
        </div>
                           </div>
                              </div>
                              {
                                certificate == true &&
                 <Link to={`/course/${courseId}/certificate/${uid}`} >
                           <button   style={{fontSize:'2.5vw',height:'12vw',padding:'0 3vw',marginRight:'2vw'}}  className="bg-blue-800 flex justify-center items-center oswald  -medium text-center text-white 

                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize ">Certificate 
                                     </button>
                                     </Link>
                              }
   
   
                                 </div>
                            )
                          })}
                 
                   
                    
                        </div>
                         }
                
            
                  
                 </div>  
                 </div>
                 
          
      
     :
   navigate('/dashboard')
   )

       
     
    })
      
      }



</div>
  )
}
