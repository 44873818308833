import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import { Link } from 'react-router-dom'
import Pricing from './pricing'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, firestore } from '../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import Footer from './footer'

export default function Home() {
 const [uid,setUid] = useState("")
 const [course,setCourse] = useState([])
 const [pop,setPop] = useState(false)
 const [spinner,setspinner] = useState(false)



 // Mobile Nav Pop
 function navigationPop(){
     if(pop == true)
         setPop(false)
     else{
         setPop(true)
     }

     

 }




  
    // Tablet Nav Pop
    function tabletNavPop(){
        setPop(true)


  }

   // Tablet Nav Pop minimize
   function tabletNavPopMinimize(){
        setPop(false)


   }

     // Mobile Nav Pop
     function mobileNavPop(){
        setPop(true)


      

  }

  // Mobile Nav Pop minimize
  function mobileNavPopMinimize(){
    setPop(false)



  }


  useEffect(()=>{

  onSnapshot(collection(firestore,"Courses"),snapshot=>{
    let temp = []
    snapshot.docs.forEach(doc=>{
      temp.push({...doc.data(),id:doc.id})
      setCourse(temp)
      

    })
  })

  

  },[])

  function desktopSpinnerHandler(){
    setspinner(false)
   }

  return (
<div>
  
  {
    spinner == true &&
    <div id='spinnerDesktop'>
      <div
  className="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
  role="status">
  <span
    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div>

      </div>
  }
 


            <div id='desktop'className='' >
   
                  
                    <div className='relative ' id='desktop'>
               
               {/* Navigation */}
                  <Nav/>
  
        
               {/*  Body */}
              <div className=" " >
                <div className="mainBodyHomePage ">

                  <div className="MuiBox-root css-16pdqkj relative">
                    <div className="MuiBox-root css-vp313s">
                      <div className="MuiBox-root css-n8ap69">
                        <div className="MuiBox-root css-0 ">
                          <h1 className="MuiTypography-root MuiTypography-h1  oswald" style={{lineHeight:'110%'}}>
                            There has never been an <br />easier way to get <br/> high quality education
                          </h1>
                          <div className="MuiBox-root css-x2aj4f">
                          <Link
                            className="css-68zbsl"
                            to='/courses'
                          >
                            <button style={{fontSize:'13px'}} type="button" className="mainBodyHomePage-Btn barlow">
                             Courses
                            </button>
                          </Link>
                        </div>
                        </div>
                    
                      
                      </div>
                      <div className="flex ">
                        {/* <img
                          alt="Image"
                          loading="lazy"
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(24).png?alt=media&token=b125c8f5-2014-437b-95ca-5603da8897f7"
                          style={{ color: "transparent", maxWidth: '50%' }}
                        /> */}
                        <img 
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: '50%' }}

                          src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(25).png?alt=media&token=bafaa349-12a6-46d7-8b02-42a3b6e8f80a" alt="" />
                        <video id="my-video" width="640" height="360" loop>
    <source src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina.mp4?alt=media&token=fd9973c9-a356-4e9a-8fec-22270eccd310" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
                      </div>
    
                  
                    </div>
                  </div>
  
              
                  
                  <div className=" css-hbu6zj">
                    <div className=" css-8bp21p">
                      <div className="css-106mqxj">
                        <div className=" css-wo8mfg">
                          <h2
                            className="MuiTypography-root MuiTypography-h2 css-448w5t"
                           
                            
                          >
                           What makes Kesht a good choice for educational institutions around the world
                          </h2>
                        </div>
                      </div>
                      <div className=" css-1w59bsm" id="nav-testimonials-slider">
                    
                      </div>
                      <div
                        className="  css-kjyy56 swiper-initialized swiper-horizontal swiper-backface-hidden"
                      >
                        <div className="flex justify-center mb-10 ">
                          <div
                            className="swiper-slide css-382l81 swiper-slide-active"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-zpwqyx">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="swiper-slide css-382l81 swiper-slide-next"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-19orgr">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                An intuitive interface that allows instructors to easily create, manage, and deliver courses without needing extensive technical skills.
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                         
                        
                        </div>
                     
                        <div className="flex justify-center">
                          <div
                            className="swiper-slide css-382l81 swiper-slide-active"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-zpwqyx">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                Our courses are more cost-effective compared to traditional offerings. Moreover, you can save on additional educational expenses such as transportation fees, printing costs, and book expenses 
                                </p>
                </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="swiper-slide css-382l81 swiper-slide-next"
                            style={{ marginRight: 40 }}
                          >
                            <div className=" css-19orgr">
                              <div className=" css-16cltnk">
                          
                                <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                                Enjoy the flexibility of learning from the comfort of your own bedroom or office, at any time that suits you.
                                </p>
                              </div>{" "}
                              <div className=" css-l7pcke">
                                <div className=" css-zg4nl1">
                                  <div className=" css-wowz6z">
                                   
                                    <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                         
                        
                        </div>
                      
                      </div>
                      
                    
                    </div>
                  </div>
                  {/* <div className=" css-w4770a">
                <div className=" css-exnllr">
                  <h5
                    className="MuiTypography-root MuiTypography-h5 css-fa5zzh"
                    
                  >
                    Choose Deel for an industry-leading experience
                  </h5>
                  <div className=" css-1c26xg0">
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={224}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 224 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          500K
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          workers onboarded globally
                        </p>
                      </div>
                    </div>
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={229}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 229 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          35K
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          global businesses supported
                        </p>
                      </div>
                    </div>
                    <div className=" css-l9c3d3">
                      <div className=" css-6rpn08">
                        <img
                          alt="Image"
                          loading="lazy"
                          width={343}
                          height={240}
                          decoding="async"
                          data-nimg={1}
                          className="css-ducv57"
                          style={{ color: "transparent", maxWidth: 343 }}
                          src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png"
                        />
                      </div>
                      <div className=" css-12vj066">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-68zxer"
                          type="key-fig/lg"
                          
                        >
                          $10B
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
                          type="medium"
                          
                        >
                          compliantly processed Global Payroll
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
  
                  <div className="MuiBox-root css-g6fhwr">
                    <div className="MuiBox-root css-qqilog">
                      <div className="MuiBox-root css-1dk9sdk">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-9vbhak"
                          type="d/xlarge"
                          
                        >
                         
                        </h5>
                      </div>
                    
                      
                    </div>
                  </div>
                  <div className=" css-1g1qdt1">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  <div className="MuiBox-root css-wkfzqz">
                    <div className="MuiBox-root css-2fgig6">
                      <div className="MuiBox-root css-1vrckbq">
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-14dya1u"
                          
                          
                        >
                          Kesht University
                        </p>
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-1d7ezcq"
                          type="d/xlarge"
                          
                        >
                          Our platform makes it easy for universities to sell their courses to students
                        </h5>
                       <p className="fixed-gap check-list  css-133cowe w-[90%]" 
                           style={{color:'#FAF4EE'}}>
                      Our platform provides an easy way for universities to create and sell online courses, as well as provide students with a secure platform for purchasing and completing their courses. We also offer a range of features to help universities track student progress and manage their accounts.</p>
                        <div className="MuiBox-root css-wsfch7">
                          <Link
                          to='/signup/institute'
                            className="css-68zbsl"
                          >
                            <button type="button" className="css-1i4wc1c">
                              Get started
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="css-1cjibqg">
                        <div className=" css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(31).png?alt=media&token=c1bff77a-ee1d-4660-8590-65fa4b61674a"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                            >
                              Digital certificates can be issued to students by universities
                            </h5>
                          </div>
                        </div>
                        <div className="css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(32).png?alt=media&token=4ed55d3f-8d1e-4dfc-b908-0ea9e15b76e4"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                              
                            >
                             Student data can be easily tracked by universities
                            </h5>
                          </div>
                        </div>
                        <div className=" css-1vpisfy">
                            <img
                              alt="Image"
                              loading="lazy"
                              width={832}
                              height={720}
                              decoding="async"
                              data-nimg={1}
                              className="css-ducv57"
                              src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(34).png?alt=media&token=6f39a7fe-a587-4b6d-93a6-9a4da936d6c3"
                              style={{ color: "transparent", maxWidth: 832 }}
                            />
                          <div className="MuiBox-root css-166nr2m">
                            <h5
                              className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
                              style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
                              
                              
                            >
                            It is easy for universities to sell their courses to students
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="MuiBox-root css-wkfzqz" >
                    <div className="MuiBox-root css-2fgig6" >
                      <div className="MuiBox-root css-1vrckbq"style={{width:'90%'}}>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-v3wz6g"
                          
                        >
                          Kesht student
                        </p>
                        <h2
                          className="MuiTypography-root MuiTypography-h2 css-1d7ezcq "
                         
                          
                        >
                          Using our platform, students can improve their grades and earn certificates by taking courses from top universities at affordable prices
                        </h2>
                        <div className="fixed-gap check-list MuiBox-root css-133cowe" 
                            style={{color:'#FAF4EE'}}
                            >
                    In order for students to get the best education, we provide them with the best learning tools and provide them with certificates. We believe that education should be accessible to all, regardless of their financial situation. We strive to create an environment where students can learn and grow, and have access to the best educational resources.
                        </div>
                        <Link
                        to='/signup/student'
                          className="css-68zbsl"
                        >
                          <button type="button" className="css-ngu94h">
                            Get started
                          </button>
                        </Link>
                      </div>
                      <div className=" css-x8al29">
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
           <img
             alt="Image"
             loading="lazy"
             width={180}
             height={170}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(36).png?alt=media&token=952beeb9-5176-4d99-a614-11334b6d9d83"
             style={{ color: "transparent", maxWidth: 180 }}
           />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             
             className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}
             
           >
             Most Experienced Team
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
       We have worked with a wide range of clients and understand what it takes give our clients the best results.
</p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
             alt="Image"
             loading="lazy"
             width={180}
             height={170}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(40).png?alt=media&token=b3b6c39e-40bc-4068-9364-5c233f6f9c59"
             style={{ color: "transparent", maxWidth: 180 }}
           />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}
             
             
           >
            Best customer support
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
           Our customer support team is committed to providing the best customer service possible..
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
           <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(41).png?alt=media&token=61b9ad3a-e558-4368-ad78-6bdbcc4b1207"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
             
             className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}
             
           >
             Fast and Reliable
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
            Our services are fast and reliable, ensuring the highest quality of output.
           </p>
         </div>
       </div>
    
     </div>
                      <div className=" css-x8al29">
      
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(44).png?alt=media&token=e9c9f82e-6334-4eb8-836a-9900d869e59f"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Outstanding performance
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
            We have been able to maintain a positive reputation in the marketplace!
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(43).png?alt=media&token=0246df31-5b3d-42d5-9436-33c9aca6944e"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Safe and secure
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
          With our system, your data is guaranteed to remain safe and secure.
           </p>
         </div>
       </div>
       <div className="MuiBox-root css-fxuizj">
         <div className="MuiBox-root css-16i37os">
         <img
           alt="Image"
           loading="lazy"
           width={180}
           height={170}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(42).png?alt=media&token=f6a0548f-48b3-4913-a982-8e23273455e7"
           style={{ color: "transparent", maxWidth: 180 }}
         />
         </div>
         <div className="MuiBox-root css-1s5133g">
           <h6
                                          className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}

             
             
           >
             Affordable price
           </h6>
           <p
             className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
             style={{width:'80%'}}
             
           >
             without sacrificing quality, we offer an affordable price that won't break the bank.
           </p>
         </div>
       </div>
     </div>
                    </div>
                  </div>
                  <div className=" css-1oru8ap">
                    <svg
                      className="css-129n9a0"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>
                
                  


 
 
  



                  <div className="MuiBox-root css-ewwtfb">
                    <div className="MuiBox-root css-13pmkot">
                      <div className="MuiBox-root css-1h43wvm">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-13p1s5k"
                          type="d/xlarge"
                          
                        >
                          Set up your educational institution on Kesht by registering now
                        </h5>
                      </div>
                      <div className=" css-13fxktb" style={{display:'flex'}}>
                      <div className="w-full css-1oyb61h">
                      
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">1</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Sign up and Select your membership type  
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                First, provide your basic information such as your name, email, and password. Next, choose the membership type that best suits your needs from the available options. Finally, complete the payment process to activate your membership.
                                </p>
                              </div>
                            </div>
                          </div>
                           
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">2</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Get your course content ready
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  Make sure you have videos, questions, and other course content prepared Our platform also has an easy-to-use interface that makes it easy for you to create and manage your course
                                </p>
                              </div>
                            </div>
                          </div>
                           
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">3</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Set your course price
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                               Set your course price in a few clicks and enjoy complete control over your content. Receive payments securely through our secure platform. Keep track of your course sales and revenue.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-full css-1i2wr7p">
                            <div className="w-full css-1si5xjn">
                              <div className="w-full css-ml5cmp">4</div>
                              <h5
                                className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
                                
                                
                              >
                                Publish your content and get paid
                              </h5>
                            </div>
                            <div className="w-full css-1gnl65p">
                              <div className="w-full css-dorob3">
                                <p
                                  className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                                  type="medium"
                                  
                                >
                                  Once your course is published, we'll review it, and you'll start getting paid when students enroll in it
                                </p>
                                <Link
                                to='/signup/institute'
                                  type="button"
                                  className="css-18kphx1"
                                >
                                  Get started
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        <div className="MuiBox-root css-fxa9l1">
                          <img
                            alt="Image"
                            loading="lazy"
                            width={640}
                            height={420}
                            decoding="async"
                            data-nimg={1}
                            className="css-ducv57"
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(22).png?alt=media&token=16df57e0-1412-406e-aac3-de21fdd0a98b"
                            style={{ color: "transparent", maxWidth: 640 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
  
        
  
         
  </div>


  <div className=" css-1g1qdt1 overflow-hidden">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  <div className="MuiBox-root css-wkfzqz">
                    <div className="MuiBox-root css-2fgig6">
   
                    </div>
                  </div>
                  <div className="MuiBox-root css-wkfzqz" >
                    <div className="MuiBox-root css-2fgig6" >
                     
                    <div className="MuiBox-root css-x8al29">
                    <h2
                          className="MuiTypography-root MuiTypography-h2 css-1d7ezcq w-full pl-10"

                >
                 Our most popular courses
                </h2>
                </div>

      <div style={{rowGap:'20px'}} className="mt-8 justify-evenly w-full flex">

{
  course.filter(i=>i.status=== 'published').slice(0,3).map(i=>{
    const {courseTitle,courseThumbnail,courseLevel,certificate,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    return(
      <Link to={`/course/${courseId}`} key={courseId}>
        

      <div className="border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md  h-full transform hover:scale-105 transition duration-500">
      <h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
      <div className="relative">
      {
      courseThumbnail == false ?
      <img  className="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
      
      :
      <img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>
      
      }
      
      <p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
      </div>
      <h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
      <p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} className="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
          {
            certificate == true ?
          <p style={{fontSize:"11px",marginLeft:'5px'}} className="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
          :
          <p style={{fontSize:"11px",marginLeft:'5px'}} className="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>
          
          }
             
      </div>
      </Link>
  
    )
  })
}


</div>
<div className='flex justify-center my-12'>

<Link to='/courses'>
                    
                    <div className="navbar-item__container cursor-pointer
                
                header-button-two"><button className=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span className="navbar-item__button-text text-gray-900 " >View More</span></button></div>
                    </Link>
</div>
                    </div>
                  </div>
                  <div className=" css-1oru8ap overflow-hidden">
                    <svg
                      className="css-129n9a0"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>          
 

         


<Footer/>

            </div>
            <div id='tablet'className='' >
   
                  
   <div className='relative ' >

{/* Navigation */}
 <Nav/>


{/*  Body */}
<div className=" " >
<div className="mainBodyHomePage ">

 <div className="MuiBox-root css-16pdqkj relative">
   <div className="MuiBox-root css-vp313s">
     <div className="MuiBox-root css-n8ap69">
       <div className="MuiBox-root css-0 ">
         <h1 className="MuiTypography-root MuiTypography-h1  oswald" style={{lineHeight:'110%'}}>
           There has never been an <br />easier way to get <br/> high quality education
         </h1>
         <div className="MuiBox-root css-x2aj4f">
         <Link
           className="css-68zbsl"
           to='/courses'
         >
           <button style={{fontSize:'13px'}} type="button" className="mainBodyHomePage-Btn barlow">
            Courses
           </button>
         </Link>
       </div>
       </div>
   
     
     </div>
     <div className="flex ">
       {/* <img
         alt="Image"
         loading="lazy"
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(24).png?alt=media&token=b125c8f5-2014-437b-95ca-5603da8897f7"
         style={{ color: "transparent", maxWidth: '50%' }}
       /> */}
       <img 
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: '50%' }}

         src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(25).png?alt=media&token=bafaa349-12a6-46d7-8b02-42a3b6e8f80a" alt="" />
       <video id="my-video" width="640" height="360" loop>
<source src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina.mp4?alt=media&token=fd9973c9-a356-4e9a-8fec-22270eccd310" type="video/mp4"/>
Your browser does not support the video tag.
</video>
     </div>

 
   </div>
 </div>


 
 <div className=" css-hbu6zj">
   <div className=" css-8bp21p">
     <div className="css-106mqxj">
       <div className=" css-wo8mfg">
         <h2
           className="MuiTypography-root MuiTypography-h2 css-448w5t"
          
           
         >
          What makes Kesht a good choice for educational institutions around the world
         </h2>
       </div>
     </div>
     <div className=" css-1w59bsm" id="nav-testimonials-slider">
   
     </div>
     <div
       className="  css-kjyy56 swiper-initialized swiper-horizontal swiper-backface-hidden"
     >
       <div className="flex justify-center mb-10 ">
         <div
           className="swiper-slide css-382l81 swiper-slide-active"
           style={{ marginRight: 40 }}
         >
           <div className=" css-zpwqyx">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
               Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div
           className="swiper-slide css-382l81 swiper-slide-next"
           style={{ marginRight: 40 }}
         >
           <div className=" css-19orgr">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                 “The best payroll solution, period. We've used every payroll
                 solution out there - Gusto, Rippling, you name it - and Deel
                 is the only one that is simple, easy to use, and global.
                 We've used it for 4 years and haven't looked back.”
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
   
        
       
       </div>
    
       <div className="flex justify-center">
         <div
           className="swiper-slide css-382l81 swiper-slide-active"
           style={{ marginRight: 40 }}
         >
           <div className=" css-zpwqyx">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
               Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div
           className="swiper-slide css-382l81 swiper-slide-next"
           style={{ marginRight: 40 }}
         >
           <div className=" css-19orgr">
             <div className=" css-16cltnk">
         
               <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu">
                 “The best payroll solution, period. We've used every payroll
                 solution out there - Gusto, Rippling, you name it - and Deel
                 is the only one that is simple, easy to use, and global.
                 We've used it for 4 years and haven't looked back.”
               </p>
             </div>{" "}
             <div className=" css-l7pcke">
               <div className=" css-zg4nl1">
                 <div className=" css-wowz6z">
                  
                   <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
                 </div>
               </div>
             </div>
           </div>
         </div>
   
        
       
       </div>
     
     </div>
     
   
   </div>
 </div>
 {/* <div className=" css-w4770a">
<div className=" css-exnllr">
 <h5
   className="MuiTypography-root MuiTypography-h5 css-fa5zzh"
   
 >
   Choose Deel for an industry-leading experience
 </h5>
 <div className=" css-1c26xg0">
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={224}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 224 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         500K
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         workers onboarded globally
       </p>
     </div>
   </div>
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={229}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 229 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         35K
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         global businesses supported
       </p>
     </div>
   </div>
   <div className=" css-l9c3d3">
     <div className=" css-6rpn08">
       <img
         alt="Image"
         loading="lazy"
         width={343}
         height={240}
         decoding="async"
         data-nimg={1}
         className="css-ducv57"
         style={{ color: "transparent", maxWidth: 343 }}
         src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png"
       />
     </div>
     <div className=" css-12vj066">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-68zxer"
         type="key-fig/lg"
         
       >
         $10B
       </h5>
       <p
         className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
         type="medium"
         
       >
         compliantly processed Global Payroll
       </p>
     </div>
   </div>
 </div>
</div>
</div> */}

 <div className="MuiBox-root css-g6fhwr">
   <div className="MuiBox-root css-qqilog">
     <div className="MuiBox-root css-1dk9sdk">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-9vbhak"
         type="d/xlarge"
         
       >
        
       </h5>
     </div>
   
     
   </div>
 </div>
 <div className=" css-1g1qdt1">
   <svg
     className="css-1fllxtj"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
   </svg>
 </div>
 <div className="MuiBox-root css-wkfzqz">
   <div className="MuiBox-root css-2fgig6">
     <div className="MuiBox-root css-1vrckbq">
       <p
         className="MuiTypography-root MuiTypography-body1 css-14dya1u"
         
         
       >
         Kesht University
       </p>
       <h5
         className="MuiTypography-root MuiTypography-h5 css-1d7ezcq"
         type="d/xlarge"
         
       >
         Our platform makes it easy for universities to sell their courses to students
       </h5>
      <p className="fixed-gap check-list  css-133cowe w-[90%]" 
          style={{color:'#FAF4EE'}}>
     Our platform provides an easy way for universities to create and sell online courses, as well as provide students with a secure platform for purchasing and completing their courses. We also offer a range of features to help universities track student progress and manage their accounts.</p>
       <div className="MuiBox-root css-wsfch7">
         <Link
         to='/signup/institute'
           className="css-68zbsl"
         >
           <button type="button" className="css-1i4wc1c">
             Get started
           </button>
         </Link>
       </div>
     </div>
     <div className="css-1cjibqg">
       <div className=" css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(31).png?alt=media&token=c1bff77a-ee1d-4660-8590-65fa4b61674a"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
           >
             Digital certificates can be issued to students by universities
           </h5>
         </div>
       </div>
       <div className="css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(32).png?alt=media&token=4ed55d3f-8d1e-4dfc-b908-0ea9e15b76e4"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
             
           >
            Student data can be easily tracked by universities
           </h5>
         </div>
       </div>
       <div className=" css-1vpisfy">
           <img
             alt="Image"
             loading="lazy"
             width={832}
             height={720}
             decoding="async"
             data-nimg={1}
             className="css-ducv57"
             src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(34).png?alt=media&token=6f39a7fe-a587-4b6d-93a6-9a4da936d6c3"
             style={{ color: "transparent", maxWidth: 832 }}
           />
         <div className="MuiBox-root css-166nr2m">
           <h5
             className="MuiTypography-root MuiTypography-h5 text-white  fjalla"
             style={{fontSize:'20px',color:'white',width:'70%',textAlign:'center'}}
             
             
           >
           It is easy for universities to sell their courses to students
           </h5>
         </div>
       </div>
     </div>
   </div>
 </div>
 <div className="MuiBox-root css-wkfzqz" >
   <div className="MuiBox-root css-2fgig6" >
     <div className="MuiBox-root css-1vrckbq"style={{width:'90%'}}>
       <p
         className="MuiTypography-root MuiTypography-body1 css-v3wz6g"
         
       >
         Kesht student
       </p>
       <h2
         className="MuiTypography-root MuiTypography-h2 css-1d7ezcq "
        
         
       >
         Using our platform, students can improve their grades and earn certificates by taking courses from top universities at affordable prices
       </h2>
       <div className="fixed-gap check-list MuiBox-root css-133cowe" 
           style={{color:'#FAF4EE'}}
           >
   In order for students to get the best education, we provide them with the best learning tools and provide them with certificates. We believe that education should be accessible to all, regardless of their financial situation. We strive to create an environment where students can learn and grow, and have access to the best educational resources.
       </div>
       <Link
       to='/signup/student'
         className="css-68zbsl"
       >
         <button type="button" className="css-ngu94h">
           Get started
         </button>
       </Link>
     </div>
     <div className=" css-x8al29">
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(36).png?alt=media&token=952beeb9-5176-4d99-a614-11334b6d9d83"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}

>
Most Experienced Team
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
We have worked with a wide range of clients and understand what it takes give our clients the best results.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(40).png?alt=media&token=b3b6c39e-40bc-4068-9364-5c233f6f9c59"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}


>
Best customer support
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
Our customer support team is committed to providing the best customer service possible..
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(41).png?alt=media&token=61b9ad3a-e558-4368-ad78-6bdbcc4b1207"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}

>
Fast and Reliable
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
Our services are fast and reliable, ensuring the highest quality of output.
</p>
</div>
</div>

</div>
     <div className=" css-x8al29">

<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(44).png?alt=media&token=e9c9f82e-6334-4eb8-836a-9900d869e59f"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}



>
Outstanding performance
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
We have been able to maintain a positive reputation in the marketplace!
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(43).png?alt=media&token=0246df31-5b3d-42d5-9436-33c9aca6944e"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}



>
Safe and secure
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
With our system, your data is guaranteed to remain safe and secure.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(42).png?alt=media&token=f6a0548f-48b3-4913-a982-8e23273455e7"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                         className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'25px',color:'white'}}



>
Affordable price
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center"
style={{width:'80%'}}

>
without sacrificing quality, we offer an affordable price that won't break the bank.
</p>
</div>
</div>
</div>
   </div>
 </div>
 <div className=" css-1oru8ap">
   <svg
     className="css-129n9a0"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
     fill='#1B1B1B'
   >
     <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
   </svg>
 </div>

 








 <div className="MuiBox-root css-ewwtfb">
   <div className="MuiBox-root css-13pmkot">
     <div className="MuiBox-root css-1h43wvm">
       <h5
         className="MuiTypography-root MuiTypography-h5 css-13p1s5k"
         type="d/xlarge"
         
       >
         Set up your educational institution on Kesht by registering now
       </h5>
     </div>
     <div className=" css-13fxktb" style={{display:'flex'}}>
     <div className="w-full css-1oyb61h">
     
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">1</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Sign up and Select your membership type  
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
               First, provide your basic information such as your name, email, and password. Next, choose the membership type that best suits your needs from the available options. Finally, complete the payment process to activate your membership.
               </p>
             </div>
           </div>
         </div>
          
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">2</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Get your course content ready
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 Make sure you have videos, questions, and other course content prepared Our platform also has an easy-to-use interface that makes it easy for you to create and manage your course
               </p>
             </div>
           </div>
         </div>
          
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">3</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Set your course price
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
              Set your course price in a few clicks and enjoy complete control over your content. Receive payments securely through our secure platform. Keep track of your course sales and revenue.
               </p>
             </div>
           </div>
         </div>
         <div className="w-full css-1i2wr7p">
           <div className="w-full css-1si5xjn">
             <div className="w-full css-ml5cmp">4</div>
             <h5
               className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
               
               
             >
               Publish your content and get paid
             </h5>
           </div>
           <div className="w-full css-1gnl65p">
             <div className="w-full css-dorob3">
               <p
                 className="MuiTypography-root MuiTypography-body1 css-il7sdz"
                 type="medium"
                 
               >
                 Once your course is published, we'll review it, and you'll start getting paid when students enroll in it
               </p>
               <Link
               to='/signup/institute'
                 type="button"
                 className="css-18kphx1"
               >
                 Get started
               </Link>
             </div>
           </div>
         </div>
       </div>
   
       <div className="MuiBox-root css-fxa9l1">
         <img
           alt="Image"
           loading="lazy"
           width={640}
           height={420}
           decoding="async"
           data-nimg={1}
           className="css-ducv57"
           src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(22).png?alt=media&token=16df57e0-1412-406e-aac3-de21fdd0a98b"
           style={{ color: "transparent", maxWidth: 640 }}
         />
       </div>
     </div>
   </div>
 </div>

</div>
</div>





</div>


<div className=" css-1g1qdt1 overflow-hidden">
   <svg
     className="css-1fllxtj"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
   </svg>
 </div>
 <div className="MuiBox-root css-wkfzqz">
   <div className="MuiBox-root css-2fgig6">

   </div>
 </div>
 <div className="MuiBox-root css-wkfzqz" >
   <div className="MuiBox-root css-2fgig6" >
    
   <div className="MuiBox-root css-x8al29">
   <h2
         className="MuiTypography-root MuiTypography-h2 css-1d7ezcq w-full pl-10"

>
Our most popular courses
</h2>
</div>

<div style={{rowGap:'20px'}} className="mt-8 justify-evenly w-full flex">

{
course.filter(i=>i.status=== 'published').slice(0,3).map(i=>{
const {courseTitle,courseThumbnail,courseLevel,certificate,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
return(
<Link to={`/course/${courseId}`} key={courseId}>


<div className="border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md  h-full transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  className="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} className="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
{
certificate == true ?
<p style={{fontSize:"11px",marginLeft:'5px'}} className="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
:
<p style={{fontSize:"11px",marginLeft:'5px'}} className="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>

}

</div>
</Link>

)
})
}


</div>
<div className='flex justify-center my-12'>

<Link to='/courses'>
   
   <div className="navbar-item__container cursor-pointer

header-button-two"><button className=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span className="navbar-item__button-text text-gray-900 " >View More</span></button></div>
   </Link>
</div>
   </div>
 </div>
 <div className=" css-1oru8ap overflow-hidden">
   <svg
     className="css-129n9a0"
     width={1620}
     height={128}
     viewBox="0 0 1620 128"
     xmlns="http://www.w3.org/2000/svg"
     fill='#1B1B1B'
   >
     <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
   </svg>
 </div>          





<Footer/>

</div>
    
            
<div id='mobile'className='' >
   
                  
   {
         pop === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button className=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span className="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} className=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
           }          
        {
         pop=== false &&
         <div className="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div className="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div className="navbar-item__container
       
       header-button-one"><div  className="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} className="navbar-item__button-text">Log in</span></div></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div className="navbar-item__container
       
       header-button-two"><div  className="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} className="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></div></div>
           </Link>
           <button onClick={navigationPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }

{
pop === false &&
<div className=" " >
<div className="mainBodyHomePage ">

<div className="  relative">
<div className="MuiBox-root ">
<div className="MuiBox-root css-n8ap69">
  <div className="MuiBox-root  ">
    <h1 className="MuiTypography-root MuiTypography-h1  oswald" style={{fontSize:'14vw'}}> 
      There has never been an <br/>easier way to get <br/> high quality education
    </h1>
    <div className="MuiBox-root css-x2aj4f">
    <Link
      className="css-68zbsl"
      to='/courses'
    >
      <button style={{fontSize:'13px'}} type="button" className="mainBodyHomePage-Btn barlow">
       Courses
      </button>
    </Link>
  </div>
  </div>


</div>
<div className="flex flex-col">
  {/* <img
    alt="Image"
    loading="lazy"
    decoding="async"
    data-nimg={1}
    className="css-ducv57"
    src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(24).png?alt=media&token=b125c8f5-2014-437b-95ca-5603da8897f7"
    style={{ color: "transparent", maxWidth: '50%' }}
  /> */}
  <img 
    className="css-ducv57"
    style={{ color: "transparent", maxWidth: '100%',objectFit:'contain' }}

    src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(25).png?alt=media&token=bafaa349-12a6-46d7-8b02-42a3b6e8f80a" alt="" />
  <video id="my-video" width="640" height="360" loop>
<source src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina.mp4?alt=media&token=fd9973c9-a356-4e9a-8fec-22270eccd310" type="video/mp4"/>
Your browser does not support the video tag.
</video>
</div>


</div>
</div>



<div className="">
<div className=" css-8bp21p">
<div className="css-106mqxj">
  <div className=" css-wo8mfg">
    <h2
      className="MuiTypography-root MuiTypography-h2 css-448w5t px-[3vw]"
     
      
    >
     What makes Kesht a good choice for educational institutions around the world
    </h2>
  </div>
</div>
<div className=" css-1w59bsm" id="nav-testimonials-slider">

</div>
<div
  className="  css-kjyy56 swiper-initialized swiper-horizontal swiper-backface-hidden"
>
  <div className="flex justify-center flex-col  ">
    <div
      className="swiper-slide css-382l81 swiper-slide-active "
      style={{ width:'90%',margin:'auto' }}
    >
      <div className=" css-zpwqyx ">
        <div className=" css-16cltnk">
    
          <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu" style={{fontSize:'3.5vw'}}>
          Kesht offers you the opportunity to access courses from renowned Ethiopian and international universities. In addition, you can learn from individual experts who possess extensive experience in their respective fields. 
          </p>
        </div>{" "}
        <div className=" css-l7pcke">
          <div className=" css-zg4nl1">
            <div className=" css-wowz6z">
             
              <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="swiper-slide css-382l81 swiper-slide-next"
      style={{ width:'90%',margin:'auto',marginTop:'20px' }}
    >
      <div className=" css-19orgr">
        <div className=" css-16cltnk">
    
          <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu" style={{fontSize:'3.5vw'}}>
          Our courses are more cost-effective compared to traditional offerings. Moreover, you can save on additional educational expenses such as transportation fees, printing costs, and book expenses 
          </p>
        </div>{" "}
        <div className=" css-l7pcke">
          <div className=" css-zg4nl1">
            <div className=" css-wowz6z">
             
              <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
            </div>
          </div>
        </div>
      </div>
    </div>

   
  
  </div>

  <div className="flex justify-center flex-col w-full"style={{marginTop:'20px'}}>
    <div
      className=" css-382l81 swiper-slide-active"
      style={{ width:'90%',margin:'auto' }}
    >
      <div className=" css-1jcaegn">
        <div className=" css-16cltnk">
    
          <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu" style={{fontSize:'3.5vw'}}>
          Enjoy the flexibility of learning from the comfort of your own bedroom or office, at any time that suits you. 
          </p>
        </div>{" "}
        <div className=" css-l7pcke">
          <div className=" css-zg4nl1">
            <div className=" css-wowz6z">
             
              <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="swiper-slide css-382l81 swiper-slide-next"
      style={{ width:'90%',margin:'auto',marginTop:'20px'}}
    >
      <div className=" css-19orgr2">
        <div className=" css-16cltnk">
    
          <p className="MuiTypography-root MuiTypography-body1 font-feature-2 css-a6gpvu" style={{fontSize:'3.5vw'}}>
          An intuitive interface that allows instructors to easily create, manage, and deliver courses without needing extensive technical skills.
          </p>
        </div>{" "}
        <div className=" css-l7pcke">
          <div className=" css-zg4nl1">
            <div className=" css-wowz6z">
             
              <p className="MuiTypography-root MuiTypography-body1 css-tqyvbu" />
            </div>
          </div>
        </div>
      </div>
    </div>

   
  
  </div>

</div>


</div>
</div>
{/* <div className=" css-w4770a">
<div className=" css-exnllr">
<h5
className="MuiTypography-root MuiTypography-h5 css-fa5zzh"

>
Choose Deel for an industry-leading experience
</h5>
<div className=" css-1c26xg0">
<div className=" css-l9c3d3">
<div className=" css-6rpn08">
  <img
    alt="Image"
    loading="lazy"
    width={224}
    height={240}
    decoding="async"
    data-nimg={1}
    className="css-ducv57"
    style={{ color: "transparent", maxWidth: 224 }}
    src="https://deel-website-media-prod.s3.amazonaws.com/User_Group_e1f4da88d2.png"
  />
</div>
<div className=" css-12vj066">
  <h5
    className="MuiTypography-root MuiTypography-h5 css-68zxer"
    type="key-fig/lg"
    
  >
    500K
  </h5>
  <p
    className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
    type="medium"
    
  >
    workers onboarded globally
  </p>
</div>
</div>
<div className=" css-l9c3d3">
<div className=" css-6rpn08">
  <img
    alt="Image"
    loading="lazy"
    width={229}
    height={240}
    decoding="async"
    data-nimg={1}
    className="css-ducv57"
    style={{ color: "transparent", maxWidth: 229 }}
    src="https://deel-website-media-prod.s3.amazonaws.com/Globe_2118ad8b7b.png"
  />
</div>
<div className=" css-12vj066">
  <h5
    className="MuiTypography-root MuiTypography-h5 css-68zxer"
    type="key-fig/lg"
    
  >
    35K
  </h5>
  <p
    className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
    type="medium"
    
  >
    global businesses supported
  </p>
</div>
</div>
<div className=" css-l9c3d3">
<div className=" css-6rpn08">
  <img
    alt="Image"
    loading="lazy"
    width={343}
    height={240}
    decoding="async"
    data-nimg={1}
    className="css-ducv57"
    style={{ color: "transparent", maxWidth: 343 }}
    src="https://deel-website-media-prod.s3.amazonaws.com/Bills_Coins_ff5c0d19fc.png"
  />
</div>
<div className=" css-12vj066">
  <h5
    className="MuiTypography-root MuiTypography-h5 css-68zxer"
    type="key-fig/lg"
    
  >
    $10B
  </h5>
  <p
    className="MuiTypography-root MuiTypography-body1 md css-1qg1cbt"
    type="medium"
    
  >
    compliantly processed Global Payroll
  </p>
</div>
</div>
</div>
</div>
</div> */}

<div className="MuiBox-root css-g6fhwr">
<div className="MuiBox-root css-qqilog">
<div className="MuiBox-root css-1dk9sdk">
  <h5
    className="MuiTypography-root MuiTypography-h5 css-9vbhak"
    type="d/xlarge"
    
  >
   
  </h5>
</div>


</div>
</div>
<div className=" css-1g1qdt1">
<svg
className="css-1fllxtj"
width={1620}
height={128}
viewBox="0 0 1620 128"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
</svg>
</div>
<div className="MuiBox-root css-wkfzqz">
<div className="MuiBox-root css-2fgig6">
<p
    className="MuiTypography-root MuiTypography-body1 css-14dya1u text-white"
    
    
  >
    Kesht University
  </p>
<div className="MuiBox-root css-1vrckbq">

  <h5
    className="MuiTypography-root MuiTypography-h5 oswald "
   style={{fontSize:'12vw'}}
    
  >
    Our platform makes it easy for universities to sell their courses to students
  </h5>
  <p
    className="MuiTypography-root MuiTypography-body1 css-10afrnc w-[90%]"
    style={{fontSize:'3.3vw'}}
    
  >
Our platform provides an easy way for universities to create and sell online courses, as well as provide students with a secure platform for purchasing and completing their courses. We also offer a range of features to help universities track student progress and manage their accounts.</p>
  <div className="MuiBox-root css-wsfch7">
    <Link
    to='/signup/institute'
      className="css-68zbsl"
    >
      <button type="button" className="css-1i4wc1c oswald"  style={{fontSize:'3.3vw'}}>
        Get started
      </button>
    </Link>
  </div>
</div>
<div className="css-1cjibqg">
  <div className=" css-1vpisfy">
      <img
        alt="Image"
        loading="lazy"
        width={832}
        height={720}
        decoding="async"
        data-nimg={1}
        className="css-ducv57"
        src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(31).png?alt=media&token=c1bff77a-ee1d-4660-8590-65fa4b61674a"
        style={{ color: "transparent", maxWidth: 832 }}
      />
    <div className="MuiBox-root css-166nr2m">
      <h5
        className="MuiTypography-root MuiTypography-h5 text-white  barlow"
        style={{fontSize:'5vw',color:'white',width:'70%',textAlign:'center'}}
        
      >
        Digital certificates can be issued to students by universities
      </h5>
    </div>
  </div>
  <div className="css-1vpisfy">
      <img
        alt="Image"
        loading="lazy"
        width={832}
        height={720}
        decoding="async"
        data-nimg={1}
        className="css-ducv57"
        src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(32).png?alt=media&token=4ed55d3f-8d1e-4dfc-b908-0ea9e15b76e4"
        style={{ color: "transparent", maxWidth: 832 }}
      />
    <div className="MuiBox-root css-166nr2m">
      <h5
        className="MuiTypography-root MuiTypography-h5 text-white  barlow"
        style={{fontSize:'5vw',color:'white',width:'70%',textAlign:'center'}}
        
        
      >
       Student data can be easily tracked by universities
      </h5>
    </div>
  </div>
  <div className=" css-1vpisfy">
      <img
        alt="Image"
        loading="lazy"
        width={832}
        height={720}
        decoding="async"
        data-nimg={1}
        className="css-ducv57"
        src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(34).png?alt=media&token=6f39a7fe-a587-4b6d-93a6-9a4da936d6c3"
        style={{ color: "transparent", maxWidth: 832 }}
      />
    <div className="MuiBox-root css-166nr2m">
      <h5
        className="MuiTypography-root MuiTypography-h5 text-white  barlow"
        style={{fontSize:'5vw',color:'white',width:'70%',textAlign:'center'}}
        
        
      >
      It is easy for universities to sell their courses to students
      </h5>
    </div>
  </div>
</div>
</div>
</div>
<div className="MuiBox-root css-wkfzqz" >
<div className="MuiBox-root css-2fgig6" >
<p
    className="MuiTypography-root MuiTypography-body1 css-14dya1u text-white"

    
  >
    Kesht student
  </p>
<div className="MuiBox-root css-1vrckbq">

  <h2
     className="MuiTypography-root MuiTypography-h5 oswald text-white"
     style={{fontSize:'12vw',color:'white',width:'90%'}}
    
  >
    Using our platform, students can improve their grades and earn certificates by taking courses from top universities at affordable prices
  </h2>
  <div className="fixed-gap check-list MuiBox-root css-133cowe w-[90%]" 
      style={{color:'#FAF4EE',width:'90%',fontSize:'3.3vw'}}
      >
In order for students to get the best education, we provide them with the best learning tools and provide them with certificates. We believe that education should be accessible to all, regardless of their financial situation. We strive to create an environment where students can learn and grow, and have access to the best educational resources.
  </div>
  <Link
  to='/signup/student'
    className="css-68zbsl"
  >
    <button type="button" className="css-ngu94h oswald" style={{font:'3.3vw',color:'white'}}>
      Get started
    </button>
  </Link>
</div>
<div className=" css-x8al29">
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(36).png?alt=media&token=952beeb9-5176-4d99-a614-11334b6d9d83"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}

>
Most Experienced Team
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
We have worked with a wide range of clients and understand what it takes give our clients the best results.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(40).png?alt=media&token=b3b6c39e-40bc-4068-9364-5c233f6f9c59"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}


>
Best customer support
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
Our customer support team is committed to providing the best customer service possible..
</p>
</div>
</div>

</div>
<div className=" css-x8al29">
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(41).png?alt=media&token=61b9ad3a-e558-4368-ad78-6bdbcc4b1207"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6

className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}

>
Fast and Reliable
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
Our services are fast and reliable, ensuring the highest quality of output.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(44).png?alt=media&token=e9c9f82e-6334-4eb8-836a-9900d869e59f"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                    className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}



>
Outstanding performance
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
We have been able to maintain a positive reputation in the marketplace!
</p>
</div>
</div>

</div>

<div className=" css-x8al29">


<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(43).png?alt=media&token=0246df31-5b3d-42d5-9436-33c9aca6944e"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                    className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}



>
Safe and secure
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
With our system, your data is guaranteed to remain safe and secure.
</p>
</div>
</div>
<div className="MuiBox-root css-fxuizj">
<div className="MuiBox-root css-16i37os">
<img
alt="Image"
loading="lazy"
width={180}
height={170}
decoding="async"
data-nimg={1}
className="css-ducv57"
src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(42).png?alt=media&token=f6a0548f-48b3-4913-a982-8e23273455e7"
style={{ color: "transparent", maxWidth: 180 }}
/>
</div>
<div className="MuiBox-root css-1s5133g">
<h6
                    className="MuiTypography-root MuiTypography-h6 barlow text-white" style={{fontSize:'5.5vw',color:'white',textAlign:'center'}}



>
Affordable  price
</h6>
<p
className="MuiTypography-root MuiTypography-body1 css-slho2y text-center barlow"
style={{width:'80%',fontSize:'3vw'}}

>
without sacrificing quality, we offer an affordable price that won't break the bank.
</p>
</div>
</div>
</div>
</div>
</div>
<div className=" css-1oru8ap">
<svg
className="css-129n9a0"
width={1620}
height={128}
viewBox="0 0 1620 128"
xmlns="http://www.w3.org/2000/svg"
fill='#1B1B1B'
>
<path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
</svg>
</div>










<div className="MuiBox-root css-ewwtfb">
<div className="MuiBox-root css-13pmkot">
<div className="MuiBox-root css-1h43wvm">
  <h5
    className="MuiTypography-root MuiTypography-h5 oswald"
    style={{fontSize:'12vw',width:'90%'}}
   
    
  >
    Set up your educational institution on Kesht by registering now
  </h5>
</div>
<div className=" css-13fxktb" style={{display:'flex',flexDirection:'column'}}>
<div className="w-full css-1oyb61h">

    <div className="w-full css-1i2wr7p">
      <div className="w-full css-1si5xjn">
        <div className="w-full css-ml5cmp">1</div>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
          
          
        >
          Sign up and Select your membership type  
        </h5>
      </div>
      <div className="w-full css-1gnl65p">
        <div className="w-full css-dorob3">
          <p
            className="MuiTypography-root MuiTypography-body1 css-il7sdz"
            type="medium"
            
          >
           First, provide your basic information such as your name, email, and password. Next, choose the membership type that best suits your needs from the available options. Finally, complete the payment process to activate your membership.
          </p>
        </div>
      </div>
    </div>
     
    <div className="w-full css-1i2wr7p">
      <div className="w-full css-1si5xjn">
        <div className="w-full css-ml5cmp">2</div>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
          
          
        >
          Get your course content ready
        </h5>
      </div>
      <div className="w-full css-1gnl65p">
        <div className="w-full css-dorob3">
          <p
            className="MuiTypography-root MuiTypography-body1 css-il7sdz"
            type="medium"
            
          >
           Make sure you have videos, questions, and other course content prepared Our platform also has an easy-to-use interface that makes it easy for you to create and manage your course
          </p>
        </div>
      </div>
    </div>
     
    <div className="w-full css-1i2wr7p">
      <div className="w-full css-1si5xjn">
        <div className="w-full css-ml5cmp">3</div>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
          
          
        >
          Set your course price
        </h5>
      </div>
      <div className="w-full css-1gnl65p">
        <div className="w-full css-dorob3">
          <p
            className="MuiTypography-root MuiTypography-body1 css-il7sdz"
            type="medium"
            
          >
            Set your course price in a few clicks and enjoy complete control over your content. Receive payments securely through our secure platform. Keep track of your course sales and revenue.
          </p>
        </div>
      </div>
    </div>
    <div className="w-full css-1i2wr7p">
      <div className="w-full css-1si5xjn">
        <div className="w-full css-ml5cmp">4</div>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-1ok5qkj"
          
          
        >
          Publish your content and get paid
        </h5>
      </div>
      <div className="w-full css-1gnl65p">
        <div className="w-full css-dorob3">
          <p
            className="MuiTypography-root MuiTypography-body1 css-il7sdz"
            type="medium"
            
          >
           Once your course is published, we'll review it, and you'll start getting paid when students enroll in it
          </p>
          <Link
          to='/signup/institute'
            type="button"
            className="">
      <button type="button" className="css-18kphx1 oswald"  style={{fontSize:'3.3vw',color:'white'}}>
            Get started
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>

  <div className="MuiBox-root css-fxa9l1">
    <img
      alt="Image"
      loading="lazy"
      width={640}
      height={420}
      decoding="async"
      data-nimg={1}
      className="css-ducv57"
      src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(22).png?alt=media&token=16df57e0-1412-406e-aac3-de21fdd0a98b"
      style={{ color: "transparent", maxWidth: 640 }}
    />
  </div>
</div>
</div>
</div>

</div>

<div className=" css-1g1qdt1 overflow-hidden">
<svg
className="css-1fllxtj"
width={1620}
height={128}
viewBox="0 0 1620 128"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
</svg>
</div>
<div className="MuiBox-root css-wkfzqz">
<div className="MuiBox-root css-2fgig6">

</div>
</div>
<div className="MuiBox-root css-wkfzqz" style={{padding:0}}>
<div className="MuiBox-root css-2fgig6" style={{padding:0}}>

<div className="MuiBox-root css-x8al29" >
<h2
    className="MuiTypography-root MuiTypography-h5 oswald  w-full "
  style={{fontSize:'12vw',color:'white'}}
>
Our most popular courses
</h2>
</div>

<div class="  text-gray-500 flex   mobileGridCourses " style={{overflowX:"scroll",margin:'3vw'}}>

{
course.slice(0,3).map(i=>{
const {courseTitle,courseThumbnail,courseLevel,certificate,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
return(
  <Link to={`/course/${courseId}`}>
<div class=" border  bg-white rounded-2xl shadow-md relative" style={{backgroundColor:"white",width:'100%',height:'100%',paddingLeft:'2vw',paddingBottom:'10vw',paddingRight:'2vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton text-center" style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white   rounded-br-lg rounded-tl-lg fjalla" style={{padding:'1.5vw 2vw 1vw 1.5vw ',fontSize:'4vw'}}>{coursePrice} ETB</p>
        </div>
        <h1 className=" text-gray-800  fjalla capitalize" style={{fontSize:"5vw",marginLeft:'3vw',marginBottom:'2vw'}}>{courseTitle}</h1>
        <div className="flex flex-col justify-center">
    
<p style={{fontSize:"3vw",marginLeft:'3vw',marginBottom:'2vw'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
{
  certificate == true ?
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
:
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>

}
    
         
  
        </div>
      </div>
      </Link>

)
})
}


</div>
<div className='flex justify-center my-12'>

<Link to='/courses'>

<div className="navbar-item__container cursor-pointer

header-button-two"><button className=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span className="navbar-item__button-text text-gray-900 " >View More</span></button></div>
</Link>
</div>
</div>
</div>
<div className=" css-1oru8ap overflow-hidden">
<svg
className="css-129n9a0"
width={1620}
height={128}
viewBox="0 0 1620 128"
xmlns="http://www.w3.org/2000/svg"
fill='#1B1B1B'
>
<path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
</svg>
</div>          





<Footer/>
</div>
}








</div>
</div>
  )
}
