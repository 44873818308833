import { collection, deleteDoc, doc, onSnapshot, setDoc,orderBy,query } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';
const Quiz = () => {
  const { questions,courseId,lectureId } = useParams();
  const [inputCount, setInputCount] = useState(parseInt(questions) || 1);
  const [answerError, setAnswerError] = useState('');
  const [uid, setuid] = useState('');
  const [index, setindex] = useState('');
  let [questionIndex, setquestionIndex] = useState(0);
  const [answer, setanswer] = useState('');
  const [user, setuser] = useState([]);
  const [notification, setnotification] = useState([]);
  const [course, setcourse] = useState([]);
  const [lecture, setlecture] = useState([]);
  const [nextLectureId,setNextLectureId] = useState("")
  
  const [lectureMain, setlectureMain] = useState([]);

  const [Test,setTest] = useState([])
const navigate = useNavigate()
  const [previewQuestions, setpreviewQuestions] = useState([]);
  const [previewQuizQuestions, setpreviewQuizQuestions] = useState([]);
  const [spinner, setspinner] = useState(true);
  const [pop, setpop] = useState(false);
  const [notificationPop, setnotificationPop] = useState(false);
  const [isLecture, setisLecture] = useState(false);


  const [success,setsuccess] = useState(false)
  const [deletePop,setdeletePop] = useState(false)
  const [quizDeleteMessage,setquizDeleteMessage] = useState(false)
  const [preview,setpreview] = useState(false)

  const [score, setScore] = useState(0);
  const scorePercentage = (score / Test.length) * 100;
  const [lock, setlock] = useState(false);
  const [result, setresult] = useState(false);
  const [loading, setloading] = useState(false);

  const [errorMain, seterrorMain] = useState("");






  const [question, setQuestion] = useState([]);
  const [questionError, setQuestionError] = useState("");
  const [choiceError, setChoiceError] = useState("");



  function deleteQuizDesktop(){
    setdeletePop(true)

  }
  function closedeleteQuizPopDesktop(){
    setdeletePop(false)

  }
  const checkAnswer = (e,ans) =>{
    if(lock == false){
      
      if(previewQuestions.answer.toLowerCase() == ans.toLowerCase()){
        e.target.classList.add('correct')
        setlock(true)
        setScore(prev=>(prev + 1))
          }
          else{
        e.target.classList.add('incorrect')
        setlock(true)
        
        
          }
    }
    
    }
    
     const  next = ()=>{
    
  
      
      if(lock == true){
        if(questionIndex == previewQuizQuestions.length - 1){
          setresult(true)
          setquestionIndex(0)
          if(75 < scorePercentage){
        
              
            
          }
    
          else{
           
    
    
          }
        }
        else{
            setquestionIndex(++questionIndex)
        setlock(false)
        const elementsToRemoveClasses = document.querySelectorAll('.incorrect, .correct');
     // Remove both classes using a loop
     for (const element of elementsToRemoveClasses) {
      element.classList.remove('incorrect', 'correct');
    }
        }
        
      
       
      }
      
    }

  function retake(){
    setquestionIndex(0)
        setresult(false)
        setlock(false)
        setScore(0)
     
    }
    function loaderSet(){
      setloading(true)
  
    }
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       
  
 
  
    onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
      setuser([{...snapshot.data()}])
    })
    onSnapshot(doc(firestore,"Courses",courseId,),snapshot=>{
      let main =[]
      main.push({...snapshot.data(),id:snapshot.id})
      setcourse(main)


  
    })

    const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

    const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

    onSnapshot(notificationRef,snapshot=>{
      let main =[]
      
  
  
  
      snapshot.docs.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
       
      setnotification(main)
      })
  
      
      
    
  
    })

    const TestRef = doc(firestore,"Courses",courseId,"lectures",lectureId,'quiz',lectureId);
  
    onSnapshot(TestRef,snapshot=>{
      let main =[]
        if(snapshot.exists()){
          console.log(snapshot.data())
    
        main.push({...snapshot.data(),id:snapshot.id})
        main.forEach((item,index) => {
          setTest(item.question)
        
          
        });
    
      }
     
     
    
    
    })
           
      
    onSnapshot(TestRef,snapshot=>{
      let main =[]
        if(snapshot.exists()){
          console.log(snapshot.data())
    
        main.push({...snapshot.data(),id:snapshot.id})
        main.forEach((item,index) => {
          setpreviewQuizQuestions(item.question)
    setpreviewQuestions(item.question[questionIndex])
        
          
        });
    
      }
     
     
    
    
    })

    const lectureRef = doc(firestore,"Courses",courseId,"lectures",lectureId);
  
    onSnapshot(lectureRef,snapshot=>{
      let main =[]
      main.push({...snapshot.data(),id:snapshot.id})
      setlecture(main)
      if(snapshot.data() &&( snapshot.data().lectureTitle ||  snapshot.data().lectureDescription  || snapshot.data().lectureVideo)){
        setisLecture(true)
      }
    
    })
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);

  function previewClose(){
    setpreview(false) 
    setquestionIndex(0)
    setresult(false)
    setlock(false)
    setScore(0)

  }

  function deleteQuiz(){
    const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId,'quiz',lectureId);

   const documentRef = doc(
     firestore,
     "AccountDetails",
     uid,
     "CoursesCreated",
     courseId,
     "lectures",
     lectureId,'quiz',lectureId
   );
   const documentRef3 = doc(firestore, "Courses", courseId,"lectures",lectureId);
   const documentRef4 = doc(
    firestore,
    "AccountDetails",
    uid,
    "CoursesCreated",
    courseId,"lectures",
    lectureId
  );
  
 
   const lectureData2 = {
     lectureQuiz: false,
   };
   deleteDoc(documentRef);
   deleteDoc(documentRef2);
   setloading(true)
   setdeletePop(false)
   setDoc(documentRef3, lectureData2, { merge: true });
   setDoc(documentRef4, lectureData2, { merge: true }).then(() => {
     setquizDeleteMessage(true)
     window.scrollTo(0, 0);
     setloading(false)

   });
 }

 function closequizDeleteMessage(){
   setquizDeleteMessage(false)
 }
  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}

  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  };
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
 
  const handleInputChange = (e, index) => {
    setquestionIndex(index)
  seterrorMain('')

    if(e.target.value.length == 0){
      setQuestionError("error")
    }

    else{
      setQuestionError("")
      
    }
    const updatedQuestion = [...question];
    updatedQuestion[index] = {
      ...updatedQuestion[index],
      input: e.target.value,
    };
    setQuestion(updatedQuestion);
  };

  const handleChoiceChange = (e, index, choiceIndex) => {
    const updatedQuestion = [...question];
    seterrorMain('')
  
    updatedQuestion[index] = {
      ...updatedQuestion[index],
      choices: updatedQuestion[index].choices.map((choice, i) => {
        if (i === choiceIndex) {
          const label = String.fromCharCode(65 + choiceIndex) + ": ";
          return e.target.value.startsWith(label)
            ? e.target.value
            : label + e.target.value;
        } else {
          return choice;
        }
      }),
    };
  
    setQuestion(updatedQuestion);
  };
  const handleInputCountChange = (e) => {
    setInputCount(parseInt(e.target.value));
  };
  function desktopSpinnerHandler(){
    setspinner(false)
   }

   function resetVideoDesktop(currentIndex){
   
    if(lectureMain[currentIndex].lectureId == lectureId){
  
    }
    else{
      const lecture = lectureMain[currentIndex]
  
      navigate(`/course/${courseId}/lecture/${lecture.id}`)
    }
  
  }
  
  const formatVideoDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };


  

  function saveQuizDesktop() {
    const uniqueKey = v4().slice(0, 8);
    const documentRef2 = doc(firestore,"Courses",courseId,"lectures",lectureId,'quiz',lectureId);

    const documentRef = doc(
      firestore,
      "AccountDetails",
      uid,
      "CoursesCreated",
      courseId,
      "lectures",
      lectureId,'quiz',lectureId
    );
    const documentRef3 = doc(firestore, "Courses", courseId,"lectures",lectureId);
    const documentRef4 = doc(
      firestore,
      "AccountDetails",
      uid,
      "CoursesCreated",
      courseId,"lectures",
      lectureId
    );
    const lectureData = {
      question,
    };
  
    const lectureData2 = {
      lectureQuiz: true,
    };
  

    const validAnswers = ['a', 'b', 'c', 'd'];
let answererror = false;
    let emptyField = null;
  
    for (const q of question) {
      if (q.input.trim().length === 0) {
        emptyField = "input";
        break;
      }
      for (const choice of q.choices) {
        if (choice.trim().length == 0 || choice.trim().length  == 2 || choice.trim().length  == 3 || choice.trim().length  == 4 ) {
          emptyField = "choice";
          break;
        }
      }
    

for (const q of question) {
  if (!validAnswers.includes(q.answer.toLowerCase())) {
    answererror = true;
    break;
  }
}


      if (q.answer.trim().length === 0) {
        emptyField = "answer";
        break;
      }
    }
  
   if (emptyField) {
      if(emptyField == 'choice'){
        seterrorMain(`Each choice should have a value of at least 4 Letters`);

      }
      else{
        seterrorMain(`Make sure all ${emptyField} are not empty`);

      }
    }
    else if (answererror) {
        setAnswerError('error');
      }
      
    
    
    else {
      // Proceed with further actions since all inputs are non-empty
      setloading(true)

      
      setDoc(documentRef, lectureData, { merge: true });
      setDoc(documentRef2, lectureData, { merge: true });
      setDoc(documentRef3, lectureData2, { merge: true });
      setDoc(documentRef4, lectureData2, { merge: true }).then(() => {
        setsuccess(true);
      setloading(false)

      });
    }
  }

  const getVideoDuration = async (videoUrl) => {
    try {
      const video = document.createElement('video');
      video.src = videoUrl;
  
      await new Promise((resolve, reject) => {
        video.addEventListener('loadedmetadata', resolve);
        video.addEventListener('error', reject);
      });
  
      const duration = video.duration;
      return duration;
    } catch (error) {
      console.error('Error retrieving video duration:', error);
      return null;
    }
  };
  const handleAnswerChange = (e, index) => {
    const updatedQuestion = [...question];
  seterrorMain('')

    updatedQuestion[index] = {
      ...updatedQuestion[index],
      answer: e.target.value,
    };
    setindex(index)
    setanswer(e.target.value)
  
    const lowercaseValue = e.target.value.toLowerCase();
    if (lowercaseValue !== "a" && lowercaseValue !== "b" && lowercaseValue !== "c" && lowercaseValue !== "d") {
      setAnswerError("error");
    } else {
      setAnswerError("");
    }
  
    setQuestion(updatedQuestion);
  };

  useEffect(() => {
    const newQuestion = [...Array(inputCount)].map((_, index) => {
      if (index < question.length) {
        return question[index];
      } else {
        return {
          input: '',
          choices: ['', '', '', ''],
          answer: '',
          quizId:lectureId
        };
      }
    });
    setQuestion(newQuestion);
  }, [inputCount]);
 question.forEach(element => {
  console.log(element)

    
  });
  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

 
  return (
    
      user.map(i=>{
      const {accountType,plan,fullName,photo} = i

        return(
      accountType == 'Teacher' ?
          lecture.map(i=>{
          const {lectureQuiz,}= i

            return(
              <div>
                        {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
<div id='mobile'>
{
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
    {
      preview == false &&
      <DashboardNav/>
    }
    {
      success == true &&
      <div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',backgroundColor:'blue',zIndex:'100'}} className='flex items-center justify-center flex-col'>
    <Link to={`/course/${courseId}`}>
         <button  type="button" class="text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
             <svg aria-hidden="true" style={{width:'7vw',height:'7vw'}} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
             <span class="sr-only">Close modal</span>
         </button>
    
         </Link>
         <div style={{width:'12vw',height:'12vw'}}class=" rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
             <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
             <span class="sr-only">Success</span>
         </div>
         <p class="anton   capitalize text-center" style={{fontSize:'8vw',color:'white'}}>The Test for this course has been successfully created</p>
    
    
    
    
    
    
    </div>
    
    }
      
  {
   quizDeleteMessage == true &&
   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
    Test  successfully removed 

   </div>
   </div>
   <svg  onClick={closequizDeleteMessage}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </div> 

  }
    {
    deletePop == true && 
    
    <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
           
         
    <div className=' m-auto  rounded  ' id='subscriptionDesktop' style={{width:"90%",height:"60%"}}>
  <div class=" p-2 mx-auto bg-white border-[1px] border-gray-200 " 
  
  >
  <div class="relative p-6">
  <a onClick={closedeleteQuizPopDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
    
  </a>
  <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Remove Test </h1>
  <p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove this test? </p>
  
  <div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a onClick={deleteQuiz} style={{fontSize:"3vw"}} class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a onClick={closedeleteQuizPopDesktop}   style={{fontSize:"3vw"}}class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
  </div>
  </div>
  </div>
  </div>  
  
  
  </div>
   }
       
      {
           loading == true ?
           <div  className='flex justify-center items-center w-full h-full' >
           <div class="relative">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
       </div>
       
             </div>
             :
        !lectureQuiz  ?
        questions <= 15 && questions >= 1 ? 
        <div className='mx-auto' style={{width:"90%"}}>
        <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>Certificates cannot be awarded to students who score less than 75%</h1>
        <p className='borel text-gray-500 text-center' style={{fontSize:"3vw"}}>Make sure that you provide each question with a choice and an answer</p>
        <div class="flex flex-wrap items-start justify-end -mb-3">
         <button onClick={saveQuizDesktop} style={{fontSize:'3vw',height:'12vw',padding:'0vw 4vw'}} class="fjalla flex items-center justify-center  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                Post Test
              </button>
            </div>
        <div style={{width:"90%"}} className='mx-auto'>
          
        <div className={`${questionError == "error" ? "block":"hidden"}`}>
        <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <div className='flex justify-center items-center'>
       <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
       </svg>
       <div className='Mooli' style={{fontSize:'3vw'}}>
    A question cannot be left blank
    
       </div>
       </div>
     
       </div>
    
    </div>
    
    
    <div className={`${questionError == "error" ? "block":"hidden"}`}>
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <div className='flex justify-center items-center'>
       <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
       </svg>
       <div className='Mooli' style={{fontSize:'3vw'}}>
       choice cannot be left blank
       </div>
       </div>
     
       </div>
    
    </div>
    <div className={`${errorMain.length != 0 ? "block":"hidden"}`}>
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <div className='flex justify-center items-center'>
       <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
       </svg>
       <div className='Mooli' style={{fontSize:'3vw'}}>
       {errorMain}
    
       </div>
       </div>
     
       </div>
    </div>
        <div className={`${answerError == "error" ? "block":"hidden"}`}>
        <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <div className='flex justify-center items-center'>
       <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
       </svg>
       <div className='Mooli' style={{fontSize:'3vw'}}>
    The answer to a question  can only be either A,B,C or D
    
       </div>
       </div>
     
       </div>
    
    </div>
          {question.map((question, index) => (
            <div className="flex flex-col relative my-5" style={{marginBottom:'15vw'}} key={index}>
         
    <div className='relative'>
    <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Question {index + 1}</p>
    
    
    <input type='text'              value={question.input}
    className="w-full w-full form-control text-black border  focus:outline-none
    focus:border-black w-full  text-gray-700 block bg-white
    border-gray-300 rounded-md" style={{paddingTop:"8vw",paddingBottom:"8vw",marginBottom:'4vw',paddingLeft:"4vw",fontSize:"3vw"}}  onChange={(e) => handleInputChange(e, index)}/>
    
    
    </div>
    <div className='flex items-center justify-evenly '>
    <div className="gridChoiceDesktop" style={{width:"75%"}}>
    
      {question.choices.map((choice, choiceIndex) => (
        <div key={choiceIndex} className="flex" style={{marginBottom:'5vw'}}>
     
                              <input type='text'    value={choice}          
     className="w-full m-auto form-control text-black border  focus:outline-none
                     focus:border-black w-full  text-gray-700 block bg-white
                     
                     border-gray-300 rounded-md" style={{paddingTop:"7vw",paddingBottom:"7vw",paddingLeft:"4vw",fontSize:"3vw"}}  onChange={(e) => handleChoiceChange(e, index, choiceIndex)}/>
       
    
        </div>
      ))}
    </div>
    <div className='relative ml-5' style={{width:"25%"}}>
      <div className='relative'>
    <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"2vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>            Answer
    </p>
    
    
    
    <input type='text'               value={question.answer}
                onChange={(e) => handleAnswerChange(e, index)}
                placeholder='A,B,C,D'
    className="w-full w-full form-control text-black border  focus:outline-none
    focus:border-black w-full  text-gray-700 block bg-white
    border-gray-300 rounded-md borel" style={{paddingTop:"8vw",paddingBottom:"8vw",marginBottom:'4vw',paddingLeft:"4vw",fontSize:"3vw"}}  />
    
    
    </div>
    
    </div>
    </div>
    
    
            </div>
          ))}
          </div>
        </div>
        :
    
        <div className='flex justify-center flex-col items-center  px-3 w-full'>
       <img style={{width:"45%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Computer%20troubleshooting-bro.svg?alt=media&token=1aa12483-de6d-47df-9436-53064707790d" alt="Error" />
    
             <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>A minimum of 1 and a maximum of 15 test questions must be provided
    
    </h1>
        </div>
    : 
    preview == false?
    <div className='w-full mb-10 mx-auto' style={{width:'90%'}}>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>The only way to edit a test is to delete it and re-create it</h1>
    <div class="flex flex-wrap items-start justify-end  my-3">
    <button onClick={()=>setpreview(true)} style={{fontSize:'3vw',height:'12vw',padding:'0vw 4vw',marginRight:'5vw'}} class=" fjalla flex justify-center items-center text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">
        
        Preview as a student
      </button>
     <button onClick={deleteQuizDesktop} style={{fontSize:'3vw',height:'12vw',padding:'0vw 4vw'}} class="fjalla flex justify-center items-center text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
            Delete Test
          </button>

  
        </div>
        <p className='home_description-header underline text-center' style={{fontSize:'4.5vw',marginBottom:'4vw'}}>Choose the Correct Answer</p>
  {Test.map((i, index) => {
    const { input, choices,answer } = i;
    return (
      <div key={index} >
        <p className='borel' style={{ fontSize: '3.5vw' }}>
          <span style={{ fontSize: '3.6vw' }} className='underline capitalize mr-1 badScript'>{answer}</span>{index + 1}. {input}
        </p>
        <div className='mb-10'>
          {choices.map((choice, choiceIndex) => {
            return (
              <p key={choiceIndex} className=' borel text-gray-500 ' style={{ fontSize: '3vw',marginLeft:'5vw' }}>
                {choice}
              </p>
            );
          })}
        </div>
      </div>
    );
  })}
</div>
:
<div  className='flex items-center justify-center h-full backLinear relative' >
<svg onClick={previewClose} xmlns="http://www.w3.org/2000/svg" style={{width:'8vw'}} fill="currentColor" class="absolute top-1.5 left-1.5 bi bi-arrow-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
  {
    result == true ?
    75 < scorePercentage ?
   
    
    <div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col' style={{width:'75%',height:'100%' ,marginTop:'5vw',marginBottom:'5vw'}}>
    <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"5vw"}}>You have successfully completed the course, congratulations 
</h1>
  <p className='fjalla' style={{fontSize:"3vw"}}> You have passed the Test with a score of {score} out of {Test.length}</p>
    
    <img style={{width:'70%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
    
<button  onClick={previewClose} style={{fontSize:"3vw",height:'13vw',width:'80%'}}  className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 mx-auto fjalla hover:bg-blue-700 ease cursor-pointer capitalize">Turn Off Preview Mode
                                  </button>

    </div>
    : 

    <div className='rounded py-8 px-5 flex justify-center items-center flex-col' style={{width:'75%',height:'90%',backgroundColor:'white',border:'#ff4a4a',marginTop:'5vw',marginBottom:'5vw'}}>
    <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"5vw"}}> You failed the Test with a score of {score} out of {Test.length}
    </h1>
    <p className='fjalla text-gray-500' style={{fontSize:"3vw"}}>In order to complete this course, you must score at least 75%. </p>

   
    <img onLoad={loaderSet}  style={{width:'70%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />
    <button onClick={previewClose}  style={{fontSize:'3vw',height:'13vw',width:'90%'}} className="mb-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 mx-auto ease cursor-pointer capitalize fjalla">Turn Off Preview Mode
                                  </button>
    <button onClick={(e)=>retake(e)}  style={{fontSize:'3vw',height:'13vw',width:'90%'}} className="bg-red-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-red-700 mx-auto ease cursor-pointer capitalize fjalla">Retake Test
                                  </button>
    </div>
                            
    :
    <div className='bg-white rounded ' style={{width:'75%',height:'90%',padding:'8vw',marginTop:'5vw',marginBottom:'5vw'}}>
     <div className='flex justify-between'>
     <h1 className='home_description-header 'style={{fontSize:'6vw',paddingBottom:'5vw'}}>Test</h1><hr />
        <p className='fjalla' style={{fontSize:'3.5vw'}}>{questionIndex + 1} of {previewQuizQuestions.length} questions </p>
     </div>

        <p className='fjalla  capitalize' style={{fontSize:'4vw',marginBottom:'5vw'}}>{questionIndex + 1}. {previewQuestions && previewQuestions.input}</p>
        <ul className='ulChoice'>
          
          <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[0]}</li>
          <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[1]}</li>
          <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[2]}</li>
          <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[3]}</li>


        </ul>
        <button onClick={(e)=>next()}  style={{fontSize:'3vw',height:'13vw',width:'80%'}}   className="bg-blue-800 w-full mx-auto flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">Next
                                  </button>


  </div>
      }
  </div>
    
    
      }
        </div>
     <div id='desktop'>
     {
       preview == false &&
       <div>
        <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
 
       <div>
       <ul className='flex justify-start border-b'>
         <div className=' flex justify-evenly   '>
               <Link to='/dashboard'>
            <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
 
               </Link>
 <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
 <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
 
               </Link>
 <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
 <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
 
               </Link>
               <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
 <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
 
               </Link>
 
               <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
 <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
 
               </Link>
 
            </div>
           
          
 
 
 
 
         </ul>
       </div>
       </div>
     }

       {
quizDeleteMessage == true &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
Quiz successfully removed 
</div>
<button onClick={closequizDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

{
errorMain.length != 0 &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
 {errorMain} 
</div>

</div>
}
{
success == true &&
<div id='successQuizCreateDesktop' className=' flex justify-center items-center'>


<div class="relative p-4 w-full  h-full md:h-auto"style={{width:"45%"}}>
   <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
     
       <button onClick={()=>navigate(0)}  type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
           <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
           <span class="sr-only">Close modal</span>
       </button>


       <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
           <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
           <span class="sr-only">Success</span>
       </div>
       <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">The Quiz for this Lecture has been successfully created</p>
<button onClick={()=>navigate(0)}   type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Preview </button>


   </div>
</div>



 </div>
}
{
deletePop == true && 
<div id='uploadCancelDesktop' className=' flex items-center justify-center'>
    
  
<div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closedeleteQuizPopDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Quiz </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Quiz? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={deleteQuiz}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closedeleteQuizPopDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
{
  plan == 'Free'?
  <div className='flex items-center justify-center flex-col '>
  <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"25%"}}/>
    <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Upgrade your plan if you wish to Prepare quizzes for your students


</h1>

  </div>
  :
        loading == true ?
        <div  className='flex justify-center items-center w-full h-full' >
        <div class="relative">
        <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
        <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>
    
          </div>
          :
!lectureQuiz ?
questions <= 15 && questions >= 1 ? 
isLecture == true ?
<div className='mx-auto' style={{width:"90%"}}>
<h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>A minimum score of 75% is required to pass the next lecture</h1>
<p className='borel text-gray-500 text-center'>Make sure that you provide each question with a choice and an answer</p>
<div class="flex flex-wrap items-start justify-end -mb-3">
<button onClick={saveQuizDesktop} style={{fontSize:'13px'}} class="fjalla inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
   
       Post Test
     </button>


   </div>
<div style={{width:"70%"}} className='mx-auto'>
<div className={`${questionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
A question cannot be left blank
</div>
</div>
</div>

<div className={`${questionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
choice cannot be left blank
</div>
</div>
</div>

<div className={`${answerError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
The answer to a question  can only be either A,B,C or D
</div>
</div>
</div>
 {question.map((question, index) => (
   <div className="flex flex-col relative my-5" key={index}>
     <p style={{fontSize:'15px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">
       Question {index + 1}
     </p>

     <input
     style={{fontSize:'12px'}}
       className="mx-2 borel border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
       type="text"
       value={question.input}
       onChange={(e) => handleInputChange(e, index)}
     />

<div className='flex items-center justify-center'>
<div className="gridChoiceDesktop">
{question.choices.map((choice, choiceIndex) => (
<div key={choiceIndex} className="flex">
 <input
  style={{fontSize:'12px'}}
   className="mx-2 borel border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
   type="text"
   value={choice}
   onChange={(e) => handleChoiceChange(e, index, choiceIndex)}
 />
</div>
))}
</div>
<div className='relative ml-5' style={{width:"20%"}}>
<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">
       Answer
     </p>
     <input
     tyle={{fontSize:'12px'}}
       className="borel mx-2 border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
       type="text"
       value={question.answer}
       onChange={(e) => handleAnswerChange(e, index)}
       placeholder='A,B,C,D'
     />
</div>
</div>


   </div>
 ))}
 </div>
</div>
:
<div>
<div className='flex items-center justify-center flex-col '>
<img style={{width:"30%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Computer%20troubleshooting-bro.svg?alt=media&token=1aa12483-de6d-47df-9436-53064707790d" alt="Error" />

  <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"25px"}}>You must upload a lecture video, the lecture title, and the lecture description before you can add a quiz


</h1>

</div>
</div>
:

<div className='flex justify-center flex-col items-center  px-3 w-full'>
    <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>A minimum of 1 and a maximum of 15 test questions must be provided

</h1>
<img style={{width:"40%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Computer%20troubleshooting-bro.svg?alt=media&token=1aa12483-de6d-47df-9436-53064707790d" alt="Error" />
</div>
:
preview == false ?
<div className='w-full mb-10 mx-auto' style={{width:'90%'}}>
 <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>The only way to edit a quiz is to delete it and re-create it</h1>
 <div class="flex flex-wrap items-start justify-end  my-3">
<button onClick={()=>setpreview(true)} style={{fontSize:'13px'}} class="mr-5 fjalla inline-flex px-5 py-3 text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">
 
 Preview as a student
</button>
<button onClick={deleteQuizDesktop} style={{fontSize:'13px'}} class="fjalla inline-flex px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
 
     Delete Test
   </button>


 </div>
{Test.map((i, index) => {
const { input, choices,answer } = i;
return (
 <div key={index} >
   <p className='borel mb-2' style={{ fontSize: '17px' }}>
     <span style={{ fontSize: '20px' }} className='underline capitalize mr-1 badScript'>{answer}</span>{index + 1}. {input}
   </p>
   <div className='mb-10'>
     {choices.map((choice, choiceIndex) => {
       return (
         <p key={choiceIndex} className=' borel ml-8 mb-1.5' style={{ fontSize: '16px' }}>
           {choice}
         </p>
       );
     })}
   </div>
 </div>
);
})}
</div>
:

<div  className='flex items-center justify-center h-full backLinear ' >
<svg onClick={previewClose} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="absolute top-1.5 left-1.5 bi bi-arrow-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
 {
   result == true ?
   75 < scorePercentage ?
  
   
   <div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'100%'}}>
   <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}>You have successfully completed the course, congratulations 
</h1>
 <p className='fjalla' style={{fontSize:"12px"}}> You have passed the Test with a score of {score} out of {Test.length}</p>
   
   <img style={{width:'60%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
<button  onClick={previewClose} style={{fontSize:"11px"}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                     rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Turn Off Preview Mode
                                 </button>

   </div>
   : 

   <div className='rounded py-8 px-5 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
   <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}> You failed the Quiz with a score of {score} out of {Test.length}
   </h1>
   <p className='fjalla text-gray-500' style={{fontSize:"12px"}}>In order to complete this lecture, you must score at least 75%. </p>

  
   <img onLoad={loaderSet}  style={{width:'50%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />
   <button onClick={previewClose}  style={{fontSize:'12px'}} className="mb-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                     rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">Turn Off Preview Mode
                                 </button>
   <button onClick={(e)=>retake(e)}  style={{fontSize:'12px'}} className="bg-red-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                     rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize fjalla">Retake Test
                                 </button>
   </div>
                           
   :
   <div className='bg-white rounded py-8 px-10 my-12' style={{width:'75%',height:'90%'}}>
    <div className='flex justify-between'>
    <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Test</h1><hr />
       <p className='fjalla'>{questionIndex + 1} of {previewQuizQuestions.length} questions </p>
    </div>

       <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {previewQuestions && previewQuestions.input}</p>
       <ul className='ulChoice'>
         
         <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[0]}</li>
         <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[1]}</li>
         <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[2]}</li>
         <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[3]}</li>


       </ul>

       <button onClick={(e)=>next()}   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                     rounded-lg fjalla transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                                 </button>


 </div>
     }
 </div>

}
</div>
  <div id='tablet'>
  {
    preview == false &&
    <DashboardNav/>
  }

    {
quizDeleteMessage == true &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
Quiz successfully removed 
</div>
<button onClick={closequizDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

{
errorMain.length != 0 &&
<div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
 {errorMain} 
</div>

</div>
}
{
success == true &&
<div id='successQuizCreateDesktop' className=' flex justify-center items-center'>


<div class="relative p-4 w-full  h-full md:h-auto"style={{width:"45%"}}>
<div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
  
    <button onClick={()=>navigate(0)}  type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Close modal</span>
    </button>


    <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
        <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Success</span>
    </div>
    <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">The Quiz for this Lecture has been successfully created</p>
<button onClick={()=>navigate(0)}   type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Preview </button>


</div>
</div>



</div>
}
{
deletePop == true && 
<div id='uploadCancelDesktop' className=' flex items-center justify-center'>
 

<div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closedeleteQuizPopDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Quiz </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove this Quiz? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={deleteQuiz}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closedeleteQuizPopDesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
}
{
        loading == true ?
        <div  className='flex justify-center items-center w-full h-full' >
        <div class="relative">
        <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
        <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>
    
          </div>
          :
!lectureQuiz ?
questions <= 15 && questions >= 1 ? 
<div className='mx-auto' style={{width:"90%"}}>
<h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"25px"}}>A minimum score of 75% is required to pass the next lecture</h1>
<p className='borel text-gray-500 text-center'>Make sure that you provide each question with a choice and an answer</p>
<div class="flex flex-wrap items-start justify-end -mb-3">
<button onClick={saveQuizDesktop} style={{fontSize:'13px'}} class="fjalla inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

    Post Test
  </button>


</div>
<div style={{width:"70%"}} className='mx-auto'>
<div className={`${questionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
A question cannot be left blank
</div>
</div>
</div>

<div className={`${questionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
choice cannot be left blank
</div>
</div>
</div>

<div className={`${answerError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli capitalize'>
The answer to a question  can only be either A,B,C or D
</div>
</div>
</div>
{question.map((question, index) => (
<div className="flex flex-col relative my-5" key={index}>
  <p style={{fontSize:'15px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">
    Question {index + 1}
  </p>

  <input
  style={{fontSize:'12px'}}
    className="mx-2 borel border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
    type="text"
    value={question.input}
    onChange={(e) => handleInputChange(e, index)}
  />

<div className='flex items-center justify-center'>
<div className="gridChoiceDesktop">
{question.choices.map((choice, choiceIndex) => (
<div key={choiceIndex} className="flex">
<input
style={{fontSize:'12px'}}
className="mx-2 borel border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
type="text"
value={choice}
onChange={(e) => handleChoiceChange(e, index, choiceIndex)}
/>
</div>
))}
</div>
<div className='relative ml-5' style={{width:"20%"}}>
<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">
    Answer
  </p>
  <input
  tyle={{fontSize:'12px'}}
    className="borel mx-2 border focus:outline-none focus:border-black w-full py-6 pr-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md"
    type="text"
    value={question.answer}
    onChange={(e) => handleAnswerChange(e, index)}
    placeholder='A,B,C,D'
  />
</div>
</div>


</div>
))}
</div>
</div>
:

<div className='flex justify-center flex-col items-center  px-3 w-full'>
 <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>A minimum of 1 and a maximum of 15 test questions must be provided

</h1>
<img style={{width:"40%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Computer%20troubleshooting-bro.svg?alt=media&token=1aa12483-de6d-47df-9436-53064707790d" alt="Error" />
</div>
:
preview == false ?
<div className='w-full mb-10 mx-auto' style={{width:'90%'}}>
<h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>The only way to edit a quiz is to delete it and re-create it</h1>
<div class="flex flex-wrap items-start justify-end  my-3">
<button onClick={()=>setpreview(true)} style={{fontSize:'13px'}} class="mr-5 fjalla inline-flex px-5 py-3 text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">

Preview as a student
</button>
<button onClick={deleteQuizDesktop} style={{fontSize:'13px'}} class="fjalla inline-flex px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">

  Delete Test
</button>


</div>
{Test.map((i, index) => {
const { input, choices,answer } = i;
return (
<div key={index} >
<p className='borel mb-2' style={{ fontSize: '17px' }}>
  <span style={{ fontSize: '20px' }} className='underline capitalize mr-1 badScript'>{answer}</span>{index + 1}. {input}
</p>
<div className='mb-10'>
  {choices.map((choice, choiceIndex) => {
    return (
      <p key={choiceIndex} className=' borel ml-8 mb-1.5' style={{ fontSize: '16px' }}>
        {choice}
      </p>
    );
  })}
</div>
</div>
);
})}
</div>
:

<div  className='flex items-center justify-center h-full backLinear ' >
<svg onClick={previewClose} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="absolute top-1.5 left-1.5 bi bi-arrow-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
{
result == true ?
75 < scorePercentage ?


<div className='bg-white rounded py-8 px-4 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'100%'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}>You have successfully completed the course, congratulations 
</h1>
<p className='fjalla' style={{fontSize:"12px"}}> You have passed the Test with a score of {score} out of {Test.length}</p>

<img style={{width:'60%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Enthusiastic-bro%20(2).svg?alt=media&token=b0960678-60e0-4c42-828a-6d0bdecd7cad" alt="bulb" />
<button  onClick={previewClose} style={{fontSize:"11px"}}  className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Turn Off Preview Mode
                              </button>

</div>
: 

<div className='rounded py-8 px-5 flex justify-center items-center flex-col my-12' style={{width:'75%',height:'90%',backgroundColor:'white',border:'#ff4a4a'}}>
<h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"30px"}}> You failed the Quiz with a score of {score} out of {Test.length}
</h1>
<p className='fjalla text-gray-500' style={{fontSize:"12px"}}>In order to complete this lecture, you must score at least 75%. </p>


<img onLoad={loaderSet}  style={{width:'50%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Anxiety-bro%20(2).svg?alt=media&token=1e6e0c8a-287e-4aee-b408-284ee412f8b8" alt="Failed" />
<button onClick={previewClose}  style={{fontSize:'12px'}} className="mb-5 bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">Turn Off Preview Mode
                              </button>
<button onClick={(e)=>retake(e)}  style={{fontSize:'12px'}} className="bg-red-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg transition duration-200 hover:bg-red-700 ease cursor-pointer capitalize fjalla">Retake Test
                              </button>
</div>
                        
:
<div className='bg-white rounded py-8 px-10 my-12' style={{width:'75%',height:'90%'}}>
 <div className='flex justify-between'>
 <h1 className='home_description-header pb-5'style={{fontSize:'30px'}}>Test</h1><hr />
    <p className='fjalla'>{questionIndex + 1} of {previewQuizQuestions.length} questions </p>
 </div>

    <p className='fjalla mb-5 capitalize' style={{fontSize:'25px'}}>{questionIndex + 1}. {previewQuestions && previewQuestions.input}</p>
    <ul className='ulChoice'>
      
      <li onClick={(e)=>{checkAnswer(e,'A')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[0]}</li>
      <li onClick={(e)=>{checkAnswer(e,'B')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[1]}</li>
      <li onClick={(e)=>{checkAnswer(e,'C')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[2]}</li>
      <li onClick={(e)=>{checkAnswer(e,'D')}} className={` choiceDesktop `}>{previewQuestions && previewQuestions.choices[3]}</li>


    </ul>

    <button onClick={(e)=>next()}   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                  rounded-lg fjalla transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Next
                              </button>


</div>
  }
</div>

}
</div>
    </div>
            )
          })
          :
      <div>

      </div>

        )
      })
    


  );
};

export default Quiz;