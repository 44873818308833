// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAEU-6FKpLJAPT7zuoNVhF6eRdToVaaXZ0",
  authDomain: "kesht-academy.firebaseapp.com",
  projectId: "kesht-academy",
  storageBucket: "kesht-academy.appspot.com",
  messagingSenderId: "614512764832",
  appId: "1:614512764832:web:c759478d27f2dca2fe66c5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
