import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import DashboardNav from './dashboardNav';


export default function Preview() {
    const {courseId} = useParams()
    const [uid,setuid] = useState("")
    const [course,setcourse] = useState([])
    const [showContent, setShowContent] = useState(false);
    const [spinner, setspinner] = useState(true);
    const [pop,setpop] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)
    const [lecture,setlecture] = useState([])
    const [Instructor,setinstructor] = useState([])
    const [instructorUid,setinstructorUid] = useState('')
    const [lecturePreview,setlecturePreview] = useState(null)
    const [firstlectureId,setFirstlectureId] = useState("")


    const [videoDurations, setVideoDurations] = useState({});
    const [selectedLecture,setselectedLecture] = useState([])
    
const navigate = useNavigate()

    
function desktopSpinnerHandler(){
  setspinner(false)
 }
function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
        const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
        const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
        onSnapshot(lecturesCollectionQuery, (snapshot) => {
          let main = [];
          snapshot.docs.forEach(doc => {
            main.push({ ...doc.data(),id:doc.id });
          });

    //          onSnapshot(lecturesCollectionQuery).then((querySnapshot) => {
    //   const emptyIndices = [];
    //   let index = 0;
    //   querySnapshot.forEach((doc) => {
    //     const data = doc.data();
    //     if (!data.lectureTitle || data.lectureTitle === 'Untitled Lecture') {
    //       emptyIndices.push(index + 1);
    //     }
    //     index++;
    //   });
    //   setlectureTitleError(`Complete the lecture title for ${emptyIndices.length != 1 ? 'lectures' : 'lecture'} ${emptyIndices.map(i => i).join(', ')}`  );

    // });
 

          const firstLecture = snapshot.docs[0];
          const firstLectureId = firstLecture?.data()?.lectureId;
          setFirstlectureId(firstLectureId);
        });
      
      
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
        setcourse(main)
        setinstructorUid(snapshot.data().instructorId)
        onSnapshot(doc(firestore,"TotalUsers",snapshot.data().instructorId),snapshot=>{
          let main =[]
          
          main.push({...snapshot.data()})
          setinstructor(main)
      
        })
      })

   
    
            
      const documentRef = collection(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures")
  const q = query(documentRef, orderBy('createdTime'));

      onSnapshot(q,snapshot=>{
        let main =[]
        snapshot.docs.forEach(doc=>{
                main.push({...doc.data()})
                setlecture(main)
              
    
        })
   
    
   

      
      })
            
    
               
     
    //    })
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, [])
      
      const videoRef = useRef(null);
      const [showPlayButton, setShowPlayButton] = useState(true);

      const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
          setShowPlayButton(false);
       
        }
      };

      const [openLectures, setOpenLectures] = useState([]);
      const handleLectureClick = (lectureTitle) => {
        if (openLectures.includes(lectureTitle)) {
          setOpenLectures(openLectures.filter((title) => title !== lectureTitle));
        } else {
          setOpenLectures([...openLectures, lectureTitle]);
        }
      };

      const getVideoDuration = async (videoUrl) => {
       if(videoUrl){
        try {
          const video = document.createElement('video');
          video.src = videoUrl;
      
          await new Promise((resolve, reject) => {
            video.addEventListener('loadedmetadata', resolve);
            video.addEventListener('error', reject);
          });
      
          const duration = video.duration;
          return duration;
        } catch (error) {
          console.error('Error retrieving video duration:', error);
          return null;
        }
       }
      };

      useEffect(() => {
        const fetchVideoDurations = async () => {
          const durations = {};
          for (const lectureItem of lecture) {
            const { lectureVideo } = lectureItem;
            const duration = await getVideoDuration(lectureVideo);
            durations[lectureVideo] = duration;
          }
          setVideoDurations(durations);
        };
    
        fetchVideoDurations();
      }, [lecture]);

      const formatVideoDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);
      
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      };

      useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const windowHeight = window.innerHeight;
    
          if (scrollPosition > windowHeight) {
            setShowContent(true);
          } else {
            setShowContent(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const [showAllLectures, setShowAllLectures] = useState(false);
      const maxVisibleLectures = 3;
      const visibleLectures = showAllLectures ? lecture : lecture.slice(0, maxVisibleLectures);
    
      const handleReadMore = () => {
        setShowAllLectures(true);
      };

        // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}

    
  return (
    <div>
           {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
           <div id='desktop' >
         
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
        
<Link to="/mycourse"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/mystudents"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 <Link to="/certificate"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage'>
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
    <h1 className='home_description-header capitalize pl-10 pt-5 text-white' style={{fontSize:'25px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'11px'}}>{lecture.length} Lectures </p>
</h1>

            <div className='flex items-center'>
                
  
            <Link to={`/course/${courseId}/preview/${firstlectureId}`} >

            <button
              type='button'
              style={{fontSize:'11px'}}
              className='mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
         
            </div>

            </div>}
          <div className='border-l ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t  px-12 bg-black h-full" >

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"13px",color:'white'}}><Link to='/dashboard' className='hover:underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='hover:underline' to={`/course/${courseId}`}>Course</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-6' style={{marginTop:'10%'}}>
    <div>
    <h1 className='anton capitalize text-white'>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='home_description-header pr-2' style={{fontSize:'16px',color:'white'}}>Course By: </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"35px",height:"35px"}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"35px",height:"35px"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white'>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
              <div className='flex items-center'>
                
            <Link to={`/course/${courseId}/preview/${firstlectureId}`} >

            <button
              type='button'
              
              className='mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
            </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'23px',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'23px',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'24px',color:'white'}}>
              {coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}>              {coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'150px',}}>
            <h1 className='step__header anton capitalize' style={{color:"black",marginLeft:'50px',fontSize:'35px'}}>During this course, you will learn: </h1>
           <div class="py-16 " >  
    <div class=" text-gray-500 ">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3 " >
        {
                courseSkills.map(i=>{
                return(
                  <div class="bg-white rounded-2xl ml-2 mr-2 " >
                <div class="mb-8  flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg>
                    <p class="ml-3 home_description-header text-gray-800  " style={{fontSize:'13px'}}>{i}</p>
                    
                </div>
            </div>
                )
                })
              }
         
       
        </div>
    </div>
</div>

           


           
        </div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'150px',marginBottom:'150px', height:'200px',width:'90%',}} className='border  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'13px'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className='border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-10 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'30px'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'13px',width:'90%'}}>{courseDescription} </p>
          </div>
          
     
          <div className="" style={{width:'50%'}}>
          <video
      className="video-player"
      controls
      poster="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658"
    >
      <source src={courseTrailer} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '25px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex'>
        <span className='borel text-gray-500 mr-2'>Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button onClick={handleReadMore} class="inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
<div id='tablet' >
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' width="18" height="18" style={{objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
        
<Link to="/mycourse"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/mystudents"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 <Link to="/certificate"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage'>
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
    <h1 className='home_description-header capitalize pl-10 pt-5 text-white' style={{fontSize:'25px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'11px'}}>{lecture.length} Lectures </p>
</h1>

            <div className='flex items-center'>
                
  
            <Link to={`/course/${courseId}/preview/1`} >
            <button
              type='button'
              style={{fontSize:'11px'}}
              className='mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
         
            </div>

            </div>}
          <div className='border-l ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t   bg-black h-full" >

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla pl-5 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"2vw",color:'white'}}><Link to='/dashboard' className='hover:underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='hover:underline' to={`/course/${courseId}`}>Course</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'20%'}}>
    <div>
    <h1 className='anton capitalize text-white' style={{fontSize:'5vw'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='home_description-header pr-2' style={{fontSize:'2.2vw',color:'white'}}>Course By: </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"6vw",height:"6vw"}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"6vw",height:"6vw"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white'>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
              <div className='flex items-center'>
                
              <Link to={`/course/${courseId}/preview/1`} >
            <button
              type='button'
              style={{fontSize:'1.8vw'}}
              className='mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
            </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'3.5vw',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'1.7vw'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'3.5vw',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'3.5vw',color:'white'}}>
              {coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'1.7vw'}}>              {coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'150px',}}>
            <h1 className='step__header anton capitalize' style={{color:"black",marginLeft:'20px',fontSize:'35px'}}>During this course, you will learn: </h1>
           <div class="py-16 " >  
    <div class=" text-gray-500 ">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3 " >
        {
                courseSkills.map(i=>{
                return(
                  <div class="bg-white rounded-2xl ml-2 mr-2 " >
                <div class="mb-8  flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg>
                    <p class="ml-3 home_description-header text-gray-800  " style={{fontSize:'13px'}}>{i}</p>
                    
                </div>
            </div>
                )
                })
              }
         
       
        </div>
    </div>
</div>

           


           
        </div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'150px',marginBottom:'150px',width:'90%',}} className='py-8 border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'1.7vw'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-10 'style={{width:'60%'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'30px'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'1.7vw',width:'90%'}}>{courseDescription} </p>
          </div>
          
     
          <div className="video-player-container" style={{width:'50%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '3vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' >Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
<div id='mobile' >

<div >
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between items-center w-full bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'25vw'}} >
    <h1 className='anton capitalize  text-white' style={{fontSize:'5vw',width:'70%',paddingLeft:'3vw',marginRight:'3vw'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'2.8vw'}}>{lecture.length} Lectures </p>
</h1>

                
  
            <Link to={`/course/${courseId}/preview/1`} >
            <button
              type='button'
              style={{fontSize:'3vw',height:'12vw',width:'25vw',padding:'0 3vw',marginTop:'3vw',marginRight:'5vw'}}
              className='mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
         

            </div>}
          <div className='border-l ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t   bg-black h-full" >
          <nav className=' flex justify-between navMobile  w-full'>
<Link to="/dashboard"  style={{width:'22%'}}>
      <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht_.__2_-removebg-preview.png?alt=media&token=17e900e3-c5a4-43ad-aac9-4b380ae7d27e' alt="logo" /> 
    </Link>
                <div   className=' flex items-center'>




                <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"9vw"}} fill="currentColor" class="bi bi-list text-white" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
                </div>




</nav>
    {
      pop == true &&
    <div id='popoutMobileHome' className='relative '>
          <nav className='shadow flex justify-between navMobile border-b w-full'>
                <div   className=' 'style={{width:'28%'}}>
    
  <svg  onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"8vw"}} fill="currentColor" class="bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
      </svg>
                </div>


  <Link to="/dashboard" onClick={popProfile} style={{width:'19%'}}>
      <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
    </Link>


<div   className='flex justify-end ' style={{width:'33.33%'}}>
<div   className=' '>

<Link to='/mycourse' >
  <button type='submit' style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw"}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
                rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer">Courses
  </button>
</Link>
</div>



</div>

</nav>


  
<ul className='w-full'>
           
                      <li className=' text-gray-800 '><Link to="/mystudents">
                      <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                            <div className='  borel' style={{fontSize:"3vw"}}>My Students </div>
    

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
                            </div>
                      </Link></li>
                      <li className=' text-gray-800 '><Link to="/transactions">
                      <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                            <div className='  borel' style={{fontSize:"3vw"}}>Transactions </div>
        
 
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

                            </div>
                      </Link></li>
                      <li className=' text-gray-800 '><Link to="/certificate">
                      <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                            <div className='  borel' style={{fontSize:"3vw"}}>Certificates </div>
          
<img style={{width:"5vw"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

                            </div>
                      </Link></li>
                      <li className=' text-gray-800 '>
                       <Link to='/notification'>

                      <div  style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                       <div className='borel 'style={{fontSize:"3vw"}}>Notifications </div>
                            <svg xmlns="http://www.w3.org/2000/svg" class=" text-gray-800"style={{width:"5vw"}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
          </div>

          </Link>
                      </li>
                      <li onClick={handleLogOut}className=' text-gray-800 '>
                      <div style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                            <div className='borel' style={{fontSize:"3vw"}}>Log out </div>
                         
                            <svg  className='' xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
        <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
      </svg>
                            </div>
                      </li>
             
            
      
      
                  </ul>
      </div>
    }

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla  flex items-center capitalize' style={{fontSize:"2.5vw",color:'white',paddingLeft:'5vw',paddingTop:'4vw'}}><Link to='/dashboard' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/course/${courseId}`}>Course</Link>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'20%'}}>
    <div>
    <h1 className='anton capitalize text-white' style={{fontSize:'6vw'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='fjalla pr-2' style={{fontSize:'3vw',color:'white'}}>Course By :- </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"7vw",height:"7vw",fontSize:'3vw'}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"7vw",height:"7vw"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white' style={{fontSize:'3vw'}}>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
              <div className='flex items-center'>
                
              <Link to={`/course/${courseId}/preview/1`} >
            <button
              type='button'
              style={{fontSize:'3vw',height:'12vw',padding:'0 3vw',marginLeft:'5vw',marginTop:'6vw'}}
              className=' text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
            </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}>              {coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'30vw',}}>
            <h1 className='step__header anton capitalize' style={{color:"black",marginLeft:'6vw',fontSize:'6vw'}}>During this course, you will learn: </h1>
           <div class="py-16 " >  
    <div class=" text-gray-500 ">
        <div class="grid-discover grid-cols-1 md:lg:xl:grid-cols-3 " >
        {
                courseSkills.map(i=>{
                return(
                  <div class="bg-white rounded-2xl  " style={{marginRight:'2vw',marginLeft:'2vw'}}>
                <div class="  flex items-center" style={{marginBottom:'5vw'}}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{width:'5vw'}} fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg>
                    <p class="ml-3 home_description-header text-gray-800  " style={{fontSize:'3vw'}}>{i}</p>
                    
                </div>
            </div>
                )
                })
              }
         
       
        </div>
    </div>
</div>

           


           
        </div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'30vw',marginBottom:'30vw',width:'90%',padding:'4vw 0'}} className=' border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'6vw',lineHeight:'6vw'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'2.5vw',marginTop:'3vw'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'30vw',marginBottom:'30vw',width:'100%',padding:'4vw 0'}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className=' 'style={{width:'60%',paddingLeft:'4vw'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'6vw',lineHeight:'6vw'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'2.8vw',width:'90%'}}>{courseDescription && courseDescription.length <= 300 ? courseDescription : courseDescription.slice(0,300) ,<p onClick={()=>courseDescription.slice(0,courseDescription.length)}>Show More</p>} </p>
          </div>
          
     
          <div className="video-player-container" style={{width:'45%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '3vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' >Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
        </div>
  )
}
