import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc,updateDoc, getDoc,deleteDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import { UseAuth } from '../context/contex';
import DashboardNav from './dashboardNav';

export default function Withdraw() {
    const {courseId} = useParams()
    const [uid,setuid] = useState("")
    const [bank,setBank] = useState("")
    const [notification,setnotification] = useState([])

    const [bankError,setBankError] = useState("")
    const [accountName,setAccountName] = useState("")
    const [accountNameError,setAccountNameError] = useState("")
    const [accountNumber,setAccountNumber] = useState("")
    const [accountNumberError,setAccountNumberError] = useState("")
    const [withdrawAmount,setwithdrawAmount] = useState("")
    const [withdrawalMessage,setWithdrawalMessage] = useState("")
    const [balanceInsufficient,setbalanceInsufficient] = useState('')

    const [success,setsuccess] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)
    const [pop,setpop] = useState(false)





    const [withdrawAmountError,setwithdrawAmountError] = useState("")
    const [spinner,setspinner] = useState(true)
    const [user,setuser] = useState([])
    const navigate = useNavigate()


    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
        //  setcred(uid)
      setuid(uid);
         
      const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

      const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
  
      onSnapshot(notificationRef,snapshot=>{
        let main =[]
        
    
    
    
        snapshot.docs.forEach(doc=>{
          main.push({...doc.data(),id:doc.id})
         
        setnotification(main)
        })
    
        
        
      
    
      })
     
      
  
    const coursesRef = collection(firestore,"AccountDetails",uid,"CoursesCreated");
    const teacherRef = doc(firestore,"TotalUsers",uid);
    const userRef = doc(firestore,"TotalUsers",uid);
  
    onSnapshot(userRef,snapshot=>{
     
      let main =[]
  
  
  
        main.push({...snapshot.data(),id:snapshot.id})
        
    setuser(main)
  
      
      
    
  
    })
    
 
  
  
  
  
  
         
    
  
  
  
  
          
  
             
   
    
              
    
    
    
    
    
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    function desktopSpinnerHandler(){
      setspinner(false)
     }
     function handleLogOut(){
      signOut(auth).then(
       navigate('/')
      )
     }

     const popProfile = () =>{
      if(pop == true){
        setpop(false)
      setnotificationPop(false)
  
  
      }
      else{
      setpop(true)
      setnotificationPop(false)
  
  
  
      }
  }

  const popNotification = () =>{
    if(notificationPop == true){
      setnotificationPop(false)
      setpop(false)
    }
    else{
    setnotificationPop(true)
    setpop(false)
  
  
  
    }
  }
     function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      setDoc(notificationRef,{
        seen:true
      },{merge:true})
      .then(() => {
        
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
      });
  
  
     }
    let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)


    function bankSet(e){
        setBank(e.target.value)
        if(e.target.value == ""){
            setBankError("error")
        }
        else {
           
            setBankError("")
        
                
             }
            }
        
             const convertTimestampToDate = (timestamp) => {
              const date = timestamp.toDate();
              const currentDate = new Date();
              const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
            
              const secondsInMinute = 60;
              const secondsInHour = 3600;
              const secondsInDay = 86400;
              const secondsInWeek = 604800;
              const secondsInMonth = 2592000;
              const secondsInYear = 31536000;
            
              let relativeTime = '';
            
              if (timeDifferenceInSeconds < secondsInMinute) {
                relativeTime = `${timeDifferenceInSeconds}s ago`;
              } else if (timeDifferenceInSeconds < secondsInHour) {
                const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
                relativeTime = `${minutes}m ago`;
              } else if (timeDifferenceInSeconds < secondsInDay) {
                const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
                relativeTime = `${hours}h ago`;
              } else if (timeDifferenceInSeconds < secondsInWeek) {
                const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
                relativeTime = `${days}d ago`;
              } else if (timeDifferenceInSeconds < secondsInMonth) {
                const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
                relativeTime = `${weeks}w ago`;
              } else if (timeDifferenceInSeconds < secondsInYear) {
                const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
                relativeTime = `${months}mo ago`;
              } else {
                const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
                relativeTime = `${years}y ago`;
              }
            
              return relativeTime;
            };

            function accountNameSet(e){
                setAccountName(e.target.value)
                if(e.target.value.length == 0){
                    setAccountNameError("error")
                }
                else {
                   
                    setAccountNameError("")
                
                        
                     }
                    }
                    function withdrawAmountSet(e){
                        setwithdrawAmount(e.target.value)
                        setbalanceInsufficient('')
                        if(e.target.value.length == 0){
                            setwithdrawAmountError("error")
                        }
                        else {
                           
                            setwithdrawAmountError("")
                        
                                
                             }
                            }

                    function accountNumberSet(e){
                        setAccountNumber(e.target.value)
                        if(e.target.value.length == 0){
                            setAccountNumberError("error")
                        }
                        else {
                           
                            setAccountNumberError("")
                        
                                
                             }
                            }


   // Mobile Nav Pop
   function mobileNavPop(){
    setpop(true)


  

}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}



  async function withdrawDesktop() {
    const checkoutId = v4()
    const documentRef3 = doc(firestore, "AccountDetails", uid, "Transaction", checkoutId);
    const documentRef5 = doc(firestore, "Transactions", checkoutId);
    const documentRef6 = doc(firestore, "TotalUsers", uid);
    const documentRef7 = doc(firestore, "TransactionWithdrawal", checkoutId);
    const documentRef8 = doc(firestore, "Withdrawal", checkoutId);

  
    try {
      // Validate withdrawal amount
      if(bank.length == 0){
        setBankError('error')
      }
      else if (accountName.length === 0) {
       setAccountNameError('error');
       
      }
      else if (accountNumber.length === 0) {
        setAccountNumberError('error');
        
       }
      
       else if (withdrawAmount.length === 0 || parseFloat(withdrawAmount) <= 0) {
        setwithdrawAmountError('error');
      }
      else{
         // Retrieve user document
      const userSnapshot = await getDoc(documentRef6);
  
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const currentBalance = userData.totalEarnings;
  
        // Convert withdrawal amount to a number
        const withdrawalAmountNum = parseFloat(withdrawAmount);
  
        // Check if the user has sufficient balance
        if (currentBalance >= withdrawalAmountNum) {
          // Store withdrawal details in the withdrawals collection
          const withdrawalData = {
            amount: withdrawalAmountNum,
            createdTime: serverTimestamp(),
            userId: uid,
            accountName:accountName,
            accountNumber:accountNumber,
            bank:bank,
            reason:'Withdrawal',
            status:'Pending',
            transactionId:checkoutId
          };
  
          await setDoc(documentRef3, withdrawalData);
          await setDoc(documentRef5, withdrawalData);
          await setDoc(documentRef7, withdrawalData);
          await setDoc(documentRef8, withdrawalData);

  
          // Update user balance
          const newBalance = currentBalance - withdrawalAmountNum;
          await updateDoc(documentRef6, { totalEarnings: newBalance,pendingWithdrawal:true });
  
          setsuccess(true);
        } else {
          setbalanceInsufficient('Insufficient balance');
        }
      } else {
        setWithdrawalMessage('User not found');
      }
      }
     
    } catch (error) {
      console.error('Error withdrawing:', error);
      setWithdrawalMessage('Error occurred during withdrawal');
    }
  }
    
    
 


  return (
    <div>
       {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
 {
  user.map(i=>{
        const{accountType,plan,fullName,photo,totalEarnings,pendingWithdrawal} = i

    return(
      accountType =='Student'?
      navigate('/dashboard')
      :
      pendingWithdrawal == false ?
      <div>
      <div id='desktop'>
      <div  >
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
  
  </div>
  <div className="flex px-5 justify-evenly">
    <img style={{width:"40%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Banknote-bro.svg?alt=media&token=5f246b15-0ffe-4217-8397-373840eaac2a" alt="" />
  <div className='pb-10    flex flex-col ' style={{width:"50%"}}>
  <div style={{fontSize:'14px'}}  class="mx-auto text-center flex px-5 py-3 text-blue-600    rounded-md mb-3">
              
             Total Balance - {totalEarnings} ETB
            </div>
      <h1 className='fjalla text-center text-gray-900 my-6' style={{fontSize:"25px"}}>You will need to select a bank in order to withdraw your funds 
  
  
  </h1>
  <div className={`${balanceInsufficient == "Insufficient balance" ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli'>
  Balance Insufficient
  </div>
  </div>
  </div>
  
  <div className="relative m-auto" style={{width:"70%"}}>
  
  <p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla">Bank</p>
  
  
  <select style={{height:'75px',fontSize:'12px'}} onChange={e=> bankSet(e)} type="text" class="mx-2 border  focus:outline-none
      focus:border-black w-full borel pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md">
                                  <option className='skill__input-option'value="">Select Bank</option>
  
  
                                  <option className='skill__input-option'value="Abay Bank">Abay Bank</option>
  <option className='skill__input-option' value="Amhara Bank">Amhara Bank</option>
  <option className='skill__input-option' value="Awash Bank">Awash Bank</option>
  <option className='skill__input-option' value="Bank of Abyssinia ">Bank of Abyssinia </option>
  <option className='skill__input-option' value="Commercial Bank of Ethiopia ">Commercial Bank of Ethiopia </option>
  
  
  
  
  
  
  
  </select> 
  <div className={`${bankError == "error" ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
   This field Can't be empty
  </div>
  </div>
  </div>
     
  </div>
  <div className= "relative m-auto my-8" style={{width:"70%"}}>
  
  
  
     <p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla">Account Name</p>
     <input style={{height:'75px',fontSize:'12px'}} onChange={e=>accountNameSet(e)}  required  autoComplete="off" placeholder='Abebe Solomon'  type="text" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 borel mb-0 ml-0 text-gray-700 block bg-white
         border-gray-300 rounded-md" />
        <div className={`${accountNameError == "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
      This field Can't be empty
    </div>
    </div>
  </div>
   </div>
  
  
  
  
   <div className= "relative m-auto" style={{width:"70%"}}>
  
  
  
     <p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3 text-gray-600 absolute fjalla ">Account Number</p>
     <input  style={{height:'75px',fontSize:'12px'}} onChange={e=>accountNumberSet(e)}  required  autoComplete="off"  type="number" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 borel mr-0 mb-0 ml-0 text-gray-700 block bg-white
         border-gray-300 rounded-md" />
        <div className={`${accountNumberError == "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
      This field Can't be empty
    </div>
    </div>
  </div>
   </div>
   <div className= "relative m-auto mt-8" style={{width:"70%"}}>
  
   <p style={{fontSize:'15px'}} className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3 text-gray-600 absolute fjalla ">Amount to withdraw</p>
  
  
  <input  style={{height:'75px',fontSize:'12px'}}onChange={e=>withdrawAmountSet(e)}  required  autoComplete="off" placeholder='1,000'  type="number" className="border  focus:outline-none
      focus:border-black w-full pt-5 pr-4 pb-5 borel pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" />
     <div className={`${withdrawAmountError== "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
   This field Can't be empty
  </div>
  </div>
  </div>
  </div>
      <button style={{fontSize:'13px'}} onClick={withdrawDesktop} class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Withdraw
            </button>
  </div>
  </div>

      </div>
      <div id='tablet'>
<DashboardNav/>

<div className="flex px-5 justify-evenly">
  <div className='pb-10    flex flex-col ' style={{width:"90%"}}>
  <div  class="mx-auto text-center flex px-5 py-3 text-blue-600    rounded-md mb-3">
              
             Total Balance - {totalEarnings} ETB
            </div>
      <h1 className='fjalla text-center text-gray-900 my-8' style={{fontSize:"4.5vw",lineHeight:'5.5vw'}}>You will need to select a bank in order to withdraw your funds 
  
  
  </h1>
  <div className={`${balanceInsufficient == "Insufficient balance" ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli'>
  Balance Insufficient
  </div>
  </div>
  </div>
  
  <div className="relative m-auto mt-8" style={{width:"80%"}}>
  
  
  <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla ">Bank</p>
  
  <select style={{height:'70px'}} onChange={e=> bankSet(e)} type="text" class="mx-2 border  focus:outline-none
      focus:border-black w-full pt-6 pr-4 pb-6 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md">
                                  <option className='skill__input-option'value="">Select Bank</option>
  
  
                                  <option className='skill__input-option'value="Abay Bank">Abay Bank</option>
  <option className='skill__input-option' value="Amhara Bank">Amhara Bank</option>
  <option className='skill__input-option' value="Awash Bank">Awash Bank</option>
  <option className='skill__input-option' value="Bank of Abyssinia ">Bank of Abyssinia </option>
  <option className='skill__input-option' value="Commercial Bank of Ethiopia ">Commercial Bank of Ethiopia </option>
  
  
  
  
  
  
  
  </select> 
  <div className={`${bankError == "error" ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
   This field Can't be empty
  </div>
  </div>
  </div>
     
  </div>
  <div className= "relative m-auto my-8" style={{width:"80%"}}>
  
  
  
     <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla">Account Name</p>
     <input style={{height:'70px'}} onChange={e=>accountNameSet(e)}  required  autoComplete="off" placeholder='Abebe Solomon'  type="text" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
         border-gray-300 rounded-md" />
        <div className={`${accountNameError == "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
      This field Can't be empty
    </div>
    </div>
  </div>
   </div>
  
  
  
  
   <div className= "relative m-auto" style={{width:"80%"}}>
  
  
  
     <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla ">Account Number</p>
     <input style={{height:'70px'}} onChange={e=>accountNumberSet(e)}  required  autoComplete="off"  type="number" className="border  focus:outline-none
         focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
         border-gray-300 rounded-md" />
        <div className={`${accountNumberError == "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
      This field Can't be empty
    </div>
    </div>
  </div>
   </div>
   <div className= "relative m-auto mt-8" style={{width:"80%"}}>
  
  
  
  <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-1 mr-0 mb-0 ml-3  text-gray-600 absolute fjalla">Amount to withdraw</p>
  <input style={{height:'70px'}}  onChange={e=>withdrawAmountSet(e)}  required  autoComplete="off" placeholder='1,000'  type="number" className="border  focus:outline-none
      focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md" />
     <div className={`${withdrawAmountError== "error" ? "block":"hidden"}`}>
  <div  className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
   This field Can't be empty
  </div>
  </div>
  </div>
  </div>
      <button onClick={withdrawDesktop} class=" m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Withdraw
            </button>
  </div>
  </div>
      </div>
      <div id='mobile'>
    <DashboardNav/>
   
    <div className="flex px-5 justify-evenly">
  <div className='pb-10    flex flex-col ' style={{width:"90%"}}>
  <div style={{fontSize:'3vw',marginTop:'3vw'}}  class="mx-auto text-center flex  text-blue-600    rounded-md mb-3">
              
             Total Balance - {totalEarnings} ETB
            </div>
      <h1 className='fjalla text-center text-gray-900 ' style={{fontSize:"5.5vw",lineHeight:'6.5vw',marginTop:'3vw',marginBottom:'5vw'}}>You will need to select a bank in order to withdraw your funds 
  
  
  </h1>

  <div style={{marginBottom:'5vw'}} className={`${balanceInsufficient == "Insufficient balance" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Balance Insufficient

</div>
</div>
</div> 
  
  <div className="relative m-auto mt-8" style={{width:"80%"}}>
  
  
  <p class="bg-white  font-medium text-gray-600 fjalla absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Bank</p>
  
  <select style={{paddingTop:"5vw",paddingBottom:"5vw",paddingLeft:"4vw",fontSize:"3vw"}} onChange={e=> bankSet(e)} type="text" class=" border  focus:outline-none
      focus:border-black w-full  text-gray-700 block bg-white
      border-gray-300 rounded-md">
                                  <option className='skill__input-option'value="">Select Bank</option>
  
  
                                  <option className='skill__input-option'value="Abay Bank">Abay Bank</option>
  <option className='skill__input-option' value="Amhara Bank">Amhara Bank</option>
  <option className='skill__input-option' value="Awash Bank">Awash Bank</option>
  <option className='skill__input-option' value="Bank of Abyssinia ">Bank of Abyssinia </option>
  <option className='skill__input-option' value="Commercial Bank of Ethiopia ">Commercial Bank of Ethiopia </option>
  
  
  
  
  
  
  
  </select> 
  <div className={`${bankError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This field Can't be empty
</div>
</div>
</div> 
  
     
  </div>
  <div className= "relative m-auto my-8" style={{width:"80%"}}>
  
  
  <p class="bg-white  font-medium text-gray-600 fjalla absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Name</p>
  
     <input style={{paddingTop:"5vw",paddingBottom:"5vw",paddingLeft:"4vw",fontSize:"3vw"}} onChange={e=>accountNameSet(e)}  required  autoComplete="off" placeholder='Abebe Solomon'  type="text" className="border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" />
      <div className={`${accountNameError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This field Can't be empty
</div>
</div>
</div> 
   </div>
  
  
  
  
   <div className= "relative m-auto" style={{width:"80%"}}>
  
  
   <p class="bg-white  font-medium text-gray-600 fjalla absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Number</p>
  
     <input style={{paddingTop:"5vw",paddingBottom:"5vw",paddingLeft:"4vw",fontSize:"3vw"}} onChange={e=>accountNumberSet(e)}  required  autoComplete="off"  type="number" className="border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" />
        <div className={`${accountNumberError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This field Can't be empty
</div>
</div>
</div> 
   </div>
   <div className= "relative m-auto mt-8" style={{width:"80%"}}>
  
  
   <p class="bg-white  font-medium text-gray-600 fjalla absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Amount to withdraw</p>
  
  <input style={{paddingTop:"5vw",paddingBottom:"5vw",paddingLeft:"4vw",fontSize:"3vw"}} onChange={e=>withdrawAmountSet(e)}  required  autoComplete="off" placeholder='1,000'  type="number" className="border  focus:outline-none
      focus:border-black w-full  text-gray-700 block bg-white
      border-gray-300 rounded-md" />
         <div className={`${withdrawAmountError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This field Can't be empty
</div>
</div>
</div> 
  </div>
      <button style={{fontSize:'3vw',padding:'4vw 0vw',marginTop:'5vw',width:'50%'}} onClick={withdrawDesktop} class=" m-auto  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Withdraw
            </button>
  </div>
  </div>
      </div>
    
     </div>
     :
    <div >
       <div class="h-screen   flex items-center flex-col" id='desktop'>
       <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' ?
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 
 :
 plan == 'Basic' && 
 <Link to='/pricing'>
 
 <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
 </Link>
 
  }
      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            
          
     <div class=" flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
             <div class="w-full lg:w-1/2 mx-8">
                 <div style={{fontSize:'30px',lineHeight:'35px'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8">We are currently processing your withdrawal. Depending on your bank, it may take up to 24 hours for the money to reach you
                 </div>
            
                 <Link to={`/dashboard`}>
                 <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                               
                 Back
                             </button>
                 </Link>      
  
             </div>
         <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
         </div>
     
     </div>
 </div>

 <div class="" id='tablet'>
      <DashboardNav/>
      
            
          
     <div class=" flex  items-center justify-between px-5 text-gray-700">
             <div class="w-full lg:w-1/2 mx-8">
                 <div style={{fontSize:'3.8vw',lineHeight:'4.5vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8">We are currently processing your withdrawal. Depending on your bank, it may take up to 24 hours for the money to reach you
                 </div>
            
                 <Link to={`/dashboard`}>
                 <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                               
                 Back
                             </button>
                 </Link>      
  
             </div>
         <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
         </div>
     
     </div>
 </div>
 <div class="" id='mobile'>
      <DashboardNav/>
      
            
          
     <div class=" flex  items-center justify-between px-5 text-gray-700 flex-col">
     <div class="w-full lg:flex lg:justify-end  mx-5 my-10" style={{width:'60%'}}>
         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
         </div>
             <div class="w-full  mx-8" style={{width:'80%'}}>
                 <div style={{fontSize:'6vw',lineHeight:'7vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8">We are currently processing your withdrawal. Depending on your bank, it may take up to 24 hours for the money to reach you
                 </div>
            
                 <Link to={`/dashboard`}>
                 <button style={{fontSize:'3vw'}} class="mr-2 inline-flex px-5 py-2 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                               
                 Back
                             </button>
                 </Link>      
  
             </div>
        
     
     </div>
 </div>
      </div>
      
    )
  })

}
    </div>

  )
}
