import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, updateDoc, deleteDoc, where,getDoc,getDocs} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { v4 } from 'uuid';
import DashboardNav from './dashboardNav'
import TagsInput from 'react-tagsinput'
import { set } from 'mongoose';


export default function Course() {
    const {courseId} = useParams()
    const [uid,setuid] = useState("")
    
    const [courseSubject,setCourseSubject] = useState("")
    const [noCourseFound,setnoCourseFound] = useState()
    const [notPublished,setnotPublished] = useState()

    const [courseSubjectError,setCourseSubjectError] = useState("")
    const [thumbnailLoad,setthumbnailLoad] = useState(true)
    const [lockedreview,setlockedreview] = useState(false)
    const [unpublishCourse,setunpublishCourse] = useState(false)

    const [studentsError,setStudentsError] = useState('')

    const [openInfo,setOpenInfo] = useState(false)
    const [deletelectureId,setdeletelectureId] = useState('')

    

    const [test,settest] = useState(false)
    const [pop,setpop] = useState(false)
    const [trailerLoad,settrailerLoad] = useState(false)
    const [testOpen,settestOpen] = useState(false)
    const [traileruploadError,settraileruploadError] = useState(false)
    const [thumbnailuploadError,setthumbnailuploadError] = useState(false)





    const [trailerUpload,settrailerUpload] = useState(false)
    const [courseThumbnailMessage,setcourseThumbnailMessage] = useState(false)
    const [courseThumbnailDeletedMessage,setcourseThumbnailDeletedMessage] = useState(false)
    const [courseTitleMessage,setcourseTitleMessage] = useState(false)
    const [courseSubjectMessage,setcourseSubjectMessage] = useState(false)
    const [courseLevelMessage,setcourseLevelMessage] = useState(false)
    const [courseDescriptionMessage,setcourseDescriptionMessage] = useState(false)
    const [courseSkillsDeleteMessage,setcourseSkillsDeleteMessage] = useState(false)
    const [courseSkillsAddedMessage,setcourseSkillsAddedMessage] = useState(false)
    const [courseTrailerMessage,setcourseTrailerMessage] = useState(false)
    const [courseTrailerDeletedMessage,setcourseTrailerDeletedMessage] = useState(false)
    const [lectureDescriptionEmpty,setLectureDescriptionEmpty] = useState(false)
    const [lectureTitleEmpty,setLectureTitleEmpty] = useState(false)
    const [lectureVideoEmpty,setLectureVideoEmpty] = useState(false)







    const [courseIntendedAgeGroupMessage,setcourseIntendedAgeGroupMessage] = useState(false)
    const [completionTimeMessage,setcompletionTimeMessage] = useState(false)







    const [thumbnailUpload,setthumbnailUpload] = useState(false)
    const [lectureDelete,setlectureDelete] = useState(false)
    const [lectureDeleteMessage,setlectureDeleteMessage] = useState(false)


    const [trailerDelete,settrailerDelete] = useState(false)
    const [thumbnailDelete,setthumbnailDelete] = useState(false)

    const [notification,setnotification] = useState([])
    const [notificationPop,setnotificationPop] = useState(false)




    const [editIconTitle,seteditIconTitle] = useState(true)
    const [editIconDescription,seteditIconDescription] = useState(false)
    const [editIconSubject,seteditIconSubject] = useState(false)
    const [editIconSkills,seteditIconSkills] = useState(false)
    const [editIconCourseLevel,seteditIconCourseLevel] = useState(false)
    const [editIconCompletionTime,seteditIconCompletionTime] = useState(false)
    const [editIconIntendedAgeGroup,seteditIconIntendedAgeGroup] = useState(false)







    const [lecture,setlecture] = useState([])
    
    const [showContent,setShowContent] = useState(false)
    const [alreadyExists,setalreadyExists] = useState(false)

    const [videoDurations,setVideoDurations] = useState(false)

    const [completionTimeNew,setcompletionTimeNew] = useState("")
    const [completionTimeError,setcompletionTimeError] = useState("")
    const [courseLevelNew,setcourseLevelNew] = useState("")
    const [courseLevelError,setcourseLevelError] = useState("")
    

    const [status,setstatus] = useState("")
    const [courseDescriptionNew,setcourseDescriptionNew] = useState("")
    const [courseDescriptionError,setcourseDescriptionError] = useState("")
    const [courseSkillsError,setcourseSkillsError] = useState("")

    const [lectureLength,setlectureLength] = useState("")
    const [Instructor,setInstructor] = useState([])
    const [courseSkillsNew,setcourseSkillsNew] = useState([])

    const [mainCourse,setmainCourse] = useState([])


    const [spinner,setspinner] = useState(true)

    const [lectureLengthError,setlectureLengthError] = useState("")
    const [lectureDescriptionError,setlectureDescriptionError] = useState("")
    const [lectureTitleError,setlectureTitleError] = useState("")
    const [lectureVideoError,setlectureVideoError] = useState("")



    const [trailer,settrailer] = useState([])
    const [trailerFetched,settrailerFetched] = useState('')

    const [trailerError,settrailerError] = useState("")
    const [testError,settestError] = useState("")
    const [showFullDescription, setShowFullDescription] = useState(false);


    const navigate = useNavigate()


    
    const [questions,setquestions] = useState("")
    const [courseError,setcourseError] = useState("")
    const [courseThumbnailError,setcourseThumbnailError] = useState("")


    const [user,setuser] = useState([])
    const [selectedCourse,setSelectedCourse] = useState([])
    const [pricing,setpricing] = useState()
    const [thumbnail,setthumbnail] = useState()



    
    const [questionsError,setquestionsError] = useState("")
    
    const [progress,setprogress] = useState("")
    const [fileMesssage,setFileMessage] = useState("")
    const [lectureId,setlectureId] = useState("")
    const [firstlectureId,setFirstlectureId] = useState("")


    
    const [files,setFiles] = useState([])
    const [course,setcourse] = useState([])

    
    const [instructorId,setinstructorId] = useState("")
    const [courseTitle,setcourseTitle] = useState("")

    const [courseTitleError,setcourseTitleError] = useState("")

    const [intendedAgeGroupNew,setintendedAgeGroupNew] = useState("")

    const [intendedAgeGroupNewError,setintendedAgeGroupNewError] = useState("")
    
    const [lectures,setlectures] = useState([])

    const [courseVideo,setcourseVideo] = useState([])
    const [courseThumbnailImg,setcourseThumbnailImg] = useState([])



    function courseDescriptionSet(e){
      setcourseDescriptionNew(e.target.value)
      if(e.target.value.length == 0){

          setcourseDescriptionError("error")
      }
      else{
          setcourseDescriptionError("")
      }
  }
  function completionTimeSet(e){
    setcompletionTimeNew(e.target.value)
    if(e.target.value == ""){
        setcompletionTimeError("error")
    }
    else {
       
        setcompletionTimeError("")
    
            
         }
        }

  const textRef = React.useRef();
  React.useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [courseDescriptionNew]);
    


  useEffect(() => {
    
    const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
    const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
     onSnapshot(lecturesCollectionQuery, (querySnapshot) => {
      const emptyIndices = [];
      let index = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.lectureDescription || data.lectureDescription.length === 0) {
          emptyIndices.push(index + 1);
        } 
        index++;
      });

      if(emptyIndices.length != 0 ){
       
        setlectureDescriptionError(`Upload the lecture Description for ${emptyIndices.length !== 1 ? 'lectures' : 'lecture'} ${emptyIndices.map(i => i).join(', ')}`);

      }
      else {
        setLectureDescriptionEmpty(false)

        setlectureDescriptionError('');
      }
    });
    
    onSnapshot(lecturesCollectionQuery, (querySnapshot) => {
      const emptyIndices = [];
      let index = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.lectureVideo || data.lectureVideo.length === 0 ) {
          emptyIndices.push(index + 1);
        } 
        index++;
      });

      if(emptyIndices.length != 0 ){
       
        setlectureVideoError(`Make sure to upload a video for ${emptyIndices.length !== 1 ? 'lecture' : 'lecture'} ${emptyIndices.map(i => i).join(', ')}`);

      }
      else {
        setLectureVideoEmpty(false)

        setlectureVideoError('');
      }
    });
    onSnapshot(lecturesCollectionQuery, (querySnapshot) => {
      const emptyIndices = [];
      let index = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.lectureTitle || data.lectureTitle === 'Untitled Lecture') {
          emptyIndices.push(index + 1);
        } 
        index++;
      });
      if(emptyIndices.length != 0 ){
       
        setlectureTitleError(`Make sure to set the title for ${emptyIndices.length !== 1 ? 'lecture' : 'lecture'} ${emptyIndices.map(i => i).join(', ')}`);

      }
      else {
        setLectureTitleEmpty(false)

        setlectureTitleError('');
      }
    });

  }, []);
  useEffect(() => {
    // Fetch the courses data from the courses collection with the courseId
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setuid(uid);
  
        const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");
        const  coursesRef= collection(firestore, "Courses");


      const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
      const coursesquery = query(coursesRef, where("courseId",'==',courseId));
      onSnapshot(coursesquery,snapshot=>{
        snapshot.docs.forEach(doc=>{
         if(doc.exists()){
         setnoCourseFound(false)

          if(doc.data().status === 'published'){
         setnotPublished(false)
      
          }
          else{
         setnotPublished(true)

          }
        }

         else{
          setnotPublished(true)
        }

        })
        
    
        
        
      
    
      })
  
      onSnapshot(notificationRef,snapshot=>{
        let main =[]
        
    
    
    
        snapshot.docs.forEach(doc=>{
          main.push({...doc.data(),id:doc.id})
         
        setnotification(main)
        })
    
        
        
      
    
      })

      onSnapshot(collection(firestore, 'Courses'), (snapshot) => {
        snapshot.docs.forEach(doc=>{
          if(doc.exists()){
            setnoCourseFound(false)

            if(doc.data().status === 'published'){
         setnotPublished(false)
           
               }
               else{
         setnotPublished(true)

               }
          }
          else{
            setnoCourseFound((true))



          }
        })
  


   
      });
  
        const userRef = doc(firestore, "TotalUsers", uid);
        onSnapshot(userRef, (snapshot) => {
          let main = [];
          main.push({ ...snapshot.data(), id: snapshot.id });
          setuser(main);
  
          const coursesRefDuplicate = doc(firestore, 'AccountDetails', uid, "Courses", courseId);
          onSnapshot(coursesRefDuplicate, (sdoc) => {
          if(sdoc.exists()){
            if (sdoc.data().courseId === courseId) {
              setalreadyExists(true);
            } else {
              setalreadyExists(false);
            }
          }
          });
        });
  
        const selectedCourseRef = doc(firestore, "AccountDetails", uid, 'Courses', courseId);
        onSnapshot(selectedCourseRef, (snapshot) => {
          let main = [];
          main.push({ ...snapshot.data() });
          setSelectedCourse(main);
        

        });
  
        const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
        const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
        onSnapshot(lecturesCollectionQuery, (snapshot) => {
          let main = [];
          snapshot.docs.forEach(doc => {
            main.push({ ...doc.data(),id:doc.id });
          });

    //          onSnapshot(lecturesCollectionQuery).then((querySnapshot) => {
    //   const emptyIndices = [];
    //   let index = 0;
    //   querySnapshot.forEach((doc) => {
    //     const data = doc.data();
    //     if (!data.lectureTitle || data.lectureTitle === 'Untitled Lecture') {
    //       emptyIndices.push(index + 1);
    //     }
    //     index++;
    //   });
    //   setlectureTitleError(`Complete the lecture title for ${emptyIndices.length != 1 ? 'lectures' : 'lecture'} ${emptyIndices.map(i => i).join(', ')}`  );

    // });
 
          setlecture(main);
          setlectures(main)

          const firstLecture = snapshot.docs[0];
          const firstLectureId = firstLecture?.data()?.lectureId;
          setFirstlectureId(firstLectureId);
        });
  

        onSnapshot(doc(firestore, 'Courses', courseId), (snapshot) => {
          let main = [];
        if(snapshot.exists()){
          main.push({ ...snapshot.data() });
          console.log(snapshot.data());

          setmainCourse(main);
          setinstructorId(snapshot.data().instructorId);
          setpricing(snapshot.data().coursePrice);
          setstatus(snapshot.data().status);
          setthumbnail(snapshot.data().courseThumbnail);
          settrailerFetched(snapshot.data().courseTrailer);
          settest(snapshot.data().isTest)
          setcourse(main)
        }

  
     if(snapshot.data()){
      const instructorRef = doc(firestore, "TotalUsers", snapshot.data().instructorId);
      onSnapshot(instructorRef, (snapshot) => {
        let main = [];
        main.push({ ...snapshot.data() });
        setInstructor(main);
      });
     }
        });
  
        // ...
      }
     
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(()=>{
 onSnapshot(doc(firestore, 'Courses', courseId), (snapshot) => {
          let main = [];
   main.push({...snapshot.data()})
          setcourse(main)

  if(snapshot.data())
       {
        const instructorRef = doc(firestore, "TotalUsers", snapshot.data().instructorId);
        onSnapshot(instructorRef, (snapshot) => {
          let main = [];
          main.push({ ...snapshot.data() });
          setInstructor(main);
        })
       }
        });
        const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
        const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
        onSnapshot(lecturesCollectionQuery, (snapshot) => {
          let main = [];
          snapshot.docs.forEach(doc => {
            main.push({ ...doc.data() });
          });
          setlecture(main);
          const firstLecture = snapshot.docs[0];
          const firstLectureId = firstLecture?.data()?.lectureId;
          setFirstlectureId(firstLectureId);
        });
  },[])
  const handleChangeCourseSkills = value => {
    setcourseSkillsNew(value);
    if (value.length ==  0){
      setcourseSkillsError("error")
  }
  else{
      setcourseSkillsError("")
  
  }
  }





  function questionsSet(e){
    setquestions(e.target.value)
    if(e.target.value== ''){

        setquestionsError("error")
    }
    else{
        setquestionsError("")
    }
}


  
    
   

    // Clear course video.name 
    function clearCourseVideo(){
        setcourseVideo([])

    }

    function courseSubjectSet(e){
      setCourseSubject(e.target.value)
      if(e.target.value.length == 0){
      setCourseSubjectError('error')

      }
      else{
      setCourseSubjectError('')

      }

    }
    function courseLevelSet(e){
      setcourseLevelNew(e.target.value)

      if(e.target.value.length == 0){
          setcourseLevelError("error")
      }
      else {
          setcourseLevelError("")
      }
  }
    function editAddSkillDesktop(){
      seteditIconSkills(true)
      
      
      
        
      }

      function editCancelAddSkillDesktop(){
        seteditIconSkills(false)
        
        
        
          
        }

      function editUpdateSkillDesktop(){
        if(courseSkillsNew.length != 0){
          updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
            courseSkills:courseSkillsNew
          }).then(
            updateDoc(doc(firestore,"Courses",courseId),{
              courseSkills:courseSkillsNew
            })
        
          ).then(
      seteditIconSkills(false),
      setcourseSkillsNew([]),
      setcourseSkillsAddedMessage(true),
      window.scrollTo(0, 0)

            
          )
        }
        
      }
      
 function editResetSkillDesktop(){
  updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
    courseSkills:[]
  }).then(
    updateDoc(doc(firestore,"Courses",courseId),{
      courseSkills:[]
    }).then(
      setcourseSkillsDeleteMessage(true),
      window.scrollTo(0, 0)

    )

  )

  
  
  
  
    
  }
  function editIntendedAgeGroupOpenerMinimizeDesktop(){

    seteditIconIntendedAgeGroup(false)
    
    
    
    
    
      
    }
    function editIntendedAgeGroupOpenerDesktop(){
    seteditIconIntendedAgeGroup(true)
      
    setintendedAgeGroupNewError('')
      
      
      
      
        
      }


      function editCompletionTimeOpenerMinimizeDesktop(){

        seteditIconCompletionTime(false)
        
        
        
        
        
          
        }
        function editCompletionTimeOpenerDesktop(){
        seteditIconCompletionTime(true)
          
        setcompletionTimeError('')
          
          
          
          
            
          }

          function editCourseLevelOpenerMinimizeDesktop(){

            seteditIconCourseLevel(false)
            
            
            
            
            
              
            }
            function editCourseLevelOpenerDesktop(){
            seteditIconCourseLevel(true)
              
            setcourseLevelError('')
              
              
              
              
                
              }
const config = {
    bucketName: 'myBucket',
    dirName: 'photos', /* optional */
    region: 'eu-west-1',
    accessKeyId: 'ANEIFNENI4324N2NIEXAMPLE',
    secretAccessKey: 'cms21uMxçduyUxYjeg20+DEkgDxe6veFosBT7eUgEXAMPLE',
}
// Edit Input Opener
function editInputOpenerDesktop(){

seteditIconTitle(false)





  
}
// Edit Input Opener
function editTitleOpenerDesktop(){
seteditIconTitle(true)
setcourseTitleError('')





  
}

// Edit Input Opener
function editSubjectOpenerDesktop(){
  if(editIconSubject == true){
    seteditIconSubject(false)
    setCourseSubjectError('')

  }
  else{
    seteditIconSubject(true)
  }
  
  
  
  
  
    
  }
// Course Data Pop handler
function coursePopDataDesktop(){
  const courseData = document.getElementById("courseDataDesktop")
  if(courseData.style.display === "block"){
    courseData.style.display="none"
  }
  else {
    courseData.style.display="block"

  }
}
function updateCourseTitle(){


 if(courseTitle.length !=0){
  updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
    courseTitle:courseTitle
  }).then(
    updateDoc(doc(firestore,"Courses",courseId),{
      courseTitle:courseTitle
    })

  ).then(
seteditIconTitle(true),
setcourseTitleMessage(true),
window.scrollTo(0, 0)

  )

 }
 else{
  setcourseTitleError('error')
 }
}

function lockedReviewSet(){
  setlockedreview(true)
}

function updateintendedAgeGroup(){


  if(intendedAgeGroupNew.length !=0){
   updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
     intendedAgeGroup:intendedAgeGroupNew
   }).then(
     updateDoc(doc(firestore,"Courses",courseId),{
       intendedAgeGroup:intendedAgeGroupNew
     })
 
   ).then(
    setcourseIntendedAgeGroupMessage(true),
 seteditIconIntendedAgeGroup(false),
 window.scrollTo(0, 0)

   )
 
  }
  else{
    setintendedAgeGroupNewError('error')
  }
 }
 function updateCourseLevel(){


  if(courseLevelNew.length !=0){
   updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
     courseLevel:courseLevelNew
   }).then(
     updateDoc(doc(firestore,"Courses",courseId),{
       courseLevel:courseLevelNew
     })
 
   ).then(
    seteditIconCourseLevel(false),
    setcourseLevelMessage(true),
    window.scrollTo(0, 0)


   )
 
  }
  else{
    setcourseLevelError('error')
  }
 }
 function updateCompletionTime(){


  if(completionTimeNew.length !=0){
   updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
     completionTime:completionTimeNew
   }).then(
     updateDoc(doc(firestore,"Courses",courseId),{
       completionTime:completionTimeNew
     })
 
   ).then(
    seteditIconCompletionTime(false),
    setcompletionTimeMessage(true),
    window.scrollTo(0, 0)

   )
 
  }
  else{
    setintendedAgeGroupNewError('error')
  }
 }
function updateCourseSubject(){

if(courseSubject.length != 0){

  updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
    courseSubject:courseSubject
  }).then(
    updateDoc(doc(firestore,"Courses",courseId),{
    courseSubject:courseSubject

    })

  ).then(
seteditIconSubject(false),
setcourseSubjectMessage(true),
window.scrollTo(0, 0)

  )
}
else{
  setCourseSubjectError('error')
}

}

function updateCourseDescription(){
 

if(courseDescriptionNew.length != 0){
  updateDoc(doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId),{
    courseDescription:courseDescriptionNew
  }).then(
    updateDoc(doc(firestore,"Courses",courseId),{
      courseDescription:courseDescriptionNew
    })

  ).then(
    seteditIconDescription(false),
    setcourseDescriptionMessage(true),
    window.scrollTo(0, 0)

  )
}
else{
  setcourseDescriptionError('error')
}

}

function desktopSpinnerHandler(){
  setspinner(false)
 }

  // Course Title Field selector Input
  function courseTitleSet(e){
    setcourseTitle(e.target.value)

    if(e.target.value.length == 0){
        setcourseTitleError("error")
    }
    else {
        setcourseTitleError("")
    }
}
  function intendedAgeGroupSet(e){
    setintendedAgeGroupNew(e.target.value)

    if(e.target.value.length == 0){
        setintendedAgeGroupNewError("error")
    }
    else {
        setintendedAgeGroupNewError("")
    }
}
function closeMessageDesktop(){
setcourseTitleMessage(false)
}
function closeMessage1000Desktop(){
  setcompletionTimeMessage(false)
  }
  function closeMessage10000Desktop(){
    setcourseIntendedAgeGroupMessage(false)
    }

    function closeMessage900Desktop(){
      setcourseDescriptionMessage(false)
      }

      function closeMessage800Desktop(){
        setcourseSkillsDeleteMessage(false)
        }

        function closeMessage8000Desktop(){
          setcourseSkillsAddedMessage(false)
          }
      function closeMessage9000Desktop(){
        setcourseLevelMessage(false)
        }
  
function closeMessage100Desktop(){
  setcourseSubjectMessage(false)
  }
function closeMessage2Desktop(){
setcourseThumbnailDeletedMessage(false)
  setprogress(0)
}

function closeMessage90Desktop(){
  setcourseThumbnailMessage(false)
    setprogress(0)
  }



function closeMessage6Desktop(){
setlectureDeleteMessage(false)
  setprogress(0)
}

function closeMessage5Desktop(){
setcourseTrailerMessage(false)
  setprogress(0)
}

function closeMessage55Desktop(){
  setcourseTrailerDeletedMessage(false)
    setprogress(0)
  }

  function closetrailerErrorDesktop(){
    settraileruploadError(false)
      setprogress(0)
    }

    function closethumbnailErrorDesktop(){
      setthumbnailuploadError(false)
        setprogress(0)
      }

function closeMessage1Desktop(){
  setcourseThumbnailDeletedMessage(false)
  setprogress(0)
}


function formatFileSize(size) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let index = 0;
  
  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }
  return `${size.toFixed(2)} ${units[index]}`;
}


function cancelUploadDesktop(){

  
  setcourseThumbnailImg("")
  setcourseThumbnailError("")

  setprogress(0)
  settrailerUpload(false)
  setthumbnailUpload(false)


}




function deleteThumbnailDesktop(){
  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
  const documentRef2 = doc(firestore,"Courses",courseId)

  const lectureData = {
 courseThumbnail:""
  }
  setDoc(documentRef, lectureData, { merge: true }).then(
    setDoc(documentRef2, lectureData, { merge: true })

  ).then(
    setthumbnailDelete(false),
    setcourseThumbnailDeletedMessage(true),
    setcourseThumbnailImg([])

    


  )


}

function deleteTrailerDesktop(){

  
  const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
  const documentRef2 = doc(firestore,"Courses",courseId)

  const lectureData = {
 courseTrailer:""
  }
  setDoc(documentRef, lectureData, { merge: true }).then(
    setDoc(documentRef2, lectureData, { merge: true })

  ).then(
    settrailerDelete(false),
    setcourseTrailerDeletedMessage(true)
  )


}

function deleteLectureDesktop(lectureId){
  setdeletelectureId(lectureId)
    setlectureDelete(true)
}

function checkthumbnailLoad(){
setthumbnailLoad(false)

}

function checktrailerLoad(){
 settrailerLoad(true)

}

function removepopdesktop(){
  setthumbnailDelete(false)

  
}
function removeUnpublishCourse(){
  setunpublishCourse(false)

  
}
async function UnpublishCourse() {
  const documentRef = doc(firestore, 'Courses', courseId);
  const documentRef1 = doc(firestore, 'AccountDetails', uid, 'CoursesCreated', courseId);
  const documentRef2 = collection(firestore, 'AccountDetails', uid, 'CoursesCreated', courseId, 'lectures');
  const lecturesCollectionQuery = query(documentRef2, orderBy('createdTime'));

  
  const students = collection(firestore, 'AccountDetails', uid, 'CoursesCreated', courseId, 'Students');
  const snapshot = await getDocs(students);
let temp = []
snapshot.docs.forEach(snapShot=>{
  temp.push([{...snapShot.data()}])
})
  if (temp.length !=0) {
    setunpublishCourse(false)

    setStudentsError('It is not possible to unpublish this course because there are active students');
  } else {
    const courseData = await getDoc(documentRef);
    const accountDetailsData = await getDoc(documentRef1);

    await setDoc(documentRef, {
      ...courseData.data(),
      status: 'unpublished',
    });

    await setDoc(documentRef1, {
      ...accountDetailsData.data(),
      status: 'unpublished',
    });

    setunpublishCourse(false);
    window.scrollTo(0, 0);
  }
}

function removepopTrailerdesktop(){
  settrailerDelete(false)
  
}

function openpopdesktop(){
  settrailerUpload(true)

  
}
function openpopDeletedesktop(){
  setthumbnailDelete(true)

  
}

function openpopTrailerdesktop(){
settrailerDelete(true)
  
}




function closePopDeleteDesktop(){
setlectureDelete(false)

  
}

function deleteHandlerDesktop(){
  deleteDoc(doc(firestore, 'AccountDetails',uid,"CoursesCreated",courseId,"lectures",deletelectureId)).then(
    deleteDoc(doc(firestore, 'Courses',courseId,"lectures",deletelectureId)).then(
      setlectureDeleteMessage(true),
     setlectureDelete(false)

    ))
  // )

}

function openPopDeleteDesktop(courseId){
  setlectureDelete(true)
  
}
function uploadTrailer(){


  const storage = getStorage()

  const maxSize = 2 * 1024 * 1024 * 1024; // 2GB

  // Check file type
 if (trailer.size > maxSize) {
   setFileMessage(`File ${trailer.name} exceeds the size limit of 2GB.`);

   return false;
 }
 else if (trailer.length == 0){
  settraileruploadError(true)
 }
 else{


  const reff = ref(storage, `resume/${trailer.name}`)
  const uploadImage= uploadBytesResumable(reff,trailer)
  uploadImage.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setprogress(progress)

  }, 
  (error) => {
    console.log(error)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  

  getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
    const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
    const documentRef2 = doc(firestore,"Courses",courseId)


    const lectureData = {
   courseTrailer:downloadURL
    }
    setDoc(documentRef, lectureData, { merge: true }).then(
      setDoc(documentRef2, lectureData, { merge: true })

    ).then(
      setcourseTrailerMessage(true)


    )

      
      
       
    
  })
     
    
      
  }

  // )}
 
)

 
}

 
  

}


function backtestDesktop (){
settestOpen(false)
}
function lockedreviewclose (){
  setlockedreview(false)
  }

function opentestDesktop (isTest){
  if(isTest == true){
    navigate(`/course/${courseId}/test/${courseId}/10`)
  }
  else{
    settestOpen(true)

  }


}

function uploadFile(){
  const upload = document.getElementById("uploadFileDesktop")
  const loading = document.getElementById("loadingUploadDesktop")

  const storage = getStorage()

  const maxSize = 2 * 1024 * 1024 * 1024; // 2GB

  // Check file type
 if (files.size > maxSize) {
   setFileMessage(`File ${files.name} exceeds the size limit of 2GB.`);

   return false;
 }
 else if(files.length == 0){
  setthumbnailuploadError(true)
 }
 else{
  const reff = ref(storage, `resume/${files.name}`)
  const uploadImage= uploadBytesResumable(reff,files)
  uploadImage.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setprogress(progress)

  }, 
  (error) => {
    console.log(error)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  

  getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
    const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
    const documentRef2 = doc(firestore,"Courses",courseId)
  const message= document.getElementById("alert-border-2")


    const lectureData = {
   courseThumbnail:downloadURL,
   lectureOrder:lectures.length + 1
    }
    setDoc(documentRef, lectureData, { merge: true }).then(
      setDoc(documentRef2, lectureData, { merge: true })

    ).then(
setcourseThumbnailMessage(true)

    )

      
      
       
    
  })
     
    
      
  }

  // )}
 
)

 

 
}

}
function handleFileSelect(event) {
  const selectedFiles = (event.target.files[0]);

    // Check file size
    
   const maxSize = 5 * 1024 * 1024; // 5MB
  // Check file type
    if (selectedFiles.size > maxSize) {
      setFileMessage(`File ${selectedFiles.name} exceeds the size limit of 5MB.`);

      return false;

    }
    else{
      setFileMessage("")
      setFiles([])
    }
  
  
    setFiles(selectedFiles);



  // Clear the file input value to allow selecting the same file again
  event.target.value = '';
}

function handleTrailerSelect(e) {
  const selectedFiles = e.target.files[0];

  // Check file size
  const maxSize = 50 * 1024 * 1024; // 50MB

  // Check file type
  const allowedFileTypes = ['video/mp4', 'video/webm', 'video/ogg'];

  if (selectedFiles.size > maxSize) {
    setFileMessage(`File ${selectedFiles.name} exceeds the size limit of 50MB.`);
    return false;
  } else if (!allowedFileTypes.includes(selectedFiles.type)) {
    setFileMessage(`File ${selectedFiles.name} is not a valid video file.`);
    return false;
  } else {
    setFileMessage('');
    settrailer(selectedFiles);
    settrailerError('')
  }

  // Clear the file input value to allow selecting the same file again
  e.target.value = '';
}

function editDescripionOpenerDesktop(){
seteditIconDescription(false)
setcourseDescriptionError('')

  
}


  // Edit Input Opener
function editInputDescripionOpenerDesktop(){
seteditIconDescription(true)




  
}


function dragOverHandler(event) {
  event.preventDefault();
}

function dragEnterHandler(event) {
  event.preventDefault();
  document.getElementById('overlay').classList.add('dragged');
}

function dragLeaveHandler(event) {
  event.preventDefault();
  document.getElementById('overlay').classList.remove('dragged');
}

function dropHandler(event) {
  event.preventDefault();
  const selectedFiles = Array.from(event.dataTransfer.files);
  setFiles([...files, ...selectedFiles]);
  document.getElementById('overlay').classList.remove('dragged');
}



function removeFile(index) {
const updatedFiles = [...files];
updatedFiles.splice(index, 1);
setFiles(updatedFiles);
}
const minimizeUploadImg = ()=>{
setthumbnailUpload(false)
  setFiles([])
}

const minimizeUploadTrailer = ()=>{
  settrailerUpload(false)
 settrailer([])
    settrailerError('')

}

useEffect(() => {
  const fetchVideoDurations = async () => {
    const durations = {};
    for (const lectureItem of lecture) {
      const { lectureVideo } = lectureItem;
      const duration = await getVideoDuration(lectureVideo);
      durations[lectureVideo] = duration;
    }
    setVideoDurations(durations);
  };

  fetchVideoDurations();
}, [lecture]);

const formatVideoDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;

    if (scrollPosition > windowHeight) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const [showAllLectures, setShowAllLectures] = useState(false);
const maxVisibleLectures = 3;
const visibleLectures = showAllLectures ? lecture : lecture.slice(0, maxVisibleLectures);

const handleReadMore = () => {
  setShowAllLectures(true);
};

const mobilevideoRef = useRef(null);
const tabletvideoRef = useRef(null);

      const [showPlayButton, setShowPlayButton] = useState(true);

      const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
          setShowPlayButton(false);
       
        }
      };

      const [isPlaying, setIsPlaying] = useState(false);
      const videoRef = useRef(null);
    
      const handlePlayPauseMobile = () => {
        const video = mobilevideoRef.current;
    
        if (isPlaying) {
          video.pause();
        } else {
          video.play();
        }
    
        setIsPlaying(!isPlaying);
      };
      const [openLectures, setOpenLectures] = useState([]);
      const handleLectureClick = (lectureTitle) => {
        if (openLectures.includes(lectureTitle)) {
          setOpenLectures(openLectures.filter((title) => title !== lectureTitle));
        } else {
          setOpenLectures([...openLectures, lectureTitle]);
        }
      };
      const handlePlayTablet = () => {
        if (tabletvideoRef.current) {
          tabletvideoRef.current.play();
          setShowPlayButton(false);
       
        }
      };
      const getVideoDuration = async (videoUrl) => {
        try {
          const video = document.createElement('video');
          video.src = videoUrl;
      
          await new Promise((resolve, reject) => {
            video.addEventListener('loadedmetadata', resolve);
            video.addEventListener('error', reject);
          });
      
          const duration = video.duration;
          return duration;
        } catch (error) {
          console.error('Error retrieving video duration:', error);
          return null;
        }
      };
const openUploadTrailerPopDesktop = ()=>{
  settrailerUpload(true)
  
}
const openUploadPopDesktop = ()=>{
 setthumbnailUpload(true)
}






 function changetopublicDesktop(){
  const documentRef = doc(firestore,'Courses',courseId)
  const documentRef1 = doc(firestore,'AccountDetails',uid,'CoursesCreated',courseId)
  const documentRef2 = collection(firestore,'AccountDetails',uid,'CoursesCreated',courseId,'lectures')
  const lecturesCollectionQuery = query(documentRef2, orderBy('createdTime'));


  if(pricing == 0){
    setcourseError('Your course should have a price set before it is made public.')
  }

  else if(thumbnail.length == 0){
    setcourseThumbnailError('Your course should have a thumbnail before it is made public.')
  
  }

   else if(lectures.length <= 4){
     setlectureLengthError('Your course should have at least 5 lectures')

   }
  
   else if(lectureDescriptionError.length != 0){
    setLectureDescriptionEmpty(true)
  
  }
  else if(lectureVideoError.length != 0){
    setLectureVideoEmpty(true)
  
  }
  else if(lectureTitleError.length != 0){
    setLectureTitleEmpty(true)
  
  }
  
  else if(trailerFetched.length == 0){
    settrailerError('Your course should have a trailer before it is made public')

  }
  else if(test == false){
    settestError('Your course should include a test to measure the progress of your students')

  }

  else{
  if(status == 'published'){
 
    setunpublishCourse(true)

      window.scrollTo(0, 0)

    // )
  }
  else if(status == 'pending'){

  }
  else{
    updateDoc(documentRef,{
           status:'pending'

    }).then(
      updateDoc(documentRef1,{
             status:'pending'

      }),
      window.scrollTo(0, 0)


    )
  }
  }

  
 
 }


 function mobileNavPop(){
  setpop(true)




}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}
 function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }
 function openReviewInfo(){
  setOpenInfo(true)
 }
 
 function openReviewInfoClose(){
  setOpenInfo(false)
 }
    // Age group Field selector Input
    function ageGroupSet(e){
      setintendedAgeGroupNew(e.target.value)

      if(e.target.value.length == 0){
          setintendedAgeGroupNewError("error")
      }
      else {
          setintendedAgeGroupNewError("")
      }
  }
    // Profile Photo Click Handler
    const popProfile = () =>{
      if(pop == true){
        setpop(false)
      setnotificationPop(false)
  
  
      }
      else{
      setpop(true)
      setnotificationPop(false)
  
  
  
      }
  }
  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  };
  const popNotification = () =>{
    if(notificationPop == true){
      setnotificationPop(false)
      setpop(false)
    }
    else{
    setnotificationPop(true)
    setpop(false)
  
  
  
    }
  }
     function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      setDoc(notificationRef,{
        seen:true
      },{merge:true})
      .then(() => {
        
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
      });
  
  
     }
    let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

  return (
    <div>
       {

        user.length != 0 ?
        
        user.map(i=>{
        const{accountType,plan,fullName,photo} = i

          return(
            (courseId.length === 7 && noCourseFound === false) ?
            accountType =="Student"? 
      notPublished === false ?
  <div>
           {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
           <div id='desktop' >
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage'>
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
    <h1 className='home_description-header capitalize pl-10 pt-5 text-white' style={{fontSize:'25px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'11px'}}>{lecture.length} Lectures </p>
</h1>

            <div className='flex items-center'>
                
  
            <div className='flex items-center'>
                
                {
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue Course
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start Course
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase Course
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
         
            </div>

            </div>}
          <div className='border-l ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t  px-5 bg-black h-full" >

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px",color:'white'}}><Link to='/dashboard' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-6' style={{marginTop:''}}>
    <div>
    <h1 className='anton capitalize text-white mt-10' style={{fontSize:'40px'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='home_description-header pr-2' style={{fontSize:'16px',color:'white'}}>Course By: </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"35px",height:"35px"}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"35px",height:"35px"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white'>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
                <div className='flex items-center'>
                
           {
            alreadyExists == true?
            <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
            <button
              type='button'
              style={{fontSize:'11px'}}
              className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Continue Course
            </button>
            <p></p>
            </Link>
            :

            coursePricing == 'Free Pricing' ?
            <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
            <button
              type='button'
              style={{fontSize:'11px'}}
              
              className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Start Course
            </button>
            <p></p>
            </Link>
            :
            <Link to={`/course/${courseId}/checkout`} >
            <button
              type='button'
              style={{fontSize:'11px'}}
              
              className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
            >
              Purchase Course
            </button>
            <p></p>
            </Link>
           }
            </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'23px',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'23px',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'24px',color:'white'}}>
              {coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'150px',}}>
<h1 className='home_description-header anton capitalize' style={{color:"black",marginLeft:'50px'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"15px"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'150px',marginBottom:'150px', height:'200px',width:'90%',}} className='border  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'13px'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className='border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-10 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'30px'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'13px',width:'90%',wordBreak:'break-all'}}>{courseDescription} </p>
          </div>
          
     
          <div className="video-player-container" style={{width:'50%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '25px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex'>
        <span className='borel text-gray-500 mr-2'>Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button onClick={handleReadMore} class="inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
<div id='tablet' >
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' width="18" height="18" style={{objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>


  
        
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage'>
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between bg-blue-900 z-100 items-center'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
    <h1 className='home_description-header capitalize pl-10 pt-2 text-white' style={{fontSize:'20px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'11px'}}>{lecture.length} Lectures </p>
</h1>

<div className='flex items-center'>
                
                {
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue 
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start 
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase 
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>

            </div>}
          <div className='border-l ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t   bg-black h-full" >

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla pl-5 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"11px",color:'white'}}><Link to='/dashboard' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses}`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'50px'}}>
    <div className='pl-12 pt-12'>
    <h1 className='anton capitalize text-white' style={{fontSize:'30px'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='home_description-header pr-2' style={{fontSize:'15px',color:'white'}}>Course By :- </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"35px",height:"35px",fontSize:'14px'}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"35px",height:"35px"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white'>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
            <div className='flex items-center'>
                
                {
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue Course
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start Course
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase Course
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'150px',}}>
<h1 className='home_description-header anton capitalize' style={{color:"black",marginLeft:'50px'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"15px"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'150px',marginBottom:'150px',width:'90%',}} className='py-8 border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'11px'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-10 'style={{width:'60%'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'30px'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'11px',width:'90%',wordBreak:'break-all'}}>{courseDescription} </p>
          </div>
          
     
          <div className="video-player-container" style={{width:'50%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '3vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' >Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
<div id='mobile' >

<div >
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
  const courseDescriptionToShow = showFullDescription
  ? courseDescription
  : courseDescription.slice(0, 300);

const handleShowMore = () => {
  setShowFullDescription(true);
};
const handleShowLess = () => {
  setShowFullDescription(false);
};
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between items-center w-full bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'25vw'}} >
    <h1 className='anton capitalize  text-white' style={{fontSize:'4.5vw',width:'70%',paddingLeft:'3vw',marginRight:'3vw'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'2.8vw'}}>{lecture.length} Lectures </p>
</h1>

                
  
<div className='flex items-center'>
                
                {
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 5vw',height:'11vw'}}

                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue 
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 5vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start 
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 5vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase 
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
         

            </div>}
          <div className=' ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '105vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t   bg-black h-full" >
          <nav className=' flex justify-between navMobile  w-full'>
<Link to="/dashboard"  style={{width:'22%'}}>
      <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht_.__2_-removebg-preview.png?alt=media&token=17e900e3-c5a4-43ad-aac9-4b380ae7d27e' alt="logo" /> 
    </Link>
                <div   className=' flex items-center'>




                <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"9vw"}} fill="currentColor" class="bi bi-list text-white" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
                </div>




</nav>
    {
      pop == true &&
      <div id='popoutMobileHome' className='relative '>
      <nav className='shadow flex justify-between navMobile border-b w-full'>
            <div   className=' 'style={{width:'28%'}}>
   
   <svg  onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"8vw"}} fill="currentColor" class="bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
   </svg>
            </div>
   
   
   <Link to="/dashboard" onClick={popProfile} style={{width:'19%'}}>
   <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
   </Link>
   
   
   <div   className='flex justify-end ' style={{width:'33.33%'}}>
   <div   className=' '>
   
   <Link to='/courses' >
   <button type='submit' style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw"}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
            rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer">Courses
   </button>
   </Link>
   </div>
   
   
   
   </div>
   
   </nav>
   
   
   
   <ul className='w-full'>
           
                  <li className=' text-gray-800 '><Link to="/achievements">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Achievements </div>
      
   <img style={{width:"5vw"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />
   
                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/enrolledcourses">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Enrolled Courses </div>
   
   
   <svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
   <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
   </svg>
                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/transactions">
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Transactions </div>
    
   
   <svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
   </svg>
   
                        </div>
                  </Link></li>
                
                  <li className=' text-gray-800 '>
                   <Link to='/notification'>
   
                  <div  style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                   <div className='borel 'style={{fontSize:"3vw"}}>Notifications </div>
                        <svg xmlns="http://www.w3.org/2000/svg" class=" text-gray-800"style={{width:"5vw"}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
      </svg>
      </div>
   
      </Link>
                  </li>
                  <li onClick={handleLogOut}className=' text-gray-800 '>
                  <div style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='borel' style={{fontSize:"3vw"}}>Log out </div>
                     
                        <svg  className='' xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
    <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
   </svg>
                        </div>
                  </li>
         
        
   
   
              </ul>
   </div>
    }

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla  flex items-center capitalize' style={{fontSize:"2.5vw",color:'white',paddingLeft:'5vw',paddingTop:'4vw'}}><Link to='/dashboard' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'13vw'}}>
    <div>
    <h1 className='anton capitalize text-white' style={{fontSize:'6vw'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center "  style={{marginTop:'3vw',marginBottom:'2vw'}}>
                      
          
                
                
      <div className='flex items-center justify-center'>
        <p className='fjalla pr-2' style={{fontSize:'3vw',color:'white'}}>Course By :- </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"7vw",height:"7vw",fontSize:'3vw'}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"7vw",height:"7vw"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white' style={{fontSize:'3vw'}}>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
            <div className='flex items-center'>
                
                {
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}

                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue Course 
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start Course
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase Course
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'30vw', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>

            <div style={{backgroundColor:"white",marginTop:'30vw',}}>
<h1 className='home_description-header  capitalize' style={{color:"black",marginLeft:'8vw',fontSize:'6vw'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"3vw"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'30vw',marginBottom:'30vw',width:'90%',padding:'4vw 0'}} className=' border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'6vw',lineHeight:'6vw'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'2.5vw',marginTop:'3vw'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'30vw',marginBottom:'30vw',width:'100%',padding:'4vw 0'}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className=' 'style={{width:'60%',paddingLeft:'4vw'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'6vw',lineHeight:'6vw'}}>Course Trailer</p>
          <p className='borel text-gray-600' style={{ fontSize: '2.8vw', width: '90%' }}>
      {courseDescriptionToShow}
      {courseDescription.length > 300 && !showFullDescription && (
        <p onClick={handleShowMore} className='fjalla underline'style={{fontSize:'3.4vw'}}>Show More</p>
      )}
        {showFullDescription && (
        <p onClick={handleShowLess} className='fjalla underline'style={{fontSize:'3.4vw'}}>Show Less</p>
      )}
    </p> 
          </div>
          
     
          <div className="video-player-container" style={{width:'45%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1  className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '5vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' style={{fontSize:'3vw'}}>Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500' style={{fontSize:'3vw'}}> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
        </div>
:
user ? navigate('/dashboard') : navigate('/')

            :
            user ?
            <div>
          <div id="desktop">
         
          <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat  ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            <div className={`${lectureVideoEmpty == true ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {lectureVideoError}  

  </div>
  </div>
  </div>
            <div className={`${lectureDescriptionEmpty == true ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {lectureDescriptionError}  

  </div>
  </div>
  </div>
  <div className={`${studentsError.length != 0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {studentsError}  

  </div>
  </div>
  </div>
  <div className={`${lectureTitleEmpty == true ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {lectureTitleError}  

  </div>
  </div>
  </div>
            {
              openInfo == true &&
              <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
              <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
              <div className='py-5 flex w-full justify-between items-center'>
              <div style={{paddingLeft:"50px"}}>
                  <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Your course is currently being reviewed </h1>
                  <p className='borel text-gray-500' style={{fontSize:'12px'}}>The review process can take up to 24 hours</p>
                  
                  </div>
              
                 
              
              
              
              
                            <svg onClick={openReviewInfoClose}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
              </svg>
                     </div>
              
              <div className='flex '>
              
                <div className='mt-10 w-1/2 mx-3' style={{marginTop:"5%"}}>
            <p className='fjalla text-gray-700 mb-2' style={{fontSize:'25px'}}>Points to keep in mind</p>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>The quality of the video</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Course Content</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Learning Objectives</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Teaching Methods</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Value for Money</p>
                </div>
                </div>
              
                
                <img style={{width:"40.5%",marginTop:"-3%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro%20(1).svg?alt=media&token=eaed36f5-8f38-4e95-8986-1f3ad0778cc3" alt="Review Course" />
              
              </div>
                
              
              
              </main>
              
              </div>
            }
    
  {
    thumbnailUpload == true &&
    <div id='uploadFileDesktop' className="uploadFileDesktop h-screen w-screen items-center flex justify-center">
  
  
  
    {
      progress != 100 & progress == 0 ?
      <main className="  mx-auto    " style={{width:'80%',height:'90%'}}>
  
      {/* file upload modal */}
      <article
        aria-label="File Upload Modal"
        className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
 
      >
        {thumbnailuploadError == true &&
         <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800  border-red-300 bg-red-50 " role="alert">
         <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
           <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <div class="ml-3 text-sm font-medium Mooli capitalize">
         Thumbnail can't be empty  
         </div>
         <button onClick={closethumbnailErrorDesktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
           <span class="sr-only">Dismiss</span>
           <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
           </svg>
         </button>
        </div>
        }
    <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload thumbnail</p>
  
        {/* overlay */}
        <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
  
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
            <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
            <button
              className="mt-2 rounded-sm px-3 py-1 bg-gray-800 text-white fjalla focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input').click();
              }}
            >
              Upload a file
            </button>
            <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
  
          </header>
              <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
  
          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
  
          <ul className="flex justify-evenley  -m-1">
            {files && files.length === 0 ? (
              <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                <img
                  className="mx-auto w-32"
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt="no data"
                />
                <span className="text-small text-gray-500">No files selected</span>
              </li>
            ) : (
                <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                  <img
                  style={{maxHeight:"150px"}}
                    className="mx-auto w-32"
                    src={URL.createObjectURL(files)}
                    alt={files.name}
                    onClick={() => removeFile()}
                  />
                  <span className="text-small text-gray-500">{files.name}</span>
                </li>
            )}
          </ul>
        </section>
         
        {/* sticky footer */}
        <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
        <div className="flex justify-evenly mt-10 mb-10">
          <button
            type="submit"
            onClick={minimizeUploadImg}
            style={{ width: '20%' }}
            className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Back
          </button>
          <button
            onClick={uploadFile}
            type="submit"
            style={{ backgroundColor: '#2141ce', width: '20%' }}
            className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Upload
          </button>
        </div>
      </article>
    </main>
    
    :
    progress == 100 ? <div style={{width:'80%',height:'90%'}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' >
           <div class="">
           <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
           <p className=' text-center oswald' style={{fontSize:"30px",width:'100%'}}>Thumbnail Successfully Uploaded</p>
           <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
       </div>
       
             </div>
             :
             <div style={{width:'80%',height:'90%'}} className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' >
           
           <div class="relative">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
           <p className='fjalla mt-4 text-center'>Uploading</p>
           <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
       </div>
       
             </div>}
   
  
  </div>
  }
    
    {
    trailerUpload == true &&
  <div id='uploadTrailerDesktop' className="uploadFileDesktop  flex items-center justify-center ">
  
  
  
    {
      progress != 100 & progress == 0 ?
      <main className="mx-auto   " style={{width:'80%',height:'90%'}}>
       
  
      {/* file upload modal */}
      <article
      
        aria-label="File Upload Modal"
        className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
    
      >
         {traileruploadError == true &&
         <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800  border-red-300 bg-red-50 " role="alert">
         <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
           <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <div class="ml-3 text-sm font-medium Mooli capitalize">
         Trailer can't be empty  
         </div>
         <button onClick={closetrailerErrorDesktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
           <span class="sr-only">Dismiss</span>
           <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
           </svg>
         </button>
        </div>
        }
    <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload Trailer</p>
  
        {/* overlay */}
        <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
  
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
            <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input-trailer"  className="hidden" onChange={handleTrailerSelect} />
            <button
              className="mt-2 fjalla bg-gray-800 text-white rounded-sm px-3 py-1  focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-trailer').click();
              }}
            >
              Upload a file
            </button>
            <p className='Mooli text-gray-500'>Supported file types include .mp4, .mov, and .avi</p>
            
  
          </header>
              <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
  
          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
  
          <ul className="flex justify-evenley  -m-1">
            {trailer && trailer.length === 0 ? (
              <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                <img
                  className="mx-auto w-32"
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt="no data"
                />
                <span className="text-small text-gray-500">No files selected</span>
              </li>
            ) : (
                <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
               
               <div class="video-container">
                  <video controls >
    <source  
    type="video/mp4"
    
    className="mx-auto "
    src={URL.createObjectURL(trailer)}

    alt={trailer.name}
    onClick={() => removeFile()} ></source>
    Your browser does not support the video tag.
  </video>
  </div>
                  <span className="text-small text-gray-500">{trailer.name}</span>
                </li>
            )}
          </ul>
        </section>
  
        {/* sticky footer */}
        <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
        <div className="flex justify-evenly mt-10 mb-10">
          <button
            type="submit"
            onClick={minimizeUploadTrailer}
            style={{ width: '20%',fontSize:'15px' }}
            className="bg-red-800 fjalla m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Back
          </button>
          <button
            onClick={uploadTrailer}
            type="submit"
            style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'15px' }}
            className="m-auto w-full fjalla flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Upload
          </button>
        </div>
      </article>
    </main>
    
    :
    progress == 100 ? <div  className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           <div class="">
           <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
           <p className='anton  text-center' style={{fontSize:"35px"}}>Trailer Successfully Uploaded</p>
           <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
       </div>
       
             </div>
             :
             <div  className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
           <div class="relative mt-5">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
           <p className='fjalla mt-4 text-center'>Uploading</p>
       </div>
       
             </div>}
   
  
  </div>
  }
  {
    testOpen == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
    <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
    <div className='py-5 flex w-full justify-between items-center'>
    <div style={{paddingLeft:"50px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Prepare a test for this Course</h1>
        <p className='borel text-gray-500' style={{fontSize:'12px'}}>It is necessary for your students to attain at least 75% to complete the course</p>
        
        </div>
    
       
    
    
    
    
                  <svg onClick={backtestDesktop}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
    
    <div className='flex '>
    
      <div className='mt-10 w-1/2 mx-3' style={{marginTop:"5%"}}>
      <div className="relative " >
      <div className="relative  " >
    
    <p style={{fontSize:'12px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Number of questions</p>
    
    
    <select style={{fontSize:'13px'}} onChange={e=>questionsSet(e)} type="text" class="fjalla mx-2 border  focus:outline-none
      focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md">
                                  <option className='skill__input-option'value="">Select Field</option>
    
    
                       
                                  <option className='skill__input-option' value="10">10</option>
    
                                  <option className='skill__input-option' value="15">15</option>
                                  <option className='skill__input-option' value="20">20</option>
                                  <option className='skill__input-option' value="25">25</option>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    </select> 
    <div className={`${questionsError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    This field Can't be empty
    </div>
    </div>
    </div>
     
    </div>
    <Link to={`${questions == '' ? '' :`/course/${courseId}/test/${courseId}/${questions}`}`}>
       <button style={{fontSize:'13px'}} type='submit' onClick={()=>questions == '' && setquestionsError('error')}  className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5 text-xl font-medium text-center text-white 
           rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare test
       </button>
       </Link>
     </div>
      </div>
    
    
      <img style={{width:"40.5%",marginTop:"-3%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Exams-bro%20(1).svg?alt=media&token=d83b0fda-0cc5-4924-9843-1a16cb33c559" alt="Prepare test" />
    
    </div>
      
    
    
    </main>
    
    </div>
  }
  {
    lockedreview == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
    <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
    <div className='py-5 flex w-full justify-between items-center'>
    <div style={{paddingLeft:"50px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>We are currently reviewing this course for publication, so you cannot make any changes at this time</h1>
        <p className='borel text-gray-500' style={{fontSize:'12px'}}>The review process can take up to 24 hours</p>
        
        </div>
    
       
    
    
    
    
                  <svg onClick={lockedreviewclose}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
    
    <div className='flex '>
    
    <div className='mt-10 w-1/2 mx-3' style={{marginTop:"5%"}}>
            <p className='fjalla text-gray-700 mb-2' style={{fontSize:'25px'}}>Points to keep in mind</p>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>The quality of the video</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Course Content</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Learning Objectives</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Teaching Methods</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Value for Money</p>
                </div>
                </div>
    
    
      <img style={{width:"40.5%",marginTop:"-3%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Customer%20Survey-amico.svg?alt=media&token=fd7c6202-fbb9-40d2-9bf6-84bcc2db89b8" alt="Prepare test" />
    
    </div>
      
    
    
    </main>
    
    </div>
  }


{
 courseTrailerDeletedMessage == true &&
 <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div class="ml-3 text-sm font-medium Mooli capitalize">
 You have successfully removed your course trailer  
 </div>
 <button onClick={closeMessage55Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
   <span class="sr-only">Dismiss</span>
   <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </button>
</div>
}
{
 courseSkillsDeleteMessage == true &&
 <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div class="ml-3 text-sm font-medium Mooli capitalize">
 You have successfully removed your course Skills 
 </div>
 <button onClick={closeMessage800Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
   <span class="sr-only">Dismiss</span>
   <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </button>
</div>
}
{
  courseTrailerMessage == true &&
  <div id="alert-border-5" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
      <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div class="ml-3 text-sm font-medium Mooli capitalize">
       Your course trailer has been successfully updated 
      </div>
      <button onClick={closeMessage5Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
        <span class="sr-only">Dismiss</span>
        <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>
  </div>
}

{
  courseTitleMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course title has been successfully updated 
  </div>
  <button onClick={closeMessageDesktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  completionTimeMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's completion Time  has been successfully updated 
  </div>
  <button onClick={closeMessage1000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseIntendedAgeGroupMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's Age Group  has been successfully updated 
  </div>
  <button onClick={closeMessage10000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseSkillsAddedMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's Skills has been successfully updated 
  </div>
  <button onClick={closeMessage8000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseDescriptionMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Description has been successfully updated 
  </div>
  <button onClick={closeMessage900Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
 courseLevelMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Level has been successfully updated 
  </div>
  <button onClick={closeMessage9000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseSubjectMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Subject has been successfully updated 
  </div>
  <button onClick={closeMessage100Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
  <div className={`${courseError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {courseError}
  </div>
  </div>
  </div>

  <div className={`${courseThumbnailError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {courseThumbnailError}
  </div>
  </div>
  </div>

  <div className={`${lectureLengthError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {lectureLengthError}
  </div>
  </div>
  </div>

  <div className={`${trailerError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {trailerError}
  </div>
  </div>
  </div>
  <div className={`${testError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {testError}
  </div>
  </div>
  </div>
{
  courseThumbnailMessage == true &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course thumbnail has been successfully Updated 
  </div>
  <button onClick={closeMessage90Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  courseThumbnailDeletedMessage == true &&
  <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
    Your Course Thumbnail Has Been Successfully Removed
  </div>
  <button onClick={closeMessage2Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
 
{
  lectureDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   lecture has been successfully removed 
  </div>
  <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
 {
  unpublishCourse && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={removeUnpublishCourse}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Unpublish Course </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to unpublish your course? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={UnpublishCourse}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Unpublish</a>
<a onClick={removeUnpublishCourse}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
  {
   thumbnailDelete && 
<div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={removepopdesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Thumbnail </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove the thumbnail? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={deleteThumbnailDesktop }  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={removepopdesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
              {
                trailerDelete == true &&
                <div id='uploadCancelTrailerDesktop' className=' flex items-center justify-center'>
         
       
                <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
       <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
       
       >
       <div class="relative p-6">
        <a onClick={removepopTrailerdesktop}   class="absolute top-1.5 right-1.5">
            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
                
        </a>
        <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Trailer </h1>
        <p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove the trailer? </p>
        
        <div class="flex flex-row mt-6 space-x-2 justify-evenly">
            <a onClick={deleteTrailerDesktop}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
            <a onClick={removepopTrailerdesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
        </div>
       </div>
       
       </div>
       </div>  
       
       
            </div>
              }
       
  {
    lectureDelete == true &&
    <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
         
       
    <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
    
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Lecture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently delete this Lecture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
  }
      
  
             {course.map((i)=>{
                  const {isTest, courseTitle,courseThumbnail,courseTrailer,courseDescription,courseSubject,courseSkills,courseLevel,completionTime,intendedAgeGroup} = i
                  if(instructorId == uid){
                    return(
                      <div className='relative'>
                        <div className={`${course.length != 0 ? "hidden":"block"}`}>
    
                             <div  className=' flex items-center justify-center  rounded m-auto bg-white'  id='loadingLoadedDesktop' style={{width:"100%",height:"100%",position:"fixed",top:0,left:0,zIndex:100}}>
             
             <div class="relative">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         </div>
         
               </div>
               </div>
    
    
      <div class="w-full bg-neutral-200 " >
    
      <div
        class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
        style={{width:progress + "%",}}>
        {Math.round(progress)} %
      </div>
    
    </div>
    
    
    <div class="flex items-center justify-between mb-8">
    {/* <h1 className='fjalla mt-5 ml-5' style={{fontSize:"30px"}}>Course setup</h1> */}
    <div style={{width:"50%"}}>
    
         
    
              </div>
             <div style={{width:"55%"}} className='flex  items-center justify-end mx-2'>
             <div className='flex   items-center    pt-3' >
             <Link to={`/course/${courseId}/certificate/${courseId}`}>
    <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 ' id='twelve'>certificates</h1>
    </Link>
 
            <Link to={`/course/${courseId}/pricing`}>   <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>pricing</h1></Link> 
    <Link to={`/course/${courseId}/students`}>
  
                <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>students</h1>
    </Link>
              
              {
                status == 'pending' ?
                <h1 style={{fontSize:"12px"}}onClick={lockedReviewSet}className='capitalize fjalla text-gray-800 mx-2  hover:text-blue-800 '>Test</h1>
                :
                <h1 style={{fontSize:"12px"}}onClick={()=>opentestDesktop(isTest)}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>Test</h1>

              }    
           </div>
      <div className='flex items-center'>
    <Link to={`/course/${courseId}/preview`}>
    
      <button style={{fontSize:"13px"}} class="inline-flex px-5 py-2 mt-5 text-blue-600 mx-2 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
         Preview
           
              </button>
    </Link>
  
    {
      status === 'published'?
      <button  style={{fontSize:"12px"}}onClick={changetopublicDesktop} class=" w-full px-5 py-3  mt-5 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
          
      Unpublish Course
    </button>
    
    :
    status === 'pending'?
    
    <div   style={{fontSize:"12px",}}onClick={changetopublicDesktop} class="flex items-center justify-center w-full px-3 py-3  mt-5 text-yellow-600 hover:text-yellow-700 focus:text-yellow-700 hover:bg-yellow-100 focus:bg-yellow-100 border border-yellow-600 rounded-md mb-3">
          
      Pending Review <svg onClick={openReviewInfo} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="cursor-pointer ml-2 bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
</svg>
    </div>
    :


    <button  style={{fontSize:"12px"}}onClick={changetopublicDesktop} class=" w-full px-5 py-2  mt-5 text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">
          
      Publish Course
    </button>
  
    }
    
   
      </div>
             </div>
    
            </div><div className='flex justify-between px-5'>
             
            <div style={{width:"58%"}}>
                      {/* <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-2 bi bi-filter-square" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M6 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                    </svg>Organize your curriculum</h1> */}
            {
              status == 'pending' ?
               <div onClick={lockedReviewSet} style={{fontSize:"12px"}} id='twelve' class=" mr-5 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
              Add Lecture 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-1 bi bi-lock" viewBox="0 0 16 16">
  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
</svg>
  
            </div>
            :
            <Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}> <button style={{fontSize:"12px"}} id='twelve' class=" mr-5 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
            Add Lecture

          </button>
          </Link>
            }
      
        
                    <table class="w-full text-sm text-left text-gray-500   border mt-5 mb-10">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" class="px-6 py-3 Mooli">
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                        Lecture
                    </th>
                   
                
                  
                
                    <th scope="col" class="px-6 py-3 Mooli">
                    PDF
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Audio
    
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Quiz
    
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Action
    
                    </th>
    
                </tr>
            </thead>
            <tbody>
    {
    lectures.map(({ lectureTitle, lectureId, lectureOrder,lectureAudio, id ,lecturePdf,lectureQuiz}, index) => {
      return (
        <tr className="bg-white border-b" key={index}>
          <td className="px-6 py-4 capitalize Mooli">
            <ol>
              <li>{index + 1}</li>
            </ol>
          </td>
          <td className="flex px-6 py-4 capitalize">
            <Link to={`lecture/${lectureId}`}>
              <p className="cursor-pointer Mooli underline text-gray-800">{lectureTitle}</p>
            </Link>
          </td>
          <td className="px-6 py-4">
         {!lecturePdf  ?    <img
              style={{ width: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
              alt=""
            /> :
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
            alt=""
          />
            }
          </td>
          <td className="px-6 py-4 text-center">
        {
            lectureAudio  ? 
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
            alt=""
          />
          :
          <img
          style={{ width: "20px" }}
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
          alt=""
        /> 
         }
          </td>
          <td className="px-6 py-4 text-center">
        
            {
              lectureQuiz == true ? 
              <img
              style={{ width: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
              alt=""
            />
            :
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
            alt=""
          /> 
            }
          </td>
          <td className="px-6 py-4 fjalla relative">
           {
            status == 'pending'?
            <svg
            onClick={lockedReviewSet}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="ml-3 hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
            viewBox="0 0 16 16"
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
          </svg>
          :
          <svg
          onClick={() => deleteLectureDesktop(id)}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="ml-3 hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
          viewBox="0 0 16 16"
        >
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
        </svg>
           }
          </td>
        </tr>
      )
    })
    }
    </tbody>
    
    </table>
    
    {
      lectures.length  == 0 &&
      <div className='w-full text-center fjalla capitalize text-red-800' style={{fontSize:"20px"}}>
      Your course does not have any lectures 
      <p className="fjalla text-gray-500 normal-case" style={{fontSize:"12px"}}>Make sure to create lectures</p>
    </div> 
    
    }
    
    {/* <div className='mt-12 mb-5'>
    <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
    </svg>Set up your course pricing</h1>
    
    </div>
    
    <div className='border ' style={{width:"90%"}}>
      <div className='flex justify-between w-full items-center px-5 pt-5'>
        <h1 className=' text-center anton capitalize  'style={{fontSize:"20px"}}></h1>
        <p className='Mooli cursor-pointer hover:underline'>Need Help</p>
      </div>
    <div className='flex flex-col justify-between w-full items-center'>
    <h1 className='fjalla text-center text-gray-900 mt-5' style={{fontSize:"20px"}}>Our flexible pricing plans let you choose whether to charge for your course or make it free. </h1>
    <p className='borel text-gray-500 text-center'style={{fontSize:"15px"}}>Your course's pricing plan has not been set</p>
        
        <button class=" m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                Add Pricing Plan 
              </button>
  
              
    
    </div>
    </div> */}
     <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Completion Time </h3>
                         {
                          editIconCompletionTime == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{completionTime}</p>
  
                         }
           
                         </div>
                         {
                          editIconCompletionTime == false &&
            
       status === 'pending' ?
            <svg id="editIconDesktop" onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
          :

                          <svg id="editIconDesktop" onClick={editCompletionTimeOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconCompletionTime == true &&
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editCompletionTimeOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconCompletionTime == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>
        <input onChange={e=>completionTimeSet(e)} defaultValue={completionTime}  required  autoComplete="off"  type="text" className="border  focus:outline-none
  focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md" />
              <div className={`${completionTimeError == "error" ? "block": "hidden"}`}>
                                             <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
            Compilation Time  can't be empty
          </div>
          </div>
        
              </div>
                              <button style={{fontSize:'12px'}} onClick={updateCompletionTime} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Level </h3>
                         {
                          editIconCourseLevel == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseLevel}</p>
  
                         }
           
                         </div>
                         {
                          editIconCourseLevel == false &&
                         
                          status === 'pending'?
                          <svg id="editIconDesktop"  onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                        :
                        <svg id="editIconDesktop" onClick={editCourseLevelOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                         }
                       {
                          editIconCourseLevel == true &&
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editCourseLevelOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconCourseLevel == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>
          <select onChange={e=> courseLevelSet(e)} type="text" class="mx-2 border  focus:outline-none
  focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md">
                 <option className='skill__input-option'value="">Select Group</option>
                           <option className='skill__input-option' value="Beginner  ">Beginner  </option>
  <option className='skill__input-option' value="Intermediate ">Intermediate </option>
  
  
                           <option className='skill__input-option'value="Advanced">Advanced</option>
  
  
  
  
  
  </select> 
              <div className={`${courseLevelError == "error" ? "block": "hidden"}`}>
                                             <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
            Course Level can't be empty
          </div>
          </div>
        
              </div>
                              <button style={{fontSize:'12px'}} onClick={updateCourseLevel} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
   <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Intended Age Group </h3>
                         {
                          editIconIntendedAgeGroup == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{intendedAgeGroup}</p>
  
                         }
           
                         </div>
                         {
                           
                          editIconIntendedAgeGroup == false &&
                          status == 'pending' ?
                          <svg id="editIconDesktop" onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                        :
                        <svg id="editIconDesktop" onClick={editIntendedAgeGroupOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                         }
                       {
                          editIconIntendedAgeGroup == true &&
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editIntendedAgeGroupOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconIntendedAgeGroup == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>
                   <select onChange={e=> ageGroupSet(e)} type="text" class="mx-2 border  focus:outline-none
  focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
  border-gray-300 rounded-md">
                           <option className='skill__input-option'value="">Select Group</option>
                           <option className='skill__input-option' value="Elementary School ">Elementary School </option>
  <option className='skill__input-option' value="High School ">High School </option>
  
  
                           <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
  <option className='skill__input-option' value="Postgraduate">Postgraduate</option>
  <option className='skill__input-option' value="Doctoral">Doctoral</option>
  
  
  
  
  
  
  
  </select> 
              <div className={`${intendedAgeGroupNewError == "error" ? "block": "hidden"}`}>
                                             <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
            Intended Age Group can't be empty
          </div>
          </div>
        
              </div>
                              <button style={{fontSize:'12px'}} onClick={updateintendedAgeGroup} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
  <div className='border rounded m-auto mt-8' >
                    
                    <div className='flex justify-between px-5 py-5 w-full '>
                   <div className='w-full'>
                    <div className='flex justify-between w-full'>
                   <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Course Description </h3>
  
                    {
                      editIconDescription == false &&
                      status == 'pending' ?
                      
                      <svg id="editIconDescripionDesktop" onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil " viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                    :
                    <svg id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil " viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                    
                   }
                   
                    </div>
     {
                      editIconDescription == false &&
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{courseDescription}</p>
      
     }
                   </div>
               
               
              {
                      editIconDescription == true &&
  
                      <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>
  
              }
                    </div>
                    {
                      editIconDescription == true &&
                      <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
    
                      <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>courseDescriptionSet(e)}  type="text" class="border  focus:outline-none
                                   focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                   border-gray-300 rounded-md" maxLength="1000" defaultValue={courseDescription}></textarea>
  
                                   <div className={`${courseDescriptionError == "error" ? "block":"hidden"}`}>
                      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                      </svg>
                      <span className="sr-only">Info</span>
                      <div className='Mooli'>
                       Description Can't be empty
                      </div>
                      </div>
                      </div>
                      <p className={courseDescriptionNew.length != 0 ? 'Mooli text-gray-500' : 'hidden'} style={{fontSize:'11px'}}>{courseDescriptionNew && courseDescriptionNew.length}/1,000</p>
                      <button style={{fontSize:'12px'}} onClick={updateCourseDescription} class="ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                            
                            Save
                          </button>
                      </div>
                    }
             
   
                    <div>
                
                    </div>
                            
                  </div>
                  <div className='border rounded my-8' >
                          <div className='flex justify-between items-center px-5 py-5 w-full'>
                         <div className='w-full'>
            <div className='flex justify-between w-full items-center'>
            <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Skills </h3>
                         {
                         courseSkills.length != 0  &&
                         status == 'pending'?
                         <p className='fjalla   cursor-pointer ' onClick={lockedReviewSet} id='cancelInputDesktop'>Remove</p>
                   :
                          <p className='fjalla   cursor-pointer ' onClick={editResetSkillDesktop} id='cancelInputDesktop'>Remove</p>
                          
                         }
            </div>
                         {
                          editIconSkills == false &&
                          <div className='gridCourseSkill'>
                          {
                           courseSkills && courseSkills.map(i=>{
                              return(
                                <span style={{fontSize:"11px",rowGap:'5px',paddingLeft:'5px',paddingRight:'5px',paddingTop:'5px',paddingBottom:'5px'}} class="mx-1 mb-2 capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>
                
                              )
                            })
                          }
                         </div>
  
  
                         }
           
                         </div>
                
                         
                       {
                        courseSkills.length == 0  &&
                         ( editIconSkills == false   ?
  
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editAddSkillDesktop} id='cancelInputDesktop'>Add</p>
                        :
                          <div className='flex'>
                                                  <p className='fjalla  cursor-pointer hover:text-red-800 mr-2' onClick={editCancelAddSkillDesktop} id='cancelInputDesktop'>Cancel</p>
  
  <p className='fjalla  cursor-pointer' onClick={editUpdateSkillDesktop} id='cancelInputDesktop'>Save</p>
  </div>
                        )
                       } 
                          </div>
                          {
                          editIconSkills == true &&
                          <div class="relative m-auto mb-10">
                        <p className='fjalla text-center mb-2'  id='cancelInputDesktop'>After writing the skill, press Enter</p>
  
                          <TagsInput
                           inputProps={{
                               placeholder: 'Gain knowledge of database components',
                               className: 'form-control text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
                           }}
                           tabindex="5"
                           
                           className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
                           maxTags={10}
                           value={courseSkillsNew}
                           onChange={handleChangeCourseSkills}
                           renderTag={({ tag, key, disabled, onRemove }) => (
                               <span key={key} style={{ color: 'white',margin:"3px",fontSize:'11px' }} className='px-5 py-2 skillGrid  bg-blue-800 fjalla' >
                                   {tag}
                                   {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer' style={{fontSize:'11px'}}>X</span>}
                               </span>
                           )}
                          />
                          
                          {courseSkillsError &&  
                           <p className={`${courseSkillsError == "" && "hidden"} ${courseSkillsError == "error" && "block"}`}>
                               <div class="fjalla flex rounded bg-danger-100 text-base text-danger-700 barel px-3 py-3" role="alert">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="25" fill="currentColor" class="mr-1 bi bi-bug" viewBox="0 0 16 16">
                                       <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
                                   </svg>Course Skills can't be empty
                               </div> 
                           </p>
                          } 
                          </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
    
                      </div>
                      <div className=' ' style={{width:"38%"}}>
                        {/* <h1 className='pl-5 pb-2   anton text-blue-800 flex  items-center text-center capitalize' style={{color:"blue",fontSize:"25px"}}>
                    Customize your course</h1> */}
                        <div className='border rounded ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Title </h3>
                         {
                          editIconTitle == true &&
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseTitle}</p>
  
                         }
           
                         </div>
                         {
                          editIconTitle == true &&
                         status == 'pending'?
                          <svg id="editIconDesktop"  onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                        :
                        <svg id="editIconDesktop" onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                         }
                       {
                          editIconTitle == false &&
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconTitle == false &&
                          <div className="relative mx-5 " id='editInputDesktop'>
                          <input style={{fontSize:'12px'}}  onChange={e=>courseTitleSet(e)}  required  autoComplete="off"  type="text" className="border  focus:outline-none
                              focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                              border-gray-300 rounded-md" defaultValue={courseTitle}/>
              <div className={`${courseTitleError == "error" ? "block": "hidden"}`}>
                                             <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
            Course Title can't be empty
          </div>
          </div>
        
              </div>
                              <button style={{fontSize:'12px'}} onClick={updateCourseTitle} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Subject </h3>
                         {
                          editIconSubject == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseSubject}</p>
  
                         }
           
                         </div>
                         {
                          editIconSubject == false &&
                          status == 'pending'?
                          <svg id="editIconDesktop" onClick={lockedReviewSet} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                        :
                        <svg id="editIconDesktop" onClick={editSubjectOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                         }
                       {
                          editIconSubject == true &&
  
                          <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editSubjectOpenerDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconSubject == true &&
  
                          <div className="relative mx-5 " id='editInputDesktop'>
                          <input style={{fontSize:'12px'}}  onChange={e=>courseSubjectSet(e)}  required  autoComplete="off"  type="text" className="border  focus:outline-none
                              focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                              border-gray-300 rounded-md" defaultValue={courseSubject}/>
              <div className={`${courseSubjectError == "error" ? "block": "hidden"}`}>
                                             <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
            Course Subject can't be empty
          </div>
          </div>
        
              </div>
                              <button style={{fontSize:'12px'}} onClick={updateCourseSubject} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div>
                        <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
  
    
                    {
    
    
    courseTrailer  ? 
    <div class="relative flex flex-col items-center w-full">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
      
      { 
      status == 'pending'?
      <p onClick={lockedReviewSet} class="fjalla text-red-800 hover:text-red-700 cursor-pointer" style={{fontSize:'12px'}}>Remove Trailer</p>
:
            <p onClick={openpopTrailerdesktop} class="fjalla text-red-800 hover:text-red-700 cursor-pointer" style={{fontSize:'12px'}}>Remove Trailer</p>
                         }     </div>
        </label>
  
  {
    courseTrailer.length != 0 ? (
      <div className="video-player-container mx-auto mb-20 rounded-md" >
        <video controls style={{maxHeight:'250px'}}>
<source src={courseTrailer} type="video/mp4" poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'></source>
Your browser does not support the video tag.
</video>
   
    </div>
     
    ) : (
      <div className="bg-white flex items-center justify-center">
        <div className="relative">
          <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
          <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
        </div>
      </div>
    )
  }
      
     
    </div>
    
    :
      <div class="flex flex-col items-center justify-center">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
            
            <p onClick={openUploadTrailerPopDesktop} class="borel hover:text-blue-800 cursor-pointer" style={{fontSize:'12px'}}>Upload Video</p>
      </div>
        </label>
    <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
    
    </div> 
    
  
    
    }
    
    
    
    
                          </div>
    
                    </div>
  
  
  
  
  
  
                    <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
  
    
                    {
    
    
    courseThumbnail  ? 
    <div class="relative flex flex-col items-center w-full ">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Thumbnail</h3>
      { 
      status == 'pending'?
      <p onClick={lockedReviewSet} class="fjalla text-red-800 hover:text-red-700 cursor-pointer" style={{fontSize:'12px'}}>Remove Thumbnail</p>
:
            <p style={{fontSize:'12px'}} onClick={openpopDeletedesktop} class="fjalla text-red-800 hover:text-red-700 cursor-pointer">Remove Thumbnail</p>
      }
            </div>
        </label>
       
    <img onLoad={checkthumbnailLoad}   class="h-auto w-full rounded-lg" src={courseThumbnail} alt="image description"/>
       {
          thumbnailLoad == true &&
          <div class=" bg-white flex items-center justify-center" id='loadThumbNail'>
          <div className='relative'>
          <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
               <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
          </div> 
          </div>
       }
    
    </div>
    
    :
      courseThumbnailImg.length == 0  ? 
      <div class="flex flex-col items-center justify-center">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between items-center w-full py-4">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Thumbnail</h3>
            
            <p style={{fontSize:'12px'}} onClick={openUploadPopDesktop} class="borel hover:text-blue-800 cursor-pointer">Upload Image</p>
      </div>
        </label>
    <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
    
    </div> :
    
    <div className='w-full '>
    <div class="flex   flex-col items-center justify-center m-auto w-full" >
        
        <label for="dropzone-file" class="text-center relative flex flex-col items-center justify-center w-full   border-gray-300  rounded-lg   ">
        {
            progress != 100 & progress == 0 ?  <div className='flex flex-col items-center'>
            <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            <p className='fjalla mb-2  w-full text-center'>
            Video Name : {courseThumbnailImg.name}
            </p>
             
            <button onClick={openpopdesktop} class=" inline-flex px-5 py-3 text-white bg-blue-700 hover:bg-blue-800 focus:bg-blue-700 rounded-md  mb-3">
                     
                        Upload Thumbnail
                      </button>
                      <p className='fjalla text-center '>
             
                </p> 
            </div> 
            :
             progress == 100 ? <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="">
             <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
             <p className='fjalla  text-center'>File Successfully Uploaded</p>
         </div>
         
               </div>
               :
               <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             
             <div class="relative">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             <p className='fjalla mt-4 text-center'>Uploading</p>
         </div>
         
               </div>
        }
    
    
    
        </label>
    </div> 
    </div> 
    
    }
    
    
    
    
                          </div>
    
                    </div>
    
                        </div>
                      </div>
            </div>
                    </div>
                    )
                  }
                  else{
                    
                  }
                 
                 
  
              }
              )} 
          </div>
          <div id="mobile">
         
         <DashboardNav/>
      
    {
      thumbnailUpload == true &&
      <div id='uploadFileDesktop' className="uploadFileDesktop h-screen w-screen flex justify-center items-center">
    
    
    
      {
        progress != 100 & progress == 0 ?
        <main className="  mx-auto    " style={{width:'80%',height:'90%'}}>
    
        {/* file upload modal */}
        <article
          aria-label="File Upload Modal"
          className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
   
        >
      <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"5vw"}}>Upload thumbnail</p>
    
          {/* overlay */}
          <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
    
          <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
            <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
              <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
           
              <button  onClick={() => {
                  document.getElementById('hidden-input').click();
                }} style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw",width:'90%'}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
                rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer">                Upload a file

  </button>
              <p className='Mooli text-gray-500' style={{fontSize:"2.5vw"}}>Supported file types include .png .jpg .jpeg</p>
    
            </header>
                <p className='Mooli text-red-800 capitalize' style={{fontSize:"3vw"}}>{fileMesssage}</p>
    
            <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900" style={{fontSize:"5vw"}}>To Upload</h1>
    
            <ul className="flex justify-evenley  -m-1">
              {files.length === 0 ? (
                <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                  <img
                    className="mx-auto w-32"
                    src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                    alt="no data"
                  />
                  <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>No files selected</span>
                </li>
              ) : (
                  <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                    <img
                    style={{maxHeight:"150px"}}
                      className="mx-auto w-32"
                      src={URL.createObjectURL(files)}
                      alt={files.name}
                      onClick={() => removeFile()}
                    />
                    <span className="text-small text-gray-500" style={{fontSize:"3vw"}}>{files.name}</span>
                  </li>
              )}
            </ul>
          </section>
           
          {/* sticky footer */}
          <div class="w-full bg-neutral-200">
            <div
              class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
              style={{ width: progress + '%' }}
            >
              {Math.round(progress)} %
            </div>
          </div>
          <div className="flex justify-evenly mt-10 mb-10">
            <button
              type="submit"
              onClick={minimizeUploadImg}
              style={{ width: '20%',fontSize:'3vw' }}
              className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
            >
              Back
            </button>
            <button
              onClick={uploadFile}
              type="submit"
              style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw' }}
              className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
            >
              Upload
            </button>
          </div>
        </article>
      </main>
      
      :
      progress == 100 ? <div style={{width:'80%',height:'90%'}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="">
             <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
             <p className='anton  text-center' style={{fontSize:"35px"}}>Thumbnail Successfully Uploaded</p>
             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
         </div>
         
               </div>
               :
               <div style={{width:'80%',height:'90%'}} className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             
             <div class="relative">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             <p className='fjalla mt-4 text-center'>Uploading</p>
             <div
              class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
              style={{ width: progress + '%' }}
            >
              {Math.round(progress)} %
            </div>
         </div>
         
               </div>}
     
    
    </div>
    }
      
      {
      trailerUpload == true &&
    <div id='uploadTrailerDesktop' className="uploadFileDesktop  flex items-center justify-center items-center ">
    
    
    
      {
        progress != 100 & progress == 0 ?
        <main className="mx-auto   " style={{width:'80%',height:'90%'}}>
    
        {/* file upload modal */}
        <article
        
          aria-label="File Upload Modal"
          className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
      
        >
      <p className='fjalla text-gray-800 pl-5 pt-5 capitalize'  style={{fontSize:"5vw"}}>Upload Trailer</p>
    
          {/* overlay */}
          <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
    
          <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
            <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
           
              <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input-trailer"  className="hidden" onChange={handleTrailerSelect} />
              <button   onClick={() => {
                  document.getElementById('hidden-input-trailer').click();
                }} style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw",width:'80%'}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
                rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer"> Upload a file
  </button>
             
              <p className='Mooli text-gray-500 px-3' tyle={{fontSize:"2.5vw",marginTop:'5vw'}}>Supported file types include .mp4, .mov, and .avi</p>
              
    
            </header>
                <p className='Mooli text-red-800 capitalize'tyle={{fontSize:"3vw"}}>{fileMesssage}</p>
    
            <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900"tyle={{fontSize:"3vw"}}>To Upload</h1>
    
            <ul className="flex justify-evenley  -m-1">
              {trailer.length === 0 ? (
                <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                  <img
                    className="mx-auto w-32"
                    src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                    alt="no data"
                  />
                  <span className="text-small text-gray-500"tyle={{fontSize:"3vw"}}>No files selected</span>
                </li>
              ) : (
                  <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                 
                 <div class="video-container">
                    <video controls >
      <source  
      type="video/mp4"
      
      className="mx-auto "
      src={URL.createObjectURL(trailer)}
  
      alt={trailer.name}
      onClick={() => removeFile()} ></source>
      Your browser does not support the video tag.
    </video>
    </div>
                    <span className="text-small text-gray-500" tyle={{fontSize:"3vw"}}>{trailer.name}</span>
                  </li>
              )}
            </ul>
          </section>
    
          {/* sticky footer */}
          <div class="w-full bg-neutral-200">
            <div
              class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
              style={{ width: progress + '%' }}
            >
              {Math.round(progress)} %
            </div>
          </div>
          <div className="flex justify-evenly mt-10 mb-10">
            <button
              type="submit"
              onClick={minimizeUploadTrailer}
              style={{ width: '20%',fontSize:'3vw' }}
              className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
            >
              Back
            </button>
            <button
              onClick={uploadTrailer}
              type="submit"
              style={{ backgroundColor: '#2141ce', width: '20%',fontSize:'3vw' }}
              className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
            >
              Upload
            </button>
          </div>
        </article>
      </main>
      
      :
      progress == 100 ? <div style={{width:'80%',height:'90%'}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="">
             <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
             <p className='anton  text-center' style={{fontSize:"35px"}}>Trailer Successfully Uploaded</p>
             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
         </div>
         
               </div>
               :
               <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
               <div class="w-full bg-neutral-200">
            <div
              class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
              style={{ width: progress + '%' }}
            >
              {Math.round(progress)} %
            </div>
          </div>
             <div class="relative mt-5">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             <p className='fjalla mt-4 text-center'>Uploading</p>
         </div>
         
               </div>}
     
    
    </div>
    }
    {
      testOpen == true &&
      <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
      <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
      <div className='py-5 flex w-full justify-between items-center'>
      <div style={{paddingLeft:"50px"}}>
          <h1 className='anton text-gray-900 capitalize' style={{fontSize:"6vw",paddingTop:"50px"}}>Prepare a test for this Course</h1>
          <p className='borel text-gray-500' style={{fontSize:'3vw'}}>It is necessary for your students to attain at least 75% to complete the course</p>
          
          </div>
      
         
      
      
      
      
                    <svg onClick={backtestDesktop}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
      </svg>
             </div>
      
      
        <div className='mt-10  mx-3' style={{marginTop:"5%"}}>
        <div className="relative " >
        <div className="relative  " >
      
      <p style={{fontSize:'12px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Number of questions</p>
      
      
      <select style={{fontSize:'13px'}} onChange={e=>questionsSet(e)} type="text" class="fjalla mx-2 border  focus:outline-none
        focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
        border-gray-300 rounded-md">
                                    <option className='skill__input-option'value="">Select Field</option>
      
      
                         
                                    <option className='skill__input-option' value="10">10</option>
      
                                    <option className='skill__input-option' value="15">15</option>
                                    <option className='skill__input-option' value="20">20</option>
                                    <option className='skill__input-option' value="25">25</option>
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      </select> 
      <div className={`${questionsError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli'>
      This field Can't be empty
      </div>
      </div>
      </div>
       
      </div>
      <Link to={`${questions == '' ? '' :`/course/${courseId}/test/${courseId}/${questions}`}`}>
         <button style={{fontSize:'13px'}} type='submit' onClick={()=>questions == '' && setquestionsError('error')}  className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5 text-xl font-medium text-center text-white 
             rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare test
         </button>
         </Link>
       </div>
        </div>
      
      
      
        
      
      
      </main>
      
      </div>
    }
  
  
  {
   courseTrailerDeletedMessage == true &&
   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
   You have successfully removed your course trailer  

   </div>
   </div>
   <svg  onClick={closeMessage55Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </div> 

  }
  {
   courseSkillsDeleteMessage == true &&
  
     <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
   You have successfully removed your course skills 

   </div>
   </div>
   <svg  onClick={closeMessage800Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </div> 
  
  }
  {
    courseTrailerMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'3vw'}}>
    Your course trailer has been successfully updated 


   </div>
   </div>
   <svg   onClick={closeMessage5Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
       <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
     </svg>
   </div> 
   
  }
  
  {
    courseTitleMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course title has been successfully updated 

  </div>
  </div>
  <svg   onClick={closeMessageDesktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
   

  }
  
  {
    completionTimeMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course's completion Time  has been successfully updated 

  </div>
  </div>
  <svg   onClick={closeMessage1000Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
    
  }
  
  {
    courseIntendedAgeGroupMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course's Age Group  has been successfully updated 

  </div>
  </div>
  <svg   onClick={closeMessage10000Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
   
  }
  
  {
    courseSkillsAddedMessage == true &&

    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course's Skills has been successfully updated 

  </div>
  </div>
  <svg    onClick={closeMessage8000Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
   
  }
  
  {
    courseDescriptionMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course Description has been successfully updated 

  </div>
  </div>
  <svg    onClick={closeMessage900Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
        }
  
  {
   courseLevelMessage == true &&
   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
   <div className='flex justify-center items-center'>
 <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div className='Mooli' style={{fontSize:'3vw'}}>

  Your course Level has been successfully updated 

 </div>
 </div>
 <svg    onClick={closeMessage9000Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </div> 
 
  }
  
  {
    courseSubjectMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>

   Your course Subject has been successfully updated 

  </div>
  </div>
  <svg    onClick={closeMessage100Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
 
  }
    <div className={`${courseError.length !=0 ? "block":"hidden"}`}>
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


   {courseError}
  </div>
  </div>
  </div>

    </div>
  {
    courseThumbnailError.length != 0 &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


   {courseThumbnailError}
  </div>
  </div>
  </div>
  }
    <div>
    
    </div>
  {
    lectureLengthError.length !=0  &&
    <div >
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


   {lectureLengthError}
  </div>
  </div>
  </div>
    </div>
  }

  {
    trailerError.length !=0 &&
    <div >
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


   {trailerError}
  </div>
  </div>
  </div>
    </div>
  }
   {
    testError.length !=0 &&
    <div >
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


   {testError}
  </div>
  </div>
  </div>
    </div>
   }
   
  {
    courseThumbnailMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>


     Your course thumbnail has been successfully Updated 
  </div>
  </div>
  <svg    onClick={closeMessage90Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
    
  }
  {
    courseThumbnailDeletedMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>
    Your Course Thumbnail Has Been Successfully Removed


  </div>
  </div>
  <svg    onClick={closeMessage2Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
    
  }
   
  {
    lectureDeleteMessage == true &&
    <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <div className='flex justify-center items-center'>
  <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div className='Mooli' style={{fontSize:'3vw'}}>
   lecture has been successfully removed 


  </div>
  </div>
  <svg    onClick={closeMessage6Desktop} style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </div> 
   
  }
   {
    thumbnailDelete == true && 
    
    <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
           
         
    <div className=' m-auto  rounded  ' id='subscriptionDesktop' style={{width:"90%",height:"60%"}}>
  <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
  
  >
  <div class="relative p-6">
  <a onClick={removepopdesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
    
  </a>
  <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Remove Thumbnail </h1>
  <p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently remove the thumbnail? </p>
  
  <div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a onClick={deleteThumbnailDesktop} style={{fontSize:"3vw"}} class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a onClick={removepopdesktop}   style={{fontSize:"3vw"}}class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
  </div>
  </div>
  </div>
  </div>  
  
  
  </div>
   }
                {
                  trailerDelete == true &&
                  <div id='uploadCancelTrailerDesktop' className=' flex items-center justify-center'>
           
         
                  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
         <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
         
         >
         <div class="relative p-6">
          <a onClick={removepopTrailerdesktop}   class="absolute top-1.5 right-1.5">
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                  
          </a>
          <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Remove Trailer </h1>
          <p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}} >Would you like to permanently remove the trailer? </p>
          
          <div class="flex flex-row mt-6 space-x-2 justify-evenly">
              <a onClick={deleteTrailerDesktop} style={{fontSize:"3vw"}} class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
              <a onClick={removepopTrailerdesktop}  style={{fontSize:"3vw"}} class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
          </div>
         </div>
         
         </div>
         </div>  
         
         
              </div>
                }
         
    {
      lectureDelete == true &&
      <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
           
         
      <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"90%",height:"50%"}}>
  <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
  
  >
  <div class="relative p-6">
  <a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
      
  </a>
  <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Lecture </h1>
  <p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:"3vw"}}>Would you like to permanently delete this Lecture? </p>
  
  <div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a  onClick={deleteHandlerDesktop} style={{fontSize:"3vw"}}class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a  onClick={closePopDeleteDesktop}  style={{fontSize:"3vw"}}class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
  </div>
  </div>
  </div>
  </div>  
  
  
  </div>
    }
        
    
               {course.map((i)=>{
                    const { isTest,courseTitle,courseThumbnail,courseTrailer,courseDescription,courseSubject,courseSkills,courseLevel,completionTime,intendedAgeGroup} = i
                    return(
                      <div className='relative'>
                        <div className={`${course.length != 0 ? "hidden":"block"}`}>
    
                             <div  className=' flex items-center justify-center  rounded m-auto bg-white'  id='loadingLoadedDesktop' style={{width:"100%",height:"100%",position:"fixed",top:0,left:0,zIndex:100}}>
             
             <div class="relative">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         </div>
         
               </div>
               </div>
    
    
      <div class="w-full bg-neutral-200 " >
    
      <div
        class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
        style={{width:progress + "%",}}>
        {Math.round(progress)} %
      </div>
    
    </div>
    
    
    <div class="flex items-center justify-end mb-8">
    {/* <h1 className='fjalla mt-5 ml-5' style={{fontSize:"30px"}}>Course setup</h1> */}
    
             <div  className='flex  items-center justify-end mx-2'>
             <div className='flex   items-center    pt-3' >
    <Link to={`/course/${courseId}/certificate/${courseId}`}>
    <h1 style={{fontSize:"3vw",marginLeft:'2vw'}}className='capitalize fjalla text-gray-800  cursor-pointer hover:text-blue-800 ' id='twelve'>certificates</h1>
    </Link>
            <Link to={`/course/${courseId}/pricing`}>   <h1 style={{fontSize:"3vw",marginLeft:'2vw'}}className='capitalize fjalla text-gray-800  cursor-pointer hover:text-blue-800 '>pricing</h1></Link> 
    <Link to={`/course/${courseId}/students`}>
  
                <h1 style={{fontSize:"3vw",marginLeft:'2vw'}}className='capitalize fjalla text-gray-800  cursor-pointer hover:text-blue-800 '>students</h1>
    </Link>
              
                <h1 style={{fontSize:"3vw",marginLeft:'2vw'}}onClick={()=>opentestDesktop(isTest)}className='capitalize fjalla text-gray-800  cursor-pointer hover:text-blue-800 '>Test</h1>
    
           </div>
      <div className='flex items-center'>
    <Link to={`/course/${courseId}/preview`}>
    
      <button style={{fontSize:"2.8vw",padding:'2vw 2vw',marginLeft:'2vw'}} class="inline-flex px-5 py-2 mt-5 text-blue-600 mx-2 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
         Preview
           
              </button>
    </Link>
  
    {
      status != 'published'?
  
      <button  style={{fontSize:"2.8vw",padding:'2vw 2vw ',marginLeft:'2vw'}}onClick={changetopublicDesktop} class=" w-full   mt-5 text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">
          
      Publish Course
    </button>
    :
  
    <button  style={{fontSize:"2.8vw",padding:'2vw 2vw ',marginLeft:'2vw'}}onClick={changetopublicDesktop} class=" w-full   mt-5 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
          
    Unpublish Course
  </button>
    }
    
   
      </div>
             </div>
    
            </div><div className='flex flex-col justify-between '>
            <div className=' mx-auto' style={{width:"95%"}}>
                
            <Link  to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}> <button style={{fontSize:"2.8vw",padding:'2vw 2vw',marginLeft:'2vw'}}id='twelve' class=" mr-5 inline-flex  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
             Add Lecture
  
           </button>
           </Link>
     
                 <table class="w-full text-sm text-left text-gray-500   border mt-5 mb-10">
                 <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr> <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                    Lectures
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                PDF
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                    Audio
                </th>
              
                <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                    Quiz
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'3vw',padding:'2vw'}}>
                    Action
                </th>
               
            </tr>
        </thead>
         <tbody>
  {
  lectures.map(({ lectureTitle, lectureId, lectureOrder,lectureAudio, id ,lecturePdf,lectureQuiz}, index) => {
   return (
     <tr className="bg-white border-b" key={index}>
       <td className="text-center capitalize Mooli" style={{padding:'2vw'}}>
           <p style={{fontSize:'3vw'}}>{index + 1}</p>
       </td>
       <td className="flex text-center capitalize"  style={{padding:'2vw'}}>
         <Link to={`lecture/${lectureId}`}>
           <p style={{fontSize:'3vw'}} className="cursor-pointer borel underline text-gray-800">{lectureTitle}</p>
         </Link>
       </td>
       <td className="text-center" style={{padding:'2vw'}}>
      {!lecturePdf  ?    <img
      className='mx-auto'
           style={{ width: "3vw" }}
           src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
           alt=""
         /> :
         <img
         className='mx-auto'
         style={{ width: "3vw" }}
         src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
         alt=""
       />
         }
       </td>
       <td className="text-center text-center" style={{padding:'2vw'}}>
     {
         lectureAudio  ? 
         <img
      className='mx-auto'

         style={{ width: "3vw" }}
         src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
         alt=""
       />
       :
       <img
      className='mx-auto'

       style={{ width: "3vw" }}
       src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
       alt=""
     /> 
      }
       </td>
       <td className="text-center text-center" style={{padding:'2vw'}}>
     
         {
           lectureQuiz == true ? 
           <img
        className='mx-auto'

           style={{ width: "3vw" }}
           src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
           alt=""
         />
         :
         <img
        className='mx-auto'

         style={{ width: "3vw" }}
         src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
         alt=""
       /> 
         }
       </td>
       <td className="text-center fjalla relative" style={{padding:'2vw'}}>
         <svg
           onClick={() => deleteLectureDesktop(id)}
           xmlns="http://www.w3.org/2000/svg"
         style={{ width: "4vw" }}
           fill="currentColor"
           className=" ml-3 hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill mx-auto"
           viewBox="0 0 16 16"
         >
           <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
         </svg>
       </td>
     </tr>
   )
  })
  }
  </tbody>
  
  </table>
  instructor
  {
   lectures.length  == 0 &&
   <div className='w-full text-center fjalla capitalize text-red-900' style={{fontSize:"5.5vw"}}>
   Your course does not have any lectures 
   <p className="fjalla text-gray-500 normal-case" style={{fontSize:"3vw"}}>Make sure to create lectures</p>
  </div> 
  
  }
                        {/* <h1 className='pl-5 pb-2   anton text-blue-800 flex  items-center text-center capitalize' style={{color:"blue",fontSize:"25px"}}>
                    Customize your course</h1> */}
                        <div className='border rounded  'style={{marginTop:'32vw'}} >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Course Title </h3>
                         {
                          editIconTitle == true &&
           <p className="borel text-gray-500 block" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{courseTitle}</p>
  
                         }
           
                         </div>
                         {
                          editIconTitle == true &&
                          <svg id="editIconDesktop" style={{width:'5vw'}} onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconTitle == false &&
                          <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconTitle == false &&
                          <div className="relative mx-5 " id='editInputDesktop'>
                   
                                                    
                                                    <input type='text'defaultValue={courseTitle} onChange={e=>courseTitleSet(e)} className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"3vw",paddingBottom:"3vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}   />
             <div className={`${courseTitleError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Title can't be empty

</div>
</div>
</div> 
             
                              <button style={{fontSize:'3vw'}} onClick={updateCourseTitle} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Course Subject </h3>
                         {
                          editIconSubject == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{courseSubject}</p>
  
                         }
           
                         </div>
                         {
                          editIconSubject == false &&
  
                          <svg id="editIconDesktop" style={{width:'5vw'}} onClick={editSubjectOpenerDesktop} xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconSubject == true &&
  
                          <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editSubjectOpenerDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconSubject == true &&
  
                          <div className="relative mx-5 " id='editInputDesktop'>
                      
                                   <input type='text'defaultValue={courseSubject} onChange={e=>courseSubjectSet(e)} className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"3vw",paddingBottom:"3vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}   />
          
                 <div className={`${courseSubjectError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Subject can't be empty

</div>
</div>
</div> 
             
                              <button style={{fontSize:'3vw'}} onClick={updateCourseSubject} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div>
                        <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
  
    
                    {
courseTrailer  ? 
    <div class="relative flex flex-col items-center w-full">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"5vw"}}>Course Trailer</h3>
            
            <p onClick={openpopTrailerdesktop} style={{fontSize:'3vw'}}class="fjalla text-red-800 hover:text-red-700 cursor-pointer" >Remove Trailer</p>
      </div>
        </label>
  
  {
    courseTrailer.length != 0 ? (
      <div className="video-player-container mx-auto mb-20 rounded-md">
      <div className="video-wrapper">
        <video
          ref={mobilevideoRef}
          className="video-player rounded-md"
          style={{ maxHeight: '250px' }}
          poster="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658"
        >
          <source src={courseTrailer} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="play-button-overlay" onClick={handlePlayPauseMobile}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="text-white play-button-icon bi bi-play-circle-fill"
            viewBox="0 0 16 16"
          >
            {isPlaying ? (
              <path d="M11 8a.5.5 0 0 1-.5.5H6.41l2.78 2.78a.5.5 0 0 1-.707.707L5.147 9.15a.5.5 0 0 1 0-.707l3.327-3.327a.5.5 0 0 1 .707.707L6.91 7.5H10.5A.5.5 0 0 1 11 8z" />
            ) : (
              <path d="M6 4.68V11.32a.5.5 0 0 0 .8.4l5.36-3.82a.5.5 0 0 0 0-.8L6.8 4.28a.5.5 0 0 0-.8.4z" />
            )}
          </svg>
        </div>
      </div>
    </div>
     
    ) : (
      <div className="bg-white flex items-center justify-center">
        <div className="relative">
          <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
          <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
        </div>
      </div>
    )
  }
      
     
    </div>
    
    :
      <div class="flex flex-col items-center justify-center">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"5vw"}}>Course Trailer</h3>
            
            <p style={{fontSize:'3vw'}} onClick={openUploadTrailerPopDesktop} class="borel hover:text-blue-800 cursor-pointer" style={{fontSize:'12px'}}>Upload Video</p>
      </div>
        </label>
    <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
    
    </div> 
    
  
    
    }
    
    
    
    
                          </div>
    
                    </div>
  
  
  
  
  
  
                    <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
                    {
    
    
    courseThumbnail.length != 0  ? 
    <div class="relative flex flex-col items-center w-full ">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"5vw"}}>Course Thumbnail</h3>
            
            <p style={{fontSize:'3vw'}} onClick={openpopDeletedesktop} class="fjalla text-red-800 hover:text-red-700 cursor-pointer">Remove Thumbnail</p>
      </div>
        </label>
       
    <img onLoad={checkthumbnailLoad}   class="h-auto w-full rounded-lg" src={courseThumbnail} alt="image description"/>
      {
        thumbnailLoad == true &&
        <div class=" bg-white flex items-center justify-center" id='loadThumbNail'>
        <div className='relative'>
        <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
        </div> 
        </div>
      }
    </div>
    
    :
      courseThumbnailImg.length == 0  ? 
      <div class="flex flex-col items-center justify-center">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between items-center w-full py-4">
      <h3 class="  home_description-header " style={{fontSize:"5vw"}}>Course Thumbnail</h3>
            
            <p style={{fontSize:'3vw'}} onClick={openUploadPopDesktop} class="borel hover:text-blue-800 cursor-pointer">Upload Image</p>
      </div>
        </label>
    <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
    
    </div> :
    
 <div></div>
    
    }
    
    
    
    
                          </div>
    
                    </div>
    
                        </div>
                      </div>
            <div className='mx-auto' style={{width:"95%"}}>
                      {/* <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-2 bi bi-filter-square" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M6 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                    </svg>Organize your curriculum</h1> */}
          
    
    {/* <div className='mt-12 mb-5'>
    <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
    </svg>Set up your course pricing</h1>
    
    </div>
    
    <div className='border ' style={{width:"90%"}}>
      <div className='flex justify-between w-full items-center px-5 pt-5'>
        <h1 className=' text-center anton capitalize  'style={{fontSize:"5vw"}}></h1>
        <p className='Mooli cursor-pointer hover:underline'>Need Help</p>
      </div>
    <div className='flex flex-col justify-between w-full items-center'>
    <h1 className='fjalla text-center text-gray-900 mt-5' style={{fontSize:"5vw"}}>Our flexible pricing plans let you choose whether to charge for your course or make it free. </h1>
    <p className='borel text-gray-500 text-center'style={{fontSize:"15px"}}>Your course's pricing plan has not been set</p>
        
        <button class=" m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                Add Pricing Plan 
              </button>
  
              
    
    </div>
    </div> */}
     <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Completion Time </h3>
                         {
                          editIconCompletionTime == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{completionTime}</p>
  
                         }
           
                         </div>
                         {
                          editIconCompletionTime == false &&
                          <svg style={{width:'5vw'}} id="editIconDesktop" onClick={editCompletionTimeOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconCompletionTime == true &&
                          <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editCompletionTimeOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconCompletionTime == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>

       <input type='text'  onChange={e=>completionTimeSet(e)} defaultValue={completionTime}  className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"3vw",paddingBottom:"3vw",paddingLeft:"4vw",fontSize:"3vw",width:"100%"}}   />
             <div className={`${completionTimeError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Compilation Time  can't be empty

</div>
</div>
</div> 
           
                              <button style={{fontSize:'3vw'}} onClick={updateCompletionTime} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Course Level </h3>
                         {
                          editIconCourseLevel == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{courseLevel}</p>
  
                         }
           
                         </div>
                         {
                          editIconCourseLevel == false &&
                          <svg  style={{width:'5vw'}} id="editIconDesktop" onClick={editCourseLevelOpenerDesktop} xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconCourseLevel == true &&
                          <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editCourseLevelOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                          </div>
                          {
                          editIconCourseLevel == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>
         <select style={{paddingRight:'4vw',fontSize:"3vw",width:"100%"}} onChange={e=> courseLevelSet(e)} type="text" class="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md">
                 <option className='skill__input-option'value="">Select Group</option>
                           <option className='skill__input-option' value="Beginner  ">Beginner  </option>
  <option className='skill__input-option' value="Intermediate ">Intermediate </option>
  
  
                           <option className='skill__input-option'value="Advanced">Advanced</option>
  
  
  
  
  
  </select> 
     <div className={`${courseLevelError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Course Level can't be empty

</div>
</div>
</div> 
              
                              <button style={{fontSize:'3vw'}} onClick={updateCourseLevel} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
   <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Intended Age Group </h3>
                         {
                          editIconIntendedAgeGroup == false &&
           <p className="borel text-gray-500 block" style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{intendedAgeGroup}</p>
  
                         }
           
                         </div>
                         {
                          editIconIntendedAgeGroup == false &&
                          <svg style={{width:'5vw'}}id="editIconDesktop" onClick={editIntendedAgeGroupOpenerDesktop} xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                         }
                       {
                          editIconIntendedAgeGroup == true &&
                          <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editIntendedAgeGroupOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>
  
                       } 
                       
                          </div>
                          {
                          editIconIntendedAgeGroup == true &&
                          <div className="relative mx-5 " id='editInputDesktop'>
                
           <select style={{paddingRight:'4vw',fontSize:"3vw",width:"100%"}} onChange={e=> ageGroupSet(e)} type="text" class="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md">
                           <option className='skill__input-option'value="">Select Group</option>
                           <option className='skill__input-option' value="Elementary School ">Elementary School </option>
  <option className='skill__input-option' value="High School ">High School </option>
  
  
                           <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
  <option className='skill__input-option' value="Postgraduate">Postgraduate</option>
  <option className='skill__input-option' value="Doctoral">Doctoral</option>
  
  
  
  
  
  
  
  </select> 
             
              <div className={`${intendedAgeGroupNewError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Intended Age Group can't be empty

</div>
</div>
</div> 
                              <button style={{fontSize:'12px'}} onClick={updateintendedAgeGroup} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
              
              Save
            </button>
                        </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
  <div className='border rounded m-auto mt-8' >
                    
                    <div className='flex justify-between px-5 py-5 w-full '>
                   <div className='w-full'>
                    <div className='flex justify-between w-full'>
                   <h3 class="  home_description-header pb-5 " style={{fontSize:"5vw"}}>Course Description </h3>
  
                    {
                      editIconDescription == false &&
                      <svg style={{width:'5vw'}} id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil " viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                    
                   }
                   
                    </div>
     {
                      editIconDescription == false &&
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{courseDescription}</p>
      
     }
                   </div>
               
               
              {
                      editIconDescription == true &&
  
                      <p style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>
  
              }
                    </div>
                    {
                      editIconDescription == true &&
                      <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
    
    <textarea
  style={{
    padding: '3vw 3.5vw',
    fontSize: '3vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  ref={textRef}
  onChange={e => courseDescriptionSet(e)}
  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
  defaultValue={courseDescription}
></textarea>
<p className='fjalla' style={{fontSize:'3vw'}}> {courseDescriptionNew && courseDescriptionNew.length + ' / 1,000'}</p>
  <div className={`${courseDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
  Course Description can't be empty
</div>
</div>
</div> 
                      <button style={{fontSize:'3vw'}} onClick={updateCourseDescription} class="ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                            
                            Save
                          </button>
                      </div>
                    }
             
   
                    <div>
                
                    </div>
                            
                  </div>
                  <div className='border rounded my-8' >
                          <div className='flex justify-between items-center px-5 py-5 w-full'>
                         <div className='w-full'>
            <div className='flex justify-between w-full items-center'>
            <h3 class="  home_description-header pb-4 " style={{fontSize:"5vw"}}>Course Skills </h3>
                         {
                         courseSkills.length != 0  &&
                   
                          <p style={{fontSize:"3vw"}} className='fjalla   cursor-pointer text-red-900' onClick={editResetSkillDesktop} id='cancelInputDesktop'>Remove</p>
  
                         }
            </div>
                         {
                          editIconSkills == false &&
                          <div className='gridCourseSkill'>
                          {
                           courseSkills && courseSkills.map(i=>{
                              return(
                                <span style={{fontSize:"3vw",rowGap:'5px',paddingLeft:'5px',paddingRight:'5px',paddingTop:'5px',paddingBottom:'5px'}} class=" capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>
                
                              )
                            })
                          }
                         </div>
  
  
                         }
           
                         </div>
                
                         
                       {
                        courseSkills.length == 0  &&
                         ( editIconSkills == false   ?
  
                          <p style={{fontSize:"3.5vw"}} className='fjalla  cursor-pointer hover:text-red-800' onClick={editAddSkillDesktop} id='cancelInputDesktop'>Add</p>
                        :
                          <div className='flex'>
                                                  <p style={{fontSize:"3vw"}} className='fjalla  cursor-pointer hover:text-red-800 mr-2' onClick={editCancelAddSkillDesktop} id='cancelInputDesktop'>Cancel</p>
  
  <p style={{fontSize:"3vw"}} className='fjalla  cursor-pointer' onClick={editUpdateSkillDesktop} id='cancelInputDesktop'>Save</p>
  </div>
                        )
                       } 
                          </div>
                          {
                          editIconSkills == true &&
                          <div class="relative m-auto mb-10">
                        <p style={{fontSize:"3vw"}} className='fjalla text-center mb-2 text-blue-800'  id='cancelInputDesktop'>After writing the skill, press Enter</p>
  
                        <TagsInput
 inputProps={{
     placeholder: 'Gain knowledge of database components',
     
     className: 'tagClass text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
 }}
 tabindex="5"
 className=' text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
 maxTags={10}
 value={courseSkillsNew}
 onChange={handleChangeCourseSkills}
 renderTag={({ tag, key, disabled, onRemove }) => (
     <span key={key} style={{ color: 'white',margin:"2vw" }} className=' py-2 skillGrid  bg-blue-800'>
         <p className='fjalla ' style={{fontSize:'3vw'}}>{tag}</p>
         {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer'>X</span>}
     </span>
 )}
/>

                          
                          {courseSkillsError == 'error' &&  
                          <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                          <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                          </svg>
                          <div className='Mooli' style={{fontSize:'3vw'}}>
                          Course Skills can't be empty
                          </div>
                          </div>
                          } 
                          </div>
                          }
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
    
                      </div>
                    
            </div>
                    </div>
                    )
    
                }
                )} 
            </div>
          <div id="tablet">
         
       <DashboardNav/>
    
  {
    thumbnailUpload == true &&
    <div id='uploadFileDesktop' className="uploadFileDesktop h-screen w-screen flex justify-center">
  
  
  
    {
      progress != 100 & progress == 0 ?
      <main className=" container mx-auto    " style={{width:'80%',height:'90%'}}>
  
      {/* file upload modal */}
      <article
        aria-label="File Upload Modal"
        className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDragEnter={dragEnterHandler}
      >
    <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload thumbnail</p>
  
        {/* overlay */}
        <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
  
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
            <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input"  className="hidden" onChange={handleFileSelect} />
            <button
              className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input').click();
              }}
            >
              Upload a file
            </button>
            <p className='Mooli text-gray-500'>Supported file types include .png .jpg .jpeg</p>
  
          </header>
              <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
  
          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
  
          <ul className="flex justify-evenley  -m-1">
            {files.length === 0 ? (
              <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                <img
                  className="mx-auto w-32"
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt="no data"
                />
                <span className="text-small text-gray-500">No files selected</span>
              </li>
            ) : (
                <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
                  <img
                  style={{maxHeight:"150px"}}
                    className="mx-auto w-32"
                    src={URL.createObjectURL(files)}
                    alt={files.name}
                    onClick={() => removeFile()}
                  />
                  <span className="text-small text-gray-500">{files.name}</span>
                </li>
            )}
          </ul>
        </section>
         
        {/* sticky footer */}
        <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
        <div className="flex justify-evenly mt-10 mb-10">
          <button
            type="submit"
            onClick={minimizeUploadImg}
            style={{ width: '20%' }}
            className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Back
          </button>
          <button
            onClick={uploadFile}
            type="submit"
            style={{ backgroundColor: '#2141ce', width: '20%' }}
            className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Upload
          </button>
        </div>
      </article>
    </main>
    
    :
    progress == 100 ? <div style={{width:'80%',height:'90%'}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           <div class="">
           <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
           <p className='anton  text-center' style={{fontSize:"35px"}}>Thumbnail Successfully Uploaded</p>
           <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
       </div>
       
             </div>
             :
             <div style={{width:'80%',height:'90%'}} className=' flex items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           
           <div class="relative">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
           <p className='fjalla mt-4 text-center'>Uploading</p>
           <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
       </div>
       
             </div>}
   
  
  </div>
  }
    
    {
    trailerUpload == true &&
  <div id='uploadTrailerDesktop' className="uploadFileDesktop  flex items-center justify-center ">
  
  
  
    {
      progress != 100 & progress == 0 ?
      <main className="mx-auto   " style={{width:'80%',height:'90%'}}>
  
      {/* file upload modal */}
      <article
      
        aria-label="File Upload Modal"
        className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
    
      >
    <p className='fjalla text-gray-800 pl-5 pt-5 capitalize' style={{fontSize:"25px"}}>Upload Trailer</p>
  
        {/* overlay */}
        <div id="overlay" className="absolute inset-0 bg-gray-200 opacity-50 hidden"></div>
  
        <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
          <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
         
            <input accept="video/mp4, video/quicktime, video/mov, video/avi" type="file" id="hidden-input-trailer"  className="hidden" onChange={handleTrailerSelect} />
            <button
              className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-trailer').click();
              }}
            >
              Upload a file
            </button>
            <p className='Mooli text-gray-500'>Supported file types include .mp4, .mov, and .avi</p>
            
  
          </header>
              <p className='Mooli text-red-800 capitalize'>{fileMesssage}</p>
  
          <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">To Upload</h1>
  
          <ul className="flex justify-evenley  -m-1">
            {trailer.length === 0 ? (
              <li className="h-full w-full text-center flex flex-col items-center justify-center" key="empty">
                <img
                  className="mx-auto w-32"
                  src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                  alt="no data"
                />
                <span className="text-small text-gray-500">No files selected</span>
              </li>
            ) : (
                <li  className="h-full border mx-1 my-1 w-full text-center flex flex-col items-center justify-center" >
               
               <div class="video-container">
                  <video controls >
    <source  
    type="video/mp4"
    
    className="mx-auto "
    src={URL.createObjectURL(trailer)}

    alt={trailer.name}
    onClick={() => removeFile()} ></source>
    Your browser does not support the video tag.
  </video>
  </div>
                  <span className="text-small text-gray-500">{trailer.name}</span>
                </li>
            )}
          </ul>
        </section>
  
        {/* sticky footer */}
        <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
        <div className="flex justify-evenly mt-10 mb-10">
          <button
            type="submit"
            onClick={minimizeUploadTrailer}
            style={{ width: '20%' }}
            className="bg-red-800 m-auto w-fullflex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Back
          </button>
          <button
            onClick={uploadTrailer}
            type="submit"
            style={{ backgroundColor: '#2141ce', width: '20%' }}
            className="m-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-red-600 ease cursor-pointer"
          >
            Upload
          </button>
        </div>
      </article>
    </main>
    
    :
    progress == 100 ? <div style={{width:'80%',height:'90%'}} className='relative bg-white flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           <div class="">
           <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
           <p className='anton  text-center' style={{fontSize:"35px"}}>Trailer Successfully Uploaded</p>
           <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
       </div>
       
             </div>
             :
             <div style={{width:'80%',height:'90%'}} className=' flex flex-col items-center justify-center my-5 rounded m-auto bg-white' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="w-full bg-neutral-200">
          <div
            class={`${progress == 0 && 'hidden'} ${progress != 0 && 'bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
            style={{ width: progress + '%' }}
          >
            {Math.round(progress)} %
          </div>
        </div>
           <div class="relative mt-5">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
           <p className='fjalla mt-4 text-center'>Uploading</p>
       </div>
       
             </div>}
   
  
  </div>
  }
  {
    testOpen == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
    <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
    <div className='py-5 flex w-full justify-between items-center'>
    <div style={{paddingLeft:"50px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>Prepare a test for this Course</h1>
        <p className='borel text-gray-500' style={{fontSize:'12px'}}>It is necessary for your students to attain at least 75% to complete the course</p>
        
        </div>
    
       
    
    
    
    
                  <svg onClick={backtestDesktop}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
    
    <div className='flex '>
    
      <div className='mt-10 w-1/2 mx-3' style={{marginTop:"5%"}}>
      <div className="relative " >
      <div className="relative  " >
    
    <p style={{fontSize:'12px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Number of questions</p>
    
    
    <select style={{fontSize:'13px'}} onChange={e=>questionsSet(e)} type="text" class="fjalla mx-2 border  focus:outline-none
      focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
      border-gray-300 rounded-md">
                                  <option className='skill__input-option'value="">Select Field</option>
    
    
                       
                                  <option className='skill__input-option' value="10">10</option>
    
                                  <option className='skill__input-option' value="15">15</option>
                                  <option className='skill__input-option' value="20">20</option>
                                  <option className='skill__input-option' value="25">25</option>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    </select> 
    <div className={`${questionsError == "error" ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    This field Can't be empty
    </div>
    </div>
    </div>
     
    </div>
    <Link to={`${questions == '' ? '' :`/course/${courseId}/test/${courseId}/${questions}`}`}>
       <button style={{fontSize:'13px'}} type='submit' onClick={()=>questions == '' && setquestionsError('error')}  className="fjalla mt-5 bg-blue-800 w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5 text-xl font-medium text-center text-white 
           rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Prepare test
       </button>
       </Link>
     </div>
      </div>
    
    
      <img style={{width:"40.5%",marginTop:"-3%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Exams-bro%20(1).svg?alt=media&token=d83b0fda-0cc5-4924-9843-1a16cb33c559" alt="Prepare test" />
    
    </div>
      
    
    
    </main>
    
    </div>
  }


{
 courseTrailerDeletedMessage == true &&
 <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div class="ml-3 text-sm font-medium Mooli capitalize">
 You have successfully removed your course trailer  
 </div>
 <button onClick={closeMessage55Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
   <span class="sr-only">Dismiss</span>
   <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </button>
</div>
}
{
 courseSkillsDeleteMessage == true &&
 <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <div class="ml-3 text-sm font-medium Mooli capitalize">
 You have successfully removed your course trailer 
 </div>
 <button onClick={closeMessage800Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
   <span class="sr-only">Dismiss</span>
   <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
   </svg>
 </button>
</div>
}
{
  courseTrailerMessage == true &&
  <div id="alert-border-5" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
      <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div class="ml-3 text-sm font-medium Mooli capitalize">
       Your course trailer has been successfully updated 
      </div>
      <button onClick={closeMessage5Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
        <span class="sr-only">Dismiss</span>
        <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>
  </div>
}

{
  courseTitleMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course title has been successfully updated 
  </div>
  <button onClick={closeMessageDesktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  completionTimeMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's completion Time  has been successfully updated 
  </div>
  <button onClick={closeMessage1000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseIntendedAgeGroupMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's Age Group  has been successfully updated 
  </div>
  <button onClick={closeMessage10000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseSkillsAddedMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course's Skills has been successfully updated 
  </div>
  <button onClick={closeMessage8000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseDescriptionMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Description has been successfully updated 
  </div>
  <button onClick={closeMessage900Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
 courseLevelMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Level has been successfully updated 
  </div>
  <button onClick={closeMessage9000Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}

{
  courseSubjectMessage == true &&
  <div id="alert-border-3" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course Subject has been successfully updated 
  </div>
  <button onClick={closeMessage100Desktop}type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
  <div className={`${courseError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {courseError}
  </div>
  </div>
  </div>

  <div className={`${courseThumbnailError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {courseThumbnailError}
  </div>
  </div>
  </div>

  <div className={`${lectureLengthError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {lectureLengthError}
  </div>
  </div>
  </div>

  <div className={`${trailerError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {trailerError}
  </div>
  </div>
  </div>
  <div className={`${testError.length !=0 ? "block":"hidden"}`}>
  <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div className='Mooli'>
  {testError}
  </div>
  </div>
  </div>
{
  courseThumbnailMessage == true &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Your course thumbnail has been successfully Updated 
  </div>
  <button onClick={closeMessage90Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
{
  courseThumbnailDeletedMessage == true &&
  <div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
    Your Course Thumbnail Has Been Successfully Removed
  </div>
  <button onClick={closeMessage2Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
 
{
  lectureDeleteMessage == true &&
  <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   lecture has been successfully removed 
  </div>
  <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
}
 {
  thumbnailDelete && 
  <div id='uploadCancelDesktop' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={removepopdesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Thumbnail </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove the thumbnail? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={deleteThumbnailDesktop}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={removepopdesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
              {
                trailerDelete == true &&
                <div id='uploadCancelTrailerDesktop' className=' flex items-center justify-center'>
         
       
                <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
       <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
       
       >
       <div class="relative p-6">
        <a onClick={removepopTrailerdesktop}   class="absolute top-1.5 right-1.5">
            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
                
        </a>
        <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Remove Trailer </h1>
        <p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently remove the trailer? </p>
        
        <div class="flex flex-row mt-6 space-x-2 justify-evenly">
            <a onClick={deleteTrailerDesktop}  class="w-full py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
            <a onClick={removepopTrailerdesktop}   class="w-full py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
        </div>
       </div>
       
       </div>
       </div>  
       
       
            </div>
              }
       
  {
    lectureDelete == true &&
    <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
         
       
    <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
    
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Lecture </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently delete this Lecture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
  }
      
  
             {course.map((i)=>{
                  const { isTest,courseTitle,courseThumbnail,courseTrailer,courseDescription,courseSubject,courseSkills,courseLevel,completionTime,intendedAgeGroup} = i
                  return(
                    <div className='relative'>
                      <div className={`${course.length != 0 ? "hidden":"block"}`}>
  
                           <div  className=' flex items-center justify-center  rounded m-auto bg-white'  id='loadingLoadedDesktop' style={{width:"100%",height:"100%",position:"fixed",top:0,left:0,zIndex:100}}>
           
           <div class="relative">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
       </div>
       
             </div>
             </div>
  
  
    <div class="w-full bg-neutral-200 " >
  
    <div
      class={`${progress == 0 && "hidden"} ${progress !=0 && "bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white"}`}
      style={{width:progress + "%",}}>
      {Math.round(progress)} %
    </div>
  
  </div>
  
  
  <div class="flex items-center justify-end mb-8">
  {/* <h1 className='fjalla mt-5 ml-5' style={{fontSize:"30px"}}>Course setup</h1> */}
  
           <div  className='flex  items-center justify-end mx-2'>
           <div className='flex   items-center    pt-3' >
  <Link to={`/course/${courseId}/certificate/${courseId}`}>
  <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 ' id='twelve'>certificates</h1>
  </Link>
          <Link to={`/course/${courseId}/pricing`}>   <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>pricing</h1></Link> 
  <Link to={`/course/${courseId}/students`}>

              <h1 style={{fontSize:"12px"}}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>students</h1>
  </Link>
            
              <h1 style={{fontSize:"12px"}}onClick={()=>opentestDesktop(isTest)}className='capitalize fjalla text-gray-800 mx-2 cursor-pointer hover:text-blue-800 '>Test</h1>
  
         </div>
    <div className='flex items-center'>
  <Link to={`/course/${courseId}/preview`}>
  
    <button style={{fontSize:"13px"}} class="inline-flex px-5 py-2 mt-5 text-blue-600 mx-2 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
       Preview
         
            </button>
  </Link>

  {
    status != 'published'?

    <button  style={{fontSize:"12px"}}onClick={changetopublicDesktop} class=" w-full px-5 py-2  mt-5 text-green-600 hover:text-green-700 focus:text-green-700 hover:bg-green-100 focus:bg-green-100 border border-green-600 rounded-md mb-3">
        
    Publish Course
  </button>
  :

  <button  style={{fontSize:"12px"}}onClick={changetopublicDesktop} class=" w-full px-5 py-2  mt-5 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
  Unpublish Course
</button>
  }
  
 
    </div>
           </div>
  
          </div><div className='flex flex-col justify-between '>
          <div className=' mx-auto' style={{width:"95%"}}>
              
          <Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}> <button style={{fontSize:"12px"}} id='twelve' class=" mr-5 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
           
           Add Lecture

         </button>
         </Link>
   
               <table class="w-full text-sm text-left text-gray-500   border mt-5 mb-10">
       <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
           <tr>
             <th scope="col" class="px-6 py-3 Mooli">
               </th>
               <th scope="col" class="px-6 py-3 Mooli">
                   Lecture
               </th>
              
           
             
           
               <th scope="col" class="px-6 py-3 Mooli">
               PDF
               </th>
               <th scope="col" class="px-6 py-3 Mooli">
               Audio

               </th>
               <th scope="col" class="px-6 py-3 Mooli">
               Quiz

               </th>
               <th scope="col" class="px-6 py-3 Mooli">
               Action

               </th>

           </tr>
       </thead>
       <tbody>
{
lectures.map(({ lectureTitle, lectureId, lectureOrder,lectureAudio, id ,lecturePdf,lectureQuiz}, index) => {
 return (
   <tr className="bg-white border-b" key={index}>
     <td className="px-6 py-4 capitalize Mooli">
       <ol>
         <li>{index + 1}</li>
       </ol>
     </td>
     <td className="flex px-6 py-4 capitalize">
       <Link to={`lecture/${lectureId}`}>
         <p className="cursor-pointer Mooli underline text-gray-800">{lectureTitle}</p>
       </Link>
     </td>
     <td className="px-6 py-4">
    {!lecturePdf  ?    <img
         style={{ width: "20px" }}
         src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
         alt=""
       /> :
       <img
       style={{ width: "20px" }}
       src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
       alt=""
     />
       }
     </td>
     <td className="px-6 py-4 text-center">
   {
       lectureAudio  ? 
       <img
       style={{ width: "20px" }}
       src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
       alt=""
     />
     :
     <img
     style={{ width: "20px" }}
     src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
     alt=""
   /> 
    }
     </td>
     <td className="px-6 py-4 text-center">
   
       {
         lectureQuiz == true ? 
         <img
         style={{ width: "20px" }}
         src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
         alt=""
       />
       :
       <img
       style={{ width: "20px" }}
       src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
       alt=""
     /> 
       }
     </td>
     <td className="px-6 py-4 fjalla relative">
       <svg
         onClick={() => deleteLectureDesktop(id)}
         xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         fill="currentColor"
         className="ml-3 hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
         viewBox="0 0 16 16"
       >
         <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
       </svg>
     </td>
   </tr>
 )
})
}
</tbody>

</table>

{
 lectures.length  == 0 &&
 <div className='w-full text-center fjalla capitalize text-red-800' style={{fontSize:"20px"}}>
 Your course does not have any lectures 
 <p className="fjalla text-gray-500 normal-case" style={{fontSize:"12px"}}>Make sure to create lectures</p>
</div> 

}
                      {/* <h1 className='pl-5 pb-2   anton text-blue-800 flex  items-center text-center capitalize' style={{color:"blue",fontSize:"25px"}}>
                  Customize your course</h1> */}
                      <div className='border rounded mt-10 ' >
                        <div className='flex justify-between px-5 py-5 '>
                       <div>
                       <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Title </h3>
                       {
                        editIconTitle == true &&
         <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseTitle}</p>

                       }
         
                       </div>
                       {
                        editIconTitle == true &&
                        <svg id="editIconDesktop" onClick={editInputOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                       }
                     {
                        editIconTitle == false &&
                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editTitleOpenerDesktop} id='cancelInputDesktop'>Cancel</p>

                     } 
                        </div>
                        {
                        editIconTitle == false &&
                        <div className="relative mx-5 " id='editInputDesktop'>
                        <input style={{fontSize:'12px'}}  onChange={e=>courseTitleSet(e)}  required  autoComplete="off"  type="text" className="border  focus:outline-none
                            focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                            border-gray-300 rounded-md" defaultValue={courseTitle}/>
            <div className={`${courseTitleError == "error" ? "block": "hidden"}`}>
                                           <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
          Course Title can't be empty
        </div>
        </div>
      
            </div>
                            <button style={{fontSize:'12px'}} onClick={updateCourseTitle} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
            Save
          </button>
                      </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>

                      <div className='border rounded mt-6' >
                        <div className='flex justify-between px-5 py-5 '>
                       <div>
                       <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Subject </h3>
                       {
                        editIconSubject == false &&
         <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseSubject}</p>

                       }
         
                       </div>
                       {
                        editIconSubject == false &&

                        <svg id="editIconDesktop" onClick={editSubjectOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                       }
                     {
                        editIconSubject == true &&

                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editSubjectOpenerDesktop} id='cancelInputDesktop'>Cancel</p>

                     } 
                        </div>
                        {
                        editIconSubject == true &&

                        <div className="relative mx-5 " id='editInputDesktop'>
                        <input style={{fontSize:'12px'}}  onChange={e=>courseSubjectSet(e)}  required  autoComplete="off"  type="text" className="border  focus:outline-none
                            focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                            border-gray-300 rounded-md" defaultValue={courseSubject}/>
            <div className={`${courseSubjectError == "error" ? "block": "hidden"}`}>
                                           <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
          Course Subject can't be empty
        </div>
        </div>
      
            </div>
                            <button style={{fontSize:'12px'}} onClick={updateCourseSubject} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
            Save
          </button>
                      </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>
                      <div>
                      <div className=' border mt-6 px-5 rounded'>
                  <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>

  
                  {
  
  
  courseTrailer  ? 
  <div class="relative flex flex-col items-center w-full">
      <label for="dropzone-file" class="w-full    ">
    <div className="flex justify-between w-full py-4 items-center">
    <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
          
          <p onClick={openpopTrailerdesktop} class="fjalla text-red-800 hover:text-red-700 cursor-pointer" style={{fontSize:'12px'}}>Remove Trailer</p>
    </div>
      </label>

{
  courseTrailer.length != 0 ? (
    <div className="video-player-container mx-auto mb-20 rounded-md" >
    <video ref={videoRef} className="video-player rounded-md" style={{maxHeight:'250px'}}poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658' >
      <source src={courseTrailer}  type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    {showPlayButton && 
    <div className="play-button-overlay" onClick={handlePlay}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
<path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
    </div>
  }
  </div>
   
  ) : (
    <div className="bg-white flex items-center justify-center">
      <div className="relative">
        <div className="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
        <div className="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div>
    </div>
  )
}
    
   
  </div>
  
  :
    <div class="flex flex-col items-center justify-center">
      <label for="dropzone-file" class="w-full    ">
    <div className="flex justify-between w-full py-4 items-center">
    <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
          
          <p onClick={openUploadTrailerPopDesktop} class="borel hover:text-blue-800 cursor-pointer" style={{fontSize:'12px'}}>Upload Video</p>
    </div>
      </label>
  <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
  
  </div> 
  

  
  }
  
  
  
  
                        </div>
  
                  </div>






                  <div className=' border mt-6 px-5 rounded'>
                  <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>

  
                  {
  
  
  courseThumbnail  ? 
  <div class="relative flex flex-col items-center w-full ">
      <label for="dropzone-file" class="w-full    ">
    <div className="flex justify-between w-full py-4 items-center">
    <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Thumbnail</h3>
          
          <p style={{fontSize:'12px'}} onClick={openpopDeletedesktop} class="fjalla text-red-800 hover:text-red-700 cursor-pointer">Remove Thumbnail</p>
    </div>
      </label>
     
  <img onLoad={checkthumbnailLoad}   class="h-auto w-full rounded-lg" src={courseThumbnail} alt="image description"/>
   {
        thumbnailLoad == true &&
<div class=" bg-white flex items-center justify-center" id='loadThumbNail'>
      <div className='relative'>
      <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div> 
      </div>
   }
     
  </div>
  
  :
    courseThumbnailImg.length == 0  ? 
    <div class="flex flex-col items-center justify-center">
      <label for="dropzone-file" class="w-full    ">
    <div className="flex justify-between items-center w-full py-4">
    <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Thumbnail</h3>
          
          <p style={{fontSize:'12px'}} onClick={openUploadPopDesktop} class="borel hover:text-blue-800 cursor-pointer">Upload Image</p>
    </div>
      </label>
  <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
  
  </div> :
  
  <div className='w-full '>
  <div class="flex   flex-col items-center justify-center m-auto w-full" >
      
      <label for="dropzone-file" class="text-center relative flex flex-col items-center justify-center w-full   border-gray-300  rounded-lg   ">
      {
          progress != 100 & progress == 0 ?  <div className='flex flex-col items-center'>
          <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          <p className='fjalla mb-2  w-full text-center'>
          Video Name : {courseThumbnailImg.name}
          </p>
           
          <button onClick={openpopdesktop} class=" inline-flex px-5 py-3 text-white bg-blue-700 hover:bg-blue-800 focus:bg-blue-700 rounded-md  mb-3">
                   
                      Upload Thumbnail
                    </button>
                    <p className='fjalla text-center '>
           
              </p> 
          </div> 
          :
           progress == 100 ? <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           <div class="">
           <svg onClick={cancelUploadDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
           <p className='fjalla  text-center'>File Successfully Uploaded</p>
       </div>
       
             </div>
             :
             <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
           
           <div class="relative">
           <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
           <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
           <p className='fjalla mt-4 text-center'>Uploading</p>
       </div>
       
             </div>
      }
  
  
  
      </label>
  </div> 
  </div> 
  
  }
  
  
  
  
                        </div>
  
                  </div>
  
                      </div>
                    </div>
          <div className='mx-auto' style={{width:"95%"}}>
                    {/* <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-2 bi bi-filter-square" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="M6 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                  </svg>Organize your curriculum</h1> */}
        
  
  {/* <div className='mt-12 mb-5'>
  <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
  </svg>Set up your course pricing</h1>
  
  </div>
  
  <div className='border ' style={{width:"90%"}}>
    <div className='flex justify-between w-full items-center px-5 pt-5'>
      <h1 className=' text-center anton capitalize  'style={{fontSize:"20px"}}></h1>
      <p className='Mooli cursor-pointer hover:underline'>Need Help</p>
    </div>
  <div className='flex flex-col justify-between w-full items-center'>
  <h1 className='fjalla text-center text-gray-900 mt-5' style={{fontSize:"20px"}}>Our flexible pricing plans let you choose whether to charge for your course or make it free. </h1>
  <p className='borel text-gray-500 text-center'style={{fontSize:"15px"}}>Your course's pricing plan has not been set</p>
      
      <button class=" m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
              Add Pricing Plan 
            </button>

            
  
  </div>
  </div> */}
   <div className='border rounded mt-8 ' >
                        <div className='flex justify-between px-5 py-5 '>
                       <div>
                       <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Completion Time </h3>
                       {
                        editIconCompletionTime == false &&
         <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{completionTime}</p>

                       }
         
                       </div>
                       {
                        editIconCompletionTime == false &&
                        <svg id="editIconDesktop" onClick={editCompletionTimeOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                       }
                     {
                        editIconCompletionTime == true &&
                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editCompletionTimeOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>

                     } 
                        </div>
                        {
                        editIconCompletionTime == true &&
                        <div className="relative mx-5 " id='editInputDesktop'>
      <input onChange={e=>completionTimeSet(e)} defaultValue={completionTime}  required  autoComplete="off"  type="text" className="border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />
            <div className={`${completionTimeError == "error" ? "block": "hidden"}`}>
                                           <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
          Compilation Time  can't be empty
        </div>
        </div>
      
            </div>
                            <button style={{fontSize:'12px'}} onClick={updateCompletionTime} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
            Save
          </button>
                      </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>
                      <div className='border rounded mt-8 ' >
                        <div className='flex justify-between px-5 py-5 '>
                       <div>
                       <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Level </h3>
                       {
                        editIconCourseLevel == false &&
         <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseLevel}</p>

                       }
         
                       </div>
                       {
                        editIconCourseLevel == false &&
                        <svg id="editIconDesktop" onClick={editCourseLevelOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                       }
                     {
                        editIconCourseLevel == true &&
                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editCourseLevelOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>

                     } 
                        </div>
                        {
                        editIconCourseLevel == true &&
                        <div className="relative mx-5 " id='editInputDesktop'>
        <select onChange={e=> courseLevelSet(e)} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
               <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Beginner  ">Beginner  </option>
<option className='skill__input-option' value="Intermediate ">Intermediate </option>


                         <option className='skill__input-option'value="Advanced">Advanced</option>





</select> 
            <div className={`${courseLevelError == "error" ? "block": "hidden"}`}>
                                           <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
          Course Level can't be empty
        </div>
        </div>
      
            </div>
                            <button style={{fontSize:'12px'}} onClick={updateCourseLevel} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
            Save
          </button>
                      </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>
 <div className='border rounded mt-8 ' >
                        <div className='flex justify-between px-5 py-5 '>
                       <div>
                       <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Intended Age Group </h3>
                       {
                        editIconIntendedAgeGroup == false &&
         <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{intendedAgeGroup}</p>

                       }
         
                       </div>
                       {
                        editIconIntendedAgeGroup == false &&
                        <svg id="editIconDesktop" onClick={editIntendedAgeGroupOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                       }
                     {
                        editIconIntendedAgeGroup == true &&
                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editIntendedAgeGroupOpenerMinimizeDesktop} id='cancelInputDesktop'>Cancel</p>

                     } 
                        </div>
                        {
                        editIconIntendedAgeGroup == true &&
                        <div className="relative mx-5 " id='editInputDesktop'>
                 <select onChange={e=> ageGroupSet(e)} type="text" class="mx-2 border  focus:outline-none
focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md">
                         <option className='skill__input-option'value="">Select Group</option>
                         <option className='skill__input-option' value="Elementary School ">Elementary School </option>
<option className='skill__input-option' value="High School ">High School </option>


                         <option className='skill__input-option'value="Undergraduate">Undergraduate</option>
<option className='skill__input-option' value="Postgraduate">Postgraduate</option>
<option className='skill__input-option' value="Doctoral">Doctoral</option>







</select> 
            <div className={`${intendedAgeGroupNewError == "error" ? "block": "hidden"}`}>
                                           <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span className="sr-only">Info</span>
        <div className='Mooli'>
          Intended Age Group can't be empty
        </div>
        </div>
      
            </div>
                            <button style={{fontSize:'12px'}} onClick={updateintendedAgeGroup} class="inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
            Save
          </button>
                      </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>

<div className='border rounded m-auto mt-8' >
                  
                  <div className='flex justify-between px-5 py-5 w-full '>
                 <div className='w-full'>
                  <div className='flex justify-between w-full'>
                 <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Course Description </h3>

                  {
                    editIconDescription == false &&
                    <svg id="editIconDescripionDesktop" onClick={editInputDescripionOpenerDesktop} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil " viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                  
                 }
                 
                  </div>
   {
                    editIconDescription == false &&
   <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{courseDescription}</p>
    
   }
                 </div>
             
             
            {
                    editIconDescription == true &&

                    <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editDescripionOpenerDesktop} id='cancelDescripionInputDesktop'>Cancel</p>

            }
                  </div>
                  {
                    editIconDescription == true &&
                    <div id='editDescripionInputDesktop' className="relative m-auto"style={{width:"90%"}}>
  
                    <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>courseDescriptionSet(e)}  type="text" class="border  focus:outline-none
                                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                 border-gray-300 rounded-md" maxLength="1000" defaultValue={courseDescription}></textarea>

                                 <div className={`${courseDescriptionError == "error" ? "block":"hidden"}`}>
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className='Mooli'>
                     Description Can't be empty
                    </div>
                    </div>
                    </div>
                    <p className={courseDescriptionNew.length != 0 ? 'Mooli text-gray-500' : 'hidden'} style={{fontSize:'11px'}}>{courseDescriptionNew && courseDescriptionNew.length}/1,000</p>
                    <button style={{fontSize:'12px'}} onClick={updateCourseDescription} class="ml-10 inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                          
                          Save
                        </button>
                    </div>
                  }
           
 
                  <div>
              
                  </div>
                          
                </div>
                <div className='border rounded my-8' >
                        <div className='flex justify-between items-center px-5 py-5 w-full'>
                       <div className='w-full'>
          <div className='flex justify-between w-full items-center'>
          <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Skills </h3>
                       {
                       courseSkills.length != 0  &&
                 
                        <p className='fjalla   cursor-pointer ' onClick={editResetSkillDesktop} id='cancelInputDesktop'>Remove</p>

                       }
          </div>
                       {
                        editIconSkills == false &&
                        <div className='gridCourseSkill'>
                        {
                         courseSkills && courseSkills.map(i=>{
                            return(
                              <span style={{fontSize:"11px",rowGap:'5px',paddingLeft:'5px',paddingRight:'5px',paddingTop:'5px',paddingBottom:'5px'}} class="mx-1 mb-2 capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>
              
                            )
                          })
                        }
                       </div>


                       }
         
                       </div>
              
                       
                     {
                      courseSkills.length == 0  &&
                       ( editIconSkills == false   ?

                        <p className='fjalla  cursor-pointer hover:text-red-800' onClick={editAddSkillDesktop} id='cancelInputDesktop'>Add</p>
                      :
                        <div className='flex'>
                                                <p className='fjalla  cursor-pointer hover:text-red-800 mr-2' onClick={editCancelAddSkillDesktop} id='cancelInputDesktop'>Cancel</p>

<p className='fjalla  cursor-pointer' onClick={editUpdateSkillDesktop} id='cancelInputDesktop'>Save</p>
</div>
                      )
                     } 
                        </div>
                        {
                        editIconSkills == true &&
                        <div class="relative m-auto mb-10">
                      <p className='fjalla text-center mb-2'  id='cancelInputDesktop'>After writing the skill, press Enter</p>

                        <TagsInput
                         inputProps={{
                             placeholder: 'Gain knowledge of database components',
                             className: 'form-control text-black skillClass m-auto focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md',
                         }}
                         tabindex="5"
                         
                         className='form-control text-black border skillClass m-auto lowercase focus:outline-none focus:border-black w-full text-gray-700 block  border-blue-300 rounded-md'
                         maxTags={10}
                         value={courseSkillsNew}
                         onChange={handleChangeCourseSkills}
                         renderTag={({ tag, key, disabled, onRemove }) => (
                             <span key={key} style={{ color: 'white',margin:"3px",fontSize:'11px' }} className='px-5 py-2 skillGrid  bg-blue-800 fjalla' >
                                 {tag}
                                 {!disabled && <span onClick={() => onRemove(key)} className='cursor-pointer' style={{fontSize:'11px'}}>X</span>}
                             </span>
                         )}
                        />
                        
                        {courseSkillsError &&  
                         <p className={`${courseSkillsError == "" && "hidden"} ${courseSkillsError == "error" && "block"}`}>
                             <div class="fjalla flex rounded bg-danger-100 text-base text-danger-700 barel px-3 py-3" role="alert">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="25" fill="currentColor" class="mr-1 bi bi-bug" viewBox="0 0 16 16">
                                     <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
                                 </svg>Course Skills can't be empty
                             </div> 
                         </p>
                        } 
                        </div>
                        }
                   
                        <div>
                    
                        </div>
                                
                      </div>

  
                    </div>
                  
          </div>
                  </div>
                  )
  
              }
              )} 
          </div>
          </div>
          :
          navigate('/')
          :
          user ? navigate('/dashboard') : navigate('/')
           

         
          )
        })


        :
        <div>

        <div id='desktop' >

<div >
  

<div >
<div id='previewLoadingDesktop'>
<div class="relative">
<div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
<div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

</div>
</div>

<div id='courseDescriptionPreviewDesktop' >

{course.map(i=>{
const {courseThumbnail,courseLevel,courseSkills,certificate,courseTitle,instructor,courseTrailer,courseDescription,instructorId,intendedAgeGroup,coursePricing ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;

return(

<div className='flex relative'>
{showContent &&
<div className='shadow-xl flex justify-between bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
<h1 className='anton capitalize pl-10 pt-5 text-white' style={{fontSize:'30px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'13px'}}>{lecture.length} Lectures </p>
</h1>

<div className='flex items-center'>
   

<Link to={`/signin`} >

<button
 type='button'
 style={{fontSize:'11px'}}
 
 className='mt-5 mr-8 text-gray-900 fjalla bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
>
 Enroll Now
</button>
<p></p>
</Link>
</div>

</div>}
<div className='border-l ' style={{width:"100%",height:"100%"}}>

<div
style={{  
 height: '100vh',
 backgroundImage: `url('${courseThumbnail}')`,
 backgroundRepeat: 'no-repeat',
 backgroundSize: 'cover',
backgroundPosition: 'center' }}><div className="border-t  px-12 bg-black-main h-full" >
  <nav className='flex justify-between px-5 navDesktop'>
   

   <Link to="/" className='  ml-5' style={{width:"15%",objectFit:"cover"}}> 
 <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT%20(1)%20(1).png?alt=media&token=f4278e06-41c9-4737-adb8-84a38de7b544' alt="logo" /> 
   </Link>

   <ul className="nav__list flex w-full" style={{justifyContent:'end'}}>
<li className="nav__list-link" style={{padding:0}}>
<Link to="/">

<div className="nav__list--anchor" style={{fontSize:"12px",color:'white'}}>Home</div>
</Link></li>
<li className="nav__list-link" style={{padding:0}}>
<Link to="/aboutus">

<div className="nav__list--anchor" style={{fontSize:"12px",color:'white'}}>About Us</div>
</Link></li>
<li className="nav__list-link" style={{padding:0}}>
<Link to="/contact">

<div className="nav__list--anchor" style={{fontSize:"12px",color:'white'}}>Contact Us</div>
</Link></li>

<li className="nav__list-link" style={{padding:0}}>
<Link className='Link' to="/pricing/teacher"><div className="nav__list--anchor" style={{fontSize:"12px",color:'white'}}>Pricing</div></Link>
</li>





<li className="nav__list-link" style={{padding:0}}>
<Link className='Link' to="/signin"><div className="nav__list--anchor" style={{fontSize:"12px",color:'white'}}>Sign In</div></Link>
</li>
</ul>

</nav>

    <div className='flex justify-between items-center capitalize ' >
<h1 className='fjalla pl-10 pt-2 mb-5 flex items-center capitalize' style={{fontSize:"12px",color:'white'}}><Link to='/' className='underline'>Home</Link> 
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


{courseTitle}</h1>

</div>
<div className='flex items-center justify-between px-6' style={{marginTop:'60px'}}>
<div>
<h1 className='anton capitalize text-white'>{courseTitle}</h1>
<div>
{
Instructor.map(i=>{
const {fullName,uid,photo} = i
return(
<div className=' rounded px-5    mx-auto'>


<div className="  flex items-center " >
         

   
   
<div className='flex items-center justify-center '>
<p className='home_description-header mr-2' style={{fontSize:'18px',color:'white'}}>Course By : </p>
                
<Link to={`/user/${instructorId && instructorId}`}>
<div className='flex items-center text-left hover:text-blue-800'>
{
 photo.length == 0?
 <div
 className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
style={{width:"40px",height:"40px"}}
>
 {fullName && fullName[0]}
 

</div>
:
<img 
style={{width:"40px",height:"40px"}}

className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
src={photo}></img>
}

                       <p className='fjalla text-white'>{fullName}</p>

</div></Link>
</div>
                 </div>
</div>
)
})
}
</div>
 <div className='flex items-center'>
   
{
<Link to={`/signin`} >
<button
 type='button'
 style={{fontSize:'11px'}}
 
 className='mt-5 mr-8 fjalla text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-white Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
>
Enroll Now
</button>
<p></p>
</Link>
}

</div>
</div>



</div>

</div>
</div>
<div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
 <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

   <h1 className='anton text-white' style={{fontSize:'25px',color:'white'}}>
 {courseLevel}

   </h1>
   <p className='borel capitalize' style={{color:'white',fontSize:'13px'}}> The course is designed for {intendedAgeGroup} students  </p>
 </div>
 <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
   <h1 className='anton text-white' style={{fontSize:'25px',color:'white'}}>
 {completionTime} to complete
   </h1>
 </div>
 <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
   <h1 className='anton text-white' style={{fontSize:'28px',color:'white'}}>
 { coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

   </h1>
   <p className='borel capitalize' style={{color:'white',fontSize:'13px'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription.slice(0,200)}
</p>
 </div>
</div>


<div style={{backgroundColor:"white",marginTop:'150px',}}>
<h1 className='home_description-header anton capitalize' style={{color:"black",marginLeft:'50px'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"18px"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>






{
certificate == true && 
<div style={{marginTop:'150px',marginBottom:'150px', height:'200px',width:'90%',}} className='border  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
<div className='pl-5 'style={{width:'60%'}}>
<p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
 <p className='borel text-gray-600' style={{fontSize:'15px'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
</div>

<div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
<img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />

</div>
</div>
}

<div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className= 'py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
<div className='pl-10 'style={{width:'60%'}}>
<p className='home_description-header capitalize ' style={{fontSize:'30px'}}>Course Trailer</p>
 <p className='borel text-gray-600' style={{fontSize:'15px',width:'90%'}}>{courseDescription} </p>
</div>


<div className="video-player-container" style={{width:'40%'}}>
<video ref={videoRef} className="video-player" poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=58bf503c-e12a-4c0d-b428-f430c7fd0642'>
<source src={courseTrailer} type="video/mp4" />
Your browser does not support the video tag.
</video>
{showPlayButton && 
<div className="play-button-overlay" onClick={handlePlay}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle-fill play-button-icon" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
</svg>
</div>
}
</div>
</div>




<div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
{visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
const isOpen = openLectures.includes(lectureTitle);
const duration = videoDurations[lectureVideo];

return (
<div
onClick={() => handleLectureClick(lectureTitle)}
className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
key={lectureTitle}
>
{/* Lecture content */}
<h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '25px' }}>
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
<path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
<path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
</svg>
{lectureTitle}
</h1>
<p className='flex'>
<span className='borel text-gray-500 mr-2'>Lecture {index + 1}</span>
{duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
</p>
{isOpen && <p className="borel">{lectureDescription}</p>}
</div>
);
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

<div class="flex flex-row  space-x-2 justify-evenly">
<button onClick={handleReadMore} class="inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Read More
</button>
</div>

)}
</div>










{/* <div className='flex justify-between'>
<div></div>

<div class="flex   justify-between py-5 px-5 ">
<div>
<div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

</div>
</div>

</div>

</div> */}

</div>
</div>



)})}
</div>

</div>
</div>
</div>
<div id='mobile' >
<div >


<div  >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
  const courseDescriptionToShow = showFullDescription
  ? courseDescription
  : courseDescription.slice(0, 300);

const handleShowMore = () => {
  setShowFullDescription(true);
};
const handleShowLess = () => {
  setShowFullDescription(false);
};
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between items-center w-full bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'25vw'}} >
    <h1 className='anton capitalize  text-white' style={{fontSize:'4.8vw',width:'70%',paddingLeft:'3vw',marginRight:'3vw'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'2.8vw'}}>{lecture.length} Lectures </p>
</h1>

                
<div className='flex items-center'>
                
                {
                user &&  user.length == 0 ?
                       <Link to={`/signin`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 6vw',height:'13vw'}}

                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Enroll 
                 </button>
                 <p></p>
                 </Link>
                 :
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 6vw',height:'13vw'}}

                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue 
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 6vw',height:'13vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start 
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'3vw',padding:'0vw 6vw',height:'13vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase 
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
         

            </div>}
          <div className=' ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '105vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="border-t   bg-black h-full" >
          <nav className=' flex justify-between navMobile  w-full'>
<Link to="/"  style={{width:'22%'}}>
      <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht_.__2_-removebg-preview.png?alt=media&token=17e900e3-c5a4-43ad-aac9-4b380ae7d27e' alt="logo" /> 
    </Link>
                <div   className=' flex items-center'>




                <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"9vw"}} fill="currentColor" class="bi bi-list text-white" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
                </div>




</nav>
    {
      pop == true &&
      <div id='popoutMobileHome' className='relative '>
      <nav className='flex justify-between navMobile '>
      <Link  onClick={popProfile}  className=' ' style={{width:"32%",objectFit:"cover"}}> 
                          <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                            </Link>
  
  <div className='flex justify-end items-center' style={{width:'80%'}}>
  <Link to='/courses'>
  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
  </svg></Link>
  <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                  </svg>
  </div>
    
      </nav>
      <ul >
         
          <Link to="/signin">
          <li className='listPopoutTablet border-b border-t' >Sign in</li>
  
           </Link>
           <Link to="/signup/student">
          <li className='listPopoutTablet  border-b' >Sign up</li>
  
  
           </Link>
           <Link to="/aboutus">
  
          <li className='listPopoutTablet  border-b' >About Us</li>
          </Link>
      
  
  
  <Link to="/pricing/teacher">
  <li className='listPopoutTablet  border-b' >Pricing</li>
  </Link>
  
  
      </ul>
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                      rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
      </div>
    }

                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla  flex items-center capitalize' style={{fontSize:"2.5vw",color:'white',paddingLeft:'5vw',paddingTop:'4vw'}}><Link to='/' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:'3vw'}} fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'13vw'}}>
    <div>
    <h1 className='anton capitalize text-white' style={{fontSize:'6vw'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center " >
                      
          
                
                
      <div className='flex items-center justify-center' style={{marginTop:'3vw',marginBottom:'3vw'}}>
        <p className='fjalla pr-2' style={{fontSize:'3vw',color:'white'}}>Course By :- </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"7vw",height:"7vw",fontSize:'3vw'}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"7vw",height:"7vw"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white' style={{fontSize:'3vw'}}>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
            <div className='flex items-center'>
                
                {
                     user &&  user.length == 0 ?
                     <Link to={`/signin`} >
               <button
                 type='button'
                 style={{fontSize:'3vw',padding:'0vw 6vw',height:'13vw'}}

                 className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
               >
                 Enroll Now 
               </button>
               <p></p>
               </Link>
               :
                 alreadyExists == true?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}

                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Continue 
                 </button>
                 <p></p>
                 </Link>
                 :
     
                 coursePricing == 'Free Pricing' ?
                 <Link to={`/course/${courseId}/lecture/${firstlectureId}`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Start 
                 </button>
                 <p></p>
                 </Link>
                 :
                 <Link to={`/course/${courseId}/checkout`} >
                 <button
                   type='button'
                   style={{fontSize:'2.8vw',padding:'0vw 6vw',height:'11vw'}}
                   
                   className='mt-5 fjalla mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Purchase 
                 </button>
                 <p></p>
                 </Link>
                }
                 </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'4.5vw',color:'white'}}>
              {coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'2.3vw'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'30vw',}}>
<h1 className='home_description-header  capitalize' style={{color:"black",marginLeft:'8vw',fontSize:'6vw'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"3vw"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'30vw',marginBottom:'30vw',width:'90%',padding:'4vw 0'}} className=' border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'6vw',lineHeight:'6vw'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'2.5vw',marginTop:'3vw'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'30vw',marginBottom:'30vw',width:'100%',padding:'4vw 0'}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className=' 'style={{width:'60%',paddingLeft:'4vw'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'6vw',lineHeight:'6vw'}}>Course Trailer</p>
          <p className='borel text-gray-600' style={{ fontSize: '2.8vw', width: '90%' }}>
      {courseDescriptionToShow}
      {courseDescription.length > 300 && !showFullDescription && (
        <p onClick={handleShowMore} className='fjalla underline'style={{fontSize:'3.4vw'}}>Show More</p>
      )}
        {showFullDescription && (
        <p onClick={handleShowLess} className='fjalla underline'style={{fontSize:'3.4vw'}}>Show Less</p>
      )}
    </p> 
          </div>
          
     
          <div className="video-player-container" style={{width:'45%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1  className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '5vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' style={{fontSize:'3vw'}}>Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500' style={{fontSize:'3vw'}}> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
<div id='tablet' >

<div >
<div id='previewLoadingDesktop'>
<div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>

     </div>
</div>

<div id='courseDescriptionPreviewDesktop' >
    {course.map(i=>{
        const {totalLectures,courseLevel,courseTitle,instructor,certificate,courseTrailer,courseSkills,courseDescription,instructorId,intendedAgeGroup,coursePricing,courseThumbnail ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
          <div className='flex relative'>
             {showContent &&
            <div className='shadow-xl flex justify-between items-center bg-blue-900 z-100'style={{zIndex:'100',position:'fixed',top:'0',left:'0',width:'100%',height:'100px'}} >
    <h1 className='home_description-header capitalize pl-10 pt-2 text-white' style={{fontSize:'20px'}}>       
{courseTitle} <br />
<p className='fjalla' style={{fontSize:'11px'}}>{lecture.length} Lectures </p>
</h1>

<div className='flex items-center'>
                
                {
                 <Link to={`/signin`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Enroll 
                 </button>
                 <p></p>
                 </Link>
     
             
                }
                 </div>

            </div>}
          <div className=' ' style={{width:"100%",height:"100%"}}>
         
            <div
            style={{  
              height: '100vh',
              backgroundImage: `url('${courseThumbnail}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
        backgroundPosition: 'center' }}><div className="   bg-black h-full" >
<nav className='flex justify-between  w-full  pt-5 px-5'>

           
<Link to="/"  style={{width:'18%'}}>
      <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht_.__2_-removebg-preview.png?alt=media&token=17e900e3-c5a4-43ad-aac9-4b380ae7d27e' alt="logo" /> 
    </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-white" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-white menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" onClick={popProfile} className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
                 <div className='flex justify-between items-center capitalize ' >
            <h1 className='fjalla pl-5 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"11px",color:'white'}}><Link to='/' className='underline'>Home</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> <Link className='underline' to={`/courses`}>Courses</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {courseTitle}</h1>
          
            </div>
      <div className='flex items-center justify-between px-4' style={{marginTop:'50px'}}>
    <div className='pl-12 pt-12'>
    <h1 className='anton capitalize text-white' style={{fontSize:'30px'}}>{courseTitle}</h1>
    <div>
            {
      Instructor.map(i=>{
        const {fullName,uid,photo} = i
        return(
          <div className=' rounded px-5    mx-auto'>

             
          <div className="  flex items-center r" >
                      
          
                
                
      <div className='flex items-center'>
        <p className='home_description-header pr-2' style={{fontSize:'15px',color:'white'}}>Course By :- </p>
                             
        <Link to={`/user/${instructorId}`}>
          <div className='flex items-center text-left hover:text-blue-800'>
            {
              photo.length == 0?
              <div
              className="mr-1 flex  items-center justify-center  bg-blue-300 rounded-full  m-auto rounded-full object-cover "
            style={{width:"35px",height:"35px",fontSize:'14px'}}
            >
              {fullName && fullName[0]}
              

            </div>
            :
            <img 
            style={{width:"35px",height:"35px"}}

            className="mr-1 flex  items-center justify-center  rounded-full  m-auto rounded-full object-cover "
            src={photo}></img>
            }
       
                                    <p className='fjalla text-white'>{fullName}</p>
          
          </div></Link>
      </div>
                              </div>
          </div>
        )
      })
    }
            </div>
            <div className='flex items-center'>
                
            <Link to={`/signin`} >
                 <button
                   type='button'
                   style={{fontSize:'11px'}}
                   className='fjalla mt-5 mr-8 text-gray-900 bg-white hover:bg-gray-100 fjalla focus:ring-4 focus:ring-white  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
                 >
                   Enroll Now
                 </button>
                 <p></p>
                 </Link>
                 </div>
    </div>
   
           

            </div>
        
            </div>
            </div>
            <div style={{marginTop:'-100px',marginBottom:'150px', height:'200px',width:'90%',}} className='rounded mx-auto shadow-md bg-gray-900  flex items-center justify-evenly'>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >

                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {courseLevel}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}> The course is designed for {intendedAgeGroup} students  </p>
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}} >
                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {completionTime} to complete
                </h1>
                {/* <p className='fjalla'>{completionTime} </p> */}
              </div>
              <div style={{width:'30%',display:'flex',justifyContent:'center',flexDirection:'column',paddingRight:'5px'}}>
                <h1 className='home_description-header text-white' style={{fontSize:'25px',color:'white'}}>
              {coursePricing && coursePricing == 'Free  Pricing' ? 'Free ' : coursePricing == '' ? 'Unset': coursePrice + ' ETB'}

                </h1>
                <p className='borel capitalize' style={{color:'white',fontSize:'11px'}}>              {coursePricing && coursePricing == 'Free  Pricing' ? 'Take this course for free' : coursePricing == '' ? 'Unset': pricingDescription}
</p>
              </div>
            </div>


            <div style={{backgroundColor:"white",marginTop:'150px',}}>
<h1 className='home_description-header anton capitalize' style={{color:"black",marginLeft:'50px'}}>During this course, you will learn: </h1>
<div class="py-8 " >  
<div class=" text-gray-500 ">
<div class=" " >
{
  courseSkills && courseSkills.map(i=>{
   return(
     <div class="bg-white rounded-2xl ml-2 mr-2 " >
   <div class="mb-3  flex items-center pl-12">

<span style={{fontSize:"15px"}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla">{i}</span>
       
   </div>
</div>
   )
   })
 }


</div>
</div>
</div>





</div>

       


           
            
         {
          certificate == true && 
          <div style={{marginTop:'150px',marginBottom:'150px',width:'90%',}} className='py-8 border   flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-5 'style={{width:'60%'}}>
          <p className='home_description-header capitalize ' style={{fontSize:'25px'}}>Completion Certificate</p>
              <p className='borel text-gray-600' style={{fontSize:'11px'}}>You will receive a certificate of completion once you have successfully completed the course This certificate is proof that you have met the requirements and passed the course. </p>
          </div>
          
          <div className='shadow-md flex justify-center' style={{fontSize:'25px',width:'30%'}}>
         <img style={{width:'110%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/photo_2024-02-16_15-12-46.jpg?alt=media&token=fc0ce1e0-0dc6-4c86-894a-92e2ae13a3ad" alt="" />
 
          </div>
            </div>
         }

            <div style={{marginTop:'150px',marginBottom:'150px',width:'100%',}} className='py-8 border-t border-r border-b  flex rounded mx-auto shadow-md bg-white  flex items-center justify-evenly'>
          <div className='pl-10 'style={{width:'60%'}}>
          <p className='home_description-header capitalize mb-3' style={{fontSize:'30px'}}>Course Trailer</p>
              <p className='borel text-gray-600' style={{fontSize:'11px',width:'90%'}}>{courseDescription} </p>
          </div>
          
     
          <div className="video-player-container" style={{width:'50%'}}>
      <video ref={videoRef}  className="video-player"  poster='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Course%20Trailer%20(1).png?alt=media&token=336c5b9f-d055-48f0-a484-2c32f459e658'>
        <source src={courseTrailer} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showPlayButton && 
      <div className="play-button-overlay" onClick={handlePlay}>
        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="text-white play-button-icon bi bi-play-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
</svg>
      </div>
    }
    </div>
            </div>

            


            <div style={{width:'80%'}} className='mx-auto shadow-md mb-12'>
  {visibleLectures.map(({ lectureTitle, lectureDescription, lectureVideo }, index) => {
    const isOpen = openLectures.includes(lectureTitle);
    const duration = videoDurations[lectureVideo];

    return (
      <div
      onClick={() => handleLectureClick(lectureTitle)}
      className="cursor-pointer border rounded pb-3 px-10 flex flex-col items-start"
      key={lectureTitle}
    >
      {/* Lecture content */}
      <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 ${isOpen ? 'selected' : ''}`} style={{ fontSize: '3vw' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
        </svg>
        {lectureTitle}
      </h1>
      <p className='flex my-4'>
        <span className='borel text-gray-500 mr-2 ' >Lecture {index + 1}</span>
        {duration && <span className='borel text-gray-500'> {formatVideoDuration(duration)}</span>}
      </p>
      {isOpen && <p className="borel">{lectureDescription}</p>}
    </div>
  );
})}

{!showAllLectures && lecture.length > maxVisibleLectures && (

       <div class="flex flex-row  space-x-2 justify-evenly">
           <button style={{fontSize:'12px'}} onClick={handleReadMore} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-white hover:bg-blue-900 focus:text-blue-700 bg-blue-800 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
             
           Read More
         </button>
    </div>

)}
</div>










{/* <div className='flex justify-between'>
  <div></div>
  
<div class="flex   justify-between py-5 px-5 ">
 <div>
 <div className='flex items-center mb-12 py-5' >
<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up mr-1" viewBox="0 0 16 16">
<path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
</svg>
Like course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" bi bi-hand-thumbs-down mr-1" viewBox="0 0 16 16">
<path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
</svg>
Dislike course
</div>

<div className='flex items-center hover:text-blue-600 cursor-pointer mx-5 Mooli text-bold'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-flag mr-1" viewBox="0 0 16 16">
<path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
</svg>
Report an issue
</div>

      </div>
 </div>

      </div>

</div> */}
   
          </div>
       </div>
      
         

    )})}
</div>

</div>

</div>
      </div>

       }

    </div>
  )
}
