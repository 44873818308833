import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import DashboardNav from './dashboardNav';

export default function PricingPlanCreator() {
    const {courseId} = useParams()
    const [uid,setuid] = useState("")
    const [spinner,setspinner] = useState(true)
    const [pop,setpop] = useState(false)
    const [success,setsuccess] = useState(false)
    const [pricingPopMain,setpricingPopMain] = useState(true)
    const [deleteMessage,setdeleteMessage] = useState('')
    const [successDescription,setSuccessDescription] = useState(false)

    const [lockedreview,setlockedreview] = useState(false)
    


    const [isLoading,setIsLoading] = useState(false)

    const [notificationPop,setnotificationPop] = useState(false)

    const [pricingPlanPop,setpricingPlanPop] = useState(false)
    const [oneTimePurchasePop,setoneTimePurchasePop] = useState(false)
    const [FreePlanPop,setFreePlanPop] = useState(false)
    const [pricingDelete,setpricingDelete] = useState(false)



    const [pricingDescriptionEditor,setpricingDescriptionEditor] = useState(false)
    const [pricingPriceEditor,setpricingPriceEditor] = useState(false)

    const [pricingModelEditor,setpricingModelEditor] = useState(false)



    const [notification,setnotification] = useState([])



    const [user,setuser] = useState([])
    const [price,setprice] = useState('')

    const navigate = useNavigate()
    

    const [paymentFrequency,setpaymentFrequency] = useState("")
    const [paymentFrequencyError,setpaymentFrequencyError] = useState("")

    const [coursePrice,setcoursePrice] = useState(0)
    const [coursePriceError,setcoursePriceError] = useState("")

    const [subscriptionCoursePrice,setsubscriptionCoursePrice] = useState("")
    const [subscriptionCoursePriceError,setsubscriptionCoursePriceError] = useState("")
    const [pricingDescription,setpricingDescription] = useState("")
    const [pricingDescriptionError,setpricingDescriptionError] = useState("")

    const [subscriptionPricingDescription,setsubscriptionPricingDescription] = useState("")
    const [subscriptionPricingDescriptionError,setsubscriptionPricingDescriptionError] = useState("")

 
    const [course,setcourse] = useState([])
    const [editPop,seteditPop] = useState(false)



    function lockedReviewSet(){
      setlockedreview(true)
    }

    function lockedreviewclose (){
      setlockedreview(false)
      }
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
  const userRef = doc(firestore,"TotalUsers",uid);
           
        onSnapshot(userRef,snapshot=>{
   
          let main =[]
      
      
      
            main.push({...snapshot.data(),id:snapshot.id})
            
        setuser(main)
      
          
          
        
      
        })
        const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

        const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
    
        onSnapshot(notificationRef,snapshot=>{
          let main =[]
          
      
      
      
          snapshot.docs.forEach(doc=>{
            main.push({...doc.data(),id:doc.id})
           
          setnotification(main)
          })
      
          
          
        
      
        })  
      
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
        setcourse(main)
        let integerNumber = parseInt(snapshot.data().coursePrice);
        setprice(integerNumber)
    
      })
    
            
    
            
    
               
     
    //    })
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

const convertTimestampToDate = (timestamp) => {
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
};
const popProfile = () =>{
  if(pop == true){
    setpop(false)
    setnotificationPop(false)

  }
  else{
  setpop(true)
  setnotificationPop(false)


  }
}
const popNotification = () =>{
if(notificationPop == true){
  setnotificationPop(false)
  setpop(false)
}
else{
setnotificationPop(true)
setpop(false)



}
}
let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  



                // Price selector Input
    function pricingSet(e){
      setcoursePrice(e.target.value)
      if(e.target.value == ""){
          setcoursePriceError("error")
      }
      else {
         
          setcoursePriceError("")
      
              
           }
          }

          
          
          
          function oneTimePurchaseHandler(e){
              e.preventDefault()
         

              const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
              const documentRef2 = doc(firestore,"Courses",courseId)

              const lectureData = {
                coursePricing:"One Time Purchase",
               coursePrice:coursePrice,
               pricingDescription:pricingDescription,
               pricingCreatedTime:serverTimestamp()


                 }

            if(coursePrice.length == 0 ) 
            setcoursePriceError("error")
          else{
            if(pricingDescription.length == 0){
              setpricingDescriptionError("error")
            }
            else{
              setDoc(documentRef, lectureData, { merge: true })
              setDoc(documentRef2, lectureData, { merge: true }).then(
                setsuccess(true)
                

              )
            }
          }


          }

          function freeModelHandler(e){
            e.preventDefault()

            const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
            const documentRef2 = doc(firestore,"Courses",courseId)

            const lectureData = {
              coursePricing:"Free Pricing",
             coursePrice:'Free',
             pricingDescription:pricingDescription,
             pricingCreatedTime:serverTimestamp()


               }

          if(coursePrice.length == 0)
          setcoursePriceError("error")
        else{
          if(pricingDescription.length == 0){
            setpricingDescriptionError("error")
          }
          else{
            setDoc(documentRef, lectureData, { merge: true })
            setDoc(documentRef2, lectureData, { merge: true }).then(
              setsuccess(true)
            )
          }
        }


        }


        function removeNotification(id){
          const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
        
          setDoc(notificationRef,{
            seen:true
          },{merge:true})
          .then(() => {
            
            setnotification(prevArray => prevArray.filter(item => item.id !== id));
          })
          .catch((error) => {
          });
        
        
         }





               // Quiz check Field selector Input
    function paymentFrequencySet(e){
      setpaymentFrequency(e.target.value)
      if(e.target.value == ""){
          setpaymentFrequencyError("error")
      }
      else {
         
          setpaymentFrequencyError("")
      
              
           }
          }

         // Profile Photo Click Handler


    const textRef = React.useRef();
    React.useEffect(() => {
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
        const taHeight = textRef.current.scrollHeight;
        textRef.current.style.height = taHeight + "px";
      }
    }, [pricingDescription]);
      // Course Objective Field selector Input
      function pricingDescriptionSet(e){
        setpricingDescription(e.target.value)

        if(e.target.value.length == 0){
            setpricingDescriptionError("error")
        }
        else {
            setpricingDescriptionError("")
        }
    }

    function subscriptionPricingDescriptionSet(e){
      setsubscriptionPricingDescription(e.target.value)

      if(e.target.value.length == 0){
          setsubscriptionPricingDescriptionError("error")
      }
      else {
          setsubscriptionPricingDescriptionError("")
      }
  }
    
    // Notification Icon Click Handler


    function handleBackOneTimePurchaseDesktop(){
    

      setTimeout(() => {
        setIsLoading(true)

        setTimeout(() => {
          setpricingPopMain(true)
          
        }, 1000);
        
      }, 1000);


      
    }

    function handlePricingPlanDesktop(){


      setTimeout(() => {
        setpricingPopMain(false)
        setIsLoading(true)

        setTimeout(() => {
          setIsLoading(false)
          setFreePlanPop(false)
          
        }, 1000);
        
      }, 1000);


      
    }


    function handlePricingPlanFromEditorDesktop(){
      setpricingPopMain(true)
      setpricingPlanPop(true)
      seteditPop(false)

      
    }

    function handleRemovePricingPlanDesktop(){
      setpricingPlanPop(false)
        


    }


    function handleOpenPricingPlanDesktop(){
      setpricingPlanPop(true)

    }

    function handleSubscriptionDesktop(){
      const subscription = document.getElementById("subscriptionDesktop")
      const pricingPlan = document.getElementById("pricingPlanDesktop")
      const loading= document.getElementById("loadingPricingDesktop")

      setTimeout(() => {
        pricingPlan.style.display="none"
        loading.style.display="flex"

        setTimeout(() => {
          loading.style.display="none"

          subscription.style.display="block"
          
        }, 1000);
        
      }, 1000);

    }

    function handleFreeDesktop(){
    

      setTimeout(() => {
        setpricingPopMain(false)
      setIsLoading(true)

        setTimeout(() => {
          setIsLoading(false)
          setFreePlanPop(true)
        }, 1000);
        
      }, 1000);

    }


    function closePopDeleteDesktop(){
      setpricingDelete(false)
    
      
    }

    function openPopDeleteDesktop(){
      setpricingDelete(true)
    
      
    }
    
    function deleteHandlerDesktop(){
      const message= document.getElementById("alert-border-6")
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
      const documentRef2 = doc(firestore,"Courses",courseId)

      const lectureData = {
        coursePricing:"",
       coursePrice:0,
       pricingDescription:"",


         }

      setDoc(documentRef, lectureData, { merge: true })
      setDoc(documentRef2, lectureData, { merge: true }).then(
        message.style.display="flex",
        setpricingDelete(false),
        setdeleteMessage('deleted')
      )
    
    }

    function deleteMessageMinimize(){
      setdeleteMessage('')
    }


    function savePricingDescription(){
      const message= document.getElementById("alert-border-7")
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
      const documentRef2 = doc(firestore,"Courses",courseId)


      const lectureData = {
        
       pricingDescription:pricingDescription


         }

    if(pricingDescription.length == 0 ){
      setpricingDescriptionError("error")
    }
    else{
      setDoc(documentRef, lectureData, { merge: true })
      setDoc(documentRef2, lectureData, { merge: true }).then(
        setpricingDescriptionEditor(false),
        setSuccessDescription(true)
      )
    }

    }


    
    function savePriceDesktop(){
      const message= document.getElementById("alert-border-7")
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
      const documentRef2 = doc(firestore,"Courses",courseId)

 
      const lectureData = {
        
       coursePrice:coursePrice


         }

    if(coursePrice.length == 0 || price == 0 ){
      setcoursePriceError("error")
    }
    else{
      setDoc(documentRef, lectureData, { merge: true })
      setDoc(documentRef2, lectureData, { merge: true }).then(
      setpricingPriceEditor(false),
        setSuccessDescription(true)
      )
    }

    }
    function handleBackFreePlanDesktop(){
   

      setTimeout(() => {
       setIsLoading(true)

        setTimeout(() => {
          setIsLoading(true)
          setpricingPopMain(true)

          
        }, 1000);
        
      }, 1000);

    }
    function handleBackSubscriptionDesktop(){
      const subscription = document.getElementById("subscriptionDesktop")
      const pricingPlan = document.getElementById("pricingPlanDesktop")
      const loading= document.getElementById("loadingPricingDesktop")

      setTimeout(() => {
        subscription.style.display="none"
        loading.style.display="flex"

        setTimeout(() => {
          loading.style.display="none"

          pricingPlan.style.display="block"
          
        }, 1000);
        
      }, 1000);

    }

    function minimizeSuccessPurchaseDesktop(){
      setpricingPlanPop(false)
      setpricingPopMain(true)
      setsuccess(false)


    
    }
    function openEditPurchaseDesktop(){
   seteditPop(true)

    
    }

    function closeMessage6Desktop(){
    setdeleteMessage('')
    }

    function closeMessage7Desktop(){
      setSuccessDescription(false)
    }

    function closeEditPurchaseDesktop(){
      seteditPop(false)

    
    }
    
  


    function editInputOpenerDesktop(){
     setpricingModelEditor(true)
    
      
    }

     // Edit Input Opener
  function editPricingOpenerDesktop(){
    setpricingModelEditor(false)
  
  
    
  }


  function editDescriptionOpenerDesktop(){
setpricingDescriptionEditor(true)
  
  
  
  
  
    
  }

   // Edit Input Opener
function editPricingDescriptionOpenerDesktop(){
setpricingDescriptionEditor(false)




  
}


function editPriceOpenerDesktop(){
setpricingPriceEditor(true)





  
}

 // Edit Input Opener
function editPricingPriceOpenerDesktop(){
setpricingPriceEditor(false)




}
function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }

 function desktopSpinnerHandler(){
   setspinner(false)
  }
  return (
    user.map(i=>{
      const{accountType,plan,fullName,photo} = i

      return(
       accountType == 'Institute'?
        <div>
                {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
        <div id='desktop'>
      
        <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
   <div className={`${deleteMessage.length != 0 ? "block":"hidden"}`}>

              <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <div class="ml-3 text-sm font-medium Mooli capitalize">
               Pricing model been successfully removed 
              </div>
              <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
                <span class="sr-only">Dismiss</span>
                <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
          </div>
          </div>
            {
    lockedreview == true &&
    <div id='testDesktopPop' className='uploadFileDesktop flex items-center justify-center'>
    <main className=" mx-auto   bg-white rounded-md" style={{width:"90%",height:"90%"}}>
    <div className='py-5 flex w-full justify-between items-center'>
    <div style={{paddingLeft:"50px"}}>
        <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"50px"}}>We are currently reviewing this course for publication, so you cannot make any changes at this time</h1>
        <p className='borel text-gray-500' style={{fontSize:'12px'}}>The review process can take up to 24 hours</p>
        
        </div>
    
       
    
    
    
    
                  <svg onClick={lockedreviewclose}  xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
           </div>
    
    <div className='flex '>
    
    <div className='mt-10 w-1/2 mx-3' style={{marginTop:"5%"}}>
            <p className='fjalla text-gray-700 mb-2' style={{fontSize:'25px'}}>Points to keep in mind</p>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>The quality of the video</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Course Content</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Learning Objectives</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Teaching Methods</p>
                </div>
                <div className='flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
  <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52z"/>
</svg>
<p class='borel pl-2' style={{fontSize:'15px'}}>Value for Money</p>
                </div>
                </div>
    
    
      <img style={{width:"40.5%",marginTop:"-3%"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Customer%20Survey-amico.svg?alt=media&token=fd7c6202-fbb9-40d2-9bf6-84bcc2db89b8" alt="Review" />
    
    </div>
      
    
    
    </main>
    
    </div>
  }

       
   
   {
       course.map(i=>{
           const {status,courseTitle,coursePricing,coursePrice,pricingCreatedTime,pricingDescription} = i
   
           return(
             <div>
   {
     coursePricing && coursePricing.length !=0 ? 
     <div className=' w-full flex-col'>
       <h1 className='fjalla capitalize pl-5 text-blue-900 mt-8' style={{fontSize:"26px"}}>Pricing Model For {courseTitle} </h1>
   
   <table class="w-full text-sm text-left text-gray-500   border mt-3">
     <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
         <tr>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Pricing Type
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Price
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Creation date
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Action
             </th>
        
      
      
   
         </tr>
     </thead>
   
   <tbody>
   
   <tr class=" bg-white border-b  ">
   
             <th scope="row" class="capitalize  fjalla text-center    px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
         {coursePricing}
             </th>
             <td class="px-6 py-4 fjalla text-center text-gray-800" style={{fontSize:"14px"}}>
               {coursePricing === 'Free Pricing' ? '0 ETB' : coursePrice + ' ETB'} 
             </td>
             <td class="px-6 py-4 borel text-center text-gray-800" style={{fontSize:"14px"}}>
             {pricingCreatedTime && convertTimestampToDate(pricingCreatedTime)}
             
             </td>
             <td class="px-6 py-4 fjalla text-center text-gray-800 flex justify-center" style={{fontSize:"14px"}}>
                 {/* {totalEarnings} ETB */}
            {
              status === 'pending'?
              <div className='flex'>
              <svg onClick={lockedReviewSet} aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer hover:text-green-800 flex-shrink-0 h-5 w-5   mr-4">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               <svg
               onClick={lockedReviewSet}
             xmlns="http://www.w3.org/2000/svg"
             width="18"
             height="18"
             fill="currentColor"
             className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
             viewBox="0 0 16 16"
           >
             <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
           </svg>
              </div>
              :
              <div className='flex'>

              <svg onClick={openEditPurchaseDesktop} aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer hover:text-green-800 flex-shrink-0 h-5 w-5   mr-4">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               <svg
               onClick={openPopDeleteDesktop}
             xmlns="http://www.w3.org/2000/svg"
             width="18"
             height="18"
             fill="currentColor"
             className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
             viewBox="0 0 16 16"
           >
             <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
           </svg>
              </div>
            }
            
             </td>
          
         
         </tr>
   
   
   
   
   
   </tbody>
   </table>
   
   </div>
     :
     <div class="flex flex-wrap items-start justify-end -mb-3  pt-7 px-8">
        <button style={{fontSize:'12px'}} onClick={handleOpenPricingPlanDesktop} class="inline-flex px-5 py-2.5 Mooli  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
               <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 h-4 w-4 -ml-1 mt-0.5 mr-2">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               Add Pricing Plan 
             </button>
             <div className='border mx-auto  flex flex-col items-center' style={{width:"90%"}}>
       <h1 className='fjalla text-center text-gray-900 mt-6' style={{fontSize:"30px"}}>Your course does not have a pricing plan </h1>
       <p className='borel text-gray-500 text-center'style={{fontSize:"12px"}}>Make your course payable by adding a pricing plan..</p>
       <button  style={{fontSize:"12px"}}onClick={handleOpenPricingPlanDesktop}class=" m-auto px-5 mt-4 py-2.5 text-blue-600 hover:text-blue-700  focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
           
               Add Pricing Plan 
             </button>
   </div>
           </div>
   }
   
        
   {
    pricingPlanPop == true &&
    <div id='pricingPlanPop' className=''>
      {
    pricingPopMain == true ?
    <div className='bg-white m-auto my-5 rounded ' id='pricingPlanDesktop' style={{width:"90%",height:"98%"}}>
    <div className='flex w-full justify-between items-center'>
    <h1 className='anton text-gray-900 capitalize' style={{fontSize:"40px",paddingLeft:"100px",paddingTop:"50px",color:"blue"}}>Select a pricing model   </h1>
           <svg onClick={handleRemovePricingPlanDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
   </svg>
    </div>
               <div onClick={handleFreeDesktop} className='border mx-10 hover:bg-gray-50 cursor-pointer rounded-md mt-12 py-8 pl-10'>
                   <h1 className='fjalla'style={{fontSize:"25px"}}>Free</h1>
                   <p style={{fontSize:"12px"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
            
               <div onClick={handlePricingPlanDesktop} className='border mx-10 hover:bg-gray-50 cursor-pointer rounded-md mt-12 py-8 pl-10'>
                   <h1 className='fjalla'style={{fontSize:"25px"}}>One time purchase</h1>
                   <p style={{fontSize:"12px"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
       </div>
       :
         isLoading == true ?
         <div className='bg-white flex items-center justify-center my-5  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"98%"}}>
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         </div>
         
         </div>
         :
         success == true ?
         <div className='bg-white m-auto  rounded my-5 ' id='successPurchaseDesktop' style={{width:"90%",height:"98%"}}>
<div className='relative bg-white flex items-center justify-center  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
 <div class="">
 <svg onClick={minimizeSuccessPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
 <p className='anton  text-center' style={{fontSize:"35px"}}>Course Pricing Successfully Set</p>
 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
</div>

   </div>
</div>
:
FreePlanPop == true ?

    
<div className='bg-white m-auto  rounded my-5' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"100px"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"35px",paddingTop:"50px"}}>Free Course </h1>
 <p className='borel text-gray-500'style={{fontSize:'12px'}}>Your students will be able to take your course for free under this pricing model</p>
</div>
        <svg onClick={handleBackFreePlanDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form onSubmit={freeModelHandler}  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>




<div className="relative" >

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Pricing model description</p>
<textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
     focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
     border-gray-300 rounded-md" maxLength="%000" placeholder='Describe your pricing model'></textarea>
     <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>
<p className='Mooli text-gray-500'style={{fontSize:'12px'}}>Max 100 Characters</p>

</div>




<div className="relative" >
<button type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Add Pricing plan
</button>
</div>
</form>
<div style={{width:"40%"}}>
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Plain%20credit%20card-bro.svg?alt=media&token=569cde7f-da05-493e-a866-51c46a39b7c7&_gl=1*1kh9yrz*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5OTExNjM1MS42LjEuMTY5OTExNjQwOS4yLjAuMA.." alt="" />
</div>
</div>  
    </div>
    :
<div className='bg-white m-auto rounded my-5' id='oneTimePurchaseDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"100px"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"35px",paddingTop:"50px"}}>One time purchase </h1>
 <p className='borel text-gray-500' style={{fontSize:'12px'}}>What is the price you would like to charge students for this course?</p>
</div>
        <svg onClick={handleBackOneTimePurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>

<div className="relative my-10">
<p className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Course Price</p>
<input style={{fontSize:'13px'}} onChange={e=>pricingSet(e)}   required  autoComplete="off"  placeholder="100 ETB" type="number" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md "/>
        <div className={`${coursePriceError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Price Can't be empty
</div>
</div>
</div>
</div>



<div className="relative" >

<p className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Pricing model description</p>
<textarea  placeholder='Students must pay a one-time payment to enroll in this course'  style={{fontSize:'13px',maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
     focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
     border-gray-300 rounded-md" maxLength="100"></textarea>
     <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>
<p className='Mooli text-gray-500' style={{fontSize:'11px'}}>Max 100 Characters</p>

</div>




<div className="relative" >
<button onClick={oneTimePurchaseHandler} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Add Pricing plan
</button>
</div>
</form>
<div style={{width:"40%"}}>
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Plain%20credit%20card-bro.svg?alt=media&token=569cde7f-da05-493e-a866-51c46a39b7c7&_gl=1*1kh9yrz*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5OTExNjM1MS42LjEuMTY5OTExNjQwOS4yLjAuMA.." alt="" />
</div>
</div>  
    </div>

      }
   

  





</div>
   }
      {
        editPop == true &&
        <div id='editPlanPop' className=''>
        <div className='bg-white m-auto  rounded my-5 ' id='editPurchaseDesktop' style={{width:"90%",height:"98%"}}>
   {
    successDescription == true &&
    <div id="alert-border-7" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Updated Pricing Model
    </div>
    <button onClick={closeMessage7Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
   }
         <div className='flex w-full justify-between items-center mb-8'>
          
      <div style={{paddingLeft:"60px"}}>
      <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"40px"}}>Edit Pricing model </h1>
         <p className='borel text-gray-500' style={{fontSize:'12px'}}>What is the price you would like to charge students for this course?</p>
      </div>
                <svg onClick={closeEditPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
         </div>
              
   <div className='flex'>
  <div style={{width:"70%"}}>
  <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>
                   <h3 class="  fjalla pb-3 " style={{fontSize:"20px"}}>Pricing Model </h3>
  {
   pricingModelEditor == false &&
      <p className="borel text-gray-500 block"id='editCoursePricingDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePricing}</p>
     
  }
                   </div>
                   {
   pricingModelEditor == false &&
   <svg onClick={editInputOpenerDesktop} id="editIconDesktop" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
   <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
 </svg>
                   }
                   {
   pricingModelEditor == true &&
   <p style={{fontSize:'15px'}} onClick={editPricingOpenerDesktop} className='fjalla  cursor-pointer hover:text-red-800' id='cancelInputDesktop'>Cancel</p>

                   }
               
                    </div>
                    {
   pricingModelEditor == true &&
   <div className="relative mx-5 " id='editInputDesktop'>
           
                     

   <div onClick={handlePricingPlanFromEditorDesktop} className="relative" >
<button type='submit'   className="bg-blue-800 w-full flex justify-center items-center py-3 pr-5  pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Change Pricing Model
</button>
</div>


</div>
                    }
                   
                    <div>
                
                    </div>
                            
                  </div>
                  <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>
                   <h3 class="  fjalla pb-5 " style={{fontSize:"20px"}}>Pricing Description </h3>
                   {
                    pricingDescriptionEditor == false &&
     <p className="borel text-gray-500 block"id='editCoursePricingDescriptionDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{pricingDescription}</p>

                   }
     
                   </div>
                   {
                    pricingDescriptionEditor == false &&
                    <svg id="editDescriptionIconDesktop"  xmlns="http://www.w3.org/2000/svg"  onClick={editDescriptionOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                   }
                 
              {
               pricingDescriptionEditor == true &&
              <p style={{fontSize:'15px'}} className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingDescriptionOpenerDesktop}  id='cancelDescriptionInputDesktop'>Cancel</p>

              }
                    </div>
                    {
               pricingDescriptionEditor == true &&
               <div id='editDescriptionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
               <textarea defaultValue={pricingDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
                            focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                            border-gray-300 rounded-md" maxLength="100"></textarea>
                            <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
               <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
               <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
               </svg>
               <span className="sr-only">Info</span>
               <div className='Mooli'>
                Description Can't be empty
               </div>
               </div>
               </div>
               <p className='Mooli text-gray-500' style={{fontSize:'11px'}}>Max 100 Characters</p>
               <button onClick={savePricingDescription} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                     
                     Save
                   </button>
               </div>
                    }
         
                    <div>
                
                    </div>
                            
                  </div>
                  <div className={coursePricing == 'Free Pricing' ? 'hidden' : 'block'}>

<div className=' rounded m-auto' >
  
  <div className='flex justify-between px-5 py-5 '>
 <div>

 <h3 class="  fjalla pb-5 " style={{fontSize:"20px"}}>Pricing Price </h3>
{
pricingPriceEditor == false &&
<p className="borel text-gray-500 block"id='editCoursePricingPriceDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePrice} ETB</p>

}
 </div>
{
pricingPriceEditor == false &&
<svg id="editPriceIconDesktop"  xmlns="http://www.w3.org/2000/svg"  onClick={editPriceOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
</svg>
}
{
pricingPriceEditor == true &&
<p className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingPriceOpenerDesktop} id='cancelPriceInputDesktop'>Cancel</p>

}                    </div>
{
pricingPriceEditor == true &&

<div id='editPriceInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
<input defaultValue={coursePrice} style={{fonts:'12px'}}    onChange={e=>pricingSet(e)}required  autoComplete="off"  type="number" className="border  focus:outline-none
focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${coursePriceError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Price must be greater than 0
</div>
</div>
</div>
<button onClick={savePriceDesktop} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Save
</button>
</div>
}

</div>
  <div>

  </div>
          
</div>
  </div>
<div style={{width:"40%"}}>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Plain%20credit%20card-bro.svg?alt=media&token=569cde7f-da05-493e-a866-51c46a39b7c7&_gl=1*1kh9yrz*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5OTExNjM1MS42LjEuMTY5OTExNjQwOS4yLjAuMA.." alt="" />
</div>
    </div>  
            </div>
            </div>
      }       
        
   {
     pricingDelete == true &&
     <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
          
        
     <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
 <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
     <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
   </svg>
     
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Pricing Model </h1>
<p class="text-sm text-gray-500 Mooli normal-case capitalize" style={{fontSize:"12px"}}>Would you like to permanently remove this model? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
 <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
 <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


 </div>
   }
         
   </div>
   
           )
       })
    }
           </div>
           <div id='tablet'>
      <DashboardNav/>

      <div className={`${deleteMessage.length != 0 ? "block":"hidden"}`}>

         <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
       <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
         <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
       </svg>
       <div class="ml-3 text-sm font-medium Mooli capitalize">
        Pricing model been successfully removed 
       </div>
       <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
         <span class="sr-only">Dismiss</span>
         <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
         </svg>
       </button>
   </div>
   </div>

   
   {
       course.map(i=>{
           const { courseTitle,coursePricing,coursePrice,pricingCreatedTime,pricingDescription} = i
   
           return(
             <div>
   {
     coursePricing && coursePricing.length !=0 ? 
     <div className=' w-full flex-col'>
       <h1 className='fjalla capitalize pl-5 text-blue-900 mt-8' style={{fontSize:"26px"}}>Pricing Model For {courseTitle} </h1>
   
   <table class="w-full text-sm text-left text-gray-500   border mt-3">
     <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
         <tr>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Pricing Type
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Price
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Creation date
             </th>
             <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
                 Action
             </th>
        
      
      
   
         </tr>
     </thead>
   
   <tbody>
   
   <tr class=" bg-white border-b  ">
   
             <th scope="row" class="capitalize  fjalla text-center    px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
         {coursePricing}
             </th>
             <td class="px-6 py-4 fjalla text-center text-gray-800" style={{fontSize:"14px"}}>
               {coursePricing === 'Free Pricing' ? '0 ETB' : coursePrice + ' ETB'} 
             </td>
             <td class="px-6 py-4 borel text-center text-gray-800" style={{fontSize:"14px"}}>
             {pricingCreatedTime && convertTimestampToDate(pricingCreatedTime)}
             
             </td>
             <td class="px-6 py-4 fjalla text-center text-gray-800 flex justify-center" style={{fontSize:"14px"}}>
                 {/* {totalEarnings} ETB */}
                 <svg onClick={openEditPurchaseDesktop} aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer hover:text-green-800 flex-shrink-0 h-5 w-5   mr-4">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               <svg
               onClick={openPopDeleteDesktop}
             xmlns="http://www.w3.org/2000/svg"
             width="18"
             height="18"
             fill="currentColor"
             className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
             viewBox="0 0 16 16"
           >
             <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
           </svg>
             </td>
          
         
         </tr>
   
   
   
   
   
   </tbody>
   </table>
   
   </div>
     :
     <div class="flex flex-wrap items-start justify-end -mb-3  pt-7 px-8">
        <button style={{fontSize:'12px'}} onClick={handleOpenPricingPlanDesktop} class="inline-flex px-5 py-2.5 Mooli  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
               <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 h-4 w-4 -ml-1 mt-0.5 mr-2">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               Add Pricing Plan 
             </button>
             <div className='border mx-auto  flex flex-col items-center' style={{width:"90%"}}>
       <h1 className='fjalla text-center text-gray-900 mt-6' style={{fontSize:"30px"}}>Your course does not have a pricing plan </h1>
       <p className='borel text-gray-500 text-center'style={{fontSize:"12px"}}>Make your course payable by adding a pricing plan..</p>
       <button  style={{fontSize:"12px"}}onClick={handleOpenPricingPlanDesktop}class=" m-auto px-5 mt-4 py-2.5 text-blue-600 hover:text-blue-700  focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
           
               Add Pricing Plan 
             </button>
   </div>
           </div>
   }
   
        
   {
    pricingPlanPop == true &&
    <div id='pricingPlanPop' className=''>
      {
    pricingPopMain == true ?
    <div className='bg-white m-auto my-5 rounded ' id='pricingPlanDesktop' style={{width:"90%",height:"98%"}}>
    <div className='flex w-full justify-between items-center'>
    <h1 className='anton text-gray-900 capitalize' style={{fontSize:"40px",paddingLeft:"100px",paddingTop:"50px",color:"blue"}}>Select a pricing model   </h1>
           <svg onClick={handleRemovePricingPlanDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
   </svg>
    </div>
               <div onClick={handleFreeDesktop} className='border mx-10 hover:bg-gray-50 cursor-pointer rounded-md mt-12 py-8 pl-10'>
                   <h1 className='fjalla'style={{fontSize:"25px"}}>Free</h1>
                   <p style={{fontSize:"12px"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
            
               <div onClick={handlePricingPlanDesktop} className='border mx-10 hover:bg-gray-50 cursor-pointer rounded-md mt-12 py-8 pl-10'>
                   <h1 className='fjalla'style={{fontSize:"25px"}}>One time purchase</h1>
                   <p style={{fontSize:"12px"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
       </div>
       :
         isLoading == true ?
         <div className='bg-white flex items-center justify-center my-5  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"98%"}}>
         <div class="relative">
         <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
         <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
         </div>
         
         </div>
         :
         success == true ?
         <div className='bg-white m-auto  rounded my-5 ' id='successPurchaseDesktop' style={{width:"90%",height:"98%"}}>
<div className='relative bg-white flex items-center justify-center  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
 <div class="">
 <svg onClick={minimizeSuccessPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
 <p className='anton  text-center' style={{fontSize:"35px"}}>Course Pricing Successfully Set</p>
 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
</div>

   </div>
</div>
:
FreePlanPop == true ?

    
<div className='bg-white m-auto  rounded my-5' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"100px"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"35px",paddingTop:"50px"}}>Free Course </h1>
 <p className='borel text-gray-500'style={{fontSize:'12px'}}>Your students will be able to take your course for free under this pricing model</p>
</div>
        <svg onClick={handleBackFreePlanDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form onSubmit={freeModelHandler}  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>




<div className="relative" >

<p style={{fontSize:'15px'}} className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Pricing model description</p>
<textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'12px'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
     focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
     border-gray-300 rounded-md" maxLength="%000" placeholder='Describe your pricing model'></textarea>
     <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>
<p className='Mooli text-gray-500'style={{fontSize:'12px'}}>Max 100 Characters</p>

</div>




<div className="relative" >
<button type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Add Pricing plan
</button>
</div>
</form>
<div style={{width:"40%"}}>
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Plain%20credit%20card-bro.svg?alt=media&token=569cde7f-da05-493e-a866-51c46a39b7c7&_gl=1*1kh9yrz*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5OTExNjM1MS42LjEuMTY5OTExNjQwOS4yLjAuMA.." alt="" />
</div>
</div>  
    </div>
    :
<div className='bg-white m-auto rounded my-5' id='oneTimePurchaseDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"100px"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"35px",paddingTop:"50px"}}>One time purchase </h1>
 <p className='borel text-gray-500' style={{fontSize:'12px'}}>What is the price you would like to charge students for this course?</p>
</div>
        <svg onClick={handleBackOneTimePurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"50%"}}>

<div className="relative my-10">
<p className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Course Price</p>
<input style={{fontSize:'13px'}} onChange={e=>pricingSet(e)}   required  autoComplete="off"  placeholder="100 ETB" type="number" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md "/>
        <div className={`${coursePriceError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Price Can't be empty
</div>
</div>
</div>
</div>



<div className="relative" >

<p className="bg-white fjalla pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Pricing model description</p>
<textarea  placeholder='Students must pay a one-time payment to enroll in this course'  style={{fontSize:'13px',maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
     focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
     border-gray-300 rounded-md" maxLength="100"></textarea>
     <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Description Can't be empty
</div>
</div>
</div>
<p className='Mooli text-gray-500' style={{fontSize:'11px'}}>Max 100 Characters</p>

</div>




<div className="relative" >
<button onClick={oneTimePurchaseHandler} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Add Pricing plan
</button>
</div>
</form>
<div style={{width:"40%"}}>
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Plain%20credit%20card-bro.svg?alt=media&token=569cde7f-da05-493e-a866-51c46a39b7c7&_gl=1*1kh9yrz*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5OTExNjM1MS42LjEuMTY5OTExNjQwOS4yLjAuMA.." alt="" />
</div>
</div>  
    </div>

      }
   

  





</div>
   }
      {
        editPop == true &&
        <div id='editPlanPop' className=''>
        <div className='bg-white m-auto  rounded my-5 ' id='editPurchaseDesktop' style={{width:"90%",height:"98%"}}>
      {
        successDescription == true &&
        <div id="alert-border-7" class="flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
        <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <div class="ml-3 text-sm font-medium Mooli capitalize">
         successfully Updated Pricing Model
        </div>
        <button onClick={closeMessage7Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
    </div>
      }
    
         <div className='flex w-full justify-between items-center mb-8'>
          
      <div style={{paddingLeft:"60px"}}>
      <h1 className='anton text-gray-900 capitalize' style={{fontSize:"30px",paddingTop:"40px"}}>Edit Pricing model </h1>
         <p className='borel text-gray-500' style={{fontSize:'12px'}}>What is the price you would like to charge students for this course?</p>
      </div>
                <svg onClick={closeEditPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
         </div>
              
   <div className='flex'>
  <div style={{width:"90%"}}>
  <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>
                   <h3 class="  fjalla pb-3 " style={{fontSize:"20px"}}>Pricing Model </h3>
  {
   pricingModelEditor == false &&
      <p className="borel text-gray-500 block"id='editCoursePricingDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePricing}</p>
     
  }
                   </div>
                   {
   pricingModelEditor == false &&
   <svg onClick={editInputOpenerDesktop} id="editIconDesktop" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
   <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
 </svg>
                   }
                   {
   pricingModelEditor == true &&
   <p style={{fontSize:'15px'}} onClick={editPricingOpenerDesktop} className='fjalla  cursor-pointer hover:text-red-800' id='cancelInputDesktop'>Cancel</p>

                   }
               
                    </div>
                    {
   pricingModelEditor == true &&
   <div className="relative mx-5 " id='editInputDesktop'>
           
                     

   <div onClick={handlePricingPlanFromEditorDesktop} className="relative" >
<button type='submit'   className="bg-blue-800 w-full flex justify-center items-center py-3 pr-5  pl-5 fjalla text-center text-white 
rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer">Change Pricing Model
</button>
</div>


</div>
                    }
                   
                    <div>
                
                    </div>
                            
                  </div>
                  <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>
                   <h3 class="  fjalla pb-5 " style={{fontSize:"20px"}}>Pricing Description </h3>
                   {
                    pricingDescriptionEditor == false &&
     <p className="borel text-gray-500 block"id='editCoursePricingDescriptionDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{pricingDescription}</p>

                   }
     
                   </div>
                   {
                    pricingDescriptionEditor == false &&
                    <svg id="editDescriptionIconDesktop"  xmlns="http://www.w3.org/2000/svg"  onClick={editDescriptionOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                   }
                 
              {
               pricingDescriptionEditor == true &&
              <p style={{fontSize:'15px'}} className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingDescriptionOpenerDesktop}  id='cancelDescriptionInputDesktop'>Cancel</p>

              }
                    </div>
                    {
               pricingDescriptionEditor == true &&
               <div id='editDescriptionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
    
               <textarea defaultValue={pricingDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>pricingDescriptionSet(e)}  type="text" class="border  focus:outline-none
                            focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                            border-gray-300 rounded-md" maxLength="100"></textarea>
                            <div className={`${pricingDescriptionError == "error" ? "block":"hidden"}`}>
               <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
               <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
               </svg>
               <span className="sr-only">Info</span>
               <div className='Mooli'>
                Description Can't be empty
               </div>
               </div>
               </div>
               <p className='Mooli text-gray-500' style={{fontSize:'11px'}}>Max 100 Characters</p>
               <button onClick={savePricingDescription} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                     
                     Save
                   </button>
               </div>
                    }
         
                    <div>
                
                    </div>
                            
                  </div>
                  <div className={coursePricing == 'Free Pricing' ? 'hidden' : 'block'}>

                  <div className=' rounded m-auto' >
                    
                    <div className='flex justify-between px-5 py-5 '>
                   <div>

                   <h3 class="  fjalla pb-5 " style={{fontSize:"20px"}}>Pricing Price </h3>
     {
      pricingPriceEditor == false &&
     <p className="borel text-gray-500 block"id='editCoursePricingPriceDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePrice} ETB</p>

     }
                   </div>
             {
              pricingPriceEditor == false &&
              <svg id="editPriceIconDesktop"  xmlns="http://www.w3.org/2000/svg"  onClick={editPriceOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
             }
{
  pricingPriceEditor == true &&
  <p className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingPriceOpenerDesktop} id='cancelPriceInputDesktop'>Cancel</p>

}                    </div>
{
  pricingPriceEditor == true &&

     <div id='editPriceInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
     <input defaultValue={coursePrice} style={{fonts:'12px'}}    onChange={e=>pricingSet(e)}required  autoComplete="off"  type="number" className="border  focus:outline-none
           focus:border-black w-full pt-3 pr-4 pb-3 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
           border-gray-300 rounded-md" />

  <div className={`${coursePriceError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Price must be greater than 0
</div>
</div>
</div>
<button onClick={savePriceDesktop} class="fjalla inline-flex px-5 py-3 mr-5 mt-5 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">

Save
</button>
</div>
}
       
          </div>
                    <div>
                
                    </div>
                            
                  </div>
  </div>

    </div>  
            </div>
            </div>
      }       
        
   {
     pricingDelete == true &&
     <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
          
        
     <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"70%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
 <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
     <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
   </svg>
     
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Pricing Model </h1>
<p class="text-sm text-gray-500 Mooli normal-case capitalize mt-1.5" style={{fontSize:"12px"}}>Would you like to permanently remove this model? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
 <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
 <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


 </div>
   }
         
   </div>
   
           )
       })
    }
           </div>
           <div id='mobile'>
     <DashboardNav/>
    
   
   <div className={`${deleteMessage.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='flex justify-between w-full'>
<div className='Mooli' style={{fontSize:'3vw'}}>
 Pricing model been successfully removed 
</div>
<svg onClick={deleteMessageMinimize} xmlns="http://www.w3.org/2000/svg" style={{width:'6vw'}} fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
</div>
</div>
</div> 
   {
       course.map(i=>{
           const { courseTitle,coursePricing,coursePrice,pricingCreatedTime,pricingDescription} = i
   
           return(
             <div>
   {
     coursePricing && coursePricing.length !=0 ? 
     <div className=' w-full flex-col'>
       <h1 className='fjalla capitalize  text-blue-900 ' style={{fontSize:"6vw",marginTop:'5vw',padding:'0vw 5vw',marginBottom:'3vw'}}>Pricing Model For {courseTitle} </h1>
   
   <table class="w-full text-sm text-left text-gray-500   border ">
   <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'2vw',padding:'1vw 0'}}>
                    Pricing Type
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'2vw',padding:'1vw 0'}}>
                    Price
                </th>
                <th scope="col" class=" fjalla text-center"style={{fontSize:'2vw',padding:'1vw 0'}}>
                    Creation Date
                </th>
              
                <th scope="col" class=" fjalla text-center"style={{fontSize:'2vw',padding:'1vw 0'}}>
                    Action
                </th>
          
               
            </tr>
        </thead>
   
   <tbody>
   <tr key={i.id} className="bg-white border-b " style={{padding:'3vw 0'}}>
       
        <td className="text-center borel" style={{ fontSize: "2.5vw",padding:'3vw 0' }}>
          {coursePricing}

        </td>
        <td className="text-center borel" style={{ fontSize: "2.5vw",padding:'3vw 0' }}>
          {coursePricing === 'Free Pricing' ? '0 ETB' : coursePrice + ' ETB'} 

        </td>
        <td className="text-center borel" style={{ fontSize: "2.5vw",padding:'3vw 0' }}>
          {pricingCreatedTime && convertTimestampToDate(pricingCreatedTime)}

        </td>
        <td class=" fjalla text-center text-gray-800 flex justify-center" style={{padding:'3vw 0'}}>
                 {/* {totalEarnings} ETB */}
                 <svg onClick={openEditPurchaseDesktop} aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{width:'4vw',height:'4vw',marginRight:'2vw'}}class="cursor-pointer hover:text-green-800 flex-shrink-0 ">
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               <svg
               onClick={openPopDeleteDesktop}
             xmlns="http://www.w3.org/2000/svg"
            style={{width:'4vw',height:'4vw'}}
             fill="currentColor"
             className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
             viewBox="0 0 16 16"
           >
             <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
           </svg>
             </td>
    
      </tr>
  
   
   
   
   
   
   </tbody>
   </table>
   
   </div>
     :
     <div class="flex flex-wrap items-start justify-end   " style={{marginTop:'4vw',marginRight:'2vw'}}>
        <button style={{padding:'3vw 3vw',marginTop:'3vw',marginBottom:'3vw',fontSize:'2.8vw'}} onClick={handleOpenPricingPlanDesktop} class="flex items-center  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md ">
               <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flex-shrink-0 " style={{width:'3vw',marginRight:'1vw'}}>
                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
               </svg>
               Add Pricing Plan 
             </button>
             <div className='border mx-auto  flex flex-col items-center' style={{width:"90%"}}>
       <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"7vw",lineHeight:'8vw',width:'95%'}}>Your course does not have a pricing plan </h1>
       <p className='borel text-gray-500 text-center'style={{fontSize:'3vw',marginTop:'2vw',width:'80%'}}>Make your course payable by adding a pricing plan..</p>
       <button onClick={handleOpenPricingPlanDesktop} style={{fontSize:'3vw',padding:'3vw 4vw',marginTop:'3vw',marginBottom:'3vw'}}class=" m-auto  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md ">
           
               Add Pricing Plan 
             </button>
   </div>
           </div>
   }
   
   {
    pricingPlanPop == true &&
    <div id='pricingPlanPop' className=''>
  

  {
    pricingPopMain == true ?
    <div className='bg-white m-auto  rounded' id='pricingPlanDesktop' style={{width:"90%",height:"98%"}}>
    <div className='flex w-full justify-between items-center'>
    <h1 className='anton text-gray-900 capitalize' style={{width:'80%',fontSize:"8vw",paddingLeft:"6vw",paddingTop:"8vw",color:"blue",lineHeight:'9vw'}}>Select a pricing model   </h1>
         <div style={{width:'20%',}}>
   
           <svg onClick={handleRemovePricingPlanDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'10vw',height:'10vw',position:'absolute',right:'2vw',top:'3vw'}} fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
   </svg>
   </div>
   
    </div>
               <div onClick={handleFreeDesktop} className='border  hover:bg-gray-50 cursor-pointer rounded-md mx-auto' style={{width:'90%',marginBottom:'4vw',paddingTop:'7vw',paddingBottom:'7vw',paddingLeft:'4vw',marginTop:'10vw'}}>
                   <h1 className='fjalla'style={{fontSize:"5vw"}}>Free</h1>
                   <p style={{fontSize:"3vw"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
            
               <div onClick={handlePricingPlanDesktop} className='border  hover:bg-gray-50 cursor-pointer rounded-md mx-auto' style={{width:'90%',marginBottom:'4vw',paddingTop:'7vw',paddingLeft:'4vw',paddingBottom:'7vw'}}>
                   <h1 className='fjalla'style={{fontSize:"5vw"}}>One time purchase</h1>
                   <p style={{fontSize:"3vw"}}className='borel text-gray-500'>Students can access your course for free </p>
               </div>
       </div>
       :
    isLoading == true ?
    <div className='bg-white flex items-center justify-center  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"98%"}}>
    <div class="relative">
    <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
    <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
    </div>
    
    </div>
    :
    success == true ?
<div className='bg-white m-auto  rounded  ' id='successPurchaseDesktop' style={{width:"90%",height:"98%"}}>
<div className='relative bg-white flex items-center justify-center  rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
 <div class="">
 <svg onClick={minimizeSuccessPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
 <p className='anton  text-center' style={{fontSize:"8vw",lineHeight:'9vw'}}>Course Pricing Successfully Set</p>
 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Young%20and%20happy-bro.svg?alt=media&token=e69e3f58-66d8-4e83-ab32-5fddc117d336" alt="success" />
</div>

   </div>
</div>
:
FreePlanPop == true ?
<div className='bg-white m-auto  rounded ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"10vw"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"8vw",paddingTop:"8vw"}}>Free Course </h1>
 <p className='borel text-gray-500'style={{fontSize:'3vw'}}>Your students will be able to take your course for free under this pricing model</p>
</div>
        <svg onClick={handleBackFreePlanDesktop} xmlns="http://www.w3.org/2000/svg"  style={{width:"10vw",height:"10vw"}}fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form onSubmit={freeModelHandler}  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"90%"}}>




<div className="relative" >

               
<p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3.5vw",marginLeft:"2vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Pricing Description </p>
   
   <textarea style={{paddingTop:"2vw",paddingBottom:"2vw",paddingLeft:"3vw",fontSize:"3.5vw",minHeight:"40vw",maxHeight:"70vw"}}  onChange={e=>pricingDescriptionSet(e)}   class="border  text-gray-600 focus:outline-none
       focus:border-black w-full text-base block bg-white
       border-gray-300 rounded-md"
       maxLength={100}></textarea>
       
       <div className={`${pricingDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be empty
</div>
</div>
</div> 
<p className='Mooli text-gray-500' style={{fontSize:'3vw'}}>Max 100 Characters</p>

</div>




<div className="relative" >
<button type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "100%", height: "15vw",marginTop:'6vw' }} class="fjalla mx-auto w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                  Add Pricing Plan
  </button>
</div>
</form>

</div>  
    </div>
    :
    <div className='bg-white m-auto  rounded my-5' id='oneTimePurchaseDesktop' style={{width:"90%",height:"98%"}}>
 <div className='flex w-full justify-between items-center'>
<div style={{paddingLeft:"10vw"}}>
<h1 className='anton text-gray-900 capitalize' style={{fontSize:"8vw",paddingTop:"8vw"}}>One time purchase </h1>

 <p className='borel text-gray-500' style={{fontSize:'3vw'}}>What is the price you would like to charge students for this course?</p>
</div>
        <svg onClick={handleBackOneTimePurchaseDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'12vw',height:'12vw'}} fill="currentColor" class="mr-5 cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
 </div>
      
<div className='flex'>
<form  autoComplete="off" className=" mt-6 mb-0  relative space-y-8 m-auto" id="first_modal" style={{width:"90%"}}>

<div className="relative m-auto " style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
   <p class="bg-white  font-medium text-gray-600 fjalla absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Course Price</p>
       


  <input style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} required autocomplete="off" onChange={e=>pricingSet(e)} placeholder="500 ETB " type="number" 
  class="w-full m-auto form-control text-black border  focus:outline-none focus:border-black w-full  text-gray-700 block bg-white border-gray-300 rounded-md" 
  
  />
     <div className={`${coursePriceError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field can't be empty
</div>
</div>
</div> 
</div>

<div className="relative" >

               
<p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3.5vw",marginLeft:"2vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Pricing Description </p>
   
   <textarea style={{paddingTop:"2vw",paddingBottom:"2vw",paddingLeft:"3vw",fontSize:"3.5vw",minHeight:"40vw",maxHeight:"70vw"}}  onChange={e=>pricingDescriptionSet(e)}   class="border  text-gray-600 focus:outline-none
       focus:border-black w-full text-base block bg-white
       border-gray-300 rounded-md"
       placeholder='Students must pay a one-time payment to enroll in this course'
       maxLength={100}></textarea>
       
       <div className={`${pricingDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be empty
</div>
</div>
</div> 
<p className='Mooli text-gray-500' style={{fontSize:'3vw'}}>Max 100 Characters</p>

</div>





<div className="relative" >

<button type="submit" onClick={oneTimePurchaseHandler} style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "100%", height: "15vw",marginTop:'6vw' }} class="fjalla mx-auto w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                  Add Pricing Plan
  </button>
</div>
</form>

</div>  
    </div>
  }
 






</div>
   }
               {
                editPop == true &&
                <div id='editPlanPop' className=''>
                <div className='bg-white m-auto  rounded my-5 ' id='editPurchaseDesktop' style={{width:"90%",height:"98%"}}>
                <div id="alert-border-7" class="hidden items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
            <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div class="ml-3 text-sm font-medium Mooli capitalize">
             successfully Updated Pricing Model
            </div>
            <button onClick={closeMessage7Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
              <span class="sr-only">Dismiss</span>
              <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </button>
        </div>
                 <div className='flex w-full justify-between items-center'>
                  
              <div style={{paddingLeft:"6vw"}}>
              <h1 className='anton text-blue-800 capitalize' style={{fontSize:"8vw",paddingTop:"10vw"}}>One time purchase </h1>
                 <p className='borel text-gray-500' style={{fontSize:'3vw'}}>What is the price you would like to charge students for this course?</p>
              </div>
                        <svg onClick={closeEditPurchaseDesktop} xmlns="http://www.w3.org/2000/svg" style={{width:'10vw',height:'10vw',marginRight:'4vw'}} fill="currentColor" class=" cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
        </svg>
                 </div>
                      
           <div className='flex'>
          <div style={{width:"90%"}}>
          <div className=' rounded m-auto' >
                            
                            <div className='flex justify-between  ' style={{padding:'0vw 5vw',marginTop:'10vw',marginBottom:'1vw'}}>
                           <div>
                           <h3 class="  anton  " style={{fontSize:"5vw",marginBottom:'1vw'}}>Pricing Model </h3>
                           {
           pricingModelEditor == false &&
              <p className="borel text-gray-500 block"id='editCoursePricingDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{coursePricing}</p>
             
          }
                           </div>
                         
                      {
                       pricingModelEditor == false &&
                      <svg onClick={editInputOpenerDesktop} id="editIconDesktop" xmlns="http://www.w3.org/2000/svg" style={{width:'4vw',height:'4vw'}} fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                      }
                      {
                       pricingModelEditor == true &&
                      <p onClick={editPricingOpenerDesktop} style={{fontSize:'3vw'}} className='fjalla  cursor-pointer hover:text-red-800' id='cancelInputDesktop'>Cancel</p>
     
                      }
                            </div>
                                {
                                 pricingModelEditor == true &&
                                 <div className="relative  " id='editInputDesktop'>
                   
                             
        
                   <button type="submit" onClick={handlePricingPlanFromEditorDesktop} style={{ backgroundColor: "#2141ce", fontSize: "3vw", width: "80%", height: "12vw" }} class="fjalla mx-auto w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
           Change Pricing Model
       </button>
           
                 
                      </div>
                                }
                         
                            <div>
                        
                            </div>
                                    
                          </div>
                          <div className=' rounded m-auto' >
                            
                            <div className='flex justify-between  ' style={{padding:'0vw 5vw',marginTop:'10vw',marginBottom:'1vw'}}>

                           <div>
                           <h3 class="  anton  " style={{fontSize:"5vw",marginBottom:'1vw'}}>Pricing Description </h3>
{
                  pricingDescriptionEditor == false &&

             <p className="borel text-gray-500 block"id='editCoursePricingDescriptionDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{pricingDescription}</p>

}             
                           </div>
                           {
                            pricingDescriptionEditor == false &&
                            <svg id="editDescriptionIconDesktop" style={{width:'4vw',height:'4vw'}} xmlns="http://www.w3.org/2000/svg"  onClick={editDescriptionOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                          </svg>
                           }
                           {
                  pricingDescriptionEditor == true &&
                 <p className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingDescriptionOpenerDesktop} style={{fontSize:'3vw'}} id='cancelDescriptionInputDesktop'>Cancel</p>

                 }
                            </div>
                            {
                  pricingDescriptionEditor == true &&
                  <div id='editDescriptionInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
            
                  <div className="relative" >

     

<textarea defaultValue={pricingDescription} style={{paddingTop:"2vw",paddingBottom:"2vw",paddingLeft:"3vw",fontSize:"3.5vw",minHeight:"40vw",maxHeight:"70vw"}}  onChange={e=>pricingDescriptionSet(e)}   class="border  text-gray-600 focus:outline-none
focus:border-black w-full text-base block bg-white
border-gray-300 rounded-md"
maxLength={100}></textarea>

<div className={`${pricingDescriptionError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be empty
</div>
</div>
</div> 
<p className='Mooli text-gray-500' style={{fontSize:'3vw'}}>Max 100 Characters</p>

</div>
  <button onClick={savePricingDescription} style={{fontSize:'3vw',padding:'3vw 5vw',marginTop:'4vw'}} class="inline-flex  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
  </div>
                              
                            }
                  
                            <div>
                        
                            </div>
                                    
                          </div>
                          <div className={coursePricing == 'Free Pricing' ? 'hidden' : 'block'}>
        
                          <div className=' rounded m-auto' >
                            
                            <div className='flex justify-between  ' style={{padding:'0vw 5vw',marginTop:'10vw',marginBottom:'1vw'}}>

                           <div>
     
                           <h3 class="  anton " style={{fontSize:"5vw",marginBottom:'1vw'}}>Pricing Price </h3>
{
  pricingPriceEditor == false &&
  <p className="borel text-gray-500 block"id='editCoursePricingPriceDesktop' style={{fontSize:"3vw",maxWidth:"100%",wordBreak:"break-all"}}>{coursePrice} ETB</p>

}        
                           </div>
                           {
                        pricingPriceEditor == false &&
                        <svg id="editPriceIconDesktop" style={{width:'4vw',height:'4vw'}} xmlns="http://www.w3.org/2000/svg"  onClick={editPriceOpenerDesktop} width="25" height="25" fill="currentColor" class=" cursor-pointer hover:text-green-800 bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                      }
                           
                      {
                        pricingPriceEditor == true &&
                      <p className='fjalla  cursor-pointer hover:text-red-800'onClick={editPricingPriceOpenerDesktop} id='cancelPriceInputDesktop'>Cancel</p>

                      }
                            </div>
                            {
                        pricingPriceEditor == true &&
                        <div id='editPriceInputDesktop' className=" relative m-auto"style={{width:"90%"}}>
                        <div className="relative m-auto " style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
       


  <input defaultValue={coursePrice} style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}} required autocomplete="off" onChange={e=>pricingSet(e)} placeholder="500 ETB " type="number" 
  class="w-full m-auto form-control text-black border  focus:outline-none focus:border-black w-full  text-gray-700 block bg-white border-gray-300 rounded-md" 
  
  />
     <div className={`${coursePriceError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Price must be greater than 0
</div>
</div>
</div> 
</div>
                        
                        
         
     
            <button onClick={savePriceDesktop} style={{fontSize:'3vw',padding:'3vw 5vw',marginTop:'4vw'}} class="inline-flex  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
        
        Save
      </button>
        </div>
                      }
                
                  </div>
                            <div>
                        
                            </div>
                                    
                          </div>
          </div>
    
            </div>  
                    </div>
                    </div>
               }
       
               {
                pricingDelete == true &&
                <div id='deleteCourseDesktop' className='  flex items-center justify-center'>
          
        
                <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"80%",height:"60%"}}>
         <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
         
         >
         <div class="relative p-6">
         <a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
                
         </a>
         <h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Pricing Model </h1>
         <p class="text-sm text-gray-500 Mooli normal-case capitalize"style={{fontSize:"3vw"}} >Would you like to permanently remove this model? </p>
         
         <div class="flex flex-row mt-6 space-x-2 justify-evenly">
            <a  onClick={deleteHandlerDesktop}style={{paddingTop:'3vw',paddingBottom:'3vw',fontSize:'3vw'}} class="w-full cursor-pointer  text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
            <a  onClick={closePopDeleteDesktop} style={{paddingTop:'3vw',paddingBottom:'3vw',fontSize:'3vw'}}  class="w-full cursor-pointer  text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
         </div>
         </div>
         </div>
         </div>  
         
         
            </div>
               }
   
         
   </div>
   
           )
       })
    }
           </div>
      </div>
      :
      navigate('/dashboard')
      )
    })
  )
}
