import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Chart from 'react-apexcharts';

const ApexChart = () => {
  const [totalEarnings, setTotalEarnings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [totalStudents, setTotalStudents] = useState(0);
  const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;

        // Fetch earnings
        const earningsQuery = query(collection(firestore, 'AccountDetails', uid, 'Students'));
        getDocs(earningsQuery)
          .then((earningsSnapshot) => {
            const earningsByDay = {};
            let totalEarningsSum = 0;

            earningsSnapshot.forEach((doc) => {
              const earningDate = new Date(convertTimestampToDate(doc.data().enrollmentDate));
              const dayOfWeekIndex = earningDate.getDay();
              const dayOfWeek = daysOfWeek[dayOfWeekIndex];
              const earningAmount = doc.data().paid;

              if (!earningsByDay[dayOfWeek]) {
                earningsByDay[dayOfWeek] = 0;
              }
              earningsByDay[dayOfWeek] += earningAmount;

              totalEarningsSum += earningAmount;
            });

            const updatedTotalEarnings = daysOfWeek.map((day) => earningsByDay[day] || 0);

            setTotalEarnings(updatedTotalEarnings);
            setTotalStudents(earningsSnapshot.size);
          })
          .catch((error) => {
            console.log('Error fetching earnings:', error);
          });
      } else {
        console.log("logged out");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const convertTimestampToDate = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleDateString();
    }
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        colors: {
          ranges: [
            {
              from: 1,
              to: Infinity,
              color: '#2141ce',
            },
            {
              from: 0,
              to: 0,
              color: '#999999',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: 'Total Earning',
        data: totalEarnings,
      },
    ],
    xaxis: {
      categories: daysOfWeek,
    },
  };

  return (
<div>
<div id='tablet'>
<h3 className='fjalla' style={{fontSize:'11px'}}>Total Earnings: {totalEarnings.reduce((sum, earnings) => sum + earnings, 0)} ETB</h3>
      <h3 className='fjalla' style={{fontSize:'11px'}}>Total Students: {totalStudents}</h3>


      {/* Render the chart */}
      <div id="chart">
        <Chart options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} height={chartOptions.chart.height} />
      </div>
    </div>
    <div id='desktop' >
      {/* Display the total earnings and total students */}
      <h3 className='fjalla' style={{fontSize:'11px'}}>Total Earnings: {totalEarnings.reduce((sum, earnings) => sum + earnings, 0)} ETB</h3>
      <h3 className='fjalla' style={{fontSize:'11px'}}>Total Students: {totalStudents}</h3>

      {/* Render the chart */}
      <div id="chart" >
        <Chart  options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} height={chartOptions.chart.height} />
      </div>
    </div>
    <div id='mobile'>
      {/* Display the total earnings and total students */}
      <h3 className='fjalla' style={{marginLeft:'5vw'}}>Total Earnings: {totalEarnings.reduce((sum, earnings) => sum + earnings, 0)} ETB</h3>
      <h3 className='fjalla' style={{marginLeft:'5vw'}}>Total Students: {totalStudents}</h3>

      {/* Render the chart */}
      <div id="chart" >
        <Chart options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} height={chartOptions.chart.height} />
      </div>
    </div>
</div>
  );
};

export default ApexChart;