import React,{useEffect,useState} from 'react'
import Nav from './Nav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { onAuthStateChanged,signOut } from 'firebase/auth'
import {auth, firestore} from '../firebase'
import { collection, doc, onSnapshot,deleteDoc } from 'firebase/firestore'
export default function PricingPage() {
    const {user} = useParams()
    const [institutes,setInstitutes] = useState(false)
    const [oneMonth,setoneMonth] = useState(true)
    const [threeMonth,setthreeMonth] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)

    
    const [year,setyear] = useState(false)
    const [pop,setpop] = useState(false)
    const [spinner,setspinner] = useState(false)
    const [uid,setuid] = useState([])



    const [User,setuser]=useState([])
    const [transaction,settransaction]=useState([])
    const [notification,setnotification]=useState([])


  const navigate = useNavigate() 

  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }const convertTimestampToDate = (timestamp) => {
   const date = timestamp.toDate();
   const currentDate = new Date();
   const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
 
   const secondsInMinute = 60;
   const secondsInHour = 3600;
   const secondsInDay = 86400;
   const secondsInWeek = 604800;
   const secondsInMonth = 2592000;
   const secondsInYear = 31536000;
 
   let relativeTime = '';
 
   if (timeDifferenceInSeconds < secondsInMinute) {
     relativeTime = `${timeDifferenceInSeconds}s ago`;
   } else if (timeDifferenceInSeconds < secondsInHour) {
     const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
     relativeTime = `${minutes}m ago`;
   } else if (timeDifferenceInSeconds < secondsInDay) {
     const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
     relativeTime = `${hours}h ago`;
   } else if (timeDifferenceInSeconds < secondsInWeek) {
     const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
     relativeTime = `${days}d ago`;
   } else if (timeDifferenceInSeconds < secondsInMonth) {
     const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
     relativeTime = `${weeks}w ago`;
   } else if (timeDifferenceInSeconds < secondsInYear) {
     const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
     relativeTime = `${months}mo ago`;
   } else {
     const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
     relativeTime = `${years}y ago`;
   }
 
   return relativeTime;
 };
 
    function removeNotification(id){
     const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
 
     deleteDoc(notificationRef)
     .then(() => {
       // Document successfully deleted from the database
       // Now remove the item from the array
       setnotification(prevArray => prevArray.filter(item => item.id !== id));
     })
     .catch((error) => {
       console.error("Error deleting document: ", error);
     });
 
 
    }
  
  function desktopSpinnerHandler(){
    setspinner(false)
   }

  function mobileNavPop(){
    setpop(true)


  

}
function getCurrentDateTimeString() {
  const currentDate = new Date();
  return currentDate.toLocaleString('en-GB', {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Use 24-hour format
  })
  .replace(/,/g, '')
  .replace(/\s/g, '');
}

// Example usage:
const dateTimeString = getCurrentDateTimeString();

const currentDateTimeString = getCurrentDateTimeString();
console.log(currentDateTimeString)

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}

    function oneMonthTeacher(){
      if(oneMonth == false){
        setoneMonth(true)
        setyear(false)
        setthreeMonth(false)
  
      }
  
   
  
    } 
  
    function threeMonthTeacher(){
      if(threeMonth == false){
        setthreeMonth(true)
        setoneMonth(false)
        setyear(false)
  
      }
  
    }
    function yearTeacher(){
      if(year == false){
        setoneMonth(false)
        setyear(true)
        setthreeMonth(false)
  
      }
   
  
    }

   
    function toInstitutesDesktop(){
      if(institutes == false){
   setInstitutes(true)
   navigate('/pricing/institute')
  
      }
    
  
  
    }
  
    function toTeacherDesktop(){
      if(institutes == true){
        setInstitutes(false)
   navigate('/pricing/teacher')

       
           }
       
  
    }
  
    


 

 


    useEffect(() => {
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
     
        onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
            let temp = []
            temp.push({...snapshot.data()})
            setuser(temp)

        })
    
    
    
    
        onSnapshot(collection(firestore,'AccountDetails',uid,'Transactions'),doc=>{
          let temp = []
          doc.docs.forEach(snapshot=>{
            temp.push({...snapshot.data()})
            settransaction(temp)
          })
        

      })
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    function desktopSpinnerHandler(){
      setspinner(false)
    }
    
  return (
    <div>
      {
        
        spinner == true &&
        <div id='spinnerDesktop'>
        <div
    class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-[#31ffaa] align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
    role="status">
    <span
      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
      >Loading...</span
    >
  </div>
  
        </div>
      }

        <div>
{
   User.length !=0 ?
   User.map(i=>{
       const {accountType,billingDate,isPending,uid,plan,fullName,photo} = i
       return(
           <div>
   {
    isPending == false ?
      <div>
     <div>
 <div id="desktop">

 <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
<div className='flex justify-between items-center w-full relative mb-5' >
<div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
<img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
  </Link>

<div>

</div>



</div>


<ul className='flex items-center'>
{
plan == 'Free' ?
<Link to='/pricing'>
<p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
</Link>

:
plan == 'Basic' && 
<Link to='/pricing'>

<p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
</Link>

}
<p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
<div  className="dropdown   mr-5 ">

<div className='notibell relative mt-2'>
{/* <p className='notificationnumber rounded'>{notification.length}</p> */}
<div>
<div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
<button    style={{color:"black"}}className=" inline-block relative">
<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
</svg>
<span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
</button>
</div>
</div>
</div>
{
notificationPop == true &&
<div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
{/* top */}
<div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
<h1 className='py-3 Mooli'>Notifications</h1>
<div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
{/* <strong>{notification.length}</strong> */}
{notification && notification.length}
</div>
</div>
<hr />
{/* end top */}
{/* body */}
{/* item */}

{/* end item */}
{/* item */}

{/* end body */}
{/* bottom */}


{
notification && notification.length != 0 ?
notification.slice(0,3).map(i=>{
const {notificationTitle,declinedReason,time,id} = i
return(
<div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
</p>

<p className='fjalla'>
{convertTimestampToDate(time)}

</p>

</div>
<div className=" px-4 py-2 mt-2 fjalla">
<Link
to="/transactions"
className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
>
view all
</Link>

</div>
</div>

)
}) :
<div> 
<h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
No notifications

</h1>
<p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

</p>
</div> 
}





{/* end bottom */}
</div>
}

</div>
<div className="flex  items-center">
{/* user */}
<div className="dropdown relative "onClick={popProfile} >

<button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >

<div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>





{
photo.length == 0 ?  
<div
className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
style={{width:"40px",height:"40px",fontSize:'12px'}}
>
{fullName[0]}

</div>
:
<img className="w-full h-full object-cover" alt='' src={photo} />


}
</div>

<div className="ml-2 capitalize flex ">


</div>
</button>
{
pop == true && 
<div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
{/* item */}
<Link to="/profile"
style={{fontSize:'12px'}}
className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
>

Profile
</Link>
{/* end item */}
{/* item */}


{/* item */}
<div
onClick={handleLogOut}
style={{fontSize:'12px'}}

className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
>
Log out
</div>
{/* end item */}
</div>
}

</div>

</div>


</ul>
</div>

</nav>
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 Months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basic3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premium3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiumannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>


<div id="tablet">

{
         pop === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
           }          
        {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'14%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'12px'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[20px] h-[20px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4 px-5" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>
<div id="mobile">

{
         pop === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
           }          
        {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>

  </div>
      </div>
    :
      transaction && transaction.map(i=>{
        const {chosenMethod,amount} = i
        return(
          <div class="h-screen   flex items-center">
          <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                  <div class="w-full lg: mx-8">
                      <div style={{fontSize:'3vw',lineHeight:'4vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                      <p className='fjalla flex flex-col' style={{fontSize:'1.5vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                      </div>
                 
                      <Link to={`/dashboard`}>
                      <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                    
                      Check out my account until the payment is verified
                                  </button>
                      </Link>      
       
                  </div>
              <div class="w-full lg:flex lg:justify-end lg: mx-5 my-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
              </div>
          
          </div>
      </div>
        )
      })
    

   }
        
       
            
       
      
       
               </div>
       )
   })
 
   :
  <div>
 <div id="desktop">

<Nav/>
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 Months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basic3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premium3month`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4">
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="MuiBox-root css-15vf48v">
        <h5
          className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
         
          style={{color:'white'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv">
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr">
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiumannual`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>


<div id="tablet">

{
         pop === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
           }          
        {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'14%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'12px'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[20px] h-[20px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4 px-5" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'40px'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>
<div id="mobile">

{
         pop === true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>

<Link to='/contact'>


<button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
</Link>
</div>
         <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>

         <div className='flex justify-between w-full mt-3'>
               <div>
               </div>
               <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                 <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
               </button>
             </div>
   
            {
             <li>
             <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
         </li>
             }
             <li>
             <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
         </li>

{
             <li>
             <Link to='/pricing/institute'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
         </li>
             }
      <li>
             <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
         </li>
         
         </ul>
     </div>
           }          
        {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={popProfile} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
 

{
   user == 'institute' ?
  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#1B1B1B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#1B1B1B"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute/free`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/basicmonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute/premiummonth`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#1B1B1B"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>



:
user != 'institute' &&
<section class="bg-white  pt-[64px]" id='desktop'>
<div class=" flex items-center  px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
        <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
        </p>
        <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
        Page not found</h1>
        <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>

        <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
<Link to='/'>
<button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>


                <span>Go back</span>
            </button></Link>
  <Link to='/contact'>
            <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                Contact Us
            </button>
  </Link>

        </div>
    </div>
</div>
</section>
}




</div>

  </div>
}




</div>
    </div>
  )
}
