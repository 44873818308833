import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { auth,firestore } from '../firebase';
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';

export default function Certificates() {
  const [certificate,setCertificate] = useState([])
  const [uid,setuid] = useState("")
  const [courseId,setcourseId] = useState("")
  const [user,setuser] = useState([])
  const [notification,setnotification] = useState([])

  const [spinner,setspinner] = useState(true)
  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)

  const navigate = useNavigate()



    



    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
        //  setcred(uid)
      setuid(uid);
         
  const userRef = doc(firestore,"TotalUsers",uid);
    
  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)

    
    
  

  })
  //   fetch the courses data from the courses collection which has courseId id 
  onSnapshot(collection(firestore, "AccountDetails", uid, "Certificates"), (snapshot) => {
    let promises = [];
  
    snapshot.docs.forEach((snapshotDoc) => {
      const courseDocRef = doc(firestore, 'Courses', snapshotDoc.data().certificateId);
      promises.push(
        new Promise((resolve, reject) => {
          onSnapshot(courseDocRef, (snap) => {
            if (snap.exists()) {
              resolve({
                ...snapshotDoc.data(),
                id: snapshotDoc.id,
                courseTitle: snap.data().courseTitle
              });
            } else {
              resolve({
                ...snapshotDoc.data(),
                id: snapshotDoc.id,
                courseTitle: 'Course not found'
              });
            }
          }, (error) => {
            reject(error);
          });
        })
      );
    });
  
    Promise.all(promises).then((main) => {
      setCertificate(main);
    }).catch((error) => {
      console.error('Error fetching course titles:', error);
    });
  });
  
          
  
  const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

  onSnapshot(notificationRef,snapshot=>{
    let main =[]
    



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })
  
             
   
  //    })
    
              
    
    
    
    
    
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    const popProfile = () =>{
      if(pop == true){
        setpop(false)
      setnotificationPop(false)
  
  
      }
      else{
      setpop(true)
      setnotificationPop(false)
  
  
  
      }
  }
  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  };
  const popNotification = () =>{
    if(notificationPop == true){
      setnotificationPop(false)
      setpop(false)
    }
    else{
    setnotificationPop(true)
    setpop(false)
  
  
  
    }
  }
     function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      setDoc(notificationRef,{
        seen:true
      },{merge:true})
      .then(() => {
        
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
      });
  
  
     }
    let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

    function openPopDeleteDesktop(id){
      const pop = document.getElementById("deleteCertificateDesktop")
    
        pop.style.display ="flex" 
    setcourseId(id)
      
    }
    function closePopDeleteDesktop(){
      const pop = document.getElementById("deleteCertificateDesktop")
    
        pop.style.display ="none" 
    
      
    }
    function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      deleteDoc(notificationRef)
      .then(() => {
        // Document successfully deleted from the database
        // Now remove the item from the array
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
      });
  
  
     }

    function closeMessage6Desktop(){
      const message= document.getElementById("alert-border-6")
      message.style.display="none"
    }
    function deleteHandlerDesktop(){
      const pop = document.getElementById("deleteCertificateDesktop")
      const message= document.getElementById("alert-border-6")
      const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
      const documentRef2 = doc(firestore,"Courses",courseId)
const documentRef3 = doc(firestore,"AccountDetails",uid,"Certificates",courseId)


      const lectureData = {
   
        certificateId:"",
        certificate:false,
certificateStudentName:"",
certificateDescription:"",
certificateInstructorName:"",
certificateInstructorSignature:"",

         }

      setDoc(documentRef, lectureData, { merge: true })
      setDoc(documentRef2, lectureData, { merge: true }).then(
        setDoc(documentRef3,lectureData)
      ).then(
      deleteDoc(doc(firestore, 'AccountDetails',uid,"Certificates",courseId))

      ).then(
        pop.style.display="none",
        message.style.display="flex"
      )
    }
    
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
 
   function desktopSpinnerHandler(){
     setspinner(false)
    }
  return (

  user.map(i=>{
    const{accountType,plan,fullName,photo} = i

    return(
      accountType =='Student' ?
      navigate('/dashboard')
      :
      <div>
        {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
      <div id="desktop">
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>

       
            
            <div id="alert-border-6" class="hidden items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
          <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <div class="ml-3 text-sm font-medium Mooli capitalize">
           Certificate successfully removed 
          </div>
          <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
            <span class="sr-only">Dismiss</span>
            <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
      </div>
            <div id='deleteCertificateDesktop' className='hidden  flex items-center justify-center'>
             
           
             <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
      <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
      
      >
      <div class="relative p-6">
      <a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
         <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
             <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
           </svg>
             
      </a>
      <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Certificate</h1>
      <p class="text-sm text-gray-500 Mooli normal-case capitalize" >Would you like to permanently remove this certificate? </p>
      <div class="flex flex-row mt-6 space-x-2 justify-evenly">
         <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
         <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
      </div>
      </div>
      </div>
      </div>  
      
      
         </div>
      
            {
              plan != 'Free'?
          certificate &&    certificate.length != 0 ?
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400  border mt-5">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                  <th scope="col" class="px-6 py-3 Mooli text-center">
                      Certificate For
                  </th>
                  <th scope="col" class="px-6 py-3 Mooli text-center">
                      Instructor
                  </th>
                  <th scope="col" class="px-6 py-3 Mooli text-center">
                      Creation date
                  </th>
                  <th scope="col" class="px-6 py-3 Mooli text-center">
                      Action
                  </th>
             
           
           
        
              </tr>
          </thead>
          {
              certificate.map(i=>{
                const {createdTime,courseTitle,certificateInstructorName,id,} = i
                return(
         
      
      <tbody>
      
      <tr class=" bg-white border-b  ">
      
                <th scope="row" class="capitalize underline fjalla text-center    px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
              {courseTitle}
                </th>
                <td class="capitalize px-6 py-4 fjalla text-center text-gray-800" style={{fontSize:"14px"}}>
                  {certificateInstructorName} 
                </td>
                <td class="px-6 py-4 borel text-center text-gray-800" style={{fontSize:"14px"}}>
                {createdTime && convertTimestampToDate(createdTime)}
                
                </td>
                <td class="px-6 py-4 fjalla text-center text-gray-800 flex justify-center" style={{fontSize:"14px"}}>
                    {/* {totalEarnings} ETB */}
      <Link to={`/course/${id}/certificate/${id}`}>
      
                  <svg   aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer hover:text-green-800 flex-shrink-0 h-5 w-5   mr-4">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg></Link>
                  <svg
                  onClick={()=>openPopDeleteDesktop(id)}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
                viewBox="0 0 16 16"
              >
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
              </svg>
                </td>
             
            
            </tr>
      
      
      
      
      
      </tbody>
      
                )
              })
            }
      </table>
              
              :
              <div className='flex items-center justify-center flex-col '>
              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"25%"}}/>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"40px"}}>You have not created any certificates
            
            
            </h1>
                <p className='borel text-gray-500 text-center'>Make sure to create certificates for your students</p>
           
              </div>
              :
         
                 <div className='flex items-center justify-center flex-col '>
                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"25%"}}/>
                   <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Upgrade your plan if you wish to create certificates for your students
               
               
               </h1>
              
                 </div>
            }
      
      </div>
      <div id='tablet'>
      <nav className='flex justify-between navTablet w-full  'style={{padding:'5vw 1vw 3vw 2vw'}}>

<Link to="/dashboard"   className='' style={{width:'15%'}}>
          <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
            </Link>

  <ul className='flex items-center justify-end' style={{width:'70%'}}>
{
plan == 'Free' &&
<Link to='/pricing'>

<p className='text-blue-800  hover:underline Mooli cursor-pointer' style={{fontSize:'15px',marginRight:'2vw'}}>Upgrade Plan</p>
</Link>



}
<div  className="dropdown   mr-5 ">
  
  <div className='notibell relative mt-2'>
 {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
<div>
  <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
    <button    style={{color:"black"}}className=" inline-block relative">
<svg style={{width:'25px'}} xmlns="http://www.w3.org/2000/svg" className=" text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
</svg>
<span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span>
</button>
  </div>
  {
    notificationPop == true &&
      <div id='notification' className=" menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
    {/* top */}
    <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
      <h1 className='py-3 Mooli'>Notifications</h1>
      <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
        {/* <strong>{notification.length}</strong> */}
        {notification && notification.length}
      </div>
    </div>
    <hr />
    {/* end top */}
    {/* body */}
    {/* item */}
  
    {/* end item */}
    {/* item */}

    {/* end body */}
    {/* bottom */}
 

    {
      notification && notification.length != 0 ?
      notification.slice(0,3).map(i=>{
        const {notificationTitle,declinedReason,time,id} = i
        return(
          <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
        </p>

        <p className='fjalla'>
          {convertTimestampToDate(time)}

        </p>
      
</div>
<div className=" px-4 py-2 mt-2 fjalla">
      <Link
        to="/notification"
        className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
      >
        view all
      </Link>
    
    </div>
</div>

        )
      }) :
      <div> 
      <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"3.5vw"}}>
  No notifications

      </h1>
      <p className='text-gray-400 text-center pb-4 px-3'style={{fontSize:"2vw"}}>
We'll let you know when deadlines are approaching, or there is a course update

      </p>
  </div> 
    }




 
    {/* end bottom */}
  </div>
  }
  </div>
  </div>
 

</div>
<div className="flex  items-center">
{/* user */}

</div>
<svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-gray-900 cursor-pointer  bi bi-list" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 


</ul>
</nav>
{
pop == true &&
<div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
<div className='flex justify-between px-7 py-5'>
<div className='flex justify-start' style={{width:'33%'}}>

<svg  onClick={popProfile} xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="currentColor" class="cursor-pointer bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
</svg>
</div>

<div className='relative' style={{width:'15%'}}>
<Link to="/dashboard"  className='' style={{width:'10%'}}>
          <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
            </Link>
            </div>
            <div className='flex justify-end' style={{width:'33%'}}>

            <Link to='/mycourse'>
  <button type='submit' style={{backgroundColor:"#2141ce",height:'50px' }} className="px-4 w-full inline-block    text-center text-white 
                    rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer fjalla">Courses</button></Link>
 
</div>

</div>
          <ul>
         <Link to="/profile">
         <li className=' text-gray-800 pb-1 ' style={{marginTop:"20px"}}>
    
    <div  className='pl-10 cursor-pointer border-t  flex justify-between py-5 pr-10'>
    <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
              
              <div className=" rounded-full  overflow-hidden" style={{width:"50px",height:"50px"}}>
          

    
    
               
    {
                    photo.length == 0 ?  
                  <div
                    className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
                  style={{width:"50px",height:"50px"}}
                  >
                    {fullName[0]}
    
                  </div>
                  :
                  <img className="w-full h-full object-cover" alt='' src={photo} />

    
                  }
            </div>
            
                <div className="ml-2 capitalize flex fjalla " style={{fontSize:'21px'}}>
           
                  {fullName}
                </div>
              </button>
               

<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
</svg>
                    </div>
              </li></Link>
              
              <li className=' text-gray-800 pb-1'><Link to="/mystudents">
              <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                    <div className=' pl-10'>My Students </div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{width:"4vw"}} fill="currentColor" class="bi bi-people w-7 h-7" viewBox="0 0 16 16">
<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
                    </div>


              </Link></li>

              <Link to='/transactions'>
              <li className=' text-gray-800 pb-1'>
              <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                    <div className=' pl-10'>Transactions </div>
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-activity w-7 h-7" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>
                    </div>
              </li>
              </Link>
              <Link to='/certificate'>
              <li className=' text-gray-800 pb-1'>
              <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                    <div className=' pl-10'>Certificates </div>
                    <img className='w-7 h-7' src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

                    </div>
              </li>
              </Link>

              <Link to='/notification'>
              <li className=' text-gray-800 pb-1'>
              <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                    <div className=' pl-10'>Notification </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
  </svg>
                    </div>
              </li>
              </Link>

              <li onClick={handleLogOut}className=' text-gray-800 pb-1'>
              <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                    <div className=' pl-10'>Log out </div>
                 
                    <svg  className='mr-2' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
<path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
</svg>
                    </div>
             </li>
     

     
       
         


          </ul>
      </div>
}
  </div>
  <div id='mobile'>
  <nav className='shadow flex justify-between navMobile border-b w-full'>
                <div   className=' '>
                <svg onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"9vw"}} fill="currentColor" class="bi bi-list text-gray-800" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
  </svg>
                </div>


  <Link to="/dashboard"  style={{width:'19%'}}>
      <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
    </Link>
<div   className=' '>

  <Link to='/mycourse' >
    <button type='submit' style={{backgroundColor:"#2141ce" ,padding:"2vw 4vw",fontSize:"3vw"}}  class="w-full flex justify-center items-center  text-xl font-medium text-center text-white 
                  rounded-md fjalla transition duration-200 hover:bg-indigo-600 ease cursor-pointer">Courses
    </button>
  </Link>
</div>

</nav>
    {
      pop == true &&
      <div id='popoutMobileHome' className='relative '>
      <nav className='shadow flex justify-between navMobile border-b w-full'>
            <div   className=' 'style={{width:'28%'}}>

<svg  onClick={popProfile} xmlns="http://www.w3.org/2000/svg" style={{width:"8vw"}} fill="currentColor" class="bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
  </svg>
            </div>


<Link to="/dashboard" onClick={popProfile} style={{width:'19%'}}>
  <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
</Link>
<div   className='flex justify-end ' style={{width:'33.33%'}}>
{
plan == 'Free' &&
<Link to='/pricing'>

<p className='text-gray-800  hover:underline Mooli cursor-pointer' style={{fontSize:'3vw',marginRight:'1.5vw'}}>Upgrade Plan</p>
</Link>



}

</div>

</nav>



<ul className='w-full'>
             <Link to="/profile">
             <li className=' text-gray-800  ' style={{marginTop:"2%"}}>
        
        <div style={{paddingLeft:"4vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className=' cursor-pointer   flex justify-between '>
        <button  className="menu-btn focus:outline-none focus:-outline flex flex-wrap items-center">
          <div style={{width:"8vw",height:"8vw"}} className=" overflow-hidden rounded-full">
          {
                  photo !=  '' &&  
                <img  className="w-full h-full object-cover" src={photo} />
  
                }
  
  {
                  photo == '' &&  
                <div
                style={{width:"8vw",height:"8vw",fontSize:"3vw"}}
                  class="flex fjalla items-center justify-center text-white  bg-gray-800 rounded-full  m-auto rounded-full object-cover "
                >
                  {fullName && fullName[0]}
  
  
                </div>
  
                }
          </div>
          <div className="ml-2 capitalize flex ">
            <div className='flex  flex-col'>
            <h1 className="text-sm text-gray-800  mb-2 p-0 leading-none fjalla" style={{fontSize:"5vw",color:"black",margin:0,marginTop:"10px",marginBottom:"8px",lineHeight:"8px"}}>
                  {fullName}  <br />
                </h1>
  
            </div>
              </div>
        </button>
                   
  
  <svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw",color:"black"}} fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
  </svg>
                        </div>
                  </li></Link>
                  <li className=' text-gray-800 '><Link to="/mystudents" >
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>My Students </div>


<svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/transactions" >
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Transactions </div>
    

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '><Link to="/certificate" onClick={popProfile}>
                  <div   style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='  borel' style={{fontSize:"3vw"}}>Certificates </div>
      
<img style={{width:"5vw"}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

                        </div>
                  </Link></li>
                  <li className=' text-gray-800 '>
                   <Link to='/notification'>

                  <div  style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                   <div className='borel 'style={{fontSize:"3vw"}}>Notifications </div>
                        <svg xmlns="http://www.w3.org/2000/svg" class=" text-gray-800"style={{width:"5vw"}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
      </svg>
      </div>

      </Link>
                  </li>
                  <li onClick={handleLogOut}className=' text-gray-800 '>
                  <div style={{paddingLeft:"12vw",paddingRight:"7vw",paddingTop:"4vw",paddingBottom:"4vw"}} className='cursor-pointer border-t  flex justify-between '>
                        <div className='borel' style={{fontSize:"3vw"}}>Log out </div>
                     
                        <svg  className='' xmlns="http://www.w3.org/2000/svg" style={{width:"5vw"}} fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
    <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
  </svg>
                        </div>
                  </li>
         
        
  
  
              </ul>
  </div>
    }
  </div>
      
          </div>
    )

  })

  )
}
