import { collection, onSnapshot,query,orderBy, doc, setDoc,getDoc, serverTimestamp, deleteDoc, updateDoc } from 'firebase/firestore';

import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { v4 } from 'uuid';
import { UseAuth } from '../context/contex';

export default function LecturePreview() {
  const {courseId,lectureId} = useParams()
  const [uid,setuid] = useState("")
  const [course,setcourse] = useState([])
  const [lecture,setlecture] = useState([])
  const [lectures,setlectures] = useState([])
  const [lectureMain,setlectureMain] = useState([])
  const [isAudio,setisAudio] = useState(false)
  const [isPicture,setisPicture] = useState(false)
  const [isDescription,setisDescription] = useState(false)
  const [spinner,setspinner] = useState(false)
  const [quizError,setquizError] = useState("")
   const [finalLecture,setfinalLecture] = useState()
    const [initialLecture,setinitialLecture] = useState()
    const [studentLecture,setstudentLecture] = useState([])
    const [completedLectureIDs,setcompletedLectureIDs] = useState([])
    const [videoDurations,setVideoDurations] = useState(false)
    const{accountType} = UseAuth()
    const [totalCompletedLectures,settotalCompletedLectures] = useState()

  const [isPdf,setisPdf] = useState(false)
  const [currentLectureId,setCurrentLectureId] = useState("")

  const [selectedlecture,setSelectedLecture] = useState([])
  const [quiz,setQuiz] = useState([])
  const [notification,setnotification] = useState(true)


  const navigate = useNavigate()

  function closeQuizeErrorMessage(){
    setquizError('')
  }

  function closeNotification(){
    setnotification(false)
  }
  const formatVideoDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const getVideoDuration = async (videoUrl) => {
    try {
      const video = document.createElement('video');
      video.src = videoUrl;
  
      await new Promise((resolve, reject) => {
        video.addEventListener('loadedmetadata', resolve);
        video.addEventListener('error', reject);
      });
  
      const duration = video.duration;
      return duration;
    } catch (error) {
      console.error('Error retrieving video duration:', error);
      return null;
    }
  };
  const handleVideoTimeUpdate = (lectureId,instructorId,lectureQuiz) => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;
    const completionPercentage = (currentTime / duration) * 100;
  
    if (completionPercentage >= 75) {
      // Call the function to complete the lecture automatically
      completeLecture(lectureId, instructorId,lectureQuiz);
    }
  };
  useEffect(() => {
    const fetchVideoDurations = async () => {
      const durations = {};
      for (const lectureItem of lectureMain) {
        const { lectureVideo } = lectureItem;
        const duration = await getVideoDuration(lectureVideo);
        durations[lectureVideo] = duration;
      }
      setVideoDurations(durations);
    };
  
    fetchVideoDurations();
  }, [lectureMain]);
  function completeLecture(lectureID, instructorId, lectureQuiz) {
    const ref = doc(firestore, 'AccountDetails', uid, 'Courses', courseId);
    const ref2 = doc(firestore, 'AccountDetails', instructorId, 'Students', uid);
    const ref3 = doc(firestore, 'AccountDetails', instructorId, 'CoursesCreated', courseId, 'Students', uid);
    
    const lecturesCollectionRef = collection(firestore, "Courses", courseId, "lectures");
               
    const lecturesCollectionQuery = query(lecturesCollectionRef, orderBy('createdTime'));
   
    window.scrollTo(0, 0);
  
    if (lectureQuiz == true) {
   
    } else {
    }
  }
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
  function openPdf(){
    setisPdf(true)
    setisAudio(false)
    setisDescription(false)
    setisPicture(false)
  
  }
  function openDescription(){
    setisPdf(false)
    setisAudio(false)
    setisDescription(true)
    setisPicture(false)
  
  }
  
  function openAudio(){
    setisPdf(false)
    setisAudio(true)
    setisDescription(false)
    setisPicture(false)
  
  }
  function openPicture(){
    setisPdf(false)
    setisAudio(false)
    setisDescription(false)
    setisPicture(true)
  
  }
  function desktopSpinnerHandler(){
    setspinner(false)
   }

   useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
      //  setcred(uid)
    setuid(uid);
       
  
  

   


    const lecturesRef1 = collection(firestore,"Courses",courseId,"lectures");
  const q1 = query(lecturesRef1, orderBy('createdTime'));
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(q1,snapshot=>{
      let main =[]
      snapshot.docs.forEach(i=>{
      main.push({...i.data(),id:i.id})
      setlectureMain(main)


      })
  
    })
              
   
    const lecturesRef = collection(firestore,"Courses",courseId,"lectures");
  const q = query(lecturesRef, orderBy('createdTime'));
  
  onSnapshot(q,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
      setlectures(main)
    })

    

    
    
  

  })
  

  const lectureRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures",lectureId);
  
  onSnapshot(lectureRef,snapshot=>{
    let main =[]
    main.push({...snapshot.data(),id:snapshot.id})
    setSelectedLecture(main)
  })
           
  const documentRef = collection(firestore,"AccountDetails",uid,"CoursesCreated",courseId,"lectures")
  onSnapshot(documentRef,snapshot=>{
    let main =[]
    snapshot.docs.forEach(doc=>{
            main.push({...doc.data()})
            setlecture(main)
          

    })
         
  //   fetch the courses data from the courses collection which has courseId id 
  onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
    let main =[]
    
    main.push({...snapshot.data()})
    setcourse(main)

  })





  
  })

    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId,'lectures',lectureId),snapshot=>{
      let main =[]
      main.push({...snapshot.data()})
      setstudentLecture(main)
  
    })
        
  
   
  


   
  
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, [lectureId]);

  // Function to handle click event and move to the next lecture
const handleNextLecture = () => {
  // Find the index of the current lecture
  const currentIndex = lectures.findIndex((lecture) => lecture.id === currentLectureId);
  setSelectedLecture([])

  const main = []
  // Check if there is a next lecture
  if (currentIndex < lectures.length - 1) {
    const nextLecture = lectures[currentIndex + 1];
    main.push({...nextLecture})
      navigate(`/course/${courseId}/preview/${nextLecture.id}`)
    setCurrentLectureId(nextLecture.id);
  } else {
    console.log("No more lectures");
  }
};
const handlePreviousLecture = () => {
  const currentIndex = lectures.findIndex((lecture) => lecture.id === currentLectureId);
  setSelectedLecture([])

  if (currentIndex > 0) {
    const previousLecture = lectures[currentIndex - 1];
    const previousLectureId = previousLecture.id;

    navigate(`/course/${courseId}/preview/${previousLectureId}`);

    // Update the current lecture ID or perform any other necessary actions
    setCurrentLectureId(previousLectureId);
  } else {
    console.log("No previous lectures");
  }
};



  function resetVideoDesktop(currentIndex){
       
    if(lectureMain[currentIndex].lectureId == lectureId){

    }
    else{
      setSelectedLecture([])
    setstudentLecture([])
      const lecture = lectureMain[currentIndex]
  
    navigate(`/course/${courseId}/preview/${lecture.id}`)
      
    }
 
  }

  function hideMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const open = document.getElementById("openDesktop")
    const body = document.getElementById("lectureBody")

    menu.style.width="0"
    hide.style.display="none"
    open.style.display="flex"

    body.style.width="100%"

    
  }

  function openMenuDesktop(){
    const menu = document.getElementById("menuDesktop")
    const hide = document.getElementById("hideDesktop")
    const body = document.getElementById("lectureBody")

    const open = document.getElementById("openDesktop")
    hide.style.display="flex"
    open.style.display="none"

    body.style.width="75%"
    
    menu.style.width="25%"
  }

  function quizPopDesktop(){
    const video = document.getElementById("courseVideoDesktop")

    
    video.style.display="none"



  }


  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      console.log(video.currentTime);
    };

    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);



  return (
    <div>
       <div id='desktop'  className='relative'>
   {
    notification == true &&
    <div className='px-3 notification-Top bg-blue-800 text-center borel flex items-center text-white justify-between '>
    You won't be able to use some of the buttons since they are only for preview purposes
    <svg onClick={closeNotification} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="cursor-pointer bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>
    </div>
   }
       <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
        
<Link to="/mycourse"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/mystudents"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 <Link to="/certificate"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
{

<div id='mainPage' className='relative'>
{
course.length !=0 ?
course.map(i=>{
const {courseTitle,isTest,instructorId,status} = i
return(

<div className='flex  '>
<div id='menuDesktop' className=' shadow-md w-full bg-white ' style={{width:"27%",overflowY:"scroll",position:'fixed',left:'5%',top:'0'}}>


 {
  lectureMain && lectureMain.length !== 0 ?
  <div>
    
<p id='hideDesktop' onClick={hideMenuDesktop} style={{fontSize:'11px'}} className='text-gray-800 cursor-pointer fjalla flex items-center hover:text-blue-800 justify-end mr-5 mt-8'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>Hide menu</p>
   {
         lectureMain.map((lecture,currentIndex) => {
           const { lectureTitle,  isCompleted,lectureVideo,id } = lecture;
           
           // Function to handle click event and move to the previous lecture
           const duration = videoDurations[lectureVideo];
           
           return (
            <div>
              
               <div onClick={()=>resetVideoDesktop(currentIndex)} className={`  ${id === lectureId ? "bg-blue-50 borderBlue" : ''} border-b  cursor-pointer`} >
              
                 <div className='flex     px-4 '>
                
    
  
        <div
        // onClick={() => handleLectureClick(lectureTitle)}
        className="cursor-pointer  rounded pb-3  flex flex-col items-start"
        key={lectureTitle}
      >
        {/* Lecture content */}
        <h1 className={`flex items-center fjalla text-center text-gray-900 mt-8 `} style={{ fontSize: '15px' }}>
        {
          completedLectureIDs.includes(id) ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="text-green-800 mr-2 bi bi-browser-edge" viewBox="0 0 16 16">
          <path d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.84 3.84 0 0 0 16 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 0 0 .473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006z"/>
          <path d="M.01 7.753a8.14 8.14 0 0 0 .753 3.641 8 8 0 0 0 6.495 4.564 5 5 0 0 1-.785-.377h-.01l-.12-.075a5.5 5.5 0 0 1-1.56-1.463A5.543 5.543 0 0 1 6.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285q.194.001.384.033a4 4 0 0 0-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49m10.263 7.912q.133-.04.265-.084-.153.047-.307.086z"/>
          <path d="M10.228 15.667a5 5 0 0 0 .303-.086l.082-.025a8.02 8.02 0 0 0 4.162-3.3.25.25 0 0 0-.331-.35q-.322.168-.663.294a6.4 6.4 0 0 1-2.243.4c-2.957 0-5.532-2.031-5.532-4.644q.003-.203.046-.399a4.54 4.54 0 0 0-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996"/>
        </svg>
        :
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="mr-2 text-blue-800 bi bi-opencollective" viewBox="0 0 16 16">
                  <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732z"/>
                  <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.07 5.07 0 0 1-2.732.781z"/>
                </svg>
  
  
        }
        
  
          {lectureTitle} 
        </h1>
      
        <p className='flex'>
          
          {duration && <span className='pt-3 borel text-gray-500'> {formatVideoDuration(duration)}</span>}
        </p>
      </div>
    
  
                 </div>
                
               </div>
              
            </div>
  
           );
         })
   }
    {
     isTest == true   &&
          <Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>
              <div className=" ">
             <div className='border-t px-4 border-b flex w-full cursor-pointer  rounded  flex flex-col items-start'>
        
        <h1 className={`flex items-center fjalla text-center text-gray-900 my-7 `} style={{ fontSize: '15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="mr-3 bi bi-calendar2-range-fill" viewBox="0 0 16 16">
<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5M10 7a1 1 0 0 0 0 2h5V7zm-4 4a1 1 0 0 0-1-1H1v2h4a1 1 0 0 0 1-1"/>
</svg>
        Final assessment
</h1>

                 
             </div>
             </div></Link>
        }
  </div>



   :
   <div className='flex flex-col justify-center' style={{height:"80vh"}}>
     <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
<p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>

   </div>

 }

</div>
<div id='lectureBody' className=' border-l flex  flex-col ' style={{width:"72%",height:"100%",position:'absolute',right:'0%',top:0,}}>

{

lectureMain.length != 0 ?

 studentLecture.length !=0 ?
studentLecture.map(i=>{
 const{lectureVideo,lectureTitle,lectureDescription,lectureQuiz,lecturePdf,lectureImg,lectureAudio} = i
 return(
   <div>
<div className='flex justify-between mt-5  w-full '>
<div className='flex items-center my-3 ml-6'>
<p style={{fontSize:'14px'}}  id='openDesktop'onClick={openMenuDesktop} className='hidden cursor-pointer fjalla flex items-center hover:text-blue-800 text-gray-800 justify-end mr-5 '>
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mr-1 bi bi-list" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>Open Menu</p>
<Link to={`/dashboard`}>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="hover:text-gray-800 text-blue-800 bi bi-house-door" viewBox="0 0 16 16">
<path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
</svg></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/1500/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>

<Link to={`/course/${courseId}/`}>
<h1 className=" fjalla hover:text-gray-800  underline hover:text-gray-800 text-blue-800" style={{fontSize:'11px'}}>{courseTitle}</h1></Link>
<svg className='hover:text-gray-800 text-blue-800 mx-1' aria-hidden="true" width="15" height="15"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
<h1 className=" fjalla    text-blue-800" style={{fontSize:'11px'}} >{lectureTitle}</h1>

     </div>
<div className='flex'>
{
initialLecture == false &&
<p onClick={handlePreviousLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Previous</p>

}
{
finalLecture == false &&
<p onClick={handleNextLecture} className='mr-8 fjalla hover:text-blue-800 cursor-pointer pt-3' style={{fontSize:"15px"}}>Next</p>

}
   </div>  


 


</div>
   <div className='' id='courseVideoDesktop'>
{
quizError.length != 0 &&

<div id="alert-border-10" class="flex items-center p-4 mb-4 text-red-800 border border-red-300 bg-red-50 rounded-xl" role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli ">
{quizError}
</div>
<button onClick={closeQuizeErrorMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
 <span class="sr-only">Dismiss</span>
 <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</button>
</div>
}

<div className='flex justify-between items-center my-5'>
   <h1 className='anton capitalize  pl-12 py-5 ' style={{fontSize:"30px"}}>{lectureTitle}</h1>
{
 lectureQuiz == true &&  
   <button style={{fontSize:'12px'}}  class=" fjalla mt-5 mr-10 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
   
   Take Quiz
 </button>
}
</div>
<video
 id="myVideoDesktop"
 ref={videoRef}
 controls
 className="mx-auto rounded-xl shadow-md"
 style={{ width: "90%", height: "60vh",objectFit:'contain' }}
 onTimeUpdate={()=>handleVideoTimeUpdate(lectureId,instructorId,lectureQuiz)}
>
 <source type="video/mp4" src={lectureVideo} />
 Your browser does not support the video tag.
</video>
   <div className='flex justify-between mt-8 px-8 '>
     <div>

     </div>
  
  
 
     {
finalLecture == true?
<Link to={`/course/${courseId}/test/${courseId}/${courseId}`}>

<button  onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Final Exam</button>
</Link>
:
<button onClick={()=>completeLecture(lectureId,instructorId,lectureQuiz)} type="button" class="mb-3  text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5  mb-2">Mark as completed</button>

}
   </div>
   <ol class="pt-8  flex items-center justify-center border-none  space-x-2  text-center text-gray-500      ">
             
             
             
            {
             isDescription ?
             <li onClick={openDescription}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             Description
             
             </li>
             :
             <li onClick={openDescription} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             <p className='block text-gray-900 text-center '>Description</p>
            
            </li>
            }
             
             
         
             {
             isAudio ?
             <li onClick={openAudio}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             Audio
             
             </li>
             :
             <li onClick={openAudio} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             <p className='block text-gray-900 text-center '>Audio</p>
            
            </li>
            }
            {
             isPdf ?
             <li onClick={openPdf}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             PDF
             
             </li>
             :
             <li onClick={openPdf} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             <p className='block text-gray-900 text-center '>PDF</p>
            
            </li>
            }
             {
             isPicture ?
             <li onClick={openPicture}  class=" flex items-center bg-blue-700  text-white text-center justify-center border cursor-pointer  hover:bg-blue-800 pt-3 pb-3 rounded-lg fjalla " style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             Picture
             
             </li>
             :
             <li onClick={openPicture} class="border flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 rounded-lg fjalla" style={{width:"22%",margin:0,fontSize:'11px'}}>
             
             <p className='block text-gray-900 text-center '>Picture</p>
            
            </li>
            }
             </ol>
<div className='flex flex-col mx-auto my-12' style={{width:'95%'}}>
{
isDescription == true ?
<div style={{width:'90%'}} className='mx-auto'>
{
lectureDescription ? 
<div className='border py-5 rounded px-5  shadow-md'>
<p className='fjalla  text-gray-900 ' style={{fontSize:"30px"}}>Lecture Description</p>
<p className='borel  text-gray-500 mt-3'  style={{fontSize:"11px"}}>{lectureDescription}</p>  </div>
:
<div style={{width:'100%',height:'40vh'}}>
<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

<p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a description</p>

</div>
}

</div>
:
isPdf ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
lecturePdf && lecturePdf.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Recommendation</p>

<a href={lecturePdf} className='py-5' target='_blank' >
<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
<path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
</svg>
</a>  

</div>
:
<div style={{width:'100%',height:'40vh'}}>

<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

<p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided a PDF</p>
</div>

}

</div>
:
isAudio ?
<div style={{width:'100%'}} lassName='mx-auto'>
{
lectureAudio &&lectureAudio.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>
<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"30px"}}>Recommendation</p>
<audio controls>
 <source src={lectureAudio} type="audio/mpeg" />
 Your browser does not support the audio element.
</audio>  

</div>
:
<div style={{width:'100%',height:'40vh'}}>

<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

<p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Audio</p>
</div>

}

</div>
:
<div style={{width:'100%'}} lassName='mx-auto'>
{
lectureImg && lectureImg.length != 0 ?
<div className='border py-5 rounded px-5  shadow-md mx-auto flex flex-col items-start'>

<p className='fjalla  text-gray-900 mb-5' style={{fontSize:"25px"}}>Attached Image</p>

<a href={lectureImg} className='py-5' target='_blank'>
<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
<path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
<path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
</svg>
</a>  

</div>
:
<div style={{width:'100%',height:'40vh'}}>

<img style={{width:'80%'}} className='mx-auto' onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/curiosity%20brain-rafiki.svg?alt=media&token=0c0753af-47da-4317-80e5-db91fea27bf8" alt="Certificate" style={{width:"25%"}}/>

<p className='fjalla text-center' style={{fontSize:'25px'}}> The Instructor has not provided an Image</p>
</div>

}

</div>

}



</div>
   
   </div>
   </div>

 )
})
:
<div>
   no lecture selected
</div>
:
<div style={{height:"90vh"}} className='flex flex-col items-center justify-center'>
           <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>Your Course Does Not Have Any Lectures </h1>
<p className='borel text-gray-500 text-center' style={{fontSize:"14px"}}>Make sure to create lectures</p>

   <img
       style={{ width: '25%' }}
       src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
       alt='no content'
     />
<Link to={`/course/${courseId}/lecture/${v4().slice(0,5)}`}>
       <button
         type='button'
         className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
       >
         Create Lecture
       </button>
     </Link>
</div>
}


</div>
</div>
)
})
:
<div>
no course 
</div>
}
</div>
}


</div>
    </div>
  )
}
