import React, { useEffect, useState } from 'react'
import DashboardNav from './dashboardNav'
import { Link, useNavigate } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, onSnapshot,deleteDoc,doc,getDocs,where,query, orderBy,setDoc } from 'firebase/firestore';

export default function MyCourse() {
    const [uid,setuid] = useState("")
    const [courseId,setcourseId] = useState("")
    const [progress,setprogress] = useState("")
    const [search,setSearch] = useState("")
    const [spinner,setspinner] = useState(true)
    const [pop,setpop] = useState(false)
    const [deletePop,setdeletePop] = useState(false)
    const [status,setstatus] = useState()

    const [deleteMessage,setdeleteMessage] = useState(false)
    const [pageNumber,setPageNumber] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)


    const [notification,setnotification] = useState([])





    const [user,setuser] = useState([])
    const [Students,setStudents] = useState([])
    const [studentsError,setStudentsError] = useState('')
    const [courseIdDelete,setcourseIdDelete] = useState("")

    
    const [totalPaid,settotalPaid] = useState()



    const navigate = useNavigate()
    function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      deleteDoc(notificationRef)
      .then(() => {
        // Document successfully deleted from the database
        // Now remove the item from the array
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
      });
  
  
     }




    const [myCourse,setmyCourse] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    
    
    
 
    
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          setuid(uid);
    
          const snapRef = collection(firestore, 'AccountDetails', uid, 'CoursesCreated');
          const q = query(snapRef, orderBy('createdTime', 'desc'));
          const studentRef = collection(firestore,"AccountDetails",uid,"Students");
          let temp = []
      onSnapshot(q,snap=>{
        snap.docs.forEach(doc=>{
          temp.push({...doc.data()})

        })
        setmyCourse(temp)

      })
          
      const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

      const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
  
      onSnapshot(notificationRef,snapshot=>{
        let main =[]
        
    
    
    
        snapshot.docs.forEach(doc=>{
          main.push({...doc.data(),id:doc.id})
         
        setnotification(main)
        })
    
        
        
      
    
      })
          onSnapshot(studentRef, async (snap) => {

            const main = [];
            let temp = [];
          
        
          
          
          
          
          
          });
          
    
          const userRef = doc(firestore, "TotalUsers", uid);
          const unsubscribeUser = onSnapshot(userRef, (snapshot) => {
            const main = [{ ...snapshot.data(), id: snapshot.id }];
            setuser(main);
          });
    
          return () => {
            unsubscribeUser();
          };
        } else {
          console.log('logged out');
        }
      });
    
      return () => {
        unsubscribe();
      };
    }, []);

    const convertTimestampToDateNavigation = (timestamp) => {
      const date = timestamp.toDate();
      const currentDate = new Date();
      const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
    
      const secondsInMinute = 60;
      const secondsInHour = 3600;
      const secondsInDay = 86400;
      const secondsInWeek = 604800;
      const secondsInMonth = 2592000;
      const secondsInYear = 31536000;
    
      let relativeTime = '';
    
      if (timeDifferenceInSeconds < secondsInMinute) {
        relativeTime = `${timeDifferenceInSeconds}s ago`;
      } else if (timeDifferenceInSeconds < secondsInHour) {
        const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
        relativeTime = `${minutes}m ago`;
      } else if (timeDifferenceInSeconds < secondsInDay) {
        const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
        relativeTime = `${hours}h ago`;
      } else if (timeDifferenceInSeconds < secondsInWeek) {
        const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
        relativeTime = `${days}d ago`;
      } else if (timeDifferenceInSeconds < secondsInMonth) {
        const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
        relativeTime = `${weeks}w ago`;
      } else if (timeDifferenceInSeconds < secondsInYear) {
        const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
        relativeTime = `${months}mo ago`;
      } else {
        const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
        relativeTime = `${years}y ago`;
      }
    
      return relativeTime;
    };
    function mobileNavPop(){
      setpop(true)
  
  
    
  
  }
  
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
  
  // Mobile Nav Pop minimize
  function mobileNavPopMinimize(){
  setpop(false)
  
  
  
  }

      function closeSuccessDesktop(){
        const success = document.getElementById("successCourseAddDesktop")
    const empty = document.getElementById("emptyCourseDesktop")

        success.style.display ="none" 
        empty.style.display ="block" 

    }

    function closePopDeleteDesktop(){
   setdeletePop(false)

      
    }
    

    function openPopDeleteDesktop(courseId,status){
   
      setcourseIdDelete(courseId)
     
setdeletePop(true)
setstatus(status)
      
    }

   async function deleteHandlerDesktop() {
  try {
    const students = collection(firestore, 'AccountDetails', uid, 'CoursesCreated', courseIdDelete, 'Students');
    const snapshot = await getDocs(students); // Get all students at once
    if (snapshot.empty) {
      await deleteDoc(doc(firestore, 'AccountDetails', uid, "CoursesCreated", courseIdDelete));
      await deleteDoc(doc(firestore, 'Courses', courseIdDelete));
      setdeletePop(false);
      setdeleteMessage(true);
    } else {
      setdeletePop(false);
      setStudentsError('It is not possible to delete this course because there are active students');
    }
  } catch (error) {
    console.error('Error deleting course:', error);
    // Handle deletion error (e.g., show error message to user)
  }
}


// Course Data Pop handler
function coursePopDataDesktop(){
  const courseData = document.getElementById("courseDataDesktop")
  if(courseData.style.display === "block"){
    courseData.style.display="none"
  }
  else {
    courseData.style.display="block"

  }
}




 function desktopSpinnerHandler(){
   setspinner(false)
  }
 
function closeMessage2Desktop(){
setdeleteMessage(false)
  setprogress(0)
}
const popProfile = () =>{
  if(pop == true){
    setpop(false)
  setnotificationPop(false)


  }
  else{
  setpop(true)
  setnotificationPop(false)



  }
}
          const popNotification = () =>{
            if(notificationPop == true){
              setnotificationPop(false)
              setpop(false)
            }
            else{
            setnotificationPop(true)
            setpop(false)
          
          
          
            }
          }
             function removeNotification(id){
              const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
          
              setDoc(notificationRef,{
                seen:true
              },{merge:true})
              .then(() => {
                
                setnotification(prevArray => prevArray.filter(item => item.id !== id));
              })
              .catch((error) => {
              });
          
          
             }

    const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString();
      };

      function clearStudentError(){
        setStudentsError('')
      }

      const userPerPage = 8
      const pagesVisited = pageNumber * userPerPage
      const filteredCourses = myCourse.filter((item) => {
       if (search === '') {
         return true;
       } else if (item.courseTitle.toLowerCase().includes(search.toLowerCase())) {
         return true;
       }
       return false;
     })


     const pageCount = Math.ceil(filteredCourses.length / userPerPage)
     function changePage({selected}){
         setPageNumber(selected)

       }

       const filteredSearch = myCourse.filter((item) => {
        if (search === '') {
          return true;
        } else if (item.courseTitle.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      })
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

  return (
  
    user.map(i=>{
      const{accountType,plan,fullName,photo} = i
return(
  
  accountType == 'Student'?
  navigate('/dashboard')
  :
  <div>
        {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
  <div id='desktop'>
  <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDateNavigation(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
{
  deleteMessage &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
 Your course has been Deleted
</div>
<button onClick={closeMessage2Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

{
  studentsError.length != 0 &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli ">
{studentsError}
</div>
<button onClick={clearStudentError} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}
{
  deletePop == true &&
  <div id='deleteCourseDesktop' className=' flex items-center justify-center'>
 

      <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
  {
    status == 'pending'?
    <div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
      
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>This course cannot be deleted </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >We are currently reviewing this course for publication, so you cannot make any changes at this time </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
:
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
      
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Course </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently delete this course? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
  }

</div>
</div>  


  </div>
}


  <div id='emptyCourseDesktop'>


{isLoading ? (
    <div className='flex items-center justify-center h-full flex-col' >
    <h1 className='anton'>Loading...</h1>
    <p className='borel'>Fetching data</p>
  </div>
) : filteredSearch.length === 0 ? (
  <div style={{fontSize:'40px'}} className='fjalla text-center flex flex-col items-center capitalize mb-10 mt-10'>
    You haven't created any course
    <img
      onLoad={desktopSpinnerHandler}
      style={{ width: '20%' }}
      src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
      alt='no content'
    />
    <Link to='/create'>
      <button
        type='button'
        className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
      >
        Add Course
      </button>
    </Link>
  </div>
) : (
  <div>
       <div >
<div className=' flex flex-col w-full justify-between'>


<div >


<div className='flex w-full space-x-8 items-center mt-5'>
<Link to="/create">
<button style={{fontSize:'11px'}} type="button" class="Mooli mb-3 ml-6 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300  mb-12 rounded-lg  px-6 py-2.5 mr-2 mb-2">Add Course</button>

</Link>

<div className="relative border focus:border-black flex items-center border-gray-300 rounded-md overflow-hidden">
<svg class=" ml-3 mr-2  w-4 h-4 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
</svg>
      <input onChange={(e)=>{setSearch(e.target.value)}} style={{height:"50px",fontSize:'12px'}} required  autoComplete="off" placeholder="Search for courses" type="text" className="  focus:outline-none
           w-full   mr-0 mb-0 ml-0 text-gray-700 block bg-white
         "/>
    
    </div>





</div>

<div className=' w-full flex-col '>
<table class="w-full text-sm text-left text-gray-500   border mt-5" style={{maxWidth:'100%'}}>
<thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
<tr>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Thumbnail
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Title
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Creation date
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Sales
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Enrollments
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
        Status
    </th>
    <th scope="col" class="py-3 Mooli text-center" style={{fontSize:'11px'}}>
    Actions

    </th>

</tr>
</thead>

<tbody>
{
filteredSearch.map(i=>{
const {courseTitle,courseId,courseThumbnail,totalEarnings,status,createdTime,totalStudents} = i
return (

<tr class=" bg-white border-b  text-center">
<th className='py-4  text-center flex justify-center items-center'>
{courseThumbnail.length != 0 ?
<img onLoad={desktopSpinnerHandler} class="h-auto max-w-lg rounded-lg text-center mx-auto " style={{height:"80px",marginLeft:'auto'}} src={courseThumbnail} alt="image description"/>
:
<img onLoad={desktopSpinnerHandler} class="h-auto max-w-lg rounded-lg text-center mx-auto" style={{height:"80px",marginLeft:'auto'}} src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

}
</th>
    <th scope="row" class="underline fjalla text-center    font-medium text-gray-900 whitespace-nowrap ">
    <Link to={`/course/${courseId}`}>
    <span className='hover:text-blue-800 text-center cursor-pointer capitalize  borel hover:text-blue-700 text-gray-600' style={{fontSize:"13px"}}>{courseTitle.slice(0,100)}</span>
    </Link>
    </th>
    <td class="mx-auto borel text-center" style={{fontSize:"12px"}}>
       {createdTime && convertTimestampToDate(createdTime)}
    </td>
    <td class="mx-auto borel text-center" style={{fontSize:"12px"}}>
        {totalEarnings} ETB
    </td>
    <td class="mx-auto borel text-center" style={{fontSize:"12px"}}>
       {totalStudents}
    </td>
    <td class="mx-auto fjalla capitalize text-center" style={{fontSize:"12px"}}>

        {status}
    </td>
    <td className="mx-auto fjalla relative text-center">
  <svg
    onClick={() => openPopDeleteDesktop(courseId,status)}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="currentColor"
    className="mx-auto text-center hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
    viewBox="0 0 16 16"
  >
    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
  </svg>
</td>
</tr>
)

}
)}




</tbody>

  


</table>
{
   filteredSearch.length  == 0 &&
   <div className='w-full text-center fjalla capitalize text-red-900 mt-8' style={{fontSize:"25px"}}>
  We couldn't find any courses matching your search criteria. Please refine it
  </div> 
  
  }
</div>

</div>

</div>

</div>

  </div>
)}






</div>

  </div>
  <div id='mobile'>
<DashboardNav/>
{
  deletePop == true &&
  <div id='deleteCourseDesktop' className=' flex items-center justify-center'>
 

      <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"70%",height:"50%"}}>
<div class="max-w-sm  mx-auto bg-white border-[1px] border-gray-200 " 
style={{padding:'2vw'}}
>
<div class="relative "
style={{padding:'4vw'}}
>
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{width:'5vw',height:'5vw'}}class=" cursor-pointer fill-current text-slate-500 hover:text-slate-900">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
      
</a>
<h1 class="  anton" style={{fontSize:"8vw",marginBottom:'3vw'}}>Delete Course </h1>
<p class="text-sm text-gray-500 Mooli normal-case" style={{fontSize:'3vw',marginBottom:'2vw'}}>Would you like to permanently delete this course? </p>

<div class="flex flex-row  space-x-2 justify-evenly" style={{marginBottom:'2vw'}}>
  <a style={{padding:'2vw 2vw',fontSize:'3vw'}}  onClick={deleteHandlerDesktop} class="w-full cursor-pointer  text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a style={{padding:'2vw 2vw',fontSize:'3vw'}}  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer  text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


  </div>
}



{
  deleteMessage == true &&
  <div  id="alert-border-4" class="flex items-center justify-evenly  text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 " style={{width:'5vw',height:'5vw',paddingLeft:'1vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class=" text-sm font-medium Mooli " style={{fontSize:'2.3vw',margin:'2vw 2vw'}}>
our course has been Deleted

</div>
<button onClick={clearStudentError} type="button" class="ml-auto  bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400  hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<svg  style={{width:'3vw',height:'3vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}
{
  studentsError.length != 0 &&
  <div  id="alert-border-4" class="flex items-center justify-evenly  text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 " style={{width:'5vw',height:'5vw',paddingLeft:'1vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class=" text-sm font-medium Mooli " style={{fontSize:'2.3vw',margin:'2vw 2vw'}}>
{studentsError}
</div>
<button onClick={clearStudentError} type="button" class="ml-auto  bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400  hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<svg  style={{width:'3vw',height:'3vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}
<div id='emptyCourseDesktop'>


{isLoading ? (
  <div className='flex items-center justify-center h-full flex-col' style={{height:'60vw'}}>
  <h1 className='anton' style={{fontSize:'4vw'}}>Loading...</h1>
  <p className='borel' style={{fontSize:'2.5vw'}}>Fetching data</p>
</div>
) : myCourse.length === 0 ? (
  <div className='anton text-center flex flex-col items-center capitalize ' style={{fontSize:'7vw',lineHeight:'8vw',paddingTop:'20vw'}}>
    You haven't created any course
    <img
      onLoad={desktopSpinnerHandler}
      style={{ width: '55%' }}
      src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
      alt='no content'
    />
    <Link to='/create' style={{width:'40%'}}>

      <button type='submit' style={{padding:"3vw",fontSize:"2.5vw",height:"10vw",width:'100%'}}  class="w-full flex justify-center items-center  fjalla text-center text-white 
                  rounded- transition duration-200 bg-blue-800 ease cursor-pointer fjalla">Add Course
    </button>
    </Link>
  </div>
) : (
  <div>
       <div >
<div className=' flex flex-col w-full justify-between'>


<div >


<div className='flex justify-between w-full space-x-8 items-center ' style={{padding:'3vw 5vw'}}>


<div style={{width:'50%'}} className="relative border focus:border-black flex items-center border-gray-300 rounded-md overflow-hidden">
<svg style={{width:'5vw',height:'5vw',margin:'0 2vw'}} class="  text-gray-800 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
</svg>

                 <input type='email' onChange={(e)=>{setSearch(e.target.value)}} placeholder='Search for courses' className="border-none w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"5vw",paddingBottom:"5vw",height:'12vw',paddingLeft:"1vw",fontSize:"2.5vw"}}  />
           
    
    </div>


    <Link to="/create" style={{ width:'35%'}}>
<button type='submit' style={{padding:'2vw 5vw',fontSize:'2.5vw',}} className=" w-full inline-block  text-xl font-medium text-center text-white 
                    rounded-lg transition duration-200 bg-blue-800 fjalla hover:bg-blue-600 ease cursor-pointer">Add course</button></Link>
   


</div>

<div className=' w-full flex-col'>
<table class="w-full text-sm text-left text-gray-500   border mt-5">
<thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
<tr>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Thumbnail
    </th>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Title
    </th>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Creation date
    </th>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Sales
    </th>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Enrollments
    </th>
    <th scope="col" class=" fjalla text-center" style={{fontSize:'2vw',padding:'2vw 0'}}>
        Status
    </th>
    <th scope="col" class=" fjalla text-center"style={{fontSize:'2vw',padding:'2vw 0'}}>
    Actions

    </th>

</tr>
</thead>

<tbody>
{
filteredSearch.slice(0,5)
.map(i=>{
const {courseTitle,courseId,courseThumbnail,totalEarnings,status,createdTime,totalStudents} = i
return (

<tr class=" bg-white border-b  ">
<th className=''>
{courseThumbnail.length != 0 ?
<img onLoad={desktopSpinnerHandler} class=" rounded overflow-hidden" style={{height:"18vw",width:'15vw',marginLeft:'1vw',objectFit:'cover',marginBottom:'3vw',marginTop:'3vw'}} src={courseThumbnail} alt="image description"/>
:
<img onLoad={desktopSpinnerHandler} class=" rounded overflow-hidden" style={{height:"18vw",width:'15vw',objectFit:'cover',marginLeft:'1vw',marginBottom:'2vw',marginTop:'3vw'}} src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

}
</th>
    <th scope="row" class="underline fjalla  text-center    font-medium text-gray-900 whitespace-nowrap ">
    <Link to={`/course/${courseId}`}>
    <span className='hover:text-blue-800  cursor-pointer capitalize  fjalla text-gray-600' style={{fontSize:"2vw"}}>{courseTitle.slice(0,100)}</span>
    </Link>
    </th>
    <td class="text-center Mooli text-gray-900" style={{fontSize:"1.5vw"}}>
       {createdTime && convertTimestampToDate(createdTime)}
    </td>
    <td class="text-center Mooli text-gray-900" style={{fontSize:"1.5vw"}}>
        {totalEarnings} ETB
    </td>
    <td class=" text-center Mooli text-gray-900" style={{fontSize:"1.5vw"}}>
       {totalStudents}
    </td>
    <td class=" fjalla capitalize" style={{fontSize:"1.5vw"}}>
        {status}
    </td>
    <td className=" fjalla relative text-center  " >
  <svg
    onClick={() => openPopDeleteDesktop(courseId)}
    xmlns="http://www.w3.org/2000/svg"
    style={{width:'3vw',height:'3vw'}}
    fill="currentColor"
    className=" hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill text-center mx-auto"
    viewBox="0 0 16 16"
  >
    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
  </svg>
    </td>
</tr>
)

}
)}





</tbody>
</table>
{
  filteredSearch.length == 0 &&
 <p className='fjalla text-center mx-auto' style={{paddingTop:'30%',width:'90%',fontSize:'5vw'}}>
  We Couldn't Find Any Matches For Your Search. Try Refining It
 </p>
}
</div>

</div>

</div>

</div>

  </div>
)}






</div>

  </div>
  <div id='tablet' className='relative'>
<DashboardNav/>


{
  deleteMessage &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli capitalize">
 Your course has been Deleted
</div>
<button onClick={closeMessage2Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}

{
  studentsError.length != 0 &&
  <div id="alert-border-4" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
<svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div class="ml-3 text-sm font-medium Mooli ">
{studentsError}
</div>
<button onClick={clearStudentError} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
<span class="sr-only">Dismiss</span>
<svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
</svg>
</button>
</div>
}
{
  deletePop == true &&
  <div id='deleteCourseDesktop' className=' flex items-center justify-center'>
 

      <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
      
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Course </h1>
<p class="text-sm text-gray-500 Mooli normal-case" >Would you like to permanently delete this course? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
  <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
  <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


  </div>
}


  <div id='emptyCourseDesktop'>


{isLoading ? (
  <div className='flex items-center justify-center h-full flex-col' style={{height:'60vw'}}>
    <h1 className='anton'>Loading...</h1>
    <p className='borel'>Fetching data</p>
  </div>
) : myCourse.length === 0 ? (
  <div className='anton text-center flex flex-col items-center capitalize mb-10 mt-10' style={{fontSize:'30px'}}>
    You haven't created any course
    <img
      onLoad={desktopSpinnerHandler}
      style={{ width: '40%' }}
      src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
      alt='no content'
    />
    <Link to='/create'>
      <button
        type='button'
        
        className='text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none '
      >
        Add Course
      </button>
    </Link>
  </div>
) : (
  <div>
       <div >
<div className=' flex flex-col w-full justify-between'>


<div >


<div className='mb-8 flex w-full space-x-8 items-center mt-5'>
<Link to="/create">
<button style={{fontSize:'1.5vw'}} type="button" class="fjalla mb-3 ml-6 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300  mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">Add Course</button>

</Link>

<div className="  relative border focus:border-black flex items-center border-gray-300 rounded-md overflow-hidden">
<svg class=" ml-3 mr-2  w-7 h-7 text-gray-800 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
</svg>
      <input onChange={(e)=>{setSearch(e.target.value)}} style={{height:"50px",fontSize:'11px'}} required  autoComplete="off" placeholder="Search for courses" type="text" className=" borel  focus:outline-none
           w-full   mr-0 mb-0 ml-0 text-gray-700 block bg-white
         "/>
    
    </div>





</div>


<div className=' w-full flex-col '>
<table class="w-full text-sm text-left text-gray-500  border ">
<thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
<tr>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Thumbnail
    </th>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Title
    </th>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Creation date
    </th>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Sales
    </th>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Enrollments
    </th>
    <th scope="col" class="fjalla py-3 text-center" style={{fontSize:"11px"}}>
        Status
    </th>
    <th scope="col" class="fjalla py-3" style={{fontSize:"11px"}}>
    Actions

    </th>

</tr>
</thead>

<tbody>
{
filteredSearch.map(i=>{
const {courseTitle,courseId,courseThumbnail,totalEarnings,status,createdTime,totalStudents} = i
return (

<tr class=" bg-white border-b  ">
<th className='py-4 pl-3'>
{courseThumbnail.length != 0 ?
<img onLoad={desktopSpinnerHandler} class="h-auto max-w-lg rounded-lg" style={{height:"60px"}} src={courseThumbnail} alt="image description"/>
:
<img onLoad={desktopSpinnerHandler} class="h-auto max-w-lg rounded-lg" style={{height:"60px"}} src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

}
</th>
    <th scope="row" class="underline fjalla    mx-auto text-center font-medium text-gray-900 whitespace-nowrap ">
    <Link to={`/course/${courseId}`}>
    <span className='hover:text-blue-800 cursor-pointer capitalize mx-auto text-center borel text-gray-600' style={{fontSize:"11px"}}>{courseTitle.slice(0,100)}</span>
    </Link>
    </th>
    <td class="mx-auto text-center borel" style={{fontSize:"10px"}}>
       {createdTime && convertTimestampToDate(createdTime)}
    </td>
    <td class="mx-auto text-center borel" style={{fontSize:"10px"}}>
        {totalEarnings} ETB
    </td>
    <td class="mx-auto text-center borel" style={{fontSize:"10px"}}>
       {totalStudents}
    </td>
    <td class="mx-auto text-center fjalla capitalize" style={{fontSize:"10px"}}>
        {status}
    </td>
    <td class="mx-auto text-center fjalla" style={{fontSize:"10px"}}>
  <svg
    onClick={() => openPopDeleteDesktop(courseId)}
    xmlns="http://www.w3.org/2000/svg"
    style={{width:"13px"}}
    fill="currentColor"
    className="mx-auto hover:text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
    viewBox="0 0 16 16"
  >
    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
  </svg>
    </td>
</tr>
)

}
)}




</tbody>
</table>
  
{
   filteredSearch.length  == 0 &&
   <div className='w-full text-center fjalla capitalize text-red-900 mt-5' style={{fontSize:"20px"}}>
   We couldn't find any courses matching your search criteria. Please refine it
  </div> 
  
  }
</div>

</div>

</div>

</div>

  </div>
)}






</div>

  </div>

  <div></div>

  <div></div>

</div>
)
    })
  
    
  )
}
