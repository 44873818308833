import { collection, doc, onSnapshot, orderBy, query, serverTimestamp, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase'
import { onAuthStateChanged,signOut } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import DashboardNav from './dashboardNav'
import moment from 'moment'

export default function Pay() {
    const [user,setuser] = useState([])
    const [course,setCourse] = useState([])
    const [cbebirr,setcbebirr] = useState(false)
    const [telebirr,settelebirr] = useState(false)
    const [boa,setboa] = useState(false)
    const [cbe,setcbe] = useState(false)
    const [progress,setprogress] = useState('')
    const [firstlectureId,setFirstLectureId] = useState('')
    const [spinner,setspinner] = useState(true)

    const [instructorName,setinstructorName] = useState('')

    const [chosenMethod,setchosenMethod] = useState("")
    const [instructorId,setinstructorId] = useState("")
    const [courseThumbnail,setcourseThumbnail] = useState("")


    const navigate = useNavigate()
    const [screenshot,setscreenshot] = useState([])
    const [screenshotError,setscreenshotError] = useState("")

    const [fullName,setFullName] = useState("")
    const [fullNameError,setfullNameError] = useState("")
    const [transferredFrom,setTransferredFrom] = useState("")
    const [transferredFromError,setTransferredFromError] = useState("")

    const [fileMesssage,setfileMesssage] = useState("")
    const [selectedLecture,setSelectedLecture] = useState([])

    const [uid,setuid] = useState("")
    const [courseTitle,setCourseTitle] = useState("")
    const [coursePrice,setCoursePrice] = useState("")
    const [amount,setamount] = useState("")
    const [depositError,setdepositError] = useState("")








    const [screenshotHandler,setscreenshotHandler] = useState([])




    const {courseId} = useParams()


   
      



      function fullNameSet(e) {
        setFullName(e.target.value)
        if(e.target.value.length == 0){
            setfullNameError("error")
        }
        else{
            setfullNameError("")
        }
      }
      
    function transferredFromSet(e) {
        setTransferredFrom(e.target.value)
        if(e.target.value.length == 0){
            setTransferredFromError("error")
        }
        else{
            setTransferredFromError("")
        }
      }

      const currentTime = moment();
  const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
  const currentYear = currentMoment.year();
  const currentMonth = currentMoment.month();
  const currentDay = currentMoment.date();
  const currentHour = currentMoment.hour();
  const currentMinute = currentMoment.minute();
  const currentSecond = currentMoment.second();
let currentDateTime = moment([currentYear, currentMonth, currentDay, currentHour, currentMinute, currentSecond]);

      
 function desktopSpinnerHandler(){
  setspinner(false)
 }

          function handleImgSelect(event) {
            const selectedFiles = (event.target.files[0]);
          
              // Check file size
              
              const maxSize = 10 * 1024 * 1024; // 10MB
              // Check file type and size
              if (selectedFiles.type.startsWith('image/')) {
                if (selectedFiles.size > maxSize) {
                  setfileMesssage(`File ${selectedFiles.name} exceeds the size limit of 10MB.`);
                  return false;
                } else {
                  setfileMesssage('');
                  setscreenshot(selectedFiles);
                }
              } else {
                setfileMesssage(`File ${selectedFiles.name} is not a valid image file.`);
                return false;
              }
          
              setscreenshotError('')
          
            // Clear the file input value to allow selecting the same file again
            event.target.value = '';
          }
        
        const minimizeUploadImg = ()=>{
          const upload = document.getElementById("uploadFileDesktop")
          upload.style.display="none"
          setscreenshot([])
        }

        function backImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="none"
            setscreenshot([])
          }
       

          function openImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="flex"
          
          }

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
           
      
      setuid(uid)
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
    
        setCourse(main)
        setinstructorId(snapshot.data().instructorId)
        setCoursePrice(snapshot.data().coursePrice)
        setCourseTitle(snapshot.data().courseTitle)
        setcourseThumbnail(snapshot.data().courseThumbnail)
        onSnapshot(doc(firestore,"TotalUsers",snapshot.data().instructorId),snapshot=>{
          setinstructorName(snapshot.data().fullName)
        
        
        })
    
      })
      const documentRef = collection(firestore,"Courses",courseId,"lectures")
      const qe = query(documentRef, orderBy('createdTime'));
    
          onSnapshot(qe,snapshot=>{
            let main =[]
            snapshot.docs.forEach(doc=>{
                    main.push({...doc.data()})
                   
                    const firstLecture = snapshot.docs[0]; // Access the first document directly

                    const firstLectureId = firstLecture.data().lectureId; // Extract the Id
                  setFirstLectureId(firstLectureId)
                
                  
        
            })
       
        
       
    
          
          })
    
   
            
      const userRef = doc(firestore,"TotalUsers",uid);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
    
        
        
      
    
      })

      onSnapshot(doc(firestore,"AccountDetails",uid,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
      
       setSelectedLecture(main)
    
      })
    
               
     
   
      
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);


      function handleCbeBirrDesktop(){
        setchosenMethod('Cbe Birr')

        if(cbebirr == true){
            setcbebirr(false)
            setscreenshotHandler([])

        }
        else {
            setcbebirr(true)
            setboa(false)
            settelebirr(false)
            setcbe(false)
            setscreenshotHandler([])
        }
      }

      function handleTelebirrDesktop(){
        setchosenMethod('Telebirr')

        if(telebirr == true){
            settelebirr(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)
            setscreenshotHandler([])

            setboa(false)
            settelebirr(true)
            setcbe(false)
        }
      }

      function handleCbeDesktop(){
        setchosenMethod('Commercial Bank Of Ethiopia')

        if(cbe == true){
            setcbe(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(false)
            settelebirr(false)
            setcbe(true)
        }
      }
      function handleBoaDesktop(){
        setchosenMethod('Bank Of Abyssinia')

        if(boa == true){
            setboa(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(true)
            settelebirr(false)
            setcbe(false)
        }
      }

      function handlePaidDesktop(courseId){

        const documentRef = doc(firestore,"AccountDetails",uid,"Courses",courseId)

        const documentRef2 = doc(firestore,"Courses",courseId,"Courses",courseId)
        const documentRef3 = doc(firestore,"AccountDetails",uid,"Transaction",courseId + uid  )
        const documentRef4 = doc(firestore,"AccountDetails",instructorId,"Transaction",courseId + uid  )
        const documentRef5 = doc(firestore,"Transactions",courseId + uid  )
        const documentRef6 = doc(firestore,"TransactionCourses",courseId + uid  )


        const storage = getStorage()
        const reff = ref(storage, `resume/${screenshot.name}`)
        const uploadImage= uploadBytesResumable(reff,screenshot)
            
        
      
        if(fullName.length == 0){
            setfullNameError("error")
            setdepositError('error')
        window.scrollTo(0, 0);

        }
        else{

          if(transferredFrom.length == 0){
            setTransferredFromError("error")
            setdepositError('error')
        window.scrollTo(0, 0);


        }

        else{
          if(screenshot.length == 0){
            setscreenshotError("error")
            setdepositError('error')
        window.scrollTo(0, 0);


        }

        else{
          setdepositError('')

         
          uploadImage.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setprogress(progress)
        
          }, 
          (error) => {
            console.log(error)
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          
        
          getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
          
              const transactionData = {
                  screenshot:downloadURL,
                  courseId:courseId,
                  paid:false,
                  isPending:true,
                createdTime:currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
                accountHolder:fullName,
                accountNumber:transferredFrom,
                transactionID:courseId + uid,
                chosenMethod:chosenMethod,
                courseTitle:courseTitle,
                price:coursePrice,
                buyerId:uid,
                sellerId:instructorId,
                state: 'Pending',

        
        
                };
              
                 setDoc(documentRef, {isPending:true,paymentStatus:'Pending',
                price:coursePrice,

                 }, { merge: true });
                 setDoc(documentRef3, transactionData, { merge: true });
                 setDoc(documentRef4, transactionData, { merge: true });
                 setDoc(documentRef5, transactionData, { merge: true });
                 setDoc(documentRef6, transactionData, { merge: true });




        
            
          })
             
            
              
          }
        
          // )}
         
        )


         
        }
        }
        
         
        }
       
  
      }
      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }
       
  return (
    <div>
         {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
       {
        user.map(i=>{
          const {accountType} = i
          return(
            accountType =="Student"?
            <div>
  <div id='desktop'>
           <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded   text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className=" sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage' >
    
    {course.map(i=>{
        const {courseTitle,instructor,courseTrailer,courseDescription,intendedAgeGroup,coursePricing ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
            <body>
  
    {
      selectedLecture.map(i=>{
        const {isPending,paymentStatus,accountHolder,accountNumber,paid} = i
        if (paid)
        navigate(`/course/${courseId}/lecture/1`)
        return(
          <div>
            {
              paymentStatus === 'Pending' ?
              <div>
              <div class="mt-20   flex items-center ">
                  <div class="flex   items-center justify-between px-5 text-gray-700">
                          <div class="w-full lg:w-1/2 mx-8">
                              <div style={{fontSize:'25px',lineHeight:'27px'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have access to the course once the verification process is complete</div>
                       
                              <Link to={`/course/${courseId}`}>
                              <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                            
                                            Back
                                          </button>
                              </Link>      
               
                          </div>
                      <div class="w-full lg:flex lg:justify-end  mx-5 ">
                      <img style={{width:'60%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
                      </div>
                  
                  </div>
              </div>
              </div>
       
              :
              paymentStatus === 'Approved'?
           navigate(`/course/${courseId}/lecture/${firstlectureId}`)

              :
              <div>
                {
                  progress != 0 & progress !=100 ?
                  <div className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                  <div class="relative">
                  <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                  <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
              </div>
              <div class="w-full bg-neutral-200 mt-3">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <p className='fjalla mt-7'>Uploading</p>

                    </div>
                    :
                    <div class="min-w-screen min-h-screen bg-gray-50 py-5">
                   <div className='justify-between w-full flex pl-8 pr-4'>
                    <div>
                    <div class="mb-2">
                            <h1 class=" font-bold text-gray-900 anton" style={{fontSize:'25px'}}>Checkout</h1>
                         
                        </div>
                    </div>
                    <div class="px-5">
                        <div class="mb-2">
                            <Link to={`/course/${courseId}`}>
                            <p  class="focus:outline-none hover:underline text-gray-900 text-sm fjalla" style={{fontSize:'11px'}}>Back</p>
                            </Link>
                        </div>
                       
                        <div class="mb-5 text-gray-400 flex ">
                        </div>
                    </div>

                   </div>
                    <p style={{fontSize:'11px'}} className='fjalla text-green-800 w-full bg-green-50 py-2 text-center'>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                      
            {
              depositError == "error" &&
                    <div id="alert-border-2" class="flex text-left items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 justify-start " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <p class=" Mooli mr-1" style={{fontSize:'11px'}}>
 Please make sure all fields are filled out correctly

 </p>

</div>
            }
              
                    <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                        <div class="w-full">
                            <div class="-mx-1 md:flex items-start">
                                <div class="px-1 md:w-7/12 ">
                                    <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                        <div class="w-full flex items-center">
                                            <div class="overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                                <img src={courseThumbnail} className='w-full h-full' alt=""/>
                                            </div>
                                            <div class="flex-grow pl-3">
                                                <h6 class="font-semibold uppercase text-gray-600" style={{fontSize:'14px'}}>{courseTitle} </h6>
                                                <p class="text-gray-400 borel" style={{fontSize:'11px'}}>By {instructorName}</p>
                                            </div>
                                            <div>
                                            <span class="fjalla" style={{fontSize:"15px",wordBreak:'keep-all'}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                        
                
                
                                    {
                                      cbebirr == true ?
                                      <div>
                                              <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                            </p>
                          </div>
                        </div>
                                       <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                          <div class="relative m-auto my-10" style={{width:"90%"}}>
                                          <div className="relative">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
              
              <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
                  border-gray-300 rounded-md" />
                 
              <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>

               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
              
              <div className="relative my-6">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
              
              <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
                  border-gray-300 rounded-md borel" />
                 
              <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
    
           <div className='flex items-center'>
      <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

           <button
              className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-img').click();
              }}
            >
              Upload Screenshot
            </button> 

    
           <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

             
           </div>
           {
            fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

           }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
                
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
                                      </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              
              {
                                      telebirr == true ?
                                      <div>
                                          <div >
                                                 <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                            </p>
                          </div>
                        </div>
                                       <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via telebirr</p>
                                  
                                       <div class="relative m-auto my-10" style={{width:"90%"}}>
                                          <div className="relative">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
              
              <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
                  border-gray-300 rounded-md" />
                 
              <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>

               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
              
              <div className="relative my-6">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
              
              <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
                  border-gray-300 rounded-md borel" />
                 
              <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
    
           <div className='flex items-center'>
      <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

           <button
              className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-img').click();
              }}
            >
              Upload Screenshot
            </button> 

    
           <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

             
           </div>
           {
            fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

           }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
                
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
                                      </div>                       </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      cbe == true ?
                                      <div>
                                                                <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                            </p>
                          </div>
                          
                        </div>
                        <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE</p>
                                  
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                     <div className="relative">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
         
         <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
             border-gray-300 rounded-md" />
            
         <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>

          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
         
         <div className="relative my-6">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
         
         <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
             border-gray-300 rounded-md borel" />
            
         <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

      <button
         className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
         onClick={() => {
           document.getElementById('hidden-input-img').click();
         }}
       >
         Upload Screenshot
       </button> 


      <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

      }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
           
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
                                 </div>                      
                                 :
                                 <div>
         
                                 </div>
      }  
              {
                                      boa == true ?
                                      <div>
                                                                                  <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                            </p>
                          </div>
                          
                        </div>
                        <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via BOA</p>
                                  
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                     <div className="relative">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
         
         <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
             border-gray-300 rounded-md" />
            
         <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>

          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
         
         <div className="relative my-6">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
         
         <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
             border-gray-300 rounded-md borel" />
            
         <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

      <button
         className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
         onClick={() => {
           document.getElementById('hidden-input-img').click();
         }}
       >
         Upload Screenshot
       </button> 


      <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

      }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
           
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
                                 </div>                     
                                 :
                                 <div>
         
                                 </div>
      }               
                           
                                    <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                        <div class="w-full flex items-center">
                                            <div class="flex-grow">
                                                <span class="text-gray-800 fjalla">Total</span>
                                            </div>
                                            <div class="pl-3">
                                                <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"25px"}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>handlePaidDesktop(courseId)} type='submit' style={{fontSize:'15px'}}  className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">I have transferred the funds
                   </button>
                                </div>
                                <div class="px-3 md:w-5/12">
                
                                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
                                                                      <span style={{fontSize:"30px"}} class="text-center fjalla">Choose Payment Method</span>
                
                 <div className='flex justify-evenly'>
                 <div style={{width:'30%'}}  onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                              </p>
                            </div>
                          </div>
                                    <div style={{width:'30%'}} onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                              </p>
                            </div>
                          </div>
                                    <div style={{width:'30%'}} onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                              </p>
                            </div>
                          </div>
                
                          <div style={{width:'30%'}} onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                              </p>
                            </div>
                          </div>
                
                 </div>
                                    </div>
                                 
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                }
              </div>
            
            }
          </div>

        
      
          
          
        )
      })
    }



</body>
      
         

    )})}
</div>

            </div>
            <div id='tablet'>
            <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' width="18" height="18" style={{objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>


  
        
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="14" height="14" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage' >
    
    {course.map(i=>{
        const {courseTitle,instructor,courseTrailer,courseDescription,intendedAgeGroup,coursePricing ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
            <body>
  
    {
      selectedLecture.map(i=>{
        const {isPending,paymentStatus,accountHolder,accountNumber,paid} = i
        if (paid)
        navigate(`/course/${courseId}/lecture/1`)
        return(
          <div>
            {
              paymentStatus === 'Pending' ?
              <div>
                
              <div class="  mt-20 flex items-center">
                  <div class="flex  items-center justify-between px-5 text-gray-700">
                  <div class="w-full lg:flex lg:justify-end  mx-5 my-12">
                      <img  style={{width:'74%'}} className='mx-auto'src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
                      </div>
                          <div class="w-full lg:w-1/2 mx-8">
                              <div style={{fontSize:'25px',lineHeight:'27px'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have access to the course once the verification process is complete</div>
                       
                              <Link to={`/course/${courseId}`}>
                              <button style={{fontSize:'12px'}} class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                            
                                            Back
                                          </button>
                              </Link>      
               
                          </div>
                  
                  
                  </div>
              </div>
              </div>
       
              :
              paymentStatus === 'Approved'?
           navigate(`/course/${courseId}/lecture/${firstlectureId}`)

              :
              <div>
                {
                  progress != 0 & progress !=100 ?
                  <div className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                  <div class="relative">
                  <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                  <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
              </div>
              <div class="w-full bg-neutral-200 mt-3">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <p className='fjalla mt-7'>Uploading</p>

                    </div>
                    :
                    <div class="min-w-screen min-h-screen bg-gray-50 py-5">
                   <div className='justify-between w-full flex pl-8 pr-4'>
                    <div>
                    <div class="mb-2">
                            <h1 class=" font-bold text-gray-900 anton" style={{fontSize:'25px'}}>Checkout</h1>
                         
                        </div>
                    </div>
                    <div class="px-5">
                        <div class="mb-2">
                            <Link to={`/course/${courseId}`}>
                            <p  class="focus:outline-none hover:underline text-gray-900 text-sm fjalla" style={{fontSize:'11px'}}>Back</p>
                            </Link>
                        </div>
                       
                        <div class="mb-5 text-gray-400 flex ">
                        </div>
                    </div>

                   </div>
                    <p style={{fontSize:'11px'}} className='fjalla text-green-800 w-full bg-green-50 py-2 text-center'>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                      
            {
              depositError == "error" &&
                    <div id="alert-border-2" class="flex w-full text-left items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  " role="alert">
 <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <p class=" Mooli mr-1" style={{fontSize:'11px'}}>
      Please make sure all fields are filled out correctly

 </p>

</div>
            }
              
                    <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                        <div class="w-full">
                            <div class="-mx-1 ">
                                <div class="w-full ">
                                    <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                        <div class="w-full flex items-center">
                                            <div class="overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                                <img src={courseThumbnail} className='w-full h-full' alt=""/>
                                            </div>
                                            <div class="flex-grow pl-3">
                                                <h6 class="font-semibold uppercase text-gray-600" style={{fontSize:'15px'}}>{courseTitle} </h6>
                                                <p class="text-gray-400 borel" style={{fontSize:'12px'}}>By {instructorName}</p>
                                            </div>
                                            <div>
                                            <span class="fjalla" style={{fontSize:"16px",wordBreak:'keep-all'}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                        
                
                
                                    {
                                      cbebirr == true ?
                                      <div>
                                              <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                            </p>
                          </div>
                        </div>
                                       <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                          <div class="relative m-auto my-10" style={{width:"90%"}}>
                                          <div className="relative">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
              
              <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
                  border-gray-300 rounded-md" />
                 
              <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>

               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
              
              <div className="relative my-6">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
              
              <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
                  border-gray-300 rounded-md borel" />
                 
              <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
    
           <div className='flex items-center'>
      <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

           <button
              className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-img').click();
              }}
            >
              Upload Screenshot
            </button> 

    
           <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

             
           </div>
           {
            fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

           }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
                
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
                                      </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              
              {
                                      telebirr == true ?
                                      <div>
                                          <div >
                                                 <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                            </p>
                          </div>
                        </div>
                                       <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via telebirr</p>
                                  
                                       <div class="relative m-auto my-10" style={{width:"90%"}}>
                                          <div className="relative">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
              
              <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
                  border-gray-300 rounded-md" />
                 
              <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>

               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
              
              <div className="relative my-6">
              
              <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
              
              <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                  focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
                  border-gray-300 rounded-md borel" />
                 
              <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
    
           <div className='flex items-center'>
      <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

           <button
              className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
              onClick={() => {
                document.getElementById('hidden-input-img').click();
              }}
            >
              Upload Screenshot
            </button> 

    
           <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

             
           </div>
           {
            fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

           }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
               <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <div className='Mooli' style={{fontSize:'11px'}}>
                
               This Field Can't be empty
              </div>
              </div>
              </div>
              </div>
                                      </div>                       </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      cbe == true ?
                                      <div>
                                                                <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                            </p>
                          </div>
                          
                        </div>
                        <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE</p>
                                  
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                     <div className="relative">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
         
         <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
             border-gray-300 rounded-md" />
            
         <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>

          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
         
         <div className="relative my-6">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
         
         <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
             border-gray-300 rounded-md borel" />
            
         <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

      <button
         className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
         onClick={() => {
           document.getElementById('hidden-input-img').click();
         }}
       >
         Upload Screenshot
       </button> 


      <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

      }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
           
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
                                 </div>                      
                                 :
                                 <div>
         
                                 </div>
      }  
              {
                                      boa == true ?
                                      <div>
                                                                                  <div class="flex items-center gap-3 mb-2">
                          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                            </p>
                          </div>
                          
                        </div>
                        <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> via BOA</p>
                                  
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                     <div className="relative">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
         
         <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
             border-gray-300 rounded-md" />
            
         <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>

          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
         
         <div className="relative my-6">
         
         <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
         
         <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
             focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
             border-gray-300 rounded-md borel" />
            
         <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

      <button
         className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
         onClick={() => {
           document.getElementById('hidden-input-img').click();
         }}
       >
         Upload Screenshot
       </button> 


      <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

      }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
         <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
         </svg>
         <span className="sr-only">Info</span>
         <div className='Mooli' style={{fontSize:'11px'}}>
           
          This Field Can't be empty
         </div>
         </div>
         </div>
         </div>
                                 </div>                     
                                 :
                                 <div>
         
                                 </div>
      }               
                           
                        
                                </div>
                                <div class="px-3 m-auto " style={{width:'70%'}}>
                
                                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
                                                                      <span style={{fontSize:"30px"}} class="text-center fjalla">Choose Payment Method</span>
                
                 <div className='flex justify-evenly'>
                 <div style={{width:'30%'}}  onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                              </p>
                            </div>
                          </div>
                                    <div style={{width:'30%'}} onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                              </p>
                            </div>
                          </div>
                                    <div style={{width:'30%'}} onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                
                            
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                              </p>
                            </div>
                          </div>
                
                          <div style={{width:'30%'}} onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                            <div class=" rounded-md  border-blue-gray-50 p-1">
                              <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                            </div>
                            <div class="flex flex-col">
                              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                              </p>
                            </div>
                          </div>
                
                 </div>
                                    </div>
                                 
                                    <div>
                                    </div>
                                </div>
                                <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                        <div class="w-full flex items-center">
                                            <div class="flex-grow">
                                                <span class="text-gray-800 fjalla">Total</span>
                                            </div>
                                            <div class="pl-3">
                                                <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"25px"}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>handlePaidDesktop(courseId)} type='submit' style={{fontSize:'15px'}}  className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">I have transferred the funds
                   </button>
                            </div>
                        </div>
                    </div>
                  
                </div>
                }
              </div>
            
            }
          </div>

        
      
          
          
        )
      })
    }



</body>
      
         

    )})}
</div>

            </div>
            <div id='mobile'>
      <DashboardNav/>
<div  >
    
    {course.map(i=>{
        const {courseTitle,instructor,courseTrailer,courseDescription,intendedAgeGroup,coursePricing ,pricingDescription,courseSubject,paymentFrequency,coursePrice,completionTime} = i;
        
        return(
 
            <body>
  
    {
      selectedLecture.map(i=>{
        const {isPending,paymentStatus,accountHolder,accountNumber,paid,price} = i
        if (paid)
        navigate(`/course/${courseId}/lecture/1`)
        return(
          <div>
            {
              paymentStatus === 'Pending' ?
              <div>
                <div id='mobile' class="h-screen flex-col   flex items-center">
         
                <div class="  flex-col flex  justify-center items-center text-gray-700">
                        <div class="w-full flex flex-col items-center justify-center m-auto " style={{width:'90%',marginTop:'5%'}}>
                      <img  style={{width:'74%',margin:'auto',paddingBottom:'10vw'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>

                            <div style={{fontSize:'5vw',lineHeight:'5.5vw'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have access to the course once the verification process is complete
                            </div>
                            <p className='fjalla flex flex-col ' style={{fontSize:'3vw',}}>Make sure {price} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                       
                            <Link to={`/course/${courseId}`}>

                            <button style={{padding:'2vw 5vw',marginTop:'3vw',fontSize:'3vw'}}  class=" capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Back
                                        </button>
                            </Link>      
             
                        </div>
           
                
                </div>
            </div>
             
              </div>
       
              :
              paymentStatus === 'Approved'?
           navigate(`/course/${courseId}/lecture/${firstlectureId}`)

              :
              <div>
                {
                  progress != 0 & progress !=100 ?
                  <div className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                  <div class="relative">
                  <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                  <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
              </div>
              <div class="w-full bg-neutral-200 mt-3">
        <div
          class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
          style={{ width: progress + '%' }}
        >
          {Math.round(progress)} %
        </div>
      </div>
      <p className='fjalla mt-7'>Uploading</p>

                    </div>
                    :
                    <div class="min-w-screen min-h-screen  py-5">
                   <div className='justify-between w-full flex pl-8 pr-4'>
                    <div>
                    <div class="mb-2">
                            <h1 class=" font-bold text-gray-900 anton" style={{fontSize:'5vw'}}>Checkout</h1>
                         
                        </div>
                    </div>
                    <div class="px-5">
                        <div class="mb-2">
                            <Link to={`/course/${courseId}`}>
                            <p  class="focus:outline-none hover:underline text-gray-900 text-sm fjalla" style={{fontSize:'3vw'}}>Back</p>
                            </Link>
                        </div>
                       
                        <div class="mb-5 text-gray-400 flex ">
                        </div>
                    </div>

                   </div>
                    <p style={{fontSize:'2.7vw'}} className='fjalla text-green-800 w-full bg-green-50 py-2 text-center'>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                      
            {
              depositError == "error" &&
              <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
              <div className='flex justify-center items-center'>
            <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div className='Mooli' style={{fontSize:'3vw'}}>
      Please make sure all fields are filled out correctly
            
            </div>
            </div>
         
            </div> 
             
     
            }
              
                    <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                        <div class="w-full">
                            <div class="-mx-1 ">
                                <div class="w-full ">
                                    <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                        <div class="w-full flex items-center">
                                            <div class="overflow-hidden rounded-lg  bg-gray-50 border border-gray-200" style={{width:'15vw',height:'15vw'}}>
                                                <img src={courseThumbnail} className='w-full h-full' alt=""/>
                                            </div>
                                            <div class="flex-grow pl-3">
                                                <h6 class="font-semibold uppercase text-gray-900 fjalla" style={{fontSize:'4vw'}}>{courseTitle} </h6>
                                                <p class="text-gray-400 borel" style={{fontSize:'3vw'}}>By {instructorName}</p>
                                            </div>
                                            <div>
                                            <span class="anton" style={{fontSize:"4vw",wordBreak:'keep-all'}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-3 m-auto " style={{width:'100%'}}>
                
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
                                                  <span style={{fontSize:"5vw"}} class="text-center fjalla">Choose Payment Method</span>
                                                  <div className='flex justify-evenly' style={{marginTop:'5vw'}}>
                                                                         <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}> 
                                                                                      <img style={{width:'80%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>CBE
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                                  <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>BOA
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                         </div>
                </div>
             
                <div>
                </div>
            </div>
                
                
            {
                                         cbebirr == true ?
                                         <div>
                                                 <div class="flex items-center gap-3 mb-2">
                             <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla ' style={{fontSize:'3vw'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                  
                 <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

           <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black   text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
              <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

           <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
              <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
                 
               
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
          
               <button    onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
    Upload Screenshot

  </button>
   
       
              <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                           
              
                           {
                                         telebirr == true ?
                           
                                               <div>
                                               <div class="flex items-center gap-3 mb-2">
                           <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                           </div>
                           <div class="flex flex-col">
                             <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                            
                             </p>
                           </div>
                         </div>
                                        <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                
               <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>
      
         <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black   text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
            <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
      <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>
      
         <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black w-full  text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
            <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder's Account Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
               
             
      
            <div className='flex items-center'>
       <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
      
        
             <button    onClick={() => {
                 document.getElementById('hidden-input-img').click();
               }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
      Upload Screenshot
      
      </button>
      
      
            <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
      
              
            </div>
            {
             fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
      
            }
      
      <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      This Field Can't be Empty
      </div>
      </div>
      </div>
                                       </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         cbe == true ?
                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                 
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         boa == true ?

                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {coursePrice} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                      
                                         :
                                         <div>
                 
                                         </div>
                                     }             
                           
                        
                                </div>
                              
                                <div style={{marginTop:'5vw'}} class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                        <div class="w-full flex items-center">
                                            <div class="flex-grow">
                                                <span class="text-gray-800 fjalla" style={{fontSize:'5vw'}}>Total</span>
                                            </div>
                                            <div class="pl-3">
                                                <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"5vw"}}>{coursePrice} ETB</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={()=>handlePaidDesktop(courseId)} type='submit' style={{fontSize:'3vw',width:'95%',height:'13vw'}}  className="bg-blue-800 mx-auto w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                       rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize fjalla">I have transferred the funds
                   </button>
                            </div>
                        </div>
                    </div>
                  
                </div>
                }
              </div>
            
            }
          </div>

        
      
          
          
        )
      })
    }



</body>
      
         

    )})}
</div>

            </div>
            </div>

            :
         <div>

         </div>
          )
        })
       }

    </div>
  )
}
