import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';

export default function Achievements() {
  const [user,setuser] = useState([])
  const [uid,setuid] = useState('')

  const [certificate,setcertificate] = useState([])
  const [course,setcourse] = useState([])
  const [notification,setnotification] = useState([])

  const [courseId,setcourseId] = useState('')

  const [copied,setcopied] = useState(false)
  const [isLoading,setisLoading] = useState(true)

  const [pop,setpop] = useState(false)
  const [notificationPop,setnotificationPop] = useState(false)




  const navigate = useNavigate()
  const [spinner,setspinner] =  useState(true)

    
  function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    deleteDoc(notificationRef)
    .then(() => {
      // Document successfully deleted from the database
      // Now remove the item from the array
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
      console.error("Error deleting document: ", error);
    });


   }

  const handleCopyUrl = () => {
    const url = `https://chekay.com/certificate/${uid}/${courseId}`;
  
    // Create a temporary input element
    const input = document.createElement('input');
    input.setAttribute('value', url);
    document.body.appendChild(input);
  
    // Select the input value
    input.select();
    input.setSelectionRange(0, 99999);
  
    // Execute the copy command
    document.execCommand('copy');
  
    // Remove the temporary input element
    document.body.removeChild(input);
  
    // Optionally, provide some visual feedback to the user
    setcopied(true)
  };
  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
 

    function closeMessage6Desktop(){
      setcopied(false)
  
    }
    
  function desktopSpinnerHandler(){
    setspinner(false)
   }
  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}



    
    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
          setuid(uid)
         

  const userRef = doc(firestore,"TotalUsers",uid);

    onSnapshot(userRef,snapshot=>{
   
      let main =[]
  
  
  
        main.push({...snapshot.data(),id:snapshot.id})
        
    setuser(main)
  
      
      
    
  
    })

    const certificateRef = collection(firestore,"AccountDetails",uid,'Certificate');

    onSnapshot(certificateRef,snapshot=>{
   
      let main =[]
  
  snapshot.docs.forEach(snapshot=>{
    main.push({...snapshot.data(),id:snapshot.id})
    setcertificate(main)
    onSnapshot(doc(firestore,'Courses',snapshot.data().courseId),snap=>{
      setcourse([{...snap.data()}])
      setcourseId(snap.data().courseId)
    })
    setisLoading(false)


  })
  
        
  
      
      
    
  
    })
    
    
      
          // ...
        } else {
          navigate('/')          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    const convertTimestampToDate = (timestamp) => {
      const date = timestamp.toDate();
      const currentDate = new Date();
      const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
    
      const secondsInMinute = 60;
      const secondsInHour = 3600;
      const secondsInDay = 86400;
      const secondsInWeek = 604800;
      const secondsInMonth = 2592000;
      const secondsInYear = 31536000;
    
      let relativeTime = '';
    
      if (timeDifferenceInSeconds < secondsInMinute) {
        relativeTime = `${timeDifferenceInSeconds}s ago`;
      } else if (timeDifferenceInSeconds < secondsInHour) {
        const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
        relativeTime = `${minutes}m ago`;
      } else if (timeDifferenceInSeconds < secondsInDay) {
        const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
        relativeTime = `${hours}h ago`;
      } else if (timeDifferenceInSeconds < secondsInWeek) {
        const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
        relativeTime = `${days}d ago`;
      } else if (timeDifferenceInSeconds < secondsInMonth) {
        const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
        relativeTime = `${weeks}w ago`;
      } else if (timeDifferenceInSeconds < secondsInYear) {
        const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
        relativeTime = `${months}mo ago`;
      } else {
        const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
        relativeTime = `${years}y ago`;
      }
    
      return relativeTime;
    };
  
  
  
       function desktopSpinnerHandler(){
        setspinner(false)
       }
  return (
  user.map(i=>{
    const {photo,plan,fullName,accountType} = i
    if(accountType == 'Student'){
      return(
        <div>
            {
         spinner == true &&
         <div id='spinnerDesktop'>
         <div
       class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
       role="status">
       <span
       class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
       >Loading...</span
       >
       </div>
       
         </div>
        }
        <div id='desktop'>
        {
         spinner == true &&
         <div id='spinnerDesktop'>
         <div
       class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
       role="status">
       <span
       class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
       >Loading...</span
       >
       </div>
       
         </div>
        }
  
            <div>
            <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>

      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/enrolledcourses' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >Enrolled Courses</li>
      
                    </Link>
      <Link to="/courses" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
                    <Link to="/achievements" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 border-b border-blue-800' >Achievements</li>
      
                    </Link>
      
           
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied Certificate URL
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
  
            {
              certificate && certificate.length == 0?
              <div className='px-6 flex items-center justify-center flex-col '>
              <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Achievement-bro.svg?alt=media&token=43bb4989-705a-4b31-a8c0-90f442c7b2ea" alt="Transaction" style={{width:"25%"}}/>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>You don't have any accomplishments on your account at the moment 
            
            
            </h1>
                <p className='borel text-gray-500 text-center' style={{fontSize:'11px'}}>All of your accomplishments for completed courses will be listed here</p>
              
              </div>
              :
              <div>
                {
                  certificate.map(i=>{
                    return(
                      course.map(i=>{
                    const {courseId,courseTitle,courseThumbnail} = i

                        return(
                          <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                          <div className='flex items-center  '>
                           <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                       <div>
                       <Link to={`/course/${courseId}`} >
                 <h1 style={{fontSize:'25px'}} className='capitalize ml-4 mb-1 fjalla cursor-pointer hover:text-blue-800 hover:underline'>
                                     {
                                     courseTitle
   
                                     }
                                   </h1></Link>
                               <p></p>
                       </div>
                          </div>
    
             
                                 <button  onClick={handleCopyUrl}  style={{fontSize:'15px'}}  className="bg-blue-800 fjalla  pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">Share Certificate
                                     </button>
    
                             </div>
                        )
                      })
                     
                    )
                  })
                }
              </div>
            }
        
            </div>
            
             
       
       </div>
        <div id='tablet'>
      
  
            <div>
    <DashboardNav/>
            {
      copied == true &&
      <div id="alert-border-6" class="flex items-center p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 " role="alert">
    <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <div class="ml-3 text-sm font-medium Mooli capitalize">
     successfully Copied Certificate URL
    </div>
    <button onClick={closeMessage6Desktop} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8  "  data-dismiss-target="#alert-border-3" aria-label="Close">
      <span class="sr-only">Dismiss</span>
      <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
    </button>
</div>
    }
  
            {
              certificate && certificate.length == 0?
              <div className='px-6 flex items-center justify-center flex-col '>
              <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Achievement-bro.svg?alt=media&token=43bb4989-705a-4b31-a8c0-90f442c7b2ea" alt="Transaction" style={{width:"25%"}}/>
                <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>You don't have any accomplishments on your account at the moment 
            
            
            </h1>
                <p className='borel text-gray-500 text-center' style={{fontSize:'11px'}}>All of your accomplishments for completed courses will be listed here</p>
              
              </div>
              :
              <div>
                {
                  certificate.map(i=>{
                    return(
                      course.map(i=>{
                    const {courseId,courseTitle,courseThumbnail} = i

                        return(
                          <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                          <div className='flex items-center  ' style={{width:'80%'}}>
                           <img style={{width:'120px',height:'120px',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                       <div>
             <Link to={`/course/${courseId}`} >
             <h1 style={{fontSize:'20px'}} className='capitalize ml-4 mb-1 fjalla cursor-pointer hover:text-blue-800 hover:underline'>
                                 {
                                 courseTitle

                                 }
                               </h1></Link>
                               <div class="w-full bg-neutral-200 ml-3 my-1">
 
    </div>
                       </div>
                          </div>

                       <button  onClick={handleCopyUrl}  style={{fontSize:'12px'}}  className="bg-blue-800 fjalla  pt-4  pb-4 px-2 text-xl font-medium text-center text-white 
                                     rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize mr-5">Share Certificate 
                                 </button>


                             </div>
                        )
                      })
                     
                    )
                  })
                }
              </div>
            }
        
            </div>
            
             
       
       </div>
         <div id='mobile'>
      
  
         <div>
 <DashboardNav/>
         {
   copied == true &&
   <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 " role="alert">
 <div className='flex justify-center items-center'>
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
successfully Copied Certificate URL


</div>
</div>
<svg  onClick={closeMessage6Desktop}style={{width:'3vw',height:'3vw',marginRight:'2vw'}} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
 </svg>
</div> 

 }

         {
          isLoading ?
          <div className='flex items-center justify-center h-full flex-col' >
          <h1 className='anton' style={{fontSize:'20px'}}>Loading...</h1>
          <p className='borel'style={{fontSize:'11px'}}>Fetching data</p>
        </div>
        :
           certificate && certificate.length == 0?
           <div className='px-6 flex items-center justify-center flex-col '>
           <img onLoad={desktopSpinnerHandler} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Achievement-bro.svg?alt=media&token=43bb4989-705a-4b31-a8c0-90f442c7b2ea" alt="Transaction" style={{width:"25%"}}/>
             <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"20px"}}>You don't have any accomplishments on your account at the moment 
         
         
         </h1>
             <p className='borel text-gray-500 text-center' style={{fontSize:'11px'}}>All of your accomplishments for completed courses will be listed here</p>
           
           </div>
           :
           <div>
             {
               certificate.map(i=>{
                 return(
                   course.map(i=>{
                 const {courseId,courseTitle,courseThumbnail} = i

                     return(
                      <div className='border items-center flex shadow-md mx-auto mt-8 justify-between' style={{width:'90%'}}>
                      <div className='flex items-center  '>
                       <img style={{width:'23vw',height:'25vw',objectFit:'cover'}} src={courseThumbnail} className='rounded' />
                   <div className='overflow-hidden'>
         <Link to={`/course/${courseId}`} >
         <h1 style={{fontSize:'4vw'}} className='capitalize ml-4 mb-1 fjalla cursor-pointer hover:text-blue-800 hover:underline'>
                             {
                             courseTitle

                             }
                           </h1></Link>
                           <div class="w-full bg-neutral-200 ml-3">

</div>
                   </div>
                      </div>

                   <button  onClick={handleCopyUrl}  style={{fontSize:'2.8vw',height:'12vw',padding:'0 2vw'}}  className="bg-blue-800 flex justify-center items-center fjalla  -medium text-center text-white 
                                 rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize ">Share Certificate 
                             </button>


                         </div>
                     )
                   })
                  
                 )
               })
             }
           </div>
         }
     
         </div>
         
          
    
    </div>
       </div>

      )
   

    }
    else{
      navigate('/dashboard')
    }
  })
  )
}
