import React, { useState } from 'react';

import { auth } from '../firebase';
import { Link } from 'react-router-dom';
import { sendEmailVerification } from 'firebase/auth';

    const UserVerified = ({ children }) => {
        const [success,setSuccess] =useState()
        const [error,seterror] =useState('')
        
     const user = auth.currentUser;
function resendVerfication(){
    sendEmailVerification(auth.currentUser)
    .then(() => {
        seterror('')

        setSuccess(true)
    }).catch(error=>{
       if(error.code == 'auth/too-many-requests'){
            seterror('Too many Requests')
       }
    })
}

function minimizeResetPassword(){
    setSuccess(false)

   }

        if (user && user.emailVerified === false) {

          return (
            <div>
               <div id='desktop'>
                <nav className='flex justify-between navTablet w-full  px-10 py-6'>

       <img style={{width:"8%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
<div></div>

</nav>
<div className={`${error.length != 0 ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    {error}
    </div>
    </div>
    </div>
{
    success == true ?
    <div id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


    <div className="relative p-4 w-full  "style={{width:"80%"}}>
    <div className="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
     
       <button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
           <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
           <span className="sr-only">Close modal</span>
       </button>
    
    
       <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
           <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
           <span className="sr-only">Success</span>
       </div>
       <p className=" text-lg font-semibold text-gray-900  capitalize">Email verification link successfully sent</p>
                    <p className='borel mb-5 text-gray-600'>Please check your email inbox for a verification link</p>
    </div>
    </div>
    
    
    
    </div>
    :
    <div className="relative flex   items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
  <img style={{width:'40%'}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Verified-rafiki.svg?alt=media&token=b1a8520c-44eb-4162-88b5-bb7ae1334c48'/>
  <div style={{width:'50%'}} className=" px-5 text-center">
    <h2 className="mb-2 text-[42px] anton">Check your inbox</h2>
    <p className="mb-2 text-lg text-zinc-500 borel"  style={{fontSize:'15px',lineHeight:'18px'}}>We are glad, that you’re with us ? We’ve sent you a verification link to the email address <span className="font-medium text-blue-500">{user.email}</span>.</p>
    <a href="https://mail.google.com/" style={{fontSize:'14px'}} target="_blank" className="mt-3 inline-block w-96 rounded bg-blue-800 fjalla px-5 py-3  text-white shadow-md shadow-blue-500/20 hover:bg-blue-900">Open the App →</a>
    <p onClick={resendVerfication} className='cursor-pointer mt-2 borel hover:underline hover:text-blue-700'>Resend Email</p>
  </div>
</div>
}

                </div>
                <div id='tablet'>
                <nav className='flex justify-between navTablet w-full  px-10 py-6'>

       <img style={{width:"8%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
<div></div>

</nav>
<div className={`${error.length != 0 ? "block":"hidden"}`}>
    <div className="flex items-center p-4 mb-1 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli'>
    {error}
    </div>
    </div>
    </div>
{
    success == true ?
    <div id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


    <div className="relative p-4 w-full  "style={{width:"80%"}}>
    <div className="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
     
       <button onClick={minimizeResetPassword}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
           <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
           <span className="sr-only">Close modal</span>
       </button>
    
    
       <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
           <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
           <span className="sr-only">Success</span>
       </div>
       <p className=" text-lg font-semibold text-gray-900  capitalize">Email verification link successfully sent</p>
                    <p className='borel mb-5 text-gray-600'>Please check your email inbox for a verification link</p>
    </div>
    </div>
    
    
    
    </div>
    :
    <div className="relative flex   items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
  <img style={{width:'40%'}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Verified-rafiki.svg?alt=media&token=b1a8520c-44eb-4162-88b5-bb7ae1334c48'/>
  <div style={{width:'60%'}} className=" px-5 text-center">
    <h2 className="mb-2  anton" style={{fontSize:'35px'}}>Check your inbox</h2>
    <p className="mb-2 text-lg text-zinc-500 borel"  style={{fontSize:'11px',lineHeight:'18px'}}>We are glad, that you’re with us ? We’ve sent you a verification link to the email address <span className="font-medium text-blue-500">{user.email}</span>.</p>
    <a href="https://mail.google.com/" style={{fontSize:'14px'}} target="_blank" className="mt-3 inline-block px-10 rounded bg-blue-800 fjalla  py-3  text-white shadow-md shadow-blue-500/20 hover:bg-blue-900">Open the App →</a>
    <p onClick={resendVerfication} style={{fontSize:'11px'}} className='cursor-pointer mt-2 borel hover:underline hover:text-blue-700'>Resend Email</p>
  </div>
</div>
}

                </div>
                <div id='mobile'>
                <nav className='flex justify-between navTablet w-full  px-5 py-6'>

       <img style={{width:"30%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
<div></div>

</nav>

    <div className={`${error.length != 0 ? "block":"hidden"}`}>
    <div style={{marginBottom:'5vw',paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',marginTop:'5vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
     <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span className="sr-only">Info</span>
    <div className='Mooli' style={{fontSize:'3vw'}}>
    {error}
    </div>
    </div>
    </div>
{
    success == true ?
    <div id='submitMobile'>

    <div className='align'>
    <svg onClick={minimizeResetPassword} style={{position:"absolute",top:"50px",right:"50px",color:"black"}} xmlns="http://www.w3.org/2000/svg" width="10vw" height="10vw" id='cancel' fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
       <h1 className='anton text-center capitalize' style={{color:"black",fontSize:"7vw",width:"95%",margin:0,paddingLeft:"10%"}}>Email verification link successfully sent</h1>
                    <p className='borel mb-5 text-gray-900 text-center' style={{fontSize:'3vw'}}>Please check your email inbox for a verification link</p>
   
       </div>
            </div>
    :
    <div className="relative flex flex-col  items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
  <img style={{width:'50%'}} src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Verified-rafiki.svg?alt=media&token=b1a8520c-44eb-4162-88b5-bb7ae1334c48'/>
  <div style={{width:'90%'}} className=" px-5 text-center">
    <h2 className="mb-2  anton" style={{fontSize:'5vw'}}>Check your inbox</h2>
    <p className="mb-2 text-lg text-zinc-500 borel"  style={{fontSize:'11px',lineHeight:'18px'}}>We are glad, that you’re with us ? We’ve sent you a verification link to the email address <span className="font-medium text-blue-500">{user.email}</span>.</p>
    <a href="https://mail.google.com/" style={{fontSize:'3vw'}} target="_blank" className="mt-3 inline-block px-10 rounded bg-blue-800 fjalla  py-3  text-white shadow-md shadow-blue-500/20 hover:bg-blue-900">Open the App →</a>
    <p onClick={resendVerfication} style={{fontSize:'2.5vw'}} className='cursor-pointer mt-2 borel hover:underline hover:text-blue-700'>Resend Email</p>
  </div>
</div>
}

                </div>
            </div>
          )
        }
    
        return children
      };

export default UserVerified;
