import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc, updateDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import ReactSignatureCanvas from 'react-signature-canvas';
import QRCode from 'qrcode.react';
import DashboardNav from './dashboardNav';

export default function Certificate() {
    const {courseId,certificateId} = useParams()
    const [uid,setuid] = useState("")
    const [spinner,setspinner] = useState(true)
    const [tookCertificate,settookCertificate] = useState(true)

    const [loading,setloading] = useState(false)
    

    const [certificateOpen,setcertificateOpen] = useState(false)
    const [certificatePreview,setcertificatePreview] = useState(false)
    const [certificateBody,setcertificateBody] = useState(true)
    const [success,setsuccess] = useState(false)
    const [deletePop,setdeletePop] = useState(false)
    const [deleteMessage,setdeleteMessage] = useState(false)



    const [NewcertificateTitle,setNewcertificateTitle] = useState('')
    const [certificateTitleError,setcertificateTitleError] = useState('')
    const [presentedToError,setpresentedToError] = useState('')
    const [NewpresentedTo,setNewpresentedTo] = useState('')
    const [signature,setsignature] = useState('')
    const [signatureError,setsignatureError] = useState('')









    const [pop,setpop] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)


    const [user,setuser] = useState([])
    const [fullName,setfullName] = useState()
    const [accountType,setaccountType] = useState()

    const [plan,setplan] = useState()
    const [photo,setphoto] = useState()

    const [notification,setnotification] = useState([])

    const [certificateName,setcertificateName] = useState('')
    const [certificateIssuanceDate,setcertificateIssuanceDate] = useState()

    const navigate = useNavigate()

    const [paymentFrequencyError,setpaymentFrequencyError] = useState("")

    const [pricingDescription,setpricingDescription] = useState("")
    const [pricingDescriptionError,setpricingDescriptionError] = useState("")

 
    const [course,setcourse] = useState([])
    const [NewcertificateDescription,setNewcertificateDescription] = useState("")
    const [courseTitle,setcourseTitle] = useState("")



    const [certificateDescriptionError,setcertificateDescriptionError] = useState("")
    const [certificateStudentName,setcertificateStudentName] = useState("")
    const [certificateStudentNameError,setcertificateStudentNameError] = useState("")

    const [NewcertificateInstructorName,setNewcertificateInstructorName] = useState("")
    const [certificateInstructorNameError,setcertificateInstructorNameError] = useState("")


    const textRef = React.useRef();
    React.useEffect(() => {
      if (textRef && textRef.current) {
        textRef.current.style.height = "0px";
        const taHeight = textRef.current.scrollHeight;
        textRef.current.style.height = taHeight + "px";
      }
    }, [NewcertificateDescription]);

    function certificateDescriptionSet(e){
      const value = e.target.value;
      setNewcertificateDescription(value);
    
      if (value.length === 0 || value.length > 280) {
        setcertificateDescriptionError("error");
      } else {
        setcertificateDescriptionError("");
      }
  }

  function certificateStudentNameSet(e){
    setcertificateStudentName(e.target.value)
    if(e.target.value.length == 0){

        setcertificateStudentNameError("error")
    }
    else{
        setcertificateStudentNameError("")
    }
}
function minimizeSuccessDesktop(){
  setsuccess(false)
}

function handleLogOut(){
  signOut(auth).then(
   navigate('/')
  )
 }

 function certificateTitleSet(e) {
  const value = e.target.value;
  setNewcertificateTitle(value);

  if (value.length === 0 || value.length > 35) {
    setcertificateTitleError("error");
  } else {
    setcertificateTitleError("");
  }
}

function presentedToSet(e) {
  const value = e.target.value;
  setNewpresentedTo(value);

  if (value.length === 0 || value.length > 60) {
    setpresentedToError("error");
  } else {
    setpresentedToError("");
  }
}

 function handleCertificateSave(e){
  e.preventDefault()
  const scrollX = 0; 
  const scrollY = window.innerHeight / 2; 
  
  window.scrollTo(scrollX, scrollY);
  if (NewcertificateTitle.length === 0 || NewcertificateTitle.length > 36) {

    setcertificateTitleError('error')
  }
  else{
    if (NewpresentedTo.length === 0 || NewpresentedTo.length > 61) {
      setpresentedToError("error");

    }
    else{
  if (NewcertificateInstructorName.length === 0 || NewcertificateInstructorName.length > 21) {
    setcertificateInstructorNameError('error')

    }
    else{
  if (NewcertificateDescription.length === 0 || NewcertificateDescription.length > 281) {
    setcertificateDescriptionError('error')
    }
    else{
  if (signature.length === 0 ) {
    setsignatureError('error')

    }
    else{
    setsignatureError('')
    const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
    const documentRef2 = doc(firestore,"Courses",courseId)
const documentRef3 = doc(firestore,"AccountDetails",uid,"Certificate",courseId + uid)


    const lectureData = {
 
      certificateId:courseId,
      certificate:true,
certificateStudentName:"",
certificateDescription:NewcertificateDescription,
certificateInstructorName:NewcertificateInstructorName,
certificateInstructorSignature:signature,
presentedTo:NewpresentedTo,
certificateTitle:NewcertificateTitle,
createdTime:serverTimestamp(),


       }

    setDoc(documentRef, lectureData, { merge: true })
    setDoc(documentRef2, lectureData, { merge: true }).then(
      setDoc(documentRef3,lectureData)
    ).then(
      setsuccess(true),
      setcertificateOpen(false),
      setNewcertificateDescription(''),
      setNewcertificateInstructorName(''),
      setNewpresentedTo(''),
      setNewcertificateTitle(''),



    )
      
    }
  
  }

 }
}
}
}


 
  function desktopSpinnerHandler(){
    setspinner(false)
   }

function certificateInstructorSet(e){
  const value = e.target.value;
  setNewcertificateInstructorName(value);

  if (value.length === 0 || value.length > 21) {
    setcertificateInstructorNameError("error");
  } else {
    setcertificateInstructorNameError("");
  }
}

   // Notification Icon Click Handler

   const popProfile = () =>{
    if(pop == true){
      setpop(false)
      setnotificationPop(false)

    }
    else{
    setpop(true)
    setnotificationPop(false)


    }
}
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
const convertTimestampToDate = (timestamp) => {
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
};

function removeNotification(id){
  const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

  setDoc(notificationRef,{
    seen:true
  },{merge:true})
  .then(() => {
    
    setnotification(prevArray => prevArray.filter(item => item.id !== id));
  })
  .catch((error) => {
  });


 }

function closeCertificate(){
  setcertificateOpen(false)


}

function openCertificate(){
setcertificateOpen(true)
  
  }

    // Mobile Nav Pop
    function mobileNavPop(){
      setpop(true)
  
  
    
  
  }
  
  // Mobile Nav Pop minimize
  function mobileNavPopMinimize(){
  setpop(false)
  
  
  
  }
  

// Notification Icon Click Handler



    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
        const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");

  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

  onSnapshot(notificationRef,snapshot=>{
    let main =[]
    



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })
        onSnapshot(doc(firestore,"TotalUsers",uid),snapshot=>{
          let main =[]
          
          main.push({...snapshot.data()})
          setuser(main)
          setfullName(snapshot.data().fullName)
          setphoto(snapshot.data().photo)
          setplan(snapshot.data().plan)
          setaccountType(snapshot.data().accountType)

        
        })
    //   fetch the courses data from the courses collection which has courseId id 
    onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
        let main =[]
        
        main.push({...snapshot.data()})
        setcourse(main)
        setcourseTitle(snapshot.data().courseTitle)
      })
    
      onSnapshot(doc(firestore,'Certificate',certificateId),snapshot=>{
        setcertificateIssuanceDate(snapshot.data() && snapshot.data().issuanceDate)
        setcertificateName(snapshot.data() && snapshot.data().certificateStudentName)
        settookCertificate(snapshot.data() && snapshot.data().tookCertificate)

        

    
      
      })

 

     
    
            
    
            
    
               
     
    //    })
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);
      

      useEffect(()=>{
        onSnapshot(doc(firestore,"Courses",courseId),snapshot=>{
          let main =[]
          
          main.push({...snapshot.data()})
          setcourse(main)
          setcourseTitle(snapshot.data().courseTitle)
        })
      
        onSnapshot(doc(firestore,'Certificate',certificateId),snapshot=>{
          setcertificateIssuanceDate(snapshot.data() && snapshot.data().issuanceDate)
          setcertificateName(snapshot.data() && snapshot.data().certificateStudentName)
          
  
      
        
        })
      },[])

      function editCertificateSignatureDesktop(){
        const signature = document.getElementById("signatureDesktop");
        const photo = document.getElementById("signaturePhotoDesktop");

        signature.style.display = "block"
        photo.style.display = "none"



        
      }

      function editCertificateInstructorNameDesktop(){
        const editor = document.getElementById("instructorEditorDesktop");
        const name = document.getElementById("instructorNameDesktop");

        editor.style.display = "flex"
        name.style.display = "none"



        
      }
      
      function closeDescriptionEditorDesktop(){
        const certificateDescriptionEditor = document.getElementById("certificateDescriptionEditorDesktop");
        const certificateDescription = document.getElementById("certificateDescriptionDesktop");

    certificateDescriptionEditor.style.display="none"
    certificateDescription.style.display="flex"

    
            }
      function editCertificateText(){
        const certificateDescription = document.getElementById("certificateDescriptionDesktop");
        const certificateDescriptionEditor = document.getElementById("certificateDescriptionEditorDesktop");
        certificateDescriptionEditor.style.display="flex"

    certificateDescription.style.display="none"
    
            }
            function openPopDeleteDesktop(){
             setdeletePop(true)
            
              
            }
            function closePopDeleteDesktop(){
              setdeletePop(false)

            
              
            }

            function checkCertificate(){
              setloading(true)
              setTimeout(() => {
                setloading(false)
                setsuccess(true)
                
              }, 1000);
            }
            
            function closeDeleteMessage(){
              setdeleteMessage(false)

            }
            function deleteHandlerDesktop(){
           
              const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
              const documentRef2 = doc(firestore,"Courses",courseId)
        const documentRef3 = doc(firestore,"AccountDetails",uid,"Certificate",courseId + uid)

        
              const lectureData = {
           
                certificateId:"",
                certificate:false,
  certificateStudentName:"",
  certificateDescription:"",
  certificateInstructorName:"",
  certificateInstructorSignature:"",
  certificateTitle:'',
  presentedTo:'',

        
                 }
        
              setDoc(documentRef, lectureData, { merge: true })
              setDoc(documentRef2, lectureData, { merge: true }).then(
                setDoc(documentRef3,lectureData)
              ).then(
             setdeletePop(false),
             setdeleteMessage(true)
              )
            
            }
      function  SignatureCollector() {
        const signatureRef = useRef();
      
        const handleClear = () => {
          signatureRef.current.clear();
          setsignature('')
          setsignatureError('')
          
        
        };
        const [windowSize, setWindowSize] = useState(window.innerWidth);

        useEffect(() => {
          const handleResize = () => {
            setWindowSize(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);
        const handleSave = () => {
          setsignatureError('');
          const scrollX = 0; 

          const scrollY = windowSize > 900 ? window.innerHeight : windowSize > 550 && windowSize <= 900 ? window.innerHeight/ 2 : window.innerHeight/7 ; 
          
          const signatureData = signatureRef.current.toDataURL();
        
          if (!signatureRef.current.isEmpty()) {
            setsignature(signatureData);
            window.scrollTo(scrollX, scrollY);
         
          } else {
            setsignatureError('error');

            window.scrollTo(scrollX, scrollY);
            // Handle the case where the user did not sign but clicked on Save
          }
        };
      

        return (
         <div>
          {
            windowSize > 900 ?
            <div >
            <h1 className='fjalla text-gray-600' style={{fontSize:'15px'}}>Please Sign here</h1>
            <div className='flex items-center justify-evenly flex-col mb-1'>

            <ReactSignatureCanvas  ref={signatureRef} canvasProps={{ width: 300, height: 100,color:"white", className: 'signature-canvas border ' }} />
   
 <div className='flex justify-center mt-2'>
 <a style={{fontSize:'12px'}}  onClick={handleClear} class="mx-2 px-3  fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Clear</a>
   <a style={{fontSize:'12px'}}  onClick={handleSave} class=" px-3 fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Save</a>

 </div>
   </div>
          </div>
          :
          windowSize > 550 && windowSize <= 900 ? 
          <div >
          <h1 className='fjalla text-gray-600' style={{fontSize:'15px'}}>Please Sign here</h1>
          <div className='flex items-center justify-evenly flex-col mb-1'>

          <ReactSignatureCanvas  ref={signatureRef} canvasProps={{ width: 300, height: 100,color:"white", className: 'signature-canvas border ' }} />
 
<div className='flex justify-center mt-2'>
<a style={{fontSize:'12px'}}  onClick={handleClear} class="mx-2 px-3  fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Clear</a>
 <a style={{fontSize:'12px'}}  onClick={handleSave} class=" px-3 fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Save</a>

</div>
 </div>
        </div>
          :
           <div >
            <h1 className='fjalla text-gray-600' style={{fontSize:'3vw'}}>Please Sign here</h1>
            <div className='flex items-center justify-evenly flex-col mb-1'>

            <ReactSignatureCanvas  ref={signatureRef} canvasProps={{ width: 300, height: 100,color:"white", className: 'signature-canvas border ' }} />
   
 <div className='flex justify-center mt-2'>
 <a style={{fontSize:'3vw',height:'13vw',padding:'0 3vw'}}  onClick={handleClear} class="mx-2 flex justify-center items-center  fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Clear</a>
   <a style={{fontSize:'3vw',height:'13vw',padding:'0 3vw'}}  onClick={handleSave} class=" flex justify-center items-center fjalla w-full cursor-pointer py-2 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Save</a>

 </div>
   </div>
          </div>
          }
       
        
         </div>
        );
      }

      function saveInstructorNameDesktop(){
        const editor = document.getElementById("instructorEditorDesktop");
        const name = document.getElementById("instructorNameDesktop");

        const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
        const documentRef2 = doc(firestore,"Courses",courseId)
        const documentRef3 = doc(firestore,"AccountDetails",uid,"Certificate",courseId + uid)
     const   certificateData =  {
      certificateInstructorName:NewcertificateInstructorName,
      createdTime:serverTimestamp(),
      certificateId:courseId + uid ,
      certificate:true,
      courseTitle:courseTitle,




     }

     setDoc(documentRef,certificateData,{merge:true}).then(
     setDoc(documentRef2,certificateData,{merge:true}).then(
      setDoc(documentRef3,certificateData,{merge:true})
     ).then(
      editor &&(  editor.style.display="none"),
      name &&(  name.style.display="flex"),


     )

     )

      }

      function saveInstructorDescriptionDesktop(){
        const editor = document.getElementById("certificateDescriptionEditorDesktop");
        const name = document.getElementById("certificateDescriptionDesktop");

        const documentRef = doc(firestore,"AccountDetails",uid,"CoursesCreated",courseId)
        const documentRef2 = doc(firestore,"Courses",courseId)
        const documentRef3 = doc(firestore,"AccountDetails",uid,"Certificate",courseId + uid)
     const   certificateData =  {
      certificateDescription:NewcertificateDescription



     }

     setDoc(documentRef,certificateData,{merge:true}).then(
     setDoc(documentRef2,certificateData,{merge:true}).then(
      setDoc(documentRef3,certificateData,{merge:true})
     ).then(
      editor &&(  editor.style.display="none"),
      name &&(  name.style.display="flex"),


     )

     )

      }


      function openPreviewCertificate(){
        const preview = document.getElementById("previewCertificateDesktop")
        const editor = document.getElementById("editorCertificateDesktop")

        editor.style.display = "none"
        preview.style.display = "flex"

      }

      function openEditorCertificate(){
    navigate(`/course/${courseId}`)
      }

  
      function formatDate(timestamp) {
        if (!timestamp) {
          return ''; // Return an empty string or handle the case when the timestamp is undefined
        }
        
        const date = timestamp.toDate();
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
      }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
     
return(
  accountType == 'Institute' ?
  <div>
  {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
  <div>
  <div id='desktop'  className='relative'>
  {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
 {
            success == true &&
            <div id='successStudentDesktop' style={{zIndex:101}}> 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"35px"}}>This is the official certificate of kesht academy</h1>
            </div>
            </div>
           }
 <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {formatDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            {
              deleteMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Certificate successfully removed 
  </div>
  <button onClick={closeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
            }

    {
      deletePop == true &&
      <div id='deleteCertificateDesktop' className='  flex items-center justify-center'>
     
   
     <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
 <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
     <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
   </svg>
     
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Certificate</h1>
<p class="text-sm text-gray-500 Mooli normal-case capitalize" >Would you like to permanently remove this certificate? </p>
<div class="flex flex-row mt-6 space-x-2 justify-evenly">
 <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
 <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


 </div>
    }
 {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"35px"}}>Certificate successfully created</h1>
            </div>
            </div>
           }
{
  certificateOpen == true ?
  <div id='certificateDesktop' className=' '  >








  {
  course.map(i=>{
    const {certificateTitle,presentedTo,certificate,certificateInstructorSignature,certificateInstructorName,certificateDescription} = i
    return(
      <div>
          <div className='flex items-center mx-5 my-5' >
  
          <div className='' >
  
  <button onClick={closeCertificate} style={{fontSize:'13px'}} class=" fjalla m-auto px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
        Back
              </button>
  
  

     
              </div>
  <div>

  </div>
      
  </div>
  <div className='mx-auto flex justify-between pb-12 px-4'  id='firstDesktop'>
  <div  style={{width:"50%"}} className='relative '>

<div className='flex w-full  relative  p-6 flex-col  certificate-container border-4 border-gray-800 relative rounded' >
<div className="certificate-border absolute top-1.5 left-1.5 right-1.5 bottom-1.5 border-l-2 border-r-2 border-b-2 border-gray-800 pointer-events-none"></div>
<div class="custom-shape-divider-top-1713134948">
   <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
       <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
   </svg>
</div>  
<p className='fjalla pt-5 capitalize text-center text-gray-800' style={{fontSize:'25px'}}>{NewcertificateTitle ? NewcertificateTitle : certificateTitle ? certificateTitle :<div class="h-3 bg-gray-200 rounded-full  w-52  mx-auto"></div>}</p>
<p className='borel pt-8 text-center text-gray-500'style={{fontSize:'12px'}}>{NewpresentedTo ? NewpresentedTo : presentedTo ? presentedTo : <div class="h-2.5 bg-gray-200 rounded-full  w-48  mx-auto -mt-4"></div> }</p>

<p className='borel pt-1 text-center text-blue-900 dancing-script'style={{fontSize:'30px'}}>Student Name</p>
<p className='borel pt-3 text-center text-gray-500'style={{fontSize:'11px',maxWidth:'90%',wordBreak:'break-all'}}>{NewcertificateDescription ? NewcertificateDescription: certificateDescription ? certificateDescription: <div className='flex flex-col item-center w-full'><div class="h-2 bg-gray-200 rounded-full  mx-auto  w-[480px] "></div> <br /><div class="h-2 bg-gray-200 rounded-full  w-[480px] -mt-1 mx-auto"></div></div>}</p>
<div>
<div className="flex w-full justify-between px-8 items-center mt-8">
<QRCode value={window.location.href}  size={50} />
<img style={{width:"70px",objectFit:'contain'}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 

<div className='flex items-center justify-center flex-col'>

{
 signature ?   <div className=''>
 <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div> : certificateInstructorSignature ? 
<div className=''>
 <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div>
:
<div className='flex items-center justify-center flex-col'>
<div class="flex items-center justify-center h-20 w-20 mb-4 bg-gray-300 rounded ">
       <svg class="w-7 h-7 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
           <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
           <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
       </svg>
   </div>
</div>
}
<p className='fjalla text-gray-700 text-center'style={{fontSize:'11px'}}>{NewcertificateInstructorName ? NewcertificateInstructorName: certificateInstructorName ? certificateInstructorName:<div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>} </p>
</div>



</div>
<div className='flex mt-8 justify-between mt-8'>
<p className='fjalla text-gray-700 text-center'style={{fontSize:'11px'}}>Issuance Date</p>
<p className='borel text-blue-800 text-center normal-case'style={{fontSize:'10px'}}>keshtacademy.com</p>

</div>
</div>
 </div>
</div>
<div style={{width:"40%"}} >
<h1 className='anton text-left mb-6 ' style={{fontSize:'30px'}}>
Describe Your Certificate
</h1>


<form  autoComplete="off"   className="wmt-12 mr-0 mb-0 ml-0 relative space-y-8  mx-auto w-full " id="first_modal" >




<div className="relative">

<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Title</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>certificateTitleSet(e)}  required  autoComplete="off" placeholder="Certificate of Completion" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${certificateTitleError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>

The title of the certificate must contain between one and 35 characters
</div>
</div>
</div>
</div>
<div className="relative">

<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Presented to message</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>presentedToSet(e)}  required  autoComplete="off" placeholder="This Certificate is Proudly presented to" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${presentedToError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>

A certificate's message must contain at least one but not more than 60 characters
</div>
</div>
</div>
</div>
<div className="relative">


<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Instructor (Your Name)</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>certificateInstructorSet(e)}  required  autoComplete="off" placeholder={fullName} type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />

<div className={`${certificateInstructorNameError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
The name of the instructor must be between 0 and 20 characters long

</div>
</div>
</div>
</div>
<div className="relative">


<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Description</p>



<textarea defaultValue={certificateDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>certificateDescriptionSet(e)}  type="text" class="border  focus:outline-none
                 focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="280"
                 placeholder='For completing (COURSE NAME). This course provided comprehensive training in (COURSE CONTENT) .This recipient has acquired valuable skills and knowledge in (SKILLS ACQUIRED)"
                 '></textarea>
                 <div className={`${certificateDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/30/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
The description of the certificate must be between 0 and 280 characters long

</div>
</div>
</div>
</div>
<div className="relative">


<SignatureCollector/>

<div className={`${signatureError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
This field Can't be empty
</div>
</div>
</div>
</div>




<div className="relative" >
<button onClick={(e)=>handleCertificateSave(e)} type='submit' style={{backgroundColor:"#2141ce" ,}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white 
rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">{certificate == true ? 'Save Changes' : 'Create Certificate'}
</button>
</div>
</form>
</div>
</div>
  
  
  <div id='previewCertificateDesktop' className="hidden w-full relative  flex flex-col items-center mx-auto" >
  <img style={{ position: 'absolute', top: 0, right: 0, widzth: '100%', zIndex: 102 }}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/resume%2Fwave-haikei.svg?alt=media&token=77ef3376-5c07-40d3-909f-b4ec48fc0775" alt="" />
  
  <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 101 }}>
        <div className='bg-blue-800 certificate-container border-4 border-blue-800 p-8 relative' style={{ height: '100%', width: '100%', backgroundColor:'white', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center' }}>
       
        <div className="certificate-border absolute top-1.5 left-1.5 right-1.5 bottom-1.5 border-2 border-blue-800 pointer-events-none"></div>
       
        <img style={{width:"10%",objectFit:"cover",position:'fixed',left:'80%',top:'40px'}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
  <div className='flex justify-center' style={{width:"250px",height:'250px',objectFit:"cover",position:'fixed',right:'30px',top:'180px'}} >
  <QRCode value={window.location.href}  size={100} />
  </div>
  <span className='bebas py-12 capitalize'style={{width:"250px",height:'250px',objectFit:"cover",position:'fixed',right:'10px',top:'500px',fontSize:"35px",lineHeight:'36px',fontWeight:'200',color:'#025B40'}}>Feb 21, 2024
  </span>
      <span className='bebas py-12 capitalize  text-gray-800'style={{height:'250px',objectFit:"cover",position:'fixed',left:'100px',top:'20px',fontSize:"5.5vw",lineHeight:'5vw',fontWeight:'300'}}>Certificate  <span className='bebas ' style={{fontSize:"5vw",lineHeight:'5vw',fontWeight:'100'}}>of Completion</span><br/>
  </span>
  <span className=' montserrat py-12 capitalize text-center'style={{width:"80%",height:'250px',objectFit:"cover",position:'fixed',left:'100px',top:'200px',fontSize:"20px",lineHeight:'36px',color:'green'}}>Proudly present to :
  </span>
  <div style={{width:"80%",objectFit:"cover",position:'fixed',left:'100px',top:'300px'}}> 
  <p className='dancing-script  border-b-blue-800 border-b-4 text-blue-800 text-center studentName'>(Student Name)
  </p>
  
  <p className='montserrat   text-gray-800 certificateDescription mt-3'>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius numquam nostrum aperiam provident cupiditate ut beatae quis eaque ipsa, maiores corrupti impedit necessitatibus cumque! Incidunt perspiciatis consequuntur distinctio nisi aliquam.
  </p>
  <div style={{objectFit:"cover",position:'fixed',left:'100px',top:'490px'}}> 

  {
   certificateInstructorSignature && certificateInstructorSignature.length != 0 &&
  
  <div>
    
  <div className='flex ' >
   <img src={certificateInstructorSignature} alt="signature"/>
  
       </div>
   
  </div>
  }
  {
  certificateInstructorName && certificateInstructorName.length != 0  ?
  <div>
  <div className='flex flex-col items-center' id='instructorNameDesktop'>
  <p className=' mr-5 montserrat' style={{fontWeight:'400',fontSize:'21px'}}>{certificateInstructorName}</p>
  <p className='montserrat mr-5 'style={{fontWeight:'100',fontSize:'15px'}}>Instructor</p>
  
       </div>
   
  </div>
  :
  <div>
  <div className='flex ' id='instructorNameDesktop'>
  <p className='badScript mr-5'>Your Name</p>
  
  
       </div>
  
  
  </div>
  }
  
  </div>
  </div>
      </div>
      </div>
      
  <div className='flex items-center justify-between mt-8' style={{zIndex:"100",width:'90%'}}>
  
  <button onClick={openEditorCertificate} class="  px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
        Back
              </button>
                          <img style={{width:"15%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
  </div>
  
  
  <span className='montserrat py-12 capitalize text-center'style={{fontSize:"40px",marginTop:"-5%",lineHeight:'36px',fontWeight:'800',color:'#025B40'}}>Certificate <br /> <span className='montserrat ' style={{fontSize:'21px',lineHeight:'18px',fontWeight:'300'}}>of <br />Completion</span><br/>
  </span>
  <p className=' text-gray-800 montserrat mx-auto  text-center'style={{fontSize:"15px",width:'50%'}}>This is to certify that</p>
  
  
  <div className='dancing-script px-5 flex justify-center text-blue-800' style={{fontSize:"62px"}}>( Student's Name )</div>
  <div id='certificateDescriptionDesktop'  className='flex items-center'>
  <p className='montserrat  text-center mx-auto ' style={{fontSize:"15px",maxWidth:"80%"}}>{certificateDescription}
  </p>
  
  
  </div>
  
  <div id='certificateDescriptionEditorDesktop'  className='hidden w-full px-6 flex items-center'>
    
  <textarea defaultValue={certificateDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>certificateDescriptionSet(e)}  type="text" class="border  focus:outline-none
                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="5000"
                 placeholder='For completing (COURSE NAME). This course provided comprehensive training in (COURSE CONTENT) .This recipient has acquired valuable skills and knowledge in (SKILLS ACQUIRED)"
                 '></textarea>
                 <p onClick={closeDescriptionEditorDesktop} className='fjalla pl-5 hover:text-red-800 cursor-pointer'>Cancel</p>
  
  </div>
  
  <div className='flex justify-evenly px-5 items-center mt-10  w-full'>
  <QRCode value={window.location.href}  size={100}/>
  
  <div className='borel flex flex-col items-center'>
  {
   certificateInstructorSignature && certificateInstructorSignature.length != 0 &&
  
  <div>
  <div className='flex ' >
   <img src={certificateInstructorSignature} alt="signature"/>
  
       </div>
   
  </div>
   
  
  
  
  
  }
  
  
  {
  certificateInstructorName && certificateInstructorName.length != 0  ?
  <div>
  <div className='flex flex-col items-center' id='instructorNameDesktop'>
  <p className=' mr-5 montserrat' style={{fontWeight:'400',fontSize:'21px'}}>{certificateInstructorName}</p>
  <p className='montserrat mr-5 'style={{fontWeight:'100',fontSize:'15px'}}>Instructor</p>
  
       </div>
   
  </div>
  :
  <div>
  <div className='flex ' id='instructorNameDesktop'>
  <p className='badScript mr-5'>Your Name</p>
  
  
       </div>
  
  
  </div>
  }
  
  </div>
  </div>
  
  </div>
  <div style={{position:'absolute',width:'100%',height:'50px',bottom:'0',left:'0'}}>
  <div style={{width:'90%'}} className='mx-auto flex justify-evenly'>
  <h1 className='text-white fjalla uppercase'>issuance date - {formatDate(certificateIssuanceDate)}</h1>
  <p className='fjalla text-white'>Chekay.com</p>
  <p className='fjalla text-white uppercase'>Certificate Id - {courseId + uid}</p>
  
  
  </div>
  </div>
      </div>
    )
  })
  }
  </div>
  :
  <div id='certificateBody' className='   pb-10  mx-auto  flex flex-col items-center' style={{width:"90%"}}>
{
course.map(i=>{
  const {certificate,courseTitle,certificateInstructorName,createdTime} = i
  return(
    plan != 'Free' ?
    certificate == true ?
  
<table class="w-full text-sm text-left text-gray-500   border mt-5">
<thead class="text-xs text-gray-900 uppercase bg-gray-50 ">
    <tr>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Certificate For
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Instructor
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Creation date
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Action
        </th>
   
 
 

    </tr>
</thead>

<tbody>

<tr class=" bg-white border-b  ">

        <th scope="row" class="capitalize underline fjalla text-center    px-6 py-4 font-medium text-gray-900 whitespace-nowrap " style={{fontSize:"12px"}}>
      {courseTitle}
        </th>
        <td class="capitalize px-6 py-4 fjalla text-center text-gray-500" style={{fontSize:"12px"}}>
          {certificateInstructorName} 
        </td>
        <td class="px-6 py-4 borel text-center text-gray-500" style={{fontSize:"12px"}}>
        {createdTime && convertTimestampToDate(createdTime)}
        
        </td>
        <td class="px-6 py-4 fjalla text-center text-gray-500 flex justify-center" style={{fontSize:"12px"}}>
            {/* {totalEarnings} ETB */}
            <svg onClick={openCertificate}  aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer text-green-800 flex-shrink-0 h-5 w-5   mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
          <svg
          onClick={openPopDeleteDesktop}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="currentColor"
        className=" text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
        viewBox="0 0 16 16"
      >
        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
      </svg>
        </td>
     
    
    </tr>





</tbody>
</table>
    :
    <div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"35%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Certificates are a great way to reward students
  
  
  </h1>
      <p className='borel text-gray-500 text-center'>Students who finish your course will receive certificates of completion with this feature.</p>
      <button onClick={openCertificate} style={{fontSize:'13px'}} class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Create Certificate 
            </button>
    </div>
    :
    
<div className='flex items-center justify-center flex-col '>
<img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"45%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Upgrade your plan if you wish to create certificates for your students


</h1>

</div>
  )
})
}
</div>
}


</div>
  <div id='tablet'  className='relative'>

     
<DashboardNav/>
            {
              deleteMessage == true &&
    <div id="alert-border-6" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 " role="alert">
  <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <div class="ml-3 text-sm font-medium Mooli capitalize">
   Certificate successfully removed 
  </div>
  <button onClick={closeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
            }

    {
      deletePop == true &&
      <div id='deleteCertificateDesktop' className='  flex items-center justify-center'>
     
   
     <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
 <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
     <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
   </svg>
     
</a>
<h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Delete Certificate</h1>
<p class="text-sm text-gray-500 Mooli normal-case capitalize" >Would you like to permanently remove this certificate? </p>
<div class="flex flex-row mt-6 space-x-2 justify-evenly">
 <a  onClick={deleteHandlerDesktop} class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
 <a  onClick={closePopDeleteDesktop}  class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


 </div>
    }
 {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"20px"}}>Certificate successfully created</h1>
            </div>
            </div>
           }
{
  certificateOpen == true ?
  <div id='certificateDesktop' className=' '  >








  {
  course.map(i=>{
    const {certificateTitle,presentedTo,certificate,certificateInstructorSignature,certificateInstructorName,certificateDescription} = i
    return(
      <div>
          <div className='flex items-center mx-5 my-5' >
  
          <div className='' >
  
  <button onClick={closeCertificate} style={{fontSize:'13px'}} class=" fjalla m-auto px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
        Back
              </button>
  
  

     
              </div>
  <div>

  </div>
      
  </div>
  <div className='mx-auto flex flex-col justify-between pb-12 px-4'  id='firstDesktop'>
<div  style={{width:"100%"}} className='relative '>

 <div className='flex w-full  relative  p-6 flex-col  certificate-container border-4 border-gray-800 relative rounded' >
 <div className="certificate-border absolute top-1.5 left-1.5 right-1.5 bottom-1.5 border-l-2 border-r-2 border-b-2 border-gray-800 pointer-events-none"></div>
 <div class="custom-shape-divider-top-1713134948">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>  
 <p className='fjalla pt-5 capitalize text-center text-gray-800' style={{fontSize:'25px'}}>{NewcertificateTitle ? NewcertificateTitle : certificateTitle ? certificateTitle :<div class="h-3 bg-gray-200 rounded-full  w-52  mx-auto"></div>}</p>
<p className='borel pt-8 text-center text-gray-500'style={{fontSize:'12px'}}>{NewpresentedTo ? NewpresentedTo : presentedTo ? presentedTo : <div class="h-2.5 bg-gray-200 rounded-full  w-48  mx-auto -mt-4"></div> }</p>

<p className='borel pt-1 text-center text-blue-900 dancing-script'style={{fontSize:'30px'}}>Student Name</p>
<p className='borel pt-3 text-center text-gray-500'style={{fontSize:'11px'}}>{NewcertificateDescription ? NewcertificateDescription: certificateDescription ? certificateDescription: <div><div class="h-2 bg-gray-200 rounded-full  max-w-[480px] "></div> <br /><div class="h-2 bg-gray-200 rounded-full  max-w-[480px] -mt-1"></div></div>}</p>
<div>
<div className="flex w-full justify-between px-8 items-center mt-8">
<QRCode value={window.location.href}  size={50} />
<img style={{width:"70px",objectFit:'contain'}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 

<div className='flex items-center justify-center flex-col'>

{
  signature ?   <div className=''>
  <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div> : certificateInstructorSignature ? 
<div className=''>
  <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div>
:
<div className='flex items-center justify-center flex-col'>
<div class="flex items-center justify-center h-20 w-20 mb-4 bg-gray-300 rounded ">
        <svg class="w-7 h-7 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
        </svg>
    </div>
</div>
}
<p className='fjalla text-gray-700 text-center'style={{fontSize:'11px'}}>{NewcertificateInstructorName ? NewcertificateInstructorName: certificateInstructorName ? certificateInstructorName:<div class="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>} </p>
</div>



</div>
<div className='flex mt-8 justify-between mt-8'>
<p className='fjalla text-gray-700 text-center'style={{fontSize:'11px'}}>Issuance Date</p>
<p className='borel text-blue-800 text-center normal-case'style={{fontSize:'10px'}}>keshtacademy.com</p>

</div>
</div>
  </div>
 </div>
<div style={{width:"80%"}} className='mx-auto '>
<div className={`${certificateTitleError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>

The title of the certificate must contain between one and 35 characters
</div>
</div>
</div>
<div className={`${presentedToError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>

A certificate's message must contain at least one but not more than 60 characters
</div>
</div>
</div>
<div className={`${certificateInstructorNameError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
The name of the instructor must be between 0 and 20 characters long

</div>
</div>
</div>
<div className={`${certificateDescriptionError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/30/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
The description of the certificate must be between 0 and 280 characters long

</div>
</div>
</div>
<div className={`${signatureError == "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'11px'}}>
Signature Can't be empty
</div>
</div>
</div>
<h1 className='fjalla text-left mb-6 mt-12' style={{fontSize:'30px'}}>
Describe Your Certificate
</h1>


<form  autoComplete="off"   className="wmt-12 mr-0 mb-0 ml-0 relative space-y-8  mx-auto w-full " id="first_modal" >




<div className="relative">

<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Title</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>certificateTitleSet(e)}  required  autoComplete="off" placeholder="Certificate of Completion" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


</div>
<div className="relative">

<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Presented to message</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>presentedToSet(e)}  required  autoComplete="off" placeholder="This Certificate is Proudly presented to" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


</div>
<div className="relative">


<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Instructor (Your Name)</p>
<input  style={{fontSize:'11px',height:'70px'}} onChange={e=>certificateInstructorSet(e)}  required  autoComplete="off" placeholder={fullName} type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 borel pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


</div>
<div className="relative">


<p style={{fontSize:'11px'}} className="fjalla bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-500 absolute">Certificate Description</p>



<textarea defaultValue={certificateDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none',fontSize:'11px'}}  ref={textRef} onChange={e=>certificateDescriptionSet(e)}  type="text" class="border  focus:outline-none
                 focus:border-black borel w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="280"
                 placeholder='For completing (COURSE NAME). This course provided comprehensive training in (COURSE CONTENT) .This recipient has acquired valuable skills and knowledge in (SKILLS ACQUIRED)"
                 '></textarea>
    
</div>
<div className="relative">


<SignatureCollector/>


</div>




<div className="relative" >
<button onClick={(e)=>handleCertificateSave(e)} type='submit' style={{backgroundColor:"#2141ce" ,}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white 
rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">{certificate == true ? 'Save Changes' : 'Create Certificate'}
</button>
<button onClick={()=>window.scrollTo(0, 0)}  style={{}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white  bg-green-700 mt-3
rounded-lg transition duration-200 hover:bg-green-900 ease cursor-pointer fjalla">Preview
</button>
</div>
</form>
</div>
</div>
  
  
  <div id='previewCertificateDesktop' className="hidden w-full relative  flex flex-col items-center mx-auto" >
  <img style={{ position: 'absolute', top: 0, right: 0, widzth: '100%', zIndex: 102 }}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/resume%2Fwave-haikei.svg?alt=media&token=77ef3376-5c07-40d3-909f-b4ec48fc0775" alt="" />
  
  <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 101 }}>
        <div className='bg-blue-800 certificate-container border-4 border-blue-800 p-8 relative' style={{ height: '100%', width: '100%', backgroundColor:'white', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundPosition: 'center' }}>
       
        <div className="certificate-border absolute top-1.5 left-1.5 right-1.5 bottom-1.5 border-2 border-blue-800 pointer-events-none"></div>
       
        <img style={{width:"10%",objectFit:"cover",position:'fixed',left:'80%',top:'40px'}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
  <div className='flex justify-center' style={{width:"250px",height:'250px',objectFit:"cover",position:'fixed',right:'30px',top:'180px'}} >
  <QRCode value={window.location.href}  size={100} />
  </div>
  <span className='bebas py-12 capitalize'style={{width:"250px",height:'250px',objectFit:"cover",position:'fixed',right:'10px',top:'500px',fontSize:"35px",lineHeight:'36px',fontWeight:'200',color:'#025B40'}}>Feb 21, 2024
  </span>
      <span className='bebas py-12 capitalize  text-gray-800'style={{height:'250px',objectFit:"cover",position:'fixed',left:'100px',top:'20px',fontSize:"5.5vw",lineHeight:'5vw',fontWeight:'300'}}>Certificate  <span className='bebas ' style={{fontSize:"5vw",lineHeight:'5vw',fontWeight:'100'}}>of Completion</span><br/>
  </span>
  <span className=' montserrat py-12 capitalize text-center'style={{width:"80%",height:'250px',objectFit:"cover",position:'fixed',left:'100px',top:'200px',fontSize:"20px",lineHeight:'36px',color:'green'}}>Proudly present to :
  </span>
  <div style={{width:"80%",objectFit:"cover",position:'fixed',left:'100px',top:'300px'}}> 
  <p className='dancing-script  border-b-blue-800 border-b-4 text-blue-800 text-center studentName'>(Student Name)
  </p>
  
  <p className='montserrat   text-gray-800 certificateDescription mt-3'>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius numquam nostrum aperiam provident cupiditate ut beatae quis eaque ipsa, maiores corrupti impedit necessitatibus cumque! Incidunt perspiciatis consequuntur distinctio nisi aliquam.
  </p>
  <div style={{objectFit:"cover",position:'fixed',left:'100px',top:'490px'}}> 

  {
   certificateInstructorSignature && certificateInstructorSignature.length != 0 &&
  
  <div>
    
  <div className='flex ' >
   <img src={certificateInstructorSignature} alt="signature"/>
  
       </div>
   
  </div>
  }
  {
  certificateInstructorName && certificateInstructorName.length != 0  ?
  <div>
  <div className='flex flex-col items-center' id='instructorNameDesktop'>
  <p className=' mr-5 montserrat' style={{fontWeight:'400',fontSize:'21px'}}>{certificateInstructorName}</p>
  <p className='montserrat mr-5 'style={{fontWeight:'100',fontSize:'15px'}}>Instructor</p>
  
       </div>
   
  </div>
  :
  <div>
  <div className='flex ' id='instructorNameDesktop'>
  <p className='badScript mr-5'>Your Name</p>
  
  
       </div>
  
  
  </div>
  }
  
  </div>
  </div>
      </div>
      </div>
      
  <div className='flex items-center justify-between mt-8' style={{zIndex:"100",width:'90%'}}>
  
  <button onClick={openEditorCertificate} class="  px-5 py-3 text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
        Back
              </button>
                          <img style={{width:"15%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
  </div>
  
  
  <span className='montserrat py-12 capitalize text-center'style={{fontSize:"40px",marginTop:"-5%",lineHeight:'36px',fontWeight:'800',color:'#025B40'}}>Certificate <br /> <span className='montserrat ' style={{fontSize:'21px',lineHeight:'18px',fontWeight:'300'}}>of <br />Completion</span><br/>
  </span>
  <p className=' text-gray-800 montserrat mx-auto  text-center'style={{fontSize:"15px",width:'50%'}}>This is to certify that</p>
  
  
  <div className='dancing-script px-5 flex justify-center text-blue-800' style={{fontSize:"62px"}}>( Student's Name )</div>
  <div id='certificateDescriptionDesktop'  className='flex items-center'>
  <p className='montserrat  text-center mx-auto ' style={{fontSize:"15px",maxWidth:"80%"}}>{certificateDescription}
  </p>
  
  
  </div>
  
  <div id='certificateDescriptionEditorDesktop'  className='hidden w-full px-6 flex items-center'>
    
  <textarea defaultValue={certificateDescription}  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}  ref={textRef} onChange={e=>certificateDescriptionSet(e)}  type="text" class="border  focus:outline-none
                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="5000"
                 placeholder='For completing (COURSE NAME). This course provided comprehensive training in (COURSE CONTENT) .This recipient has acquired valuable skills and knowledge in (SKILLS ACQUIRED)"
                 '></textarea>
                 <p onClick={closeDescriptionEditorDesktop} className='fjalla pl-5 hover:text-red-800 cursor-pointer'>Cancel</p>
  
  </div>
  
  <div className='flex justify-evenly px-5 items-center mt-10  w-full'>
  <QRCode value={window.location.href}  size={100}/>
  
  <div className='borel flex flex-col items-center'>
  {
   certificateInstructorSignature && certificateInstructorSignature.length != 0 &&
  
  <div>
  <div className='flex ' >
   <img src={certificateInstructorSignature} alt="signature"/>
  
       </div>
   
  </div>
   
  
  
  
  
  }
  
  
  {
  certificateInstructorName && certificateInstructorName.length != 0  ?
  <div>
  <div className='flex flex-col items-center' id='instructorNameDesktop'>
  <p className=' mr-5 montserrat' style={{fontWeight:'400',fontSize:'21px'}}>{certificateInstructorName}</p>
  <p className='montserrat mr-5 'style={{fontWeight:'100',fontSize:'15px'}}>Instructor</p>
  
       </div>
   
  </div>
  :
  <div>
  <div className='flex ' id='instructorNameDesktop'>
  <p className='badScript mr-5'>Your Name</p>
  
  
       </div>
  
  
  </div>
  }
  
  </div>
  </div>
  
  </div>
  <div style={{position:'absolute',width:'100%',height:'50px',bottom:'0',left:'0'}}>
  <div style={{width:'90%'}} className='mx-auto flex justify-evenly'>
  <h1 className='text-white fjalla uppercase'>issuance date - {formatDate(certificateIssuanceDate)}</h1>
  <p className='fjalla text-white'>Chekay.com</p>
  <p className='fjalla text-white uppercase'>Certificate Id - {courseId + uid}</p>
  
  
  </div>
  </div>
      </div>
    )
  })
  }
  </div>
  :
  <div id='certificateBody' className='   pb-10  mx-auto  flex flex-col items-center' style={{width:"90%"}}>
{
course.map(i=>{
  const {certificate,courseTitle,certificateInstructorName,createdTime} = i
  return(
    plan != 'Free' ?
    certificate == true ?
  
<table class="w-full text-sm text-left text-gray-500   border mt-5">
<thead class="text-xs text-gray-900 uppercase bg-gray-50 ">
    <tr>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Certificate For
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Instructor
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Creation date
        </th>
        <th scope="col" class="px-6 py-3 Mooli text-center" style={{fontSize:'11px'}}>
            Action
        </th>
   
 
 

    </tr>
</thead>

<tbody>

<tr class=" bg-white border-b  ">

        <th scope="row" class="capitalize underline fjalla text-center    px-6 py-4 font-medium text-gray-900 whitespace-nowrap " style={{fontSize:"12px"}}>
      {courseTitle}
        </th>
        <td class="capitalize px-6 py-4 fjalla text-center text-gray-500" style={{fontSize:"12px"}}>
          {certificateInstructorName} 
        </td>
        <td class="px-6 py-4 borel text-center text-gray-500" style={{fontSize:"12px"}}>
        {createdTime && convertTimestampToDate(createdTime)}
        
        </td>
        <td class="px-6 py-4 fjalla text-center text-gray-500 flex justify-center" style={{fontSize:"12px"}}>
            {/* {totalEarnings} ETB */}
            <svg onClick={openCertificate}  aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer text-green-800 flex-shrink-0 h-5 w-5   mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
          <svg
          onClick={openPopDeleteDesktop}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="currentColor"
        className=" text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
        viewBox="0 0 16 16"
      >
        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
      </svg>
        </td>
     
    
    </tr>





</tbody>
</table>
    :
    <div className='px-6 flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"35%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Certificates are a great way to reward students
  
  
  </h1>
      <p className='borel text-gray-500 text-center'>Students who finish your course will receive certificates of completion with this feature.</p>
      <button onClick={openCertificate} style={{fontSize:'13px'}} class="fjalla m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Create Certificate 
            </button>
    </div>
    :
    
<div className='flex items-center justify-center flex-col '>
<img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"45%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Upgrade your plan if you wish to create certificates for your students


</h1>

</div>
  )
})
}
</div>
}


</div>

<div id='mobile'  className='relative'>

     
<DashboardNav/>
            {
              deleteMessage == true &&
              <div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between w-full text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
    Certificate successfully removed 
   </div>
   </div>

   <button onClick={closeDeleteMessage} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 "  data-dismiss-target="#alert-border-3" aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg  class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>

   </div> 

            }

    {
      deletePop == true &&
      <div id='deleteCertificateDesktop' className='  flex items-center justify-center'>
     
   
     <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"80%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a  onClick={closePopDeleteDesktop}   class="absolute top-1.5 right-1.5">
 <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
     <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
   </svg>
     
</a>
<h1 class="text-3xl  anton" style={{fontSize:"5vw"}}>Delete Certificate</h1>
<p class="text-sm text-gray-500 Mooli normal-case capitalize" style={{fontSize:"3vw"}}>Would you like to permanently remove this certificate? </p>
<div class="flex flex-row mt-6 space-x-2 justify-evenly">
 <a  onClick={deleteHandlerDesktop} style={{fontSize:"3vw"}}class=" fjalla w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-700 border border-red-600 rounded-lg hover:bg-red-800">Delete</a>
 <a  onClick={closePopDeleteDesktop}  style={{fontSize:"3vw"}}class="w-full fjalla cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


 </div>
    }
 {
            success == true &&
            <div id='successStudentDesktop' > 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded  ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            <div class="w-20 h-20 rounded-full bg-green-400  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"20px"}}>Certificate successfully created</h1>
            </div>
            </div>
           }
{
  certificateOpen == true ?
  <div id='certificateDesktop' className=' '  >








  {
  course.map(i=>{
    const {certificateTitle,presentedTo,certificate,certificateInstructorSignature,certificateInstructorName,certificateDescription} = i
    return(
      <div>
          <div className='flex items-center mx-5 my-5' >
  
          <div className='' >
  
  <button onClick={closeCertificate} style={{fontSize:'2.8vw',height:'12vw',padding:'0 3vw'}} class=" fjalla m-auto  text-red-600 hover:text-red-700 focus:text-red-700 hover:bg-red-100 focus:bg-red-100 border border-red-600 rounded-md mb-3">
        
        Back
              </button>
  
  

     
              </div>
  <div>

  </div>
      
  </div>
  <div className='mx-auto flex flex-col justify-between pb-12 px-4'  id='firstDesktop'>
<div  style={{width:"100%"}} className='relative '>

 <div style={{padding:'4vw'}} className='flex w-full  relative  flex-col  certificate-container border-4 border-gray-800 relative rounded' >
 <div className="certificate-border absolute top-1.5 left-1.5 right-1.5 bottom-1.5 border-l-2 border-r-2 border-b-2 border-gray-800 pointer-events-none"></div>
 <div class="custom-shape-divider-top-1713134948">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>  
 <p className='fjalla pt-5 capitalize text-center text-gray-800' style={{fontSize:'6vw',zIndex:'101',marginTop:'3vw'}}>{NewcertificateTitle ? NewcertificateTitle : certificateTitle ? certificateTitle :<div style={{width:'50%'}} class="h-3 bg-gray-300 rounded-full    mx-auto"></div>}</p>
<p className='borel  text-center text-gray-500'style={{fontSize:'3vw',paddingTop:'4vw'}}>{NewpresentedTo ? NewpresentedTo : presentedTo ? presentedTo : <div style={{width:'45%',marginTop:'3vw'}} class="h-2.5 bg-gray-300 rounded-full    mx-auto -mt-4"></div> }</p>

<p className='borel pt-1 text-center text-blue-900 dancing-script'style={{fontSize:'8vw'}}>Student Name</p>
<p className='borel pt-3 text-center text-gray-500'style={{fontSize:'3vw'}}>{NewcertificateDescription ? NewcertificateDescription: certificateDescription ? certificateDescription: <div><div class="h-2 bg-gray-300 rounded-full  max-w-[480px] "></div> <br /><div class="h-2 bg-gray-300 rounded-full  max-w-[480px] -mt-1"></div></div>}</p>
<div>
<div className="flex w-full justify-evenly  items-center " style={{padding:'6vw 0vw'}}>
<div style={{width:'30%'}}>
<QRCode value={window.location.href}  size={30} />

</div>
<div style={{width:'30%'}}>

<img style={{width:"50px",objectFit:'contain'}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
</div>

<div className='flex items-center justify-center flex-col' style={{width:'30%'}}>

{
  signature ?   <div className=''>
  <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div> : certificateInstructorSignature ? 
<div className=''>
  <img src={signature ?   signature : certificateInstructorSignature } style={{width:'80px'}} alt="" />

</div>
:
<div className='flex items-center justify-center flex-col '>
<div class="flex items-center justify-center  mb-4 bg-gray-300 rounded " style={{width:'10vw',height:'10vw'}}>
    
    </div>
</div>
}
<p className='fjalla text-gray-700 text-center'style={{fontSize:'3vw'}}>{NewcertificateInstructorName ? NewcertificateInstructorName: certificateInstructorName ? certificateInstructorName:<div class="h-2.5 bg-gray-200 rounded-full   w-10 mb-2"></div>} </p>
</div>



</div>
<div className='flex mt-8 justify-between mt-8'>
<p className='fjalla text-gray-700 text-center'style={{fontSize:'11px'}}>Issuance Date</p>
<p className='borel text-blue-800 text-center normal-case'style={{fontSize:'10px'}}>keshtacademy.com</p>

</div>
</div>
  </div>
 </div>
<div style={{width:"100%"}} className='mx-auto '>
<div className={`${certificateTitleError == "error" ? "block":"hidden"}`}>

<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
   The title of the certificate must contain between one and 35 characters

   </div>
   </div>

   </div> 
   </div> 
   
<div className={`${presentedToError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
   A certificate's message must contain at least one but not more than 60 characters

   </div>
   </div>

   </div> 
</div>
<div className={`${certificateInstructorNameError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
   The name of the instructor must be between 0 and 20 characters long

   </div>
   </div>

   </div> 
</div>

<div className={`${certificateDescriptionError == "error" ? "block":"hidden"}`}>

<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
   The description of the certificate must be between 0 and 280 characters long

   </div>
   </div>

   </div> 
</div>
<div className={`${signatureError == "error" ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%',marginRight:'2vw'}} className="flex items-center justify-between text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
     <div className='flex justify-center items-center'>
   <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
   <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
   </svg>
   <div className='Mooli' style={{fontSize:'2.8vw'}}>
   Signature Can't be empty

   </div>
   </div>

   </div> 

</div>
<h1 className='fjalla text-left ' style={{fontSize:'6vw',padding:'8vw',}}>
Describe Your Certificate
</h1>


<div  autoComplete="off"   className=" mr-0 mb-0 ml-0 relative space-y-8  mx-auto w-full "  >


<div className="relative m-auto " style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Certificate Title</p>

           <input defaultValue={certificateTitle} type='text'  placeholder="Certificate of Completion" className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md borel w-full" style={{width:"100%",height:'15vw',paddingLeft:"4vw",fontSize:"2.7vw"}}   onChange={e=>certificateTitleSet(e)}/>
         
   </div>

   <div className="relative m-auto w-full" style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Presented to message</p>

           <input defaultValue={presentedTo} type='text'  placeholder="This Certificate is Proudly presented to" className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md borel" style={{width:"100%",height:'15vw',paddingLeft:"4vw",fontSize:"2.7vw"}}   onChange={e=>presentedToSet(e)}/>
         
   </div>
   <div className="relative m-auto w-full" style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Certificate Instructor (Your Name)</p>

           <input type='text'  defaultValue={certificateInstructorName} placeholder={fullName}  className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md borel" style={{width:"100%",height:'15vw',paddingLeft:"4vw",fontSize:"2.7vw"}}   onChange={e=>certificateInstructorSet(e)}/>
         
   </div>

   <div className="relative m-auto w-full" style={{width:"100%",marginBottom:"10vw",marginTop:"4vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute fjalla"style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Certificate Description</p>

           <textarea defaultValue={certificateDescription}
  style={{
    padding: '3vw 3.5vw',
    fontSize: '2.8vw',
    minHeight: '50vw',
    maxHeight: '80vw',
  }}
  ref={textRef}
  onChange={e=>certificateDescriptionSet(e)}

  className="border text-gray-600 focus:outline-none focus:border-black w-full text-base block bg-white border-gray-300 rounded-md"
  maxLength={1000}
  placeholder='For completing (COURSE NAME). During this course, this recipient acquired valuable skills and knowledge in (COURSE CONTENT).
'
></textarea>
   </div>


<div className="relative">


<SignatureCollector/>


</div>




<div className="relative" >
<button onClick={(e)=>handleCertificateSave(e)} type='submit' style={{backgroundColor:"#2141ce" ,}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white 
rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer fjalla">{certificate == true ? 'Save Changes' : 'Create Certificate'}
</button>
<button onClick={()=>window.scrollTo(0, 0)}  style={{}}  className="w-full flex justify-center items-center pt-3 pr-5 pb-3 pl-5  text-center text-white  bg-green-700 mt-3
rounded-lg transition duration-200 hover:bg-green-900 ease cursor-pointer fjalla">Preview
</button>
</div>
</div>
</div>
</div>
  
  

  <div style={{position:'absolute',width:'100%',height:'50px',bottom:'0',left:'0'}}>
  <div style={{width:'90%'}} className='mx-auto flex justify-evenly'>
  <h1 className='text-white fjalla uppercase'>issuance date - {formatDate(certificateIssuanceDate)}</h1>
  <p className='fjalla text-white'>Chekay.com</p>
  <p className='fjalla text-white uppercase'>Certificate Id - {courseId + uid}</p>
  
  
  </div>
  </div>
      </div>
    )
  })
  }
  </div>
  :
  <div id='certificateBody' className='   pb-10  mx-auto  flex flex-col items-center' style={{width:"95%"}}>
{
course.map(i=>{
  const {certificate,courseTitle,certificateInstructorName,createdTime} = i
  return(
    plan != 'Free' ?
    certificate == true ?
  
<table class="w-full text-sm text-left text-gray-500   border mt-5">
<thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
    <tr>
        <th scope="col" class=" py-3 fjalla text-center" style={{fontSize:'2.5vw'}}>
            Certificate For
        </th>
        <th scope="col" class=" py-3 fjalla text-center" style={{fontSize:'2.5vw'}}>
            Instructor
        </th>
        <th scope="col" class=" py-3 fjalla text-center" style={{fontSize:'2.5vw'}}>
            Creation date
        </th>
        <th scope="col" class=" py-3 fjalla text-center" style={{fontSize:'2.5vw'}}>
            Action
        </th>
   
 
 

    </tr>
</thead>

<tbody>

<tr class=" bg-white border-b  ">

        <th scope="row" class="capitalize underline borel text-center     py-4 font-medium text-gray-900 whitespace-nowrap " style={{fontSize:"2.6vw"}}>
      {courseTitle}
        </th>
        <td class="capitalize  py-4 borel text-center text-gray-500" style={{fontSize:"2.6vw"}}>
          {certificateInstructorName} 
        </td>
        <td class=" py-4 borel text-center text-gray-500" style={{fontSize:"2.6vw"}}>
        {createdTime && convertTimestampToDate(createdTime)}
        
        </td>
        <td class=" py-4 borel text-center text-gray-500 flex justify-center" style={{fontSize:"2.6vw"}}>
            {/* {totalEarnings} ETB */}
            <svg onClick={openCertificate} 
      style={{width:'4vw'}}
      aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer text-green-800 flex-shrink-0 h-5 w-5   mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
          <svg
          onClick={openPopDeleteDesktop}
        xmlns="http://www.w3.org/2000/svg"
      style={{width:'4vw'}}
        fill="currentColor"
        className=" text-red-500 text-gray-600 cursor-pointer bi bi-trash3-fill"
        viewBox="0 0 16 16"
      >
        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
      </svg>
        </td>
     
    
    </tr>





</tbody>
</table>
    :
    <div className=' flex items-center justify-center flex-col '>
    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"35%"}}/>
      <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"5vw"}}>Certificates are a great way to reward students
  
  
  </h1>
      <p className='borel text-gray-500 text-center' style={{fontSize:'2.8vw'}}>Students who finish your course will receive certificates of completion with this feature.</p>
      <button onClick={openCertificate} style={{fontSize:'2.8vw',height:'12vw',padding:'0 2vw'}} class="fjalla m-auto  mt-4  text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
          
      Create Certificate 
            </button>
    </div>
    :
    
<div className='flex items-center justify-center flex-col '>
<img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Certification-bro.svg?alt=media&token=80843307-ae74-4221-8587-381b0b816f34" alt="Certificate" style={{width:"45%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8' style={{fontSize:"30px"}}>Upgrade your plan if you wish to create certificates for your students


</h1>

</div>
  )
})
}
</div>
}


</div>





</div>
</div>
:
course.map(i=>{
  const {courseTitle,certificateTitle,presentedTo,issuanceDate,certificate,certificateId,certificateInstructorSignature,certificateInstructorName,certificateDescription} = i

  return(
    tookCertificate == true ?
<div>
  <div id='desktop'>
  {
            success == true &&
            <div id='successStudentDesktop' style={{zIndex:101}}> 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
<QRCode value={window.location.href}  size={70} />

            <div class="mt-12 w-20 h-20 rounded-full bg-green-500  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"35px",padding:0}}>This is the official certificate of Kesht Academy. </h1>
      <p   className="fjalla"
                    type="small">This certificate indicates that {certificateName ? certificateName:'the student'} has completed {courseTitle}</p>
            </div>
            </div>
           }
  <div className=" css-1g1qdt1">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  
                  <div className=" css-wkfzqz">
                  <div className="css-1c8o6vr" 
                  style={{background:'#1B1B1B',marginBottom:'15px'}}
                  >
                        <p
                          className="oswald"
                          style={{fontSize:'15px',textAlign:'center',margin:0,padding:0,width:'100%',color:'white'}}
                          
                        >
                              
                         Kesht Academy ™
                        </p>
                        </div>
                    <div className=" css-2fgig6">
                      <div className="flex">
               
            
              
          
             
             <div className=" css-1c8o6vr " style={{minWidth:'600px'}}>
             <div className=" css-vlx4w9 " >
              <div className=" css-12y5ho8" />
              {
               
                <div className=' w-full'>
                   <div >
                    <h1 className='anton capitalize'
                           style={{textAlign:'center',width:'100%',fontSize:'35px'}}
                           >{certificateTitle}</h1>
              <p
                           className="young text-gray-600 mb-6"
                           style={{textAlign:'center',width:'100%',fontSize:'13px'}}
                           
                           
                         >
                           {presentedTo}
                         </p>
                         <h5
                           className="oswald text-center css-vlx4w9 "
                           style={{back:'black',textAlign:'center',width:'100%',margin:0,padding:0,fontSize:'35px',display:'flex',justifyContent:'center',alignItems:'center'}}
                         >
                          {certificateName}
                         </h5>
                         
                  
            
              </div>
              <div className=" css-gl4o9m flex-col">
             <p   
                           style={{textAlign:'center',width:'100%',margin:0,padding:0,wordBreak:'break-all'}}
                           className="MuiTypography-root MuiTypography-body1 md css-xpxqog text-center"
                    type="small">
                      {certificateDescription}</p>
                      
              <div className=" css-1kvsmvo">
                <div className="flex items-center css-1x6lh4h">
                  <img
                    alt="greenTick-icon"
                    loading="lazy"
                    width={12}
                    height={12}
                    decoding="async"
                    data-nimg={1}
                    src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                    style={{ color: "transparent" }}
                  />
                  <p
                    className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                    type="small"
                  >
                    {certificateIssuanceDate && formatDate(certificateIssuanceDate)}
                  </p>
                </div>
           
            
              </div>
        <div className="flex justify-between w-full items-center">
        {
                 loading == true ?
                  <button style={{fontSize:'13px',width:'100px',display:'flex',justifyContent:'center'}} type="button" className="cursor-not-allowed oswald flex justify-center items-center text-center mainBodyHomePage-Btn ">
                   <div class="relative">
                 <div class="w-5 h-5 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-5 h-5 border-white border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 </div> 
                 </button>
                 :
                 <button onClick={checkCertificate} style={{fontSize:'13px',width:'100px',display:'flex',justifyContent:'center'}} type="button" className="oswald flex justify-center items-center text-center mainBodyHomePage-Btn ">
                 Verify 
                </button>
              }
                           <div className='borel flex flex-col items-center '>
{
 certificateInstructorSignature && certificateInstructorSignature.length != 0 &&

<div>
<div className='flex' >
 <img src={certificateInstructorSignature} alt="signature" style={{width:'150px'}}/>

     </div>
 
</div>
 




}


{
certificateInstructorName && certificateInstructorName.length != 0  ?
<div>
<div className='flex flex-col items-center' id='instructorNameDesktop'>
<p className=' mr-5 oswald' style={{fontWeight:'400',fontSize:'18px',color:'black',margin:0,padding:0}}>{certificateInstructorName}</p>
<p className='oswald mr-5 'style={{fontWeight:'100',fontSize:'12px',color:'black',margin:0,padding:0}}>Instructor</p>

     </div>
 
</div>
:
<div>
<div className='flex ' id='instructorNameDesktop'>
<p className='badScript mr-5'>Your Name</p>


     </div>


</div>
}

</div>
        </div>
             
             </div>
                </div>

              }
             
             </div>
         
             
             </div>
            
          
                      </div>
                      <div className='flex justify-evenly  items-center   w-full'>
{/* <img style={{width:"15%",objectFit:"cover"}}  src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" />  */}



</div>

                    </div>
                  </div>
                  <div className=" css-1oru8ap">
                    <svg
                      className="css-129n9a0"
                      width={'100%'}
                      style={{marginTop:'-10'}}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>

</div>
<div id='mobile'>
  {
            success == true &&
            <div id='successStudentDesktop' style={{zIndex:101}}> 
            <div className='relative bg-white m-auto flex flex-col items-center justify-center  rounded my-5 ' id='freePlanDesktop' style={{width:"90%",height:"98%"}}>
            <svg onClick={minimizeSuccessDesktop} xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
<QRCode value={window.location.href}  size={70} />

            <div class="mt-12 w-20 h-20 rounded-full bg-green-500  p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg aria-hidden="true" class="w-10 h-10 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="sr-only">Success</span>
                  </div>
      <h1 className='oswald text-center text-gray-900 mt-2' style={{fontSize:"35px",padding:0}}>This is the official certificate of Kesht Academy. </h1>
      <p   className="fjalla"
                    type="small">This certificate indicates that {certificateName ? certificateName:'the student'} has completed {courseTitle}</p>
            </div>
            </div>
           }
  <div className=" css-1g1qdt1">
                    <svg
                      className="css-1fllxtj"
                      width={1620}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z" />
                    </svg>
                  </div>
                  
                  <div className=" css-wkfzqz">
                  <div className="css-1c8o6vr" 
                  style={{background:'#1B1B1B',marginBottom:'15px'}}
                  >
                        <p
                          className="oswald"
                          style={{fontSize:'15px',textAlign:'center',margin:0,padding:0,width:'100%',color:'white'}}
                          
                        >
                              
                         Kesht Academy ™
                        </p>
                        </div>
                    <div className=" css-2fgig6">
                      <div className="flex">
               
            
              
          
             
             <div className=" css-1c8o6vr " style={{minWidth:'90%',padding:0}}>
             <div className=" css-vlx4w9 "style={{paddingLeft:0,paddingRight:0}} >
              <div className=" css-12y5ho8" />
              {
               
                <div className=' w-full'>
                   <div >
                    <h1 className='anton capitalize'
                           style={{textAlign:'center',width:'100%',fontSize:'8vw',lineHeight:'8.5vw'}}
                           >{certificateTitle}</h1>
              <p
                           className="young text-gray-600 "
                           style={{textAlign:'center',width:'100%',fontSize:'2.8vw',marginTop:'3vw',marginBottom:'5vw'}}
                           
                           
                         >
                           {presentedTo}
                         </p>
                         <h5
                           className="oswald text-center css-vlx4w9 "
                           style={{back:'black',textAlign:'center',width:'100%',margin:0,padding:0,fontSize:'10vw',display:'flex',justifyContent:'center',alignItems:'center'}}
                         >
                          {certificateName}
                         </h5>
                         
                  
            
              </div>
              <div className=" css-gl4o9m flex-col">
             <p   
                           style={{textAlign:'center',width:'100%',margin:0,padding:0,wordBreak:'break-all',fontSize:'3vw'}}
                           className="MuiTypography-root MuiTypography-body1 md css-xpxqog text-center"
                    type="small">
                      {certificateDescription}</p>
                      
              <div className=" css-1kvsmvo">
                <div className="flex items-center css-1x6lh4h">
                  <img
                    alt="greenTick-icon"
                    loading="lazy"
                    width={12}
                    height={12}
                    decoding="async"
                    data-nimg={1}
                    src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                    style={{ color: "transparent" }}
                  />
                  <p
                    className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                    type="small"
                  >
                    {certificateIssuanceDate && formatDate(certificateIssuanceDate)}
                  </p>
                </div>
           
            
              </div>
        <div className="flex justify-between w-full items-center">
        {
                 loading == true ?
                  <button style={{fontSize:'3vw',width:'20vw',display:'flex',justifyContent:'center'}} type="button" className="cursor-not-allowed oswald flex justify-center items-center text-center mainBodyHomePage-Btn ">
                   <div class="relative">
                 <div class="w-5 h-5 border-blue-200 border-2 rounded-full"></div>
                 <div class="w-5 h-5 border-white border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
                 </div> 
                 </button>
                 :
                 <button onClick={checkCertificate} style={{fontSize:'3vw',color:'white',width:'20vw',display:'flex',justifyContent:'center'}} type="button" className="oswald flex justify-center items-center text-center mainBodyHomePage-Btn ">
                 Verify 
                </button>
              }
                           <div className='borel flex flex-col items-center '>
{
 certificateInstructorSignature && certificateInstructorSignature.length != 0 &&

<div>
<div className='flex' >
 <img src={certificateInstructorSignature} alt="signature" style={{width:'150px'}}/>

     </div>
 
</div>
 




}


{
certificateInstructorName && certificateInstructorName.length != 0  ?
<div>
<div className='flex flex-col items-center' id='instructorNameDesktop'>
<p className=' mr-5 oswald' style={{fontWeight:'400',fontSize:'18px',color:'black',margin:0,padding:0}}>{certificateInstructorName}</p>
<p className='oswald mr-5 'style={{fontWeight:'100',fontSize:'12px',color:'black',margin:0,padding:0}}>Instructor</p>

     </div>
 
</div>
:
<div>
<div className='flex ' id='instructorNameDesktop'>
<p className='badScript mr-5'>Your Name</p>


     </div>


</div>
}

</div>
        </div>
             
             </div>
                </div>

              }
             
             </div>
         
             
             </div>
            
          
                      </div>
                      <div className='flex justify-evenly  items-center   w-full'>
{/* <img style={{width:"15%",objectFit:"cover"}}  src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" />  */}



</div>

                    </div>
                  </div>
                  <div className=" css-1oru8ap">
                    <svg
                      className="css-129n9a0"
                      width={'100%'}
                      style={{marginTop:'-10'}}
                      height={128}
                      viewBox="0 0 1620 128"
                      xmlns="http://www.w3.org/2000/svg"
                      fill='#1B1B1B'
                    >
                      <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z" />
                    </svg>
                  </div>

</div>

</div>
:
navigate(`/course/${courseId}`)

  )
})

)


  
}
